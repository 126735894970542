import React from "react"

const GlobalContext = React.createContext({
  chatOpen: false,
  showIPOBanner: false,
  showSubMenu: false,
  openGetStarted: () => { },
  openHubspotCalendar: () => { },
  closeHubspotCalendar: () => { },
  toggleChat: () => { },
  toggleIPOBanner: () => { },
  toggleSubMenu: () => { },
})

export default GlobalContext
