import React, { useRef, useEffect } from "react"
import Helmet from "react-helmet"

import SEO from "../components/seo"

export default function MeetingScheduledPage() {
  const modalRef = useRef()

  useEffect(() => {
    modalRef.current.open(null, null, null, null, true)
  }, [])

  return (
    <>
      <Helmet
        meta={[
          {
            name: "robots",
            content: "noindex",
          },
        ]}
      />
      <SEO title="Meeting scheduled" ogImage={require("/src/assets/images/og/og-startpage-min.png").default} />
    </>
  )
}
