import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import LogoFooter from "../assets/images/regily-white-logo.svg"

import GlobalContext from "../context/global-context"

const Logo = styled(Link)`
  width: 230px;
  height: 28px;
  text-indent: -9999px;
  background: url(${LogoFooter}) no-repeat center/contain;
`

const Container = styled.div`
  background: #000;
  grid-area: footer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  padding: 25px 30px;
  z-index: 2100;

  ${props =>
    props.chatOpen &&
    css`
      z-index: initial;
    `}

  @media screen and (max-width: 980px) {
    flex-direction: column;
    border-bottom: 2px solid #000;
  }

  @media screen and (max-width: 6400px) {

    padding: 25px 0px;
  }

`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1380px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const FooterBottom = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`

const Address = styled.p`
  font-size: 12px;
  color: #bdbcc1;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 640px) {
    flex-direction: column;

    span {
      display: none;
    }
  }

  a {
    color: #fff;
    margin-left: 4px;
    color: #bdbcc1;

    &:first-child {
      margin-left: 0;
      margin-right: 4px;
      white-space: nowrap;
      @media screen and (max-width: 640px) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    &:hover {
      color: #fff;
    }
  }
`

const EmailLink = styled.a`
  color: #fff;
  font-size: 12px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`
const FooterBody = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 60px;
  a {
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 12px;
  }

  @media screen and (max-width: 640px) {
    width: 94%;
     a {
       text-align: center;
     }
  }
  
`
const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100% ;
  a {
    line-height: 20px;
  }
`
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #929292;
  @media screen and (max-width: 640px) {
    text-align: center;
  }
`

const Footer = ({ className, location }) => {
  const globalContext = useContext(GlobalContext)
  const baseUrl = "https://checkin.com/"
  const FooterArray = [
    [
      {
        title: "Product",
      },
      {
        title: "Checkin Software",
        link: baseUrl
      },
      {
        title: "ID Scanner",
        link: baseUrl + "id-scanner/"
      },
      {
        title: "Checkin Connect",
        link: baseUrl + "connect/"
      },
    ],
    [
      {
        title: "Industries",
      },

      {
        title: "iGaming",
        link: baseUrl + "industry-igaming"
      },
      {
        title: "Travel",
        link: baseUrl + "industry-travel"
      },
      {
        title: "Finance",
        link: baseUrl + "industry-finance"
      },
    ],
    [
      {
        title: "Checkin.com",
      },
      {
        title: "About us",
        link: baseUrl + "about-us"
      },
      {
        title: "Contact",
        link: baseUrl + "about-us"
      },
      {
        title: "Blog",
        link: baseUrl + "blog"
      },
    ],
    [
      {
        title: "Resources",
      },
      {
        title: "Supported documents",
        link: baseUrl + "id-scanner/documents"
      },
      {
        title: "Documentation",
        link: "https://dev.checkin.com"
      },
      {
        title: "Privacy policy",
        link: baseUrl + "privacy-policy"
      },
    ]
  ]

  return (
    <Container className={className} chatOpen={globalContext.chatOpen}>
      <Wrapper>
        <FooterBottom>
          <LogoContainer>
            <Logo to="/" />
          </LogoContainer>
          <Address>
            <a href={"https://group.checkin.com"}>
              Checkin.com Group AB, Engelbrektsplan 2, 114 34 Stockholm
            </a>
            {location.pathname.includes("about-us") && (
              <>
                {" "}
                <span>|</span>{" "}
                <a target="__blank" href="/files">
                  Documents
                </a>
              </>
            )}
          </Address>
        </FooterBottom>
      </Wrapper>
    </Container>
  )
}

export default Footer
