import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"

import { pushCustomDataLayerEvent } from "../../state/actions"
import useCounter from "../../hooks/use-counter"

const Container = styled.div`
  margin-top: 40px;
  position: relative;
`

const Table = styled.table`
  border-collapse: collapse;
  font-weight: 400;

  tr {
    font-size: 24px;
    line-height: 32px;

    @media screen and (max-width: 640px) {
      font-size: 15px;
      line-height: 20px;
    }

    th {
      background: #f0f0f0;
      width: 429px;
      font-weight: 400;
      height: 92px;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      position: relative;
      border-top: none;

      @media screen and (max-width: 1024px) {
        width: 300px;
        height: 92px;
      }

      @media screen and (max-width: 640px) {
        width: 137px;
        height: 82px;
      }

      span {
        position: relative;
        z-index: 10;
      }

      &:first-child {
        width: 145px;
        border-left: none;
        border-right: none;

        @media screen and (max-width: 1024px) {
          width: 100px;
        }

        @media screen and (max-width: 640px) {
          width: 60px;
        }
      }
    }

    &:first-child {
      th:first-child {
        background: transparent;
        border: none;
      }
      th:nth-child(2) {
        border-left: none;
        border-bottom: none;
      }
      th:nth-child(3) {
        border-right: none;
        border-bottom: none;
      }

      @media screen and (max-width: 1024px) {
        th {
          font-size: 20px;
        }
      }

      @media screen and (max-width: 640px) {
        th {
          font-size: 15px;
        }
      }
    }

    &:last-of-type {
      th,
      td {
        border-bottom: none;
      }
    }

    td {
      width: 429px;
      height: 92px;
      text-align: center;
      border: 1px solid #f0f0f0;
      box-sizing: border-box;
      position: relative;
      background: #fff;

      @media screen and (max-width: 1024px) {
        width: 300px;
        height: 92px;
      }

      @media screen and (max-width: 640px) {
        width: 137px;
        height: 82px;
      }

      span {
        position: relative;
        z-index: 10;
      }

      &:last-child {
        border-right: none;
      }
    }

    span {
      transition: all 0.5s ease;
    }
  }
`

const CalcRow = styled.tr`
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      th span {
        font-weight: 700;
      }

      td span {
        font-weight: 700;
      }
    `}
`

const Selector = styled.div`
  position: absolute;
  width: calc(100% + 20px);
  height: 92px;
  background: #b0f1d4;
  border: 1px solid #040404;
  box-sizing: border-box;
  top: 92px;
  transform: ${props => `translate3d(0, ${props.selectedRow * 92}px, 0)`};
  left: -10px;
  transition: all 0.5s ease;
  box-shadow: 3px 4px 0 0 rgba(0, 0, 0, 0.09);

  @media screen and (max-width: 640px) {
    height: 82px;
    top: 82px;
    transform: ${props => `translate3d(0, ${props.selectedRow * 82}px, 0)`};
  }
`

const CounterSpan = ({ data }) => {
  const dataCounter = useCounter(data)

  return <>{dataCounter.toLocaleString()}</>
}

export default function ResultsTable({
  values = [],
  onChange = () => {},
  initialSelectedRow = 0,
}) {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState(initialSelectedRow)

  useEffect(() => {
    onChange(values[selectedRow])
  }, [values, selectedRow, onChange])

  return (
    <Container>
      <Table>
        <tbody>
          <tr>
            <th></th>
            <th>
              Additional <br /> sign-ups / month
            </th>
            <th>
              Customer LTV <br />
              uplift / month
            </th>
          </tr>
          {values.map((row, index) => (
            <CalcRow
              key={index}
              onClick={() => {
                setSelectedRow(index)
                dispatch(
                  pushCustomDataLayerEvent({
                    event: "funnel_engagement",
                    eventCategory: "calculator",
                    eventAction: "interaction",
                    eventLabel: `table_click_${values[index].uplift}`,
                  })
                )

                dispatch(
                  pushCustomDataLayerEvent({
                    event: "funnel_engagement",
                    eventCategory: "calculator",
                    eventAction: "interaction",
                    eventLabel: `table_click`,
                  })
                )
              }}
              selected={selectedRow === index}
            >
              <th>
                <span>{row.uplift}%</span>
              </th>
              <td>
                <span>
                  <CounterSpan data={row.additionalSingups} />
                </span>
              </td>
              <td>
                <span>
                  €<CounterSpan data={row.aggregatedUplift} />
                </span>
              </td>
            </CalcRow>
          ))}
        </tbody>
      </Table>
      <Selector selectedRow={selectedRow}>
        {/* {values.map((row, index) => (
          <SelectorTooltip isVisible={selectedRow === index} key={index}>
            <span>{row.selectorTooltip}</span>
          </SelectorTooltip>
        ))} */}
      </Selector>
    </Container>
  )
}
