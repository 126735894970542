/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "normalize.css"
import "nouislider"
import Cookies from "js-cookie"
import cuid from "cuid"
import getUrlParam from "./src/utils/get-url-param"
import smoothscroll from "smoothscroll-polyfill"
import axios from "axios"
import serviceURL from "./src/utils/service-url"


import "./src/utils/fonts.css"
import "./node_modules/nouislider/distribute/nouislider.min.css"
require("imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap")

smoothscroll.polyfill()

window.hsConversationsSettings = {
  loadImmediately: false,
  inlineEmbedSelector: "#hs-chat-widget-container",
}


!window.regilyEventCallback &&
  Object.defineProperty(window, "regilyEventCallback", {
    value: function (regilyEventCallbackData) {
      return Promise.resolve({})
    },
  })

!window.regilyUpdateCallback &&
  Object.defineProperty(window, "regilyUpdateCallback", {
    value: function (data, dataDiff) {
      return Promise.resolve({})
    },
  })

!window.regilyDoneCallback &&
  Object.defineProperty(window, "regilyDoneCallback", {
    value: function (regilyDoneCallbackData) {
      return Promise.resolve({})
    },
  })

window.addEventListener(
  "touchmove",
  e => {
    e = e.originalEvent || e
    if (e.scale > 1 || e.scale < 1) {
      e.preventDefault()
    }
  },
  { passive: false }
)

export { default as wrapPageElement } from "./src/state/wrapper"
