import React, { useState, useContext } from "react"
import styled, { css } from "styled-components"
import { navigate } from "gatsby"
import closeIcon from "../../assets/images/close-icon.svg"
import GreenCircle from "../../components/green-circle"

import SEO from "../../components/seo"

import GlobalContext from "../../context/global-context"

import {
  H2,
  PageContainer,
} from "../../components/common"

const CustomPageContainer = styled(PageContainer)`
  width: 945px !important;

  ${H2}{
    text-align: center;
  }

  @media screen and (max-width: 980px) {
    width: 614px !important;
  }

  @media screen and (max-width: 640px) {
    width: 345px !important;
  }
`

const CloseButton = styled.button`
  outline: none;
  box-shadow: none;
  background: url(${closeIcon}) no-repeat center, transparent;
  border: none;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 40px;
  top: 34px;
  height: 16px;
  width: 21px;
  cursor: pointer;
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  background: #fff;
  width: 750px;
  margin: 0px auto 0 auto;
  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    font-size: 18px;
    margin: 32px auto 0 auto;
  }

  @media screen and (max-width: 640px) {
    flex-direction: row;
    font-size: 18px;
    width: 99%;
  }
`
const DisclaimerContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f8f8f7;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Disclaimer = styled.div`
  width: 946px;
  height: 861px;
  background: #fff;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 40px;
  box-sizing: border-box;
  position: relative;

  ${H2} {
    margin-bottom: 34px;

    @media screen and (max-width: 980px) {

    margin-top: 20px;
    }

  }

  @media screen and (max-width: 980px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 640px) {
    padding: 60px 0px;
  }

  table, th, td {
    border: 1px solid #D3D3D3;
    border-collapse: collapse;
  }

  table {
    width: 100%;
  }

  th {
    background: #F0F0F0;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    height: 54px;
  }

  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    padding-left: 20px;
    height: 54px;
  }
`

const DisclaimerButtonsContainer = styled.div`
  display: flex;
  margin-top: 70px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }

  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }
`

const DisclaimerButton = styled.button`
  width: 215px;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid #000;
  outline: none;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  margin: 0 22px;
  cursor: pointer;
  background: #fff;
  font-size: 15px;
  font-weight: 700;

  &:hover {
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 1);
  }

  @media screen and (max-width: 640px) {
    margin: 10px 0;
  }
`

const Tab = styled.div`
  width: auto;
  overflow: hidden;
  font-size: 16px;
  height: 73px;
  border-bottom: 1px solid #C8C8C7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 60px;
  @media screen and (max-width: 640px) {
    padding-left: 20px;
    padding-right: 10px;
  }
  ${props =>
    props.header &&
    css`
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
      height: 73px;
      justify-content: center;
    `}

  ${props =>
    props.green &&
    css`
      background: rgba(176, 241, 212, 0.2);
      border-right: 1px solid #C8C8C7;
      border-left: 1px solid #C8C8C7;
      align-items: center;
      justify-content: center;
      padding-left: 0px;
      `}

`

const OverflowContainer = styled.div`
  overflow-y: auto;
`

const InHouse = styled.div`
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #C8C8C7;
  border-left: 1px solid #C8C8C7;
  

  & ${Tab}:first-child {
    background: #F0F0F0;
    display: flex;
    align-items: center;
    padding-left: 0px;
  }

`

const EndToEnd = styled.div`
  width: 100%;
  overflow: hidden;

  & ${Tab}:first-child {
    background: #B0F1D4;
    display: flex;
    align-items: center;
    padding-left: 0px;
    border: 1px solid #040404;
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.0853365);
  }

  & ${Tab}:nth-child(2) {
    background: #B0F1D4;
    display: flex;
    align-items: center;
    padding-left: 0px;
    border: 1px solid #040404;
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.0853365);
  }

  @media screen and (max-width: 640px) {
    width:47%;
  }
`

const MobileTab = styled(Tab)`
display:none !important;
@media screen and (max-width: 640px) {
  display: flex !important;
  position: absolute;
  -ms-flex-align: center;
  right: 0px;
  z-index: 11111111;
  width: 100%;
}
`

export default function Features({ location }) {
  const [termsAccepted, setTemrsAccepted] = useState(false)
  const globalContext = useContext(GlobalContext)
  const country = new URLSearchParams(location.search).get("country") || 'Canada';

  const arrayContent = {
    Canada: [
      "User data",
      "Job details",
      "KYC: Document and credit file",
      "PEP/HIO check",
      "Spending, time and balance limits",
      "Geo-location check",
      "Document & ID scanning",
      "Biometric/liveness check",
      "2-FA authentication"
    ],
    Netherlands: [
      "User data",
      "BSN number",
      "Spending, time and balance limits",
      "CRUKS check",
      "Bank authentication",
      "PEP checks",
      "Document & ID scanning",
      "Biometric/liveness check",
      "2-FA authentication"
    ]
  }

  return (
    <>
      <SEO
        title="All KYC solutions for iGaming customer onboarding"
        description="All the technical solutions required for user onboarding with high conversion and full compliance when operating iGaming brands in 190+ markets."
        canonical="/igaming/features/"
        ogImage={require("/src/assets/images/og/og-igaming-lp-min.png").default}
      />

      {!termsAccepted && (
        <>

          <CustomPageContainer>
            <DisclaimerContainer>
              <Disclaimer>
                <CloseButton
                  onClick={() => {
                    navigate("/industry-igaming")
                  }}
                />
                <H2>Features required for {country}</H2>

                <OverflowContainer>
                  <TabsContainer>

                    <InHouse>
                      <Tab header >Requirements</Tab>
                      {arrayContent[country].map((tab, index) => (
                        <Tab key={index} >{tab}</Tab>
                      ))}
                    </InHouse>
                    <EndToEnd>
                      <MobileTab header> Checkin.com support </MobileTab>
                      <Tab header> Checkin.com support </Tab>

                      {arrayContent[country].map((element, index) => (
                        <Tab key={index} green >
                          <GreenCircle
                            index={index}
                          />
                        </Tab>
                      ))}
                    </EndToEnd>
                  </TabsContainer>
                </OverflowContainer>

                <DisclaimerButtonsContainer>
                  <DisclaimerButton
                    style={{ background: "#FFD63E" }}
                    onClick={() => globalContext.openGetStarted()}
                  >
                    Talk to sales
                  </DisclaimerButton>
                </DisclaimerButtonsContainer>
              </Disclaimer>
            </DisclaimerContainer>
          </CustomPageContainer>
        </>
      )}
    </>
  )
}
