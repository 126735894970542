import React, { useState, useContext, useEffect, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import GreenCircle from "../../components/green-circle"
import ActionButton from "../../components/action-button"
import SEO from "../../components/seo"
import PartnerQuotes from "../../components/netherland/partner-quotes"


import StepIcon1 from "../../assets/images/product/step-1.inline.svg"
import StepIcon2 from "../../assets/images/product/step-2.inline.svg"
import StepIcon3 from "../../assets/images/product/step-3-new.inline.svg"
import Data from "../../assets/images/netherland/data.inline.svg"
import DataThumb from "../../assets/images/netherland/datathumb.inline.svg"
import Pep from "../../assets/images/netherland/pep.inline.svg"
import PepThumb from "../../assets/images/netherland/pepthumb.inline.svg"
import Mobile from "../../assets/images/netherland/mobile.inline.svg"
import MobileThumb from "../../assets/images/netherland/mobilethumb.inline.svg"
import GlobalContext from "../../context/global-context"
import loadable from '@loadable/component'
const IdScanner = loadable(() => import('../../components/netherland/id-scanner'))

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const CustomGreenCircle = styled(GreenCircle)`
  opacity: 0;
  &::after {
    content: none
  }
  &.FadeIn{
    animation: ${FadeIn} 1s ease forwards 1s;
    ${props =>
    props && props.index + 1 && `
          animation-delay: ${props.index * 0.2}s;
          `
  }
  }
`

const Image = styled(Img)`
  ${props =>
    props.isSpaced &&
    css`
      margin: 60px 0;
    `}

    ${props =>
    props.isSpacedBottom &&
    css`
        margin: 0 0 60px;
      `}

    ${props =>
    props.isSpacedTop &&
    css`
        margin: 60px 0 0;
      `}
`

const ImageDesktop = styled(Image)`
  @media screen and (max-width: 980px) {
    display: none !important;
  }

  ${props =>
    props.left &&
    css`
        margin-left: 100px;
      `}

  ${props =>
    props.right &&
    css`
        margin-right: 100px;
      `}

`

const ImageTablet = styled(Image)`
  left: -50px !important;
  @media screen and (max-width: 640px) {
    display: none !important;
  }
  @media screen and (min-width: 981px) {
    display: none !important;
  }
`

const ImageMobile = styled(Image)`
  @media screen and (min-width: 641px) {
    display: none !important;
  }
`

const H1 = styled.h1`
  font-size: 48px;
  line-height: 56px;
  font-weight: 900;
  margin-bottom: 16px;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media screen and (max-width: 640px) {
    font-size: 34px;
    line-height: 44px;
    width: 320px;
  }
`

const H2 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;

  br {
    display: block;
  }

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;
    br {
      display: none;
    }
  }
`
const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`

const CTAText = styled(H4)`
  text-align: center;
  br {
    display: none;
  }

  @media screen and (max-width: 980px) {
    width: 389px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
    br {
      display: block;
    }
  }
`

const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
`

const SectionDescription1 = styled(SectionDescription)`
  width: 679px;

  @media screen and (max-width: 980px) {
    width: 578px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const SectionDescription4 = styled(SectionDescription)`
  width: 650px;

  @media screen and (max-width: 980px) {
    width: 460px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const Container = styled.div`
  flex: 1;
  width: 1170px;
  align-self: center;
  padding: 135px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 980px) {
    padding: 40px 0 0;
    width: 640px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
    padding: 50px 0 0;
  }
`

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;

  &:last-child {
    margin-bottom: 80px;
  }
`

const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`

const FeaturesContainer = styled.div`
  width: 1064px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    width: 523px;
  }

  @media screen and (max-width: 640px) {
    width: 296px;
  }
`


const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 330px;
  z-index: 1;

  p {
    font-size: 20px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    width: 280px;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`

const StepsContainer = styled.div`
  width: 895px;
  display: flex;
  justify-content: space-between;
  margin: 66px 0;
  position: relative;
  // border-bottom: 2px solid rgba(0, 0, 0, 0.04);


  @media screen and (max-width: 980px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const CustomStepsContainer = styled(StepsContainer)`
border-bottom: 2px solid rgba(0, 0, 0, 0.04);

@media screen and (max-width: 980px) {
  display: flex;
}

@media screen and (max-width: 640px) {
  width: 610px;
  margin-bottom: 0px;
}
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 240px;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid #000000;
    `}

  ${H4} {
    margin: 26px 0 10px;
    font-size: 20px;
    line-height: 30px;
  }

  ${Text} {
    line-height: 24px;
  }

  @media screen and (max-width: 980px) {
    &:last-child {
      grid-column: span 2;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    margin-top: 0;
    margin-bottom: 20px;
    grid-column: 1;
  }
`

const CustomStep = styled(Step)`
${H4} {
  font-size: 18px;
  line-height: 24px;
}

img {
  width: 69px;
  height: 69px;
}

@media screen and (max-width: 980px) {

  ${H4} {
    font-size: 14px;
    line-height: 22px;
    width: 174px;
  }

  &:last-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 640px) {
  width: 188px;
  margin-bottom:0px;
  ${H4} {
    font-size: 14px;
    line-height: 22px;
  }
}
`
const Mobilecontainer = styled.div`
@media screen and (max-width: 640px) {
  overflow-x: auto;
    width: 371px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
}
`

const OverflowContainer = styled.div`
  overflow-y: auto;
  margin-bottom: 60px;
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  background: #fff;
  width: 750px;
  margin: 63px auto 0 auto;
  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    font-size: 18px;
    margin: 32px auto 0 auto;
  }

  @media screen and (max-width: 640px) {
    flex-direction: row;
    font-size: 18px;
    width: 320px;
  }
`
const Tab = styled.div`
  width: auto;
  overflow: hidden;
  font-size: 16px;
  height: 73px;
  border-bottom: 1px solid #C8C8C7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 60px;
  @media screen and (max-width: 640px) {
    padding-left: 20px;
    padding-right: 10px;
  }
  ${props =>
    props.header &&
    css`
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
      height: 73px;
      justify-content: center;
    `}

  ${props =>
    props.green &&
    css`
      background: rgba(176, 241, 212, 0.2);
      border-right: 1px solid #C8C8C7;
      border-left: 1px solid #C8C8C7;
      align-items: center;
      justify-content: center;
      padding-left: 0px;
      `}

`

const InHouse = styled.div`
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #C8C8C7;
  border-left: 1px solid #C8C8C7;
  

  & ${Tab}:first-child {
    background: #F0F0F0;
    display: flex;
    align-items: center;
    padding-left: 0px;
  }

`

const EndToEnd = styled.div`
  width: 100%;
  overflow: hidden;

  & ${Tab}:first-child {
    background: #B0F1D4;
    display: flex;
    align-items: center;
    padding-left: 0px;
    border: 1px solid #040404;
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.0853365);
  }

  & ${Tab}:nth-child(2) {
    background: #B0F1D4;
    display: flex;
    align-items: center;
    padding-left: 0px;
    border: 1px solid #040404;
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.0853365);
  }

  @media screen and (max-width: 640px) {
    width:47%;
  }
`

const ImageComtainer = styled.div`
  width: 40px;
  height: 40px;
`

const StepContent = styled.div`
width: 800px;
height: 320px;
display: flex;
justify-content: space-between;
margin-bottom: 66px;
// border-bottom: 2px solid rgba(0, 0, 0, 0.04);

@media screen and (max-width: 980px) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  justify-items: flex-start;
}

@media screen and (max-width: 640px) {
  grid-template-columns: 1fr;
  margin-top: 25px;
  height: auto;
}

`

const List = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
@media screen and (max-width: 640px) {
  ul {
    margin-left: 16px;
  }
}

li {
  font-size: 16px;
  line-height: 32px;
  padding-left: 10px;
}
}

`

const ListImage = styled.div`
  height: 70px;
  min-height: 70px;
  display: block;
  
  @media screen and (max-width: 980px) {
    display: none;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
`

const MobileTab = styled(Tab)`
display:none !important;
@media screen and (max-width: 640px) {
  display: flex !important;
  position: absolute;
  -ms-flex-align: center;
  right: 0px;
  z-index: 11111111;
  width: 100%;
}
`

const LastStep = styled(Step)`
  ${H4} {
    margin-top: 2px;
  }
`

const Line = styled.hr`
  position: absolute;
  border-top: 1px dashed #000000;
  width: 702px;
  margin-left: 95px;
  top: 23px;

  @media screen and (max-width: 980px) {
    display: none;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
`

const IconContainer = styled.div`
  position: relative;

  svg {
    background-color: #F9F9F8;
    width: 75px;
  }
`

const LastIconContainer = styled.div`
  position: relative;
  bottom: 15px;

  svg {
    background-color: #F9F9F8;
    width: 75px;
  }
`

export default function ProductPage({ data }) {
  const globalContext = useContext(GlobalContext)
  const [activestep, setactivestep] = useState(0)
  const [fadeIn2, setFadeIn2] = useState(false)


  // start useIntersect 
  const ref = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setFadeIn2(entry.isIntersecting);
          observer.unobserve(ref.current);
        }
      }, { rootMargin: '-10px' }
    );

    ref && observer.observe(ref.current);

    return () => observer.unobserve(ref.current);
  }, []);

  // end useIntersect
  const stepsContent = [
    {
      list: [
        'CRUKS check (returns unique CRUKS code)',
        'IBAN account holder verification',
        'Authentication (e.g. bank login or selfie, liveness check)',
        'Image of a valid ID document',
        'PEP check (Sanctions lists and politically exposed persons screening)',
      ],
      image: PepThumb,
    },
    {

      list: [
        'First name',
        'Last name',
        'Date of birth',
        'Address of residence',
        'Type, number, date, and place of issue of a valid identity document',
        'BSN number (NL residents) or Place of birth (non-NL residents)',
        'IBAN',
        'Daily, monthly, and weekly deposit limits',
        'Maximum account balance limit',
        'Daily, monthly, and weekly time limits',
      ],
      image: DataThumb,

    },
    {
      list: [
        '2-factor authentication',
        'CRUKS code check',
      ],
      image: MobileThumb,

    },
  ]

  const Icon = (props) => {

    const TheIcon = stepsContent[activestep].image
    return <TheIcon {...props} />
  }

  return (
    <Container>
      <SEO
        title="Full solution for iGaming KYC onboarding in the Netherlands"
        description="Get the go-to solution for launching iGaming products with compliant and top-performing signup, identity and login in the Netherlands."
        canonical="/industry-igaming/netherlands/"
        ogImage={require("/src/assets/images/og/og-igaming-nl-lp-min.png").default}
      />
      <Section>
        <Sectionheader primary>
          <H1>Take your iGaming brand to
            <br /> the Netherlands with ease</H1>
          <SectionDescription1>
            Checkin.com is the go-to solution for launching fast and easy with 100% compliant signup, identity and login in the Netherlands.
          </SectionDescription1>
        </Sectionheader>

        <ImageDesktop isSpaced fixed={data.allInOne.desktop.fixed} />
        <ImageTablet isSpaced fixed={data.allInOne.tablet.fixed} />
        <ImageMobile isSpaced fixed={data.allInOneMobile.mobile.fixed} />

        <FeaturesContainer>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Access every required solution from a single point</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Optimal signup, identity & login out of the box</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p> Flexible integration through JS, SDK or API</p>
          </Feature>
        </FeaturesContainer>
        <CTAText>
          Go live in record speed with Checkin.com
        </CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>

      <Section id="requirements" >
        <Sectionheader>
          <H2>Requirements for gambling operators</H2>
          <SectionDescription4>
            To register new players in the Netherlands, specific features in your signup and login flow are required to satisfy regulatory requirements.
          </SectionDescription4>
        </Sectionheader>
        <Mobilecontainer>
          <CustomStepsContainer>
            <CustomStep active={activestep === 0} onClick={() => setactivestep(0)}>
              <Pep />
              <H4>User verifications required during sign-up</H4>
            </CustomStep>
            <CustomStep active={activestep === 1} onClick={() => setactivestep(1)}>
              <Data />
              <H4>User data required in order to sign up</H4>
            </CustomStep>
            <CustomStep active={activestep === 2} onClick={() => setactivestep(2)}>
              <Mobile />
              <H4>Login <br /> requirements</H4>
            </CustomStep>
          </CustomStepsContainer>
        </Mobilecontainer>

        <StepContent>
          <List>
            <ul>
              {stepsContent[activestep].list.map((listElement, index) => (
                <li key={index}>{(listElement)}</li>
              ))}
            </ul>
          </List>
          <ListImage>
            <Icon />
          </ListImage>
        </StepContent>

        <CTAText>Get every solution out of the box</CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>



      <Section id="go-live-fast" >
        <Sectionheader primary>
          <H2>Go live faster with Checkin.com</H2>
          <SectionDescription1>
            Checkin.com is the go-to solution for operators who wish to launch in the Netherlands fast and easy without the hassle of building
            and maintaining all required solutions in-house.
          </SectionDescription1>
        </Sectionheader>
        <PartnerQuotes />
        <OverflowContainer>
          <TabsContainer
            ref={ref}
          >
            <InHouse>
              <Tab header >Requirements</Tab>
              <Tab >User data</Tab>
              <Tab >BSN number</Tab>
              <Tab >Spending, time and balance limits</Tab>
              <Tab >CRUKS check</Tab>
              <Tab >Bank authentication</Tab>
              <Tab ><a href="/product/aml-pep-sanction-checks/">PEP checks</a></Tab>
              <Tab ><a href="/id-scanner/">Document & ID scanning</a></Tab>
              <Tab ><a href="/id-scanner/">Biometric/liveness check</a></Tab>
              <Tab >2-FA authentication</Tab>
            </InHouse>
            <EndToEnd>
              <MobileTab header> Checkin.com support </MobileTab>
              <Tab header> Checkin.com support </Tab>

              {[...Array(9)].map((element, index) => (
                <Tab key={index} green >
                  <CustomGreenCircle
                    index={index}
                    className={!!fadeIn2 ? 'FadeIn' : null}
                  />
                </Tab>
              ))}
            </EndToEnd>
          </TabsContainer>
        </OverflowContainer>


        <CTAText>Go live in record speed with Checkin.com</CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>

      <Section id="ocr" >

        <IdScanner />

      </Section>

      <Section id="custom-demo">
        <Sectionheader>
          <H2>Get a custom demo for your brand</H2>
          <SectionDescription4>
            Our product is developed to be fast and easy for you to access. We configure and customize the entire integration to fit into your existing architecture and minimize the work required on your end.
          </SectionDescription4>
        </Sectionheader>

        <StepsContainer>
          <Line />
          <Step>
            <IconContainer><StepIcon1 /></IconContainer>
            <H4>Talk to an expert</H4>
            <Text>
              Reach out to us so we can prepare a custom proposal based on your brand, data to collect and prerequisites.
            </Text>
          </Step>
          <Step>
            <IconContainer><StepIcon2 /></IconContainer>
            <H4>Receive a custom demo</H4>
            <Text>
              See exactly how your solution will look and feel in your site or app before agreeing on commercials.
            </Text>
          </Step>
          <LastStep>
            <LastIconContainer><StepIcon3 /></LastIconContainer>
            <H4>Launch with ease</H4>
            <Text>
              We prepare everything for you
              and once your partner key is active, you can integrate easily through front-end JS, SDK, or API.
            </Text>
          </LastStep>
        </StepsContainer>

        <CTAText>See the solution in <br /> your app or site</CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>

    </Container >
  )
}

export const pageQuery = graphql`
  query {
  allInOne: file(relativePath: { eq: "netherland/Illustration.png" }) {
    desktop: childImageSharp {
      fixed(height: 343, width: 1081) {
          ...GatsbyImageSharpFixed_tracedSVG
      }
    }

    tablet: childImageSharp {
      fixed(height: 285, width: 899) {
          ...GatsbyImageSharpFixed_tracedSVG
      }
    }
  }

  allInOneMobile: file(
    relativePath: { eq: "netherland/Illustration.png" }
  ) {
    mobile: childImageSharp {
      fixed(height: 153, width: 402) {
          ...GatsbyImageSharpFixed_tracedSVG
      }
    }
  }

  endToEnd: file(relativePath: { eq: "product/end_to_end.png" }) {
    desktop: childImageSharp {
      fixed(height: 198, width: 164) {
          ...GatsbyImageSharpFixed_tracedSVG
      }
    }
  }

  endToEndMobile: file(
    relativePath: { eq: "product/mobile/end_to_end.png" }
  ) {
    tablet: childImageSharp {
      fixed(height: 163, width: 135) {
          ...GatsbyImageSharpFixed_tracedSVG
      }
    }
  }
}
`
