import styled from "styled-components"
import LayoutGrid from "../layout-grid"

const Grid = styled(LayoutGrid)`
  grid-template-columns: auto repeat(12, 64px) auto;
  grid-gap: 32px;
  row-gap: 24px;
  padding-top: 85px;
  z-index:1;


  @media screen and (max-width: 980px) {
    grid-template-columns: auto repeat(8, 68px) auto;
    grid-gap: 20px;
    overflow: hidden;
    padding-top: 50px;

  }

  @media screen and (max-width: 640px) {
    grid-template-columns: auto 160px 160px auto;
    grid-gap: 16px 24px;
    overflow: hidden;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: auto 171px 172px auto;
    grid-column-gap: 0px;
    grid-row-gap: 8px;
    overflow: hidden;
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: auto 132px 132px auto;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    overflow: hidden;
  }

  grid-template-areas:
    ". . . switcher switcher switcher switcher switcher switcher switcher switcher . . ."
    ". . . header header header header header header header header . . ."
    "header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation"
    ". . . . . . . . . . . . . ."
    ". . bento-header bento-header bento-header bento-header bento-header bento-header bento-header bento-header bento-header bento-header . ."
    ". bento bento bento bento bento bento bento bento bento bento bento bento ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". video video video video video video video video video video video video ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". verify verify verify verify verify verify verify verify verify verify verify verify ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". seamless seamless seamless seamless seamless seamless seamless seamless seamless seamless seamless seamless ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". faq faq faq faq faq faq faq faq faq faq faq faq ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales . "
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ;

  @media screen and (max-width: 980px) {
    grid-template-areas:
    ". switcher switcher switcher switcher switcher switcher switcher switcher ."
    ". header header header header header header header header ."
    "header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation"
    ". . . . . . . . . ."
    ". . bento-header bento-header bento-header bento-header bento-header bento-header . ."
    ". bento bento bento bento bento bento bento bento ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". video video video video video video video video ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". verify verify verify verify verify verify verify verify ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". seamless seamless seamless seamless seamless seamless seamless seamless ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". faq faq faq faq faq faq faq faq ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales talk-to-sales . "
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ;
  }

  @media screen and (max-width: 640px) {
    grid-template-areas:
    "switcher switcher switcher switcher "
    "header header header header "
    "header-animation header-animation header-animation header-animation "
    ". . . ."
    ". bento-header bento-header ."
    ". bento bento ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". enterprise enterprise ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". video video ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". verify verify ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". seamless seamless ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". faq faq ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". talk-to-sales talk-to-sales . "
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ;
  }

  @media screen and (max-width: 375px) {
    grid-template-areas:
    ". switcher switcher ."
    ". header header ."
    "header-animation header-animation header-animation header-animation "
    ". . . ."
    ". bento-header bento-header ."
    ". bento bento ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". enterprise enterprise ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". video video ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". verify verify ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". faq faq ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". talk-to-sales talk-to-sales . "
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ;
  }
`

export default Grid
