import React, { useContext } from "react";
import styled from "styled-components";
import GlobalContext from "../../context/global-context"



const Container = styled.div`
grid-area: partnership;
display: flex;

@media screen and (max-width : 980px){
    flex-direction: column;
}

p {
color: #4A4A4A;
font-family: "Object Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; 
}

`
const Column = styled.div`
flex: 1; 
margin: 15px;
display: inline-flex;
padding: 32px 36px 155.132px 32px;
flex-direction: column;
align-items: center;
border: 1px solid  #0D0D0D;
background: #FDFFFB;
box-shadow: 3.289px 3.289px 0px 0px rgba(13, 13, 13, 0.10);
position: relative;

@media screen and (max-width: 640px){
    margin: 0px;
}

button {
    color: #0D0D0D;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    border: 1px solid #0D0D0D;
    background: #fff;
    box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.10);
    width: 260px;
    height: 55px;
    position: absolute;
    bottom: 40px;
    opacity: 0;
    cursor: pointer;

    &:hover {
        box-shadow: 5px 5px 0 0 #000;
    }

}
&:hover {
    box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.10);
    transition: 0.4s;
    background: #FBD65D;
    button {
        opacity: 1;
        transition: 0.4s;
    }
}
`
const ColumnHeader = styled.h2`
color: #0D0D0D;
font-family: "Object Sans";
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 36px; 
letter-spacing: -0.56px;
margin-bottom: 10px;
`


const Gateway = ({ modalRef }) => {
    const globalContext = useContext(GlobalContext)

    return (
        <Container>
            <Column>
                <ColumnHeader>Technology Partnerships</ColumnHeader>
                <p>Offer Checkin.com's identity and signup solutions to enhance your product's value. Ideal for software and tech companies looking to offer additional benefits to their customers and earn commissions.</p>
                <button className="hs-cta-trigger-button hs-cta-trigger-button-162913511979" >Let's talk!</button>
            </Column>
            <Column>
                <ColumnHeader>Reseller Partnerships</ColumnHeader>
                <p>Sell our solutions directly to your customers, fitting seamlessly with your existing offerings. Enjoy competitive pricing and improved margins by partnering with us.</p>
                <button className="hs-cta-trigger-button hs-cta-trigger-button-162913511979" >Let's talk!</button>

            </Column>
            <Column>
                <ColumnHeader>Referral Partnerships</ColumnHeader>
                <p>Recommend Checkin.com and earn a commission for every successful referral. Ideal for consultants and businesses with a network in industries that require robust KYC solutions.</p>
                <button className="hs-cta-trigger-button hs-cta-trigger-button-162913511979" >Let's talk!</button>

            </Column>

        </Container>
    );
}

export default Gateway; 