import React, { useEffect } from "react"
import { Provider } from "react-redux"

import store from "./store"
import { silentAuth } from "../utils/auth"

const SessionCheck = ({ children }) => {
  useEffect(() => {
    silentAuth(() => { })
  }, [])

  return <>{children}</>
}

const Wrapper = ({ element }) => (
  <Provider store={store}>
    <SessionCheck>{element}</SessionCheck>
  </Provider>
)

export default Wrapper
