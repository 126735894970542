import axios from "axios"
import Cookies from "js-cookie"
import _get from "lodash.get"
import { deviceType } from "react-device-detect"
import serviceURL from "../utils/service-url"
import { navigate } from "gatsby"
import {
  format,
  startOfWeek,
  endOfWeek,
  addWeeks,
  addMinutes,
  isWeekend,
  addDays,
  eachDayOfInterval,
} from "date-fns"

const DETECT_DEVICE = "DETECT_DEVICE"
const SET_WEBSITE = "SET_WEBSITE"
const SET_REFRESH_DURING_SCANNING = "SET_REFRESH_DURING_SCANNING"
const SET_HEADERS = "SET_HEADERS"
const SET_SCANNING_STATUS = "SET_SCANNING_STATUS"
const SET_SCANNING_RESULTS = "SET_SCANNING_RESULTS"
const SET_SCANNING_TOKEN = "SET_SCANNING_TOKEN"
const SET_SCANNING_PROGRESS = "SET_SCANNING_PROGRESS"
const SET_SCANNING_ILLUSTRATION = "SET_SCANNING_ILLUSTRATION"
const RESET_SCANNING_STATE = "RESET_SCANNING_STATE"
const SET_BETA_EMAIL = "SET_BETA_EMAIL"
const SET_BETA_FIRSTNAME = "SET_BETA_FIRSTNAME"
const SET_BETA_USERS_LIMIT = "SET_BETA_USERS_LIMIT"
const SET_BETA_SHEET_RANGE = "SET_BETA_SHEET_RANGE"
const RESET_BETA = "RESET_BETA"
const SET_PUBLISHING_DATA = "SET_PUBLISHING_DATA"
const SET_CUSTOMISATION_KEYS = "SET_CUSTOMISATION_KEYS"
const RESET_GLOBAL_STATE = "RESET_GLOBAL_STATE"
const SET_WINDOW_HEIGHT = "SET_WINDOW_HEIGHT"
const SET_SITE_ID = "SET_SITE_ID"
const SET_FORCE_WP_FLOW = "SET_FORCE_WP_FLOW"
const SET_REMOTE_TOOL_SITE = "SET_REMOTE_TOOL_SITE"
const SET_REMOTE_TOOL_RESULTS = "SET_REMOTE_TOOL_RESULTS"
const SET_REMOTE_TOOL_ERROR = "SET_REMOTE_TOOL_ERROR"
const SET_REMOTE_TOOL_FETCHING = "SET_REMOTE_TOOL_FETCHING"
const SET_REMOTE_TOOL_HYDRATING = "SET_REMOTE_TOOL_HYDRATING"
const SET_REMOTE_TOOL_AFFILIATES = "SET_REMOTE_TOOL_AFFILIATES"
const SET_REMOTE_TOOL_NEW_AFFILIATE = "SET_REMOTE_TOOL_NEW_AFFILIATE"
const UPDATE_REMOTE_TOOL = "UPDATE_REMOTE_TOOL"
const RESET_REMOTE_TOOL = "RESET_REMOTE_TOOL"
const UPDATE_CALENDAR = "UPDATE_CALENDAR"
const RESET_CALENDAR = "RESET_CALENDAR"
const UPDATE_COMPANY_TRACKING = "UPDATE_COMPANY_TRACKING"
const RESET_COMPANY_TRACKING = "RESET_COMPANY_TRACKING"
const UPDATE_LABS_LOGIN = "UPDATE_LABS_LOGIN"

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const setForceWPFlow = () => ({
  type: SET_FORCE_WP_FLOW,
})

const detectDevice = () => ({
  type: DETECT_DEVICE,
})

const setWebsite = value => (dispatch, getState) => {
  const website = getState().global.website
  if (!website && !!value) dispatch(pushDataLayerEvent("init_2_start"))

  if (value.includes("wpflow:")) {
    dispatch(setForceWPFlow())
  }

  dispatch({
    type: SET_WEBSITE,
    value: value.replace(/@/g, "").replace("wpflow:", ""),
  })
}

const setRefreshDuringScanning = value => ({
  type: SET_REFRESH_DURING_SCANNING,
  value,
})

const setHeaders = value => ({
  type: SET_HEADERS,
  value,
})

const setScanningToken = token => ({
  type: SET_SCANNING_TOKEN,
  token,
})

const setScanningStatus = status => ({
  type: SET_SCANNING_STATUS,
  status,
})

const setScanningProgress = progress => ({
  type: SET_SCANNING_PROGRESS,
  progress,
})

const setScanningIllustration = illustration => ({
  type: SET_SCANNING_ILLUSTRATION,
  illustration,
})

const setScanningResults = results => ({
  type: SET_SCANNING_RESULTS,
  results,
})

const resetScanningState = () => ({
  type: RESET_SCANNING_STATE,
})

const setBetaSheetRange = range => ({
  type: SET_BETA_SHEET_RANGE,
  range,
})

const resetBeta = () => ({
  type: RESET_BETA,
})

const setSiteId = id => ({
  type: SET_SITE_ID,
  id,
})

const updateLabsLogin = data => ({
  type: UPDATE_LABS_LOGIN,
  data
})

const saveSite = (url = null) => async (dispatch, getState) => {
  try {
    const state = getState()
    const response = await axios.post(
      `${serviceURL().customizationAPI}/sites`,
      {
        url: url || state.global.website,
        settings: {},
      },
      {
        headers: {
          Authorization: "",
        },
      }
    )

    dispatch(setSiteId(response.data.id))
  } catch { }
}

const updateSite = data => async (dispatch, getState) => {
  try {
    const state = getState()
    if (!state.global.siteId) {
      setTimeout(() => {
        dispatch(updateSite(data))
      }, 2000)
    }

    await axios.patch(
      `${serviceURL().customizationAPI}/sites/${state.global.siteId}`,
      {
        ...data,
        url: data.url || state.global.website,
        email: data.email || state.beta.email,
        above5000: data.above5000 || state.beta.usersAboveLimit,
        wordpress:
          data.wordpress ||
          _get(state, "scanning.results.IsWordPress.wordpress.isWP", false),
        cuid: data.cuid || Cookies.get("gtag-id"),
      }
    )
  } catch (error) { }
}

const updateTracking = data => async (dispatch, getState) => {
  try {
    await axios.post(`${serviceURL().metadata}/statistics`, {
      type: "site",
      sessionId: Cookies.get("gtag-id") || null,
      cuid: Cookies.get("gtag-id") || null,
      device: deviceType || "desktop",
      source: ["www.regily.com", "regily.com"].includes(
        window.location.hostname
      )
        ? "regily.com"
        : null,
      action: data.action,
    })
  } catch (error) { }
}

const updateSheet = (rowValues = []) => async (dispatch, getState) => {
  try {
    const state = getState()
    const sheetResponse = await axios.post(`${serviceURL().regilyAPI}/sheets`, {
      spreadsheetId: "15wDFlmbCMPnsXR9C3UIGp26s4MVdwfnpNworJTHV2Uc",
      range: getState().beta.sheetRange || null,
      values: [
        [
          rowValues[0] || null,
          rowValues[1] || state.global.website || null,
          rowValues[2] || state.beta.email || null,
          rowValues[3] ||
          (state.beta.usersAboveLimit === null
            ? null
            : state.beta.usersAboveLimit
              ? "yes"
              : "no"),
          rowValues[4] ||
          (_get(
            state,
            "scanning.results.IsWordPress.wordpress.isWP",
            null
          ) === null
            ? null
            : _get(state, "scanning.results.IsWordPress.wordpress.isWP")
              ? "yes"
              : "no"),
          rowValues[5] || Cookies.get("gtag-id") || null,
          rowValues[6] ||
            ["www.regily.com", "regily.com"].includes(window.location.hostname)
            ? "regily.com"
            : "" || null,
          rowValues[7] || null,
          rowValues[8] || null,
          rowValues[9] || null,
        ],
      ],
    })

    if (!state.beta.sheetRange)
      dispatch(
        setBetaSheetRange(
          sheetResponse.data.data.updates.updatedRange.split(":")[0]
        )
      )
  } catch (error) {
    console.error(error)
  }
}

const checkScanningProgress = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${serviceURL().scanner}/progress?token=${getState().scanning.token}`
    )

    const endResult =
      response.data.events &&
      response.data.events.filter(
        event => event.type && event.type === "ExecutionSucceeded"
      )

    const failedEvent =
      response.data.events &&
      response.data.events.filter(
        event => event.type && event.type === "ExecutionFailed"
      )

    if (failedEvent && failedEvent.length > 0) {
      throw new Error()
    }

    const newResults =
      response.data.events &&
      response.data.events.reduce((accumulator, currentValue) => {
        if (!currentValue.stateExitedEventDetails) return accumulator
        else {
          accumulator[currentValue.stateExitedEventDetails.name] = JSON.parse(
            currentValue.stateExitedEventDetails.output
          )
          return accumulator
        }
      }, {})

    if (getState().scanning.status) {
      dispatch(setScanningResults(newResults))
      if (getState().beta.sheetRange) dispatch(updateSheet())
    }

    if (endResult && endResult.length > 0) {
      dispatch(updateTracking("scanning_completed"))
      dispatch(setScanningStatus("succeeded"))
      return
    }

    await sleep(1000)

    if (getState().scanning.status) dispatch(checkScanningProgress())
  } catch (error) {
    dispatch(setScanningStatus("failed"))
  }
}

const startScanning = () => async (dispatch, getState) => {
  try {
    dispatch(updateTracking({ event: "scanning_started" }))
    dispatch(setScanningStatus("running"))
    const response = await axios.post(`${serviceURL().scanner}/execution`, {
      url: getState().global.website,
    })
    dispatch(setScanningToken(response.data.token))
    dispatch(checkScanningProgress())

    const date = new Date()

    dispatch(saveSite())

    dispatch(
      updateSheet([
        `${date.getUTCDate()}/${date.getUTCMonth() +
        1} ${date.getUTCHours()}:${date.getUTCMinutes()}`,
        null,
        null,
        null,
        null,
        null,
        null,
        "No",
        "No",
        deviceType || "desktop",
      ])
    )

    dispatch(
      trackBehaviorInfo({
        scannedWebsite: true,
        scannedWebsites: [
          ..._get(getState(), "companyTracking.behavior.scannedWebsites", []),
          getState().global.website,
        ],
      })
    )
  } catch (error) {
    dispatch(setScanningStatus("failed"))
  }
}

const setBetaEmail = email => (dispatch, getState) => {
  const currentEmail = getState().beta.email
  const usersAboveLimit = getState().beta.usersAboveLimit

  if (!currentEmail && !!email)
    dispatch(pushDataLayerEvent("reg_1_start", usersAboveLimit ? null : "3b"))

  dispatch({
    type: SET_BETA_EMAIL,
    email,
  })

  dispatch(
    trackBehaviorInfo({
      enteredEmail: true,
    })
  )
}

const setBetaFirstname = firstname => ({
  type: SET_BETA_FIRSTNAME,
  firstname,
})

const setBetaUsersLimit = above => dispatch => {
  dispatch(pushDataLayerEvent(above ? "qfy_1_over_5000" : "qfy_2_under_5000"))

  dispatch({
    type: SET_BETA_USERS_LIMIT,
    above,
  })
}

const retrieveScanData = () => async (dispatch, getState) => {
  dispatch(setScanningStatus("running-retrieve"))
  const tokenResponse = await axios.get(
    `${serviceURL().scanner}/token?url=${getState().global.website}`
  )
  dispatch(saveSite())
  dispatch(setScanningToken(tokenResponse.data.Token))
  await dispatch(checkScanningProgress())
}

const startPublishing = () => async (dispatch, getState) => {
  try {
    if (!_get(getState().scanning, "token", null)) {
      dispatch(setScanningStatus("running"))
      const tokenResponse = await axios.get(
        `${serviceURL().scanner}/token?url=${getState().global.website}`
      )
      dispatch(setScanningToken(tokenResponse.data.Token))
      await dispatch(checkScanningProgress())
    }

    const response = await axios.get(
      `//${getState().global.hostname}/?rest_route=/flowbrain/v1/scan`
    )
    const data = response.data
    dispatch(setPublishingData(data))

    const forms = _get(data, "forms", [])

    /**
     * send customisation data
     */
    const customisationData = {
      name: _get(
        getState().scanning,
        "results.DomainLookup.domain.name",
        getState().global.partner
      ),
      projects: forms
        .map(form => ({
          siteId: getState().global.siteId,
          name: form.title || "",
          fieldsToCollect: form.fieldsToCollect || {},
          copy: form.copy || null,
          theme: _get(getState().scanning, "results.Theme.theme") || {},
          status: "draft",
          options: {
            wordpress: {
              callback: {
                email: {
                  address: getState().beta.email,
                  enabled: true,
                },
                database: {
                  enabled: true,
                },
              },
            },
          },
        }))
        .filter(form => !!form.name),
    }

    const customisationResponse = await axios.post(
      `${serviceURL().customizationAPI}/customizations`,
      customisationData
    )

    const customisationKeys = _get(
      customisationResponse,
      "data.partnerProjectKeys",
      ""
    )

    dispatch(setCustomisationKeys(customisationKeys))
  } catch (e) {
    console.log(e)
  }
}

const setPublishingData = data => ({
  type: SET_PUBLISHING_DATA,
  data,
})

const setCustomisationKeys = keys => ({
  type: SET_CUSTOMISATION_KEYS,
  keys,
})

const resetGlobalState = () => ({
  type: RESET_GLOBAL_STATE,
})

const setWindowHeight = height => ({
  type: SET_WINDOW_HEIGHT,
  height,
})

const pushCustomDataLayerEvent = event => () => {
}

const pushDataLayerEvent = (event, onbFlowStep = null) => () => {
  const events = {
    init_1_focus: "0",
    init_2_start: "0",
    init_3_success: "1",
    init_4_error: "0",
    qfy_1_over_5000: "2",
    qfy_2_under_5000: "2",
    reg_1_start: "3a",
    reg_2_success: "3a",
    reg_3_invite_start: "3b",
    reg_success: "4",
    plugin_start: "5",
    plugin_download: "5",
    plugin_open_wp: "5",
  }

  if (typeof window === "undefined") return
  window.dataLayer &&
    window.dataLayer.push({
      onbFlowStep: onbFlowStep || events[event],
      event,
    })
}

const openDemoFlow = () => async (_, getState) => {
  const openSignup = () => {
    try {
      window.regily.signUp.open()
    } catch (error) {
      setTimeout(() => {
        openSignup()
      }, 1000)
    }
  }

  const customisationData = {
    name: _get(
      getState().scanning,
      "results.DomainLookup.domain.name",
      getState().global.partner
    ),
    projects: [
      {
        name: "demo",
        fieldsToCollect: {
          fullname: {},
          email: {},
          phonenumber_local: {},
          phonenumber_E164: {},
          phonenumber_otp_verification: {},
          phonenumber_prefix: {},
          genericInputParagraph: {
            moduleInputType: "genericInputParagraph",
            map: "message",
            validations: [
              {
                type: "string",
                format: "nonEmpty",
              },
            ],
          },
        },
        copy: [
          {
            inputType: "genericInputParagraph",
            content: {
              collectionlabel: "Your message",
              actionlabel: "Type a message",
            },
          },
        ],
        theme: _get(getState().scanning, "results.Theme.theme") || {},
      },
    ],
  }

  const customisationResponse = await axios.post(
    `${serviceURL().customizationAPI}/customizations`,
    customisationData
  )

  const customisationKey = _get(
    customisationResponse,
    "data.partnerProjectKeys[0]",
    ""
  )

  if (!window.regily) {
    const script = document.createElement("script")
    script.src = `${serviceURL().platform
      }/js/loader.js?flowbrainId=${encodeURIComponent(customisationKey)}`
    script.async = true
    document.body.appendChild(script)
  }

  openSignup()
}

const setRemoteToolSite = (site = "") => ({
  type: SET_REMOTE_TOOL_SITE,
  site,
})

const setRemoteToolResults = results => ({
  type: SET_REMOTE_TOOL_RESULTS,
  results,
})

const setRemoteToolError = error => ({
  type: SET_REMOTE_TOOL_ERROR,
  error,
})

const setRemoteToolFetching = fetching => ({
  type: SET_REMOTE_TOOL_FETCHING,
  fetching,
})

const setRemoteToolHydrating = hydrating => ({
  type: SET_REMOTE_TOOL_HYDRATING,
  hydrating,
})

const setRemoteToolAffiliates = affiliates => ({
  type: SET_REMOTE_TOOL_AFFILIATES,
  affiliates,
})

const setRemoteToolNewAffiliate = affiliate => ({
  type: SET_REMOTE_TOOL_NEW_AFFILIATE,
  affiliate,
})

const resetRemoteTool = () => ({
  type: RESET_REMOTE_TOOL,
})

const updateRemoteTool = data => ({
  type: UPDATE_REMOTE_TOOL,
  data,
})

const runRemoteTest = (hydrating = false) => async (dispatch, getState) => {
  const fetching = getState().remoteTool.fetching
  if (fetching) return

  const site = getState().remoteTool.site
  const cleanSite = site.replace(/(^\w+:|^)\/\//, "").replace(/\/$/g, "")

  try {
    hydrating
      ? dispatch(setRemoteToolHydrating(true))
      : dispatch(setRemoteToolFetching(true))
    const response = await axios.post(`${serviceURL().remoteTool}`, {
      remoteUrl: `https://${cleanSite}`,
    })

    dispatch(setRemoteToolResults(response.data))
    if (!hydrating) navigate("/remote/tool/results")
    setTimeout(() => {
      hydrating
        ? dispatch(setRemoteToolHydrating(false))
        : dispatch(setRemoteToolFetching(false))
    }, 100)
  } catch (error) {
    console.log(error)
    hydrating
      ? dispatch(setRemoteToolHydrating(false))
      : dispatch(setRemoteToolFetching(false))
    dispatch(setRemoteToolError(true))
  }
}

const saveNewAffiliate = () => async (dispatch, getState) => {
  const fetching = getState().remoteTool.fetching
  const affiliates = getState().remoteTool.affiliates
  const newAffiliate = getState().remoteTool.newAffiliate
  const shareID = getState().remoteTool.shareID
  const targetURL = getState().remoteTool.results.target.url

  if (fetching) return

  try {
    dispatch(setRemoteToolFetching(true))
    const response = await axios.post(
      `${serviceURL().api}/affiliates?parent=${shareID || ""}`,
      { ...newAffiliate, targetURL }
    )
    dispatch(
      setRemoteToolAffiliates([
        {
          id: _get(response, "data.id", ""),
          domain: _get(response, "data.data.domain", ""),
          trackingURL: _get(response, "data.data.trackingURL", ""),
          targetURL: _get(response, "data.data.targetURL", ""),
        },
        ...affiliates,
      ])
    )

    if (!shareID)
      dispatch(
        updateRemoteTool({
          shareID: _get(response, "data.parent", ""),
        })
      )

    dispatch(setRemoteToolFetching(false))
    dispatch(
      setRemoteToolNewAffiliate({
        domain: "",
        trackingURL: "",
      })
    )
  } catch (error) {
    console.log(error)
    dispatch(setRemoteToolFetching(false))
  }
}

const editAffiliate = (id, newData) => async (dispatch, getState) => {
  const affiliates = getState().remoteTool.affiliates
  const targetURL = getState().remoteTool.results.target.url

  try {
    dispatch(setRemoteToolFetching(true))
    const response = await axios.put(`${serviceURL().api}/affiliates/${id}`, {
      ...newData,
      targetURL,
    })

    dispatch(
      setRemoteToolAffiliates(
        affiliates.map(aff => {
          if (aff.id === id)
            return {
              id: _get(response, "data.id", ""),
              domain: _get(response, "data.data.domain", ""),
              trackingURL: _get(response, "data.data.trackingURL", ""),
              targetURL: _get(response, "data.data.targetURL", ""),
            }

          return aff
        })
      )
    )

    dispatch(setRemoteToolFetching(false))
  } catch (error) {
    console.log(error)
    dispatch(setRemoteToolFetching(false))
  }
}

const cancelDeleteAffiliate = id => (dispatch, getState) => {
  const affiliatesToDelete = getState().remoteTool.affiliatesToDelete

  dispatch(
    updateRemoteTool({
      affiliatesToDelete: affiliatesToDelete.filter(aff => aff.id !== id),
    })
  )
}

const effectiveDeleteAffiliate = id => (dispatch, getState) => {
  try {
    const affiliates = getState().remoteTool.affiliates
    const affiliatesToDelete = getState().remoteTool.affiliatesToDelete
    const affiliateToDelete = affiliatesToDelete.filter(aff => aff.id === id)[0]

    axios.delete(`${serviceURL().api}/affiliates/${affiliateToDelete.id}`)

    dispatch(setRemoteToolAffiliates(affiliates.filter(aff => aff.id !== id)))

    dispatch(
      updateRemoteTool({
        affiliatesToDelete: affiliatesToDelete.filter(aff => aff.id !== id),
      })
    )
  } catch (error) {
    console.log(error)
  }
}

let deleteCodeCounter = 0

const deleteAffiliate = id => (dispatch, getState) => {
  const deleteCode = deleteCodeCounter
  deleteCodeCounter++

  try {
    const affiliates = getState().remoteTool.affiliates
    const affiliatesToDelete = getState().remoteTool.affiliatesToDelete
    const affiliateToDelete = affiliates.filter(aff => aff.id === id)[0]

    dispatch(
      updateRemoteTool({
        affiliatesToDelete: [
          ...affiliatesToDelete,
          { ...affiliateToDelete, deleteCode },
        ],
      })
    )

    setTimeout(() => {
      const affiliates = getState().remoteTool.affiliates
      const affiliatesToDelete = getState().remoteTool.affiliatesToDelete
      const affiliateToDelete = affiliatesToDelete.filter(
        aff => aff.id === id
      )[0]

      if (affiliateToDelete && affiliateToDelete.deleteCode === deleteCode) {
        axios.delete(`${serviceURL().api}/affiliates/${affiliateToDelete.id}`)

        dispatch(
          setRemoteToolAffiliates(affiliates.filter(aff => aff.id !== id))
        )

        dispatch(
          updateRemoteTool({
            affiliatesToDelete: affiliatesToDelete.filter(aff => aff.id !== id),
          })
        )
      }
    }, 5000)
  } catch (error) {
    console.log(error)
  }
}

const selectAffiliate = id => async (dispatch, getState) => {
  const affiliates = getState().remoteTool.affiliates
  const selectedAffiliate = getState().remoteTool.selectedAffiliate

  if (selectedAffiliate && selectedAffiliate.id === id) return

  dispatch(
    updateRemoteTool({
      selectedAffiliate: null,
    })
  )

  try {
    dispatch(setRemoteToolFetching(true))

    let select = affiliates.filter(aff => aff.id === id)

    if (select[0]) {
      dispatch(
        updateRemoteTool({
          selectedAffiliate: select[0],
        })
      )
      dispatch(setRemoteToolFetching(false))
      return
    }

    const response = await axios.get(`${serviceURL().api}/affiliates/${id}`)

    select = response.data

    if (select[0]) {
      dispatch(
        updateRemoteTool({
          selectedAffiliate: {
            id: _get(select[0], "id", ""),
            domain: _get(select[0], "data.domain", ""),
            trackingURL: _get(select[0], "data.trackingURL", ""),
            targetURL: _get(select[0], "data.targetURL", ""),
          },
        })
      )
    }

    dispatch(setRemoteToolFetching(false))
  } catch (error) {
    console.log(error)
  }
}

const hydrateRemoteTool = shareID => async (dispatch, getState) => {
  const oldShareID = getState().remoteTool.shareID

  dispatch(
    updateRemoteTool({
      affiliatesToDelete: [],
    })
  )

  try {
    if (oldShareID !== shareID) {
      dispatch(resetRemoteTool())
      dispatch(
        updateRemoteTool({
          shareID,
        })
      )
    }

    dispatch(setRemoteToolHydrating(true))
    const response = await axios.get(
      `${serviceURL().api}/affiliates/${shareID}`
    )

    if (response.data.length === 0) throw new Error("No data found")

    const site = response.data[0].data.targetURL

    dispatch(setRemoteToolSite(site))

    dispatch(
      setRemoteToolAffiliates(
        response.data
          .map(aff => ({
            id: _get(aff, "id", ""),
            domain: _get(aff, "data.domain", ""),
            trackingURL: _get(aff, "data.trackingURL", ""),
            targetURL: _get(aff, "data.targetURL", ""),
          }))
          .reverse()
      )
    )

    dispatch(setRemoteToolHydrating(false))
    dispatch(runRemoteTest(true))
  } catch (error) {
    console.log(error)
    dispatch(setRemoteToolHydrating(false))
  }
}

const updateCalendar = data => ({
  type: UPDATE_CALENDAR,
  data,
})

const resetCalendar = () => ({
  type: RESET_CALENDAR,
})

const fetchCalendarDays = () => async (dispatch, getState) => {
  const rangeStart = getState().calendar.selectedRange.start
  const rangeEnd = getState().calendar.selectedRange.end
  const timeZone = getState().calendar.selectedTimeZone

  try {
    dispatch(
      updateCalendar({
        fetchingDays: true,
      })
    )

    const response = await axios.get(`${serviceURL().api}/calendar`, {
      params: {
        rangeStart,
        rangeEnd,
        timeZone,
      },
    })

    const firstValidDay = response.data.filter(d => d.available)[0]

    if (!firstValidDay) return dispatch(incrementCalendarWeek())

    dispatch(
      updateCalendar({
        days: response.data,
        fetchingDays: false,
        selectedDay: firstValidDay ? firstValidDay.date : null,
      })
    )
  } catch (error) {
    console.log(error)
    dispatch(
      updateCalendar({
        fetchingDays: false,
      })
    )
  }
}

const incrementCalendarWeek = () => (dispatch, getState) => {
  const isMobile = getState().global.isMobileOnly
  const rangeStart = getState().calendar.selectedRange.start
  const rangeEnd = getState().calendar.selectedRange.end

  if (isMobile) {
    const startDate = addDays(new Date(rangeEnd), 1)
    const daysRange = eachDayOfInterval({
      start: startDate,
      end: addDays(startDate, 4),
    })
      .filter(d => !isWeekend(d))
      .slice(0, 3)

    dispatch(
      updateCalendar({
        selectedRange: {
          start: format(daysRange[0], "yyyy-MM-dd"),
          end: format(daysRange[2], "yyyy-MM-dd"),
        },
      })
    )

    return dispatch(fetchCalendarDays())
  }

  dispatch(
    updateCalendar({
      selectedRange: {
        start: format(addWeeks(new Date(rangeStart), 1), "yyyy-MM-dd"),
        end: format(addWeeks(new Date(rangeEnd), 1), "yyyy-MM-dd"),
      },
    })
  )

  dispatch(fetchCalendarDays())
}

const decrementCalendarWeek = () => (dispatch, getState) => {
  const isMobile = getState().global.isMobileOnly
  const rangeStart = getState().calendar.selectedRange.start
  const rangeEnd = getState().calendar.selectedRange.end

  if (isMobile) {
    const endDate = addDays(new Date(rangeStart), -1)
    const daysRange = eachDayOfInterval({
      start: addDays(endDate, -4),
      end: endDate,
    })
      .filter(d => !isWeekend(d))
      .slice(0, 3)

    dispatch(
      updateCalendar({
        selectedRange: {
          start: format(daysRange[0], "yyyy-MM-dd"),
          end: format(daysRange[2], "yyyy-MM-dd"),
        },
      })
    )

    return dispatch(fetchCalendarDays())
  }

  dispatch(
    updateCalendar({
      selectedRange: {
        start: format(addWeeks(new Date(rangeStart), -1), "yyyy-MM-dd"),
        end: format(addWeeks(new Date(rangeEnd), -1), "yyyy-MM-dd"),
      },
    })
  )

  dispatch(fetchCalendarDays())
}

const initializeCalendar = () => (dispatch, getState) => {
  const isMobile = getState().global.isMobileOnly
  const weekend = isWeekend(new Date())
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (isMobile) {
    const startDate = new Date()
    const daysRange = eachDayOfInterval({
      start: startDate,
      end: addDays(startDate, 4),
    })
      .filter(d => !isWeekend(d))
      .slice(0, 3)

    dispatch(
      updateCalendar({
        selectedRange: {
          start: format(daysRange[0], "yyyy-MM-dd"),
          end: format(daysRange[2], "yyyy-MM-dd"),
        },
        currentWeek: startDate,
        detectedTimeZone: timeZone,
        selectedTimeZone: timeZone,
        fetchingDays: false,
      })
    )

    return dispatch(fetchCalendarDays())
  }

  try {
    const rangeStart = format(
      startOfWeek(weekend ? addWeeks(new Date(), 1) : new Date(), {
        weekStartsOn: 1,
      }),
      "yyyy-MM-dd"
    )

    const rangeEnd = format(
      endOfWeek(weekend ? addWeeks(new Date(), 1) : new Date(), {
        weekStartsOn: 1,
      }),
      "yyyy-MM-dd"
    )

    dispatch(
      updateCalendar({
        selectedRange: {
          start: rangeStart,
          end: rangeEnd,
        },
        currentWeek: rangeStart,
        detectedTimeZone: timeZone,
        selectedTimeZone: timeZone,
        fetchingDays: false,
      })
    )

    dispatch(fetchCalendarDays())
  } catch (error) {
    console.log(error)
  }
}

const createCalendarEvent = () => async (dispatch, getState) => {
  const [start, timeZone, primaryEmail, description] = [
    getState().calendar.selectedSlot.startTime,
    getState().calendar.selectedTimeZone,
    getState().calendar.primaryEmail,
    getState().calendar.businessDescription,
  ]

  try {
    const response = await axios.post(`${serviceURL().api}/calendar`, {
      start,
      end: addMinutes(new Date(start), 30),
      timeZone,
      attendees: [{ email: primaryEmail }],
      description,
    })

    dispatch(
      updateCalendar({
        eventId: response.data.data.id,
      })
    )
  } catch (error) {
    console.log(error)
  }
}

const addGuestsToCalendarEvent = () => async (dispatch, getState) => {
  const [eventId, primaryEmail, guestsEmails] = [
    getState().calendar.eventId,
    getState().calendar.primaryEmail,
    getState().calendar.guestsEmails,
  ]

  try {
    await axios.patch(`${serviceURL().api}/calendar/${eventId}`, {
      attendees: [
        { email: primaryEmail },
        ...guestsEmails.map(ge => ({ email: ge })),
      ],
    })
  } catch (error) {
    console.log(error)
  }
}

const updateCompanyTracking = data => ({
  type: UPDATE_COMPANY_TRACKING,
  data,
})

const resetCompanyTracking = () => ({
  type: RESET_COMPANY_TRACKING,
})

const trackCompanyInfo = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(`${serviceURL().revealAPI}`)
    const companyInfo = response.data.company

    dispatch(
      updateCompanyTracking({
        company: {
          name: _get(companyInfo, "name", ""),
          industry: _get(companyInfo, "category.industry", ""),
          tags: _get(companyInfo, "tags", []),
          employees: _get(companyInfo, "metrics.employees", null),
          tech: _get(companyInfo, "tech", []),
          description: _get(companyInfo, "description", ""),
          foundedYear: _get(companyInfo, "foundedYear", null),
          estimatedAnnualRevenue: _get(
            companyInfo,
            "metrics.estimatedAnnualRevenue",
            ""
          ),
          logo: _get(companyInfo, "logo", ""),
          country: _get(companyInfo, "geo.country", ""),
          city: _get(companyInfo, "geo.city", ""),
        },
      })
    )
  } catch (error) {
    console.log(error)
  }
}

const trackBehaviorInfo = (data = {}, incrementNumberOfVisits = false) => (
  dispatch,
  getState
) => {
  const oldBehaviorInfo = JSON.parse(localStorage.getItem("behaviorInfo"))

  const newBehaviorInfo = {
    returningVisitor: _get(oldBehaviorInfo, "numberOfVisits", 0) >= 1,
    numberOfVisits: incrementNumberOfVisits
      ? _get(oldBehaviorInfo, "numberOfVisits", 0) + 1
      : _get(oldBehaviorInfo, "numberOfVisits", 0),
    scannedWebsite: _get(
      data,
      "scannedWebsite",
      _get(oldBehaviorInfo, "scannedWebsite", false)
    ),
    scannedWebsites: _get(
      data,
      "scannedWebsites",
      _get(oldBehaviorInfo, "scannedWebsites", [])
    ),
    enteredEmail: _get(
      data,
      "enteredEmail",
      _get(oldBehaviorInfo, "enteredEmail", false)
    ),
  }

  dispatch(
    updateCompanyTracking({
      behavior: { ...newBehaviorInfo },
    })
  )

  localStorage.setItem("behaviorInfo", JSON.stringify(newBehaviorInfo))
}

export {
  DETECT_DEVICE,
  SET_WEBSITE,
  SET_REFRESH_DURING_SCANNING,
  SET_HEADERS,
  SET_SCANNING_STATUS,
  SET_SCANNING_TOKEN,
  SET_SCANNING_RESULTS,
  SET_SCANNING_PROGRESS,
  SET_SCANNING_ILLUSTRATION,
  RESET_SCANNING_STATE,
  SET_BETA_EMAIL,
  SET_BETA_USERS_LIMIT,
  SET_BETA_FIRSTNAME,
  SET_BETA_SHEET_RANGE,
  SET_PUBLISHING_DATA,
  SET_CUSTOMISATION_KEYS,
  RESET_GLOBAL_STATE,
  SET_WINDOW_HEIGHT,
  RESET_BETA,
  SET_SITE_ID,
  SET_FORCE_WP_FLOW,
  SET_REMOTE_TOOL_SITE,
  SET_REMOTE_TOOL_RESULTS,
  SET_REMOTE_TOOL_ERROR,
  SET_REMOTE_TOOL_FETCHING,
  SET_REMOTE_TOOL_HYDRATING,
  SET_REMOTE_TOOL_AFFILIATES,
  SET_REMOTE_TOOL_NEW_AFFILIATE,
  UPDATE_REMOTE_TOOL,
  RESET_REMOTE_TOOL,
  UPDATE_CALENDAR,
  RESET_CALENDAR,
  UPDATE_COMPANY_TRACKING,
  RESET_COMPANY_TRACKING,
  UPDATE_LABS_LOGIN,
  detectDevice,
  setWebsite,
  setRefreshDuringScanning,
  setHeaders,
  updateSheet,
  startScanning,
  setScanningStatus,
  setScanningResults,
  setScanningProgress,
  setScanningIllustration,
  resetScanningState,
  setBetaEmail,
  setBetaUsersLimit,
  setBetaFirstname,
  setBetaSheetRange,
  resetBeta,
  retrieveScanData,
  startPublishing,
  setPublishingData,
  setCustomisationKeys,
  resetGlobalState,
  pushDataLayerEvent,
  pushCustomDataLayerEvent,
  setWindowHeight,
  openDemoFlow,
  setSiteId,
  saveSite,
  updateSite,
  updateTracking,
  setForceWPFlow,
  setRemoteToolSite,
  setRemoteToolResults,
  setRemoteToolError,
  setRemoteToolFetching,
  setRemoteToolHydrating,
  runRemoteTest,
  setRemoteToolAffiliates,
  setRemoteToolNewAffiliate,
  saveNewAffiliate,
  resetRemoteTool,
  updateRemoteTool,
  editAffiliate,
  deleteAffiliate,
  cancelDeleteAffiliate,
  effectiveDeleteAffiliate,
  selectAffiliate,
  hydrateRemoteTool,
  updateCalendar,
  resetCalendar,
  fetchCalendarDays,
  initializeCalendar,
  incrementCalendarWeek,
  decrementCalendarWeek,
  createCalendarEvent,
  addGuestsToCalendarEvent,
  updateCompanyTracking,
  resetCompanyTracking,
  trackCompanyInfo,
  trackBehaviorInfo,
  updateLabsLogin,
}
