import React from "react"
import styled from "styled-components"

import GreenCircle from "../green-circle"

const CustomGreenCircle = styled(GreenCircle)`
  margin-top: 0;
  margin-right: 24px;
`

const Container = styled.div`
  width: 190px;
  display: flex;
  z-index: 1;
  align-items: center;

  @media screen and (max-width: 980px) {
    width: auto;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 40px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Text = styled.p`
  font-size: 20px;
  line-height: 30px;
  flex: 1;

  @media screen and (max-width: 980px) {
    margin-top: 8px;
  }

  @media screen and (max-width: 640px) {
    font-size: 18px;
    line-height: 28px;

    br {
      display: none;
    }
  }
`

const Feature = ({ className, children }) => {
  return (
    <Container className={className}>
      <CustomGreenCircle />
      <Text>{children}</Text>
    </Container>
  )
}

export default Feature
