import React, { useRef } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import Helmet from "react-helmet"

import { pushCustomDataLayerEvent } from "../../state/actions"

import faqPlus from "../../assets/images/connect/faq-plus.svg"
import faqMinus from "../../assets/images/connect/faq-minus.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: faq;
  align-items: center;
  z-index: 1;
`

const Header = styled.h2`
  font-size: 34px;
  margin-bottom: 42px;

  @media screen and (max-width: 640px) {
    font-size: 30px;
    margin-bottom: 40px;
    text-align: center;
  }
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  position: relative;
  background: #fff;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px dashed #000;
    top: -10px;
    left: 10px;
    z-index: -1;
    box-sizing: border-box;
  }
`

const TabLabelIcon = styled.div`
  height: 40px;
  width: 40px;
  background: url(${faqPlus}) no-repeat center/100%;
`

const TabLabel = styled.label.attrs(props => ({
  onClick: e => {
    props.dispatch(
      pushCustomDataLayerEvent({
        event: "funnel_engagement",
        eventCategory: "remote",
        eventAction: "faq_click",
        eventLabel: e.target.innerText,
      })
    )
  },
}))`
  font-size: 18px;
  font-weight: 700;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #f9f9f8;
  cursor: pointer;
  border-top: 1px solid #000;

  p {
    flex: 1;
  }

  @media screen and (max-width: 980px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }
`

const Tab = styled.div`
  width: 100%;
  overflow: hidden;

  &:first-child {
    ${TabLabel} {
      border-top: none;
    }
  }
`

const TabContent = styled.div`
  box-sizing: border-box;
  max-height: 0;
  opacity: 0;
  background: #fff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: rgba(0, 0, 0, 0.1);
    border-top: 1px solid #000;
  }
`

const TabContentContainer = styled.div`
  padding: 30px;
  font-size: 16px;
  line-height: 28px;

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }

  ul {
    margin: 30px 0 30px 30px;

    li {
      margin: 10px 0;
    }
  }

  h3 {
    font-size: 16px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const TabSelector = styled.input.attrs({
  type: "radio",
  name: "faq",
})`
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked ~ ${TabContent} {
    max-height: 100vh;
    opacity: 1;
  }

  &:checked ~ ${TabLabel} {
    background: #ddeff4;

    ${TabLabelIcon} {
      background: url(${faqMinus}) no-repeat center/100%;
    }
  }
`

const faqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Quais serviços a <a href='https://checkin.com/'>Checkin.com</a> oferece no Brasil? ",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>A Checkin.com oferece verificação de identidade, vivacidade e idade, validação de CPF, triagem de PEP, AML e sanções, além de armazenamento seguro de dados de clientes, atendendo às necessidades dos operadores de iGaming no Brasil.</p>",
      },
    },
    {
      "@type": "Question",
      name: "O fluxo da <a href='https://checkin.com/'>Checkin.com</a> está totalmente em conformidade com as novas regulamentações? ",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Sim, nosso objetivo é evitar que você se preocupe com o processo KYC, já que você terá todas as informações corretas sobre seus jogadores.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Qual é o tempo médio de verificação de identidade?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>O tempo médio de verificação de documentos usando o software de escaneamento de ID da Checkin.com é de aproximadamente 5 segundos.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Qual é a diferença entre Verificação de Vivacidade Passiva e Ativa?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>A verificação de vivacidade passiva ocorre em segundo plano, sem que o jogador perceba, ao contrário da vivacidade ativa, onde o jogador deve executar ações indicadas na tela.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Qual é a funcionalidade de validação de CPF? ",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>A validação de CPF verifica o CPF do jogador diretamente nos bancos de dados governamentais, adicionando uma camada extra de segurança para os operadores.</p>",
      },
    },
    {
      "@type": "Question",
      name: "O software da <a href='https://checkin.com/'>Checkin.com</a> pode se integrar ao nosso banco de dados atual e verificar todos os nossos jogadores, mesmo sem termos colaborado juntos antes?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Sim, todos os jogadores existentes passarão por uma verificação de vivacidade passiva, e depois buscaremos em seu banco de dados para identificar o rosto correto entre os usuários atuais.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Nosso fluxo pode ser personalizado de alguma forma?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>A solução da Checkin.com é totalmente white-label, o que significa que o fluxo é personalizado de acordo com o design do seu site e configurado com base nos requisitos que você tem, como idiomas, documentos suportados, restrição de idade, etc.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Por quanto tempo a <a href='https://checkin.com/'>Checkin.com</a> armazena os dados dos clientes?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>A Checkin.com armazena os dados dos clientes de forma segura por até 10 anos, garantindo conformidade com os requisitos regulatórios.</p>",
      },
    },
    {
      "@type": "Question",
      name: "A <a href='https://checkin.com/'>Checkin.com</a> fornece monitoramento contínuo dos perfis dos clientes? ",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Sim, a Checkin.com oferece monitoramento contínuo e reavaliação de perfis de risco, verificando milhares de listas de sanções e PEP (Pessoas Politicamente Expostas) atualizadas, além de confirmar se um jogador está associado a alguma organização esportiva no Brasil.</p>",
      },
    },
    {
      "@type": "Question",
      name: "O que é a detecção de deep fake e por que ela é importante?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Com o aumento dos deep fakes no Brasil, a detecção de deep fake da Checkin.com garante que a identidade do jogador seja autêntica e não manipulada, ajudando os operadores a prevenir fraudes de identidade.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Quantos tipos de documentos de identificação são suportados?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>A Checkin.com suporta mais de 14.000 documentos de identificação em mais de 80 idiomas.</p>",
      },
    },
  ],
}

const FAQ = () => {
  const dispatch = useDispatch()
  const openTab = useRef()

  const onTabClick = e => {
    if (e.target === openTab.current) {
      openTab.current = null
      e.target.checked = false
      return
    }

    openTab.current = e.target
  }

  return (
    <Container>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>
      <Header>Perguntas frequentes</Header>
      <TabsContainer>
        {faqSchema.mainEntity.map((item, index) => (
          <Tab key={index}>
            <TabSelector id={`faq-${index}`} onClick={onTabClick} />
            <TabLabel htmlFor={`faq-${index}`} dispatch={dispatch}>
              <p dangerouslySetInnerHTML={{ __html: item.name }}></p>
              <TabLabelIcon />
            </TabLabel>
            <TabContent>
              <TabContentContainer
                dangerouslySetInnerHTML={{ __html: item.acceptedAnswer.text }}
              ></TabContentContainer>
            </TabContent>
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export default FAQ
