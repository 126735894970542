import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"
import { useSelector } from "react-redux"



import Next from "../../assets/images/ocr/next.inline.svg"
import Invalid from "../../assets/images/ocr/invalid.inline.svg"
import UserBehavior from "../../assets/images/botlens/userBehavior.svg"
import SourceMonitoring from "../../assets/images/botlens/sourceMonitoring.svg"
import FlowSeq from "../../assets/images/botlens/flowSeq.svg"
import ArrowSquare from "../../assets/images/arrow.inline.svg"




const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  z-index: 1;
  position: relative;

.sliders {
  display: none;
  @media screen and (max-width: 640px) {
    display:block;
  }

}

@media screen and (max-width: 640px) {
  padding: 0;
  height: auto;
}
`

const Mobilecontainer = styled.div`

  width: 100%;
@media screen and (max-width: 640px) {
    display: none;
    overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
}
`


const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const StepsContainer = styled.div`
  display: flex;
  justify-content: start;
  margin: 0px 0 40px 0;
  position: relative;


  @media screen and (max-width: 980px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const CustomStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  justify-content: center;

  ${H4} {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #757575;
    padding: 9px 16px;
  }

  ${Text} {
    line-height: 24px;
  }

  ${props =>
    props.active &&
    css`
      
      border: 1px dashed #000;
      ${H4} {
        font-weight: 400;
        color: #000;
      }
    `}

  img {
    width: 69px;
    height: 69px;
  }

  @media screen and (max-width: 980px) {

    ${H4} {
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
    }

    ${props =>
    props.active &&
    css`
        ${H4} {
          color: #0D0D0D;
        }
      `}

    &:last-child {
      margin-top: 0px;
      grid-column: span 2;
    }
  }

  @media screen and (max-width: 640px) {
    width: 230px;
    margin-bottom:0px;
    margin-top: 0;
    grid-column: 1;
    ${H4} {
      font-size: 14px;
      line-height: 22px;
    }
  }
`

const CustomStepsContainer = styled(StepsContainer)`

@media screen and (max-width: 980px) {
  display: flex;
}

@media screen and (max-width: 640px) {
  width: 600px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
`

const ListImage = styled.div`
  display: flex;
  width: 300px;

  object {
    width: 100%;
  }

  @media screen and (max-width: 980px) {
    margin-left: 0px;
    width: 196px;
    height: 225px;
  }
  @media screen and (max-width: 640px) {
    height: auto;
    margin: 10px auto;
      width: 250px;
      height: 250px;
   }
  

`
const NextButton = styled.span`
  position: absolute;
  width: 44px;
  top: 270px;
  right: -18px;
  z-index: 1;
  cursor: pointer;
`

const PreviousButton = styled.span`
  position: absolute;
  width: 44px;
  top: 270px;
  left: -18px;
  z-index: 1;
  cursor: pointer;
`
const InvalidNext = styled(Invalid)`
  transform: rotate(180deg);
`
const Prev = styled(Next)`
  transform: rotate(180deg);
`
const StepContent = styled.div`
height: 480px;
width: 950px;
flex-shrink: 0;
padding: 10px 71px;
display: flex;
justify-content: space-between;
margin-bottom: 10px;
background-color: #FFFFFF;
border: 1px solid #000;
position: relative;

&.reverse {
  flex-direction: row-reverse;

  ${ListImage} {
    justify-content: flex-start;
  }
}

${ListImage} {
  justify-content: flex-end;
}

&.fade-enter {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-exit-active {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-enter-active,
&.fade-exit-active {
  transition: all 0.3s ease;

}
&.fade-enter-active,
&.fade-exit-active,
&.fade-exit,
&.fade-enter{
  &::after {
    display:none;
  }
}

&::after {
  content: "";
  border: 1px dashed #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 10px ;
  left: 10px ;
  z-index: -1;
  
}

@media screen and (max-width: 980px) {
  flex-direction: row;
  padding: 30px;
  align-items: center;
  width: 505px;
  height: 256px;
}

@media screen and (max-width: 640px) {
  grid-template-columns: 1fr;
  margin-top: 25px;
  height: 530px;
  width: 96%;
  flex-direction: column;

  ${ListImage}, .reverse ${ListImage} {
    justify-content: center !important;
    position: absolute;
    bottom: -10px;
  }

  &.reverse {
    flex-direction: column;
  }

  &.fade-enter {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.3s ease;
  }

  &.fade-enter-active,
  &.fade-exit-active,
  &.fade-exit,
  &.fade-enter{
    &::after {
      display:none;
    }
  }

}

`

const List = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
width: 390px;
@media screen and (max-width: 980px) {
  width: 317px;
}
@media screen and (max-width: 640px) {
  width: auto;
  ul {
    margin-left: 16px;
  }
}
${H4}{
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  @media screen and (max-width: 980px) {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    order: 2;
  }
}
p {
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width: 980px) {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
  }

  @media screen and (max-width: 640px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #535353;
    order: 3;
  }
 }
 ul {
  margin-top: 15px;
  margin-left: 14px;
 }




li {
  font-size: 16px;
  line-height: 32px;
  padding-left: 10px;
  font-weight: bold;
}
}

`
const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  margin-top: 44px;

  p {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
    line-height: 26px;
    border-radius: 14px;
    border: 1px solid #000;
    padding: 0 12px;
    color: #000;

  }

  @media screen and (max-width: 980px) {
    margin-top: 24px;

    p {
      font-size: 8px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  @media screen and (max-width: 640px) {
    order: 1;
    margin-top: 0px;
    margin-bottom: 16px;

  }
`
const Steps = styled.div`
display: flex;
overflow: scroll;
gap: 35px;
&::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 980px) {
  height: 270px;
}
@media screen and (max-width: 640px) {
  height: auto;
  padding-bottom: 3px;
}

/* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

const Navigation = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  gap: 16px;

  @media screen and (max-width: 640px) {
    display: none;
  }
`

const customArrow = styled(ArrowSquare)`
  height: 44px;
  width: 44px;
  position: relative;   
  cursor: pointer;
  padding: 10px;

    &.disabled {
      opacity: 0.3;
    }
    &.able {
      opacity: 1;
    }
`

const Right = styled(customArrow)`
  box-shadow: 5px 5px 0px rgb(0 0 0 / 10%);

`

const Left = styled(customArrow)`
  transform: rotate(180deg);
  box-shadow: 5px -5px 0px rgb(0 0 0 / 10%);

`

const Flex = styled.div`
  display: flex;
  border: 1px solid #000;
  `

export default function Biometric({
  gridArea,
}) {

  const scrollRef = useRef(null);
  const [activestep, setactivestep] = useState(0)
  const isMobile = useSelector(state => state.global.isMobile);
  const biometrictRef = useRef(null);


  const stepsContent = [
    {
      header: "Restrict underage users",
      subheader: "Verify users' ages to keep adult content, products, and services from reaching minors. Young people are major online shoppers and service users.",
      image: (< object data={FlowSeq} type="image/svg+xml" ></object>),
      video: "/videos/face-recognition/FR-Age.mp4",
      videoMobile: "/videos/face-recognition/mobile/FR-Age.mp4",
      tags: [
        { name: "Online gaming", color: "rgba(250, 218, 123, 0.29)" },
        { name: "Gambling and betting", color: "#FF986B" },
        { name: "Online dating", color: "rgba(200, 148, 214, 0.38)" },
        { name: "Alcohol, tobacco, vaping", color: "#C0EAD6" },
        { name: "Sensitive content", color: "rgba(65, 183, 243, 0.28)" }
      ]
    },
    {
      header: "Fighting fraud",
      subheader: 'Our solution identifies duplicate data and known faces in one place, enabling you to adjust verification as needed.',
      image: (< object data={UserBehavior} type="image/svg+xml" ></object>),
      video: "/videos/face-recognition/FR-Pay.mp4",
      videoMobile: "/videos/face-recognition/mobile/FR-Pay.mp4",
      tags: [
        { name: "Fraud", color: "#DDEFF4" },
        { name: "Same face but with different data", color: "#FADA7B" }
      ]
    },
    {
      header: "Face recognition",
      subheader: "Checkin.com's face recognition secures access, recognizes users, and simplifies password recovery for areas like hotel rooms and boarding gates.",
      image: (< object data={SourceMonitoring} type="image/svg+xml" ></object>),
      video: "/videos/face-recognition/FR-Agent.mp4",
      videoMobile: "/videos/face-recognition/mobile/FR-Agent.mp4",
      tags: [
        { name: "Confirmation of transactions", color: "#F77EB1" },
        { name: "Recognizing the user again", color: "#FF986B" },
        { name: "Password recovery", color: "#ECDAF1" }
      ]
    }
  ]


  const scrollToDiv = (divId) => {
    const divElement = document.getElementById(divId);
    if (divElement && scrollRef.current) {
      divElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  };

  const buttonHandler = (index) => {
    scrollToDiv('step' + index)
    setactivestep(index)
  }

  const hScrollLeft = () => {
    if (activestep > 0) {
      scrollToDiv('step' + (activestep - 1))
      setactivestep(activestep - 1)
    }
  }

  const hScrollRight = () => {
    if (activestep < 2) {
      scrollToDiv('step' + (activestep + 1))
      setactivestep(activestep + 1)
    }
  }

  return (
    <Container gridArea={gridArea}>

      <Mobilecontainer className="InstantValue">
        <CustomStepsContainer>
          <CustomStep onClick={() => buttonHandler(0)} className="step1" active={activestep === 0} >
            <H4>Restrict underage users </H4>
          </CustomStep>
          <CustomStep onClick={() => buttonHandler(1)} className="step2" active={activestep === 1} >
            <H4>Fighting fraud</H4>
          </CustomStep>
          <CustomStep onClick={() => buttonHandler(2)} className="step3" active={activestep === 2} >
            <H4>Recognize existing customers</H4>
          </CustomStep>
        </CustomStepsContainer>
      </Mobilecontainer>
      <Navigation><Flex> <Left className={activestep === 0 ? 'disabled' : 'able'} onClick={hScrollLeft} /></Flex> <Flex><Right className={activestep === 2 ? 'disabled' : 'able'} onClick={hScrollRight} /></Flex> </Navigation>



      <Steps ref={scrollRef}>
        <NextButton className="sliders" onClick={hScrollRight} > {activestep === 2 ? (<InvalidNext />) : (<Next />)}</NextButton>
        <PreviousButton className="sliders" onClick={hScrollLeft}>{activestep === 0 ? (<Invalid />) : (<Prev />)}</PreviousButton>
        {stepsContent.map((step, index) => (
          <StepContent className={index % 2 === 0 ? 'reverse' : ''} id={`step${index}`} key={index}>
            <List>
              <H4>{step.header}</H4>
              <p>{step.subheader}</p>
              <Tags>
                {step.tags.map((tag, index) => (
                  <p style={{ backgroundColor: tag.color }} key={index}>{tag.name}</p>
                ))}
              </Tags>
            </List>
            <ListImage>
              <video
                webkit-playsinline="true"
                autoPlay loop muted playsInline>
                <source src={isMobile ? step.videoMobile : step.video} type='video/mp4' />
              </video>
            </ListImage>

          </StepContent>
        ))}
      </Steps>

    </Container>
  )
}
