import React from "react"
import styled from "styled-components"

import SEO from "../components/seo"
import ActionButton from "../components/action-button"

const Contaier = styled.div`
  flex: 1;
  width: 781px;
  align-self: center;
  padding: 135px 0 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${ActionButton} {
    margin-top: 0;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 980px) {
    padding: 40px 0 100px;
    width: 619px;
  }

  @media screen and (max-width: 640px) {
    width: 335px;
    padding: 50px 0 100px;
  }
`

const Header = styled.h1`
  font-size: 48px;
  font-weight: 900;
  margin-bottom: 40px;

  @media screen and (max-width: 980px) {
    text-align: center;
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 900;
  }

  @media screen and (max-width: 640px) {
    font-size: 34px;
    margin-bottom: 44px;
  }
`

const SubHeader = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 42px;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 16px;
    margin-top: 20px;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 24px;
    margin-top: 10px;
  }
`

const Text = styled.p`
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 980px) {
    line-height: 28px;
  }
`

const Phone = styled.span`
font-weight: bold;
font-size: 16px;
color: #0D0D0D;
white-space: nowrap;
`

const Contact = styled(Text)`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 30px;

  a:hover {
    font-weight: bold;
    cursor: pointer;
  }

  @media screen and (max-width: 980px) {
    line-height: 28px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 640px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`

export default function AboutUsPage() {
  return (
    <Contaier>
      <SEO
        title="How Checkin.com is re-inventing customer onboarding and KYC"
        description="The first lines of code was written back in 2017. Our goal since then has been clear: to establish a new internet standard for checkins."
        canonical="/about-us/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />
      <Header>About us</Header>

      <SubHeader>Defining the internet standard for checkins</SubHeader>
      <Text>
        The first line of code for the Checkin.com framework was written back in
        2017. Our goal since then has been clear: to establish a new internet
        standard for checkins.
      </Text>
      <Text>
        What we’re trying to achieve is In many ways similar to how online
        payment suppliers have created simple, safe, and efficient solutions for
        online transactions (“checkouts”).
      </Text>
      <Text>
        Our software combines multiple UX-systems, data sources, and identity
        solutions to instantly configure the best possible experience for each
        end-user at the point of checkin.
      </Text>

      <SubHeader>We’re just getting started</SubHeader>
      <Text>
        Our world-class team is distributed in more than 12 countries and on three continents and we are constantly looking for more talent to join our quest.

      </Text>

      <SubHeader>Owned and operated by Checkin.com Group AB</SubHeader>
      <Text>
        Our head office is located at Engelbrektsplan 2, 114 34 Stockholm, Sweden.
      </Text>


      <ActionButton
        style={{ marginBottom: '50px' }}
        onClick={() => window.open("https://group.checkin.com", "_blank")}
      >
        Visit group.checkin.com
      </ActionButton>
      <br />

      <SubHeader>Customer support</SubHeader>
      <Text>
        Please contact our support team at <a style={{ fontWeight: 'bold', textDecoration: 'underline' }} href="mailto:support@checkin.com">support@checkin.com</a> or reach out to <Phone>+1 (347) 434-8955</Phone>. Our support team is ready to help you anytime.

      </Text>

      <SubHeader
        style={{ marginTop: '20px' }}
      >
        Other inquiries</SubHeader>

      <Contact>
        Job applications -{" "}
        <a href="mailto:join@checkin.com">join@checkin.com</a>
      </Contact>
      <Contact>
        Media inquiries -{" "}
        <a href="mailto:press@checkin.com">press@checkin.com</a>
      </Contact>
      <Contact>
        Sales - <a href="mailto:sales@checkin.com">sales@checkin.com</a>
      </Contact>
    </Contaier>
  )
}
