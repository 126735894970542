import React, { useContext, useRef } from "react"
import { useDispatch } from "react-redux"

import styled, { css, keyframes } from "styled-components"

import GlobalContext from "../../context/global-context"
import { pushCustomDataLayerEvent } from "../../state/actions"
import ActionButton from "../action-button"
import { useInView } from 'react-intersection-observer';

import Left from "../../assets/images/igaming/left-plug.inline.svg"
import Right from "../../assets/images/igaming/right-plug.inline.svg"
import Aspire from "../../assets/images/igaming/aspire-global.png"
import Gig from "../../assets/images/igaming/gig.inline.svg"
import Matrix from "../../assets/images/igaming/matrix.inline.svg"
import Playtech from "../../assets/images/playtech_box.inline.svg"
import Orxy from "../../assets/images/igaming/orxy.inline.svg"
import Pragmatic from "../../assets/images/igaming/pragmatic.inline.svg"
import Gogaming from "../../assets/images/gogaming.inline.svg"

const fadeIn = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  justify-content: space-between;

`

const CustomActionButton = styled(ActionButton)`
  width: 263px;
  z-index: 20;
  margin-top: 30px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 63px;
  grid-area: ${props => props.gridArea};
  width: 50%;
  @media screen and (max-width: 980px) {
    width:70%;
  }

  @media screen and (max-width: 640px) {
    margin-top : 20px;
    width:100%;

  }
`
const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 57px;
  @media screen and (max-width: 640px) {
  justify-content: center;

    svg {
      display: none;
    }
  }

`

const Header = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;
  white-space: pre-line;

  @media screen and (max-width: 640px) {
    font-size: 28px;
    line-height: 34px;
  }

  ${props =>
    props.primary &&
    css`
      font-size: 48px;
      line-height: 56px;
      width: 618px;
      margin-bottom: 16px;

      @media screen and (max-width: 980px) {
        width: 600px;
      }

      @media screen and (max-width: 640px) {
        line-height: 44px;
        font-size: 34px;
        width: 335px;
      }
    `}
`

const Subheader = styled.p`
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;

  @media screen and (max-width: 640px) {
    line-height: 32px;
    font-size: 18px;
  }
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`


const CTAText = styled(H4)`
  margin-top: 30px;
  text-align: left;

  @media screen and (max-width: 980px) {
    text-align: center;

  }

  @media screen and (max-width: 640px) {
    width: 333px;
    text-align: center;

  }
`

const PlugLogos = styled.div`
display: grid;
gap: 20px;
grid-template-columns: auto auto auto;

img {
  width: 230px;
}
svg {
  opacity:0;
}
@media screen and (max-width: 980px) {
  grid-template-columns: auto auto auto;

  svg, img {
    width: 200px;
    height: auto;
  }
}

@media screen and (max-width: 640px) {
  grid-template-columns: auto auto;
  gap: 5px;

  svg, img {
    width: 164px;
    height: auto;
  }
}
  
`

const Logo = styled.div`

&:first-of-type {
  border: 1.7px solid #040404;
  background: var(--secondary-colours-primary-colours-white, #FFF);
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.09);
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
}

grid-column: span 1;

&:last-of-type {
  grid-column: 1/span 3;
}

@media screen and (max-width: 640px) {
  &:last-of-type {
    grid-column: 1/span 2;
  }
}

&.fadeInLogo svg {
  animation: ${fadeIn} 0.5s ease forwards 0s;
  animation-delay: ${props => props.index * 200 + 'ms'};
}
  
`

export default function LeadingBrands({
  gridArea,
}) {
  const dispatch = useDispatch()
  const globalContext = useContext(GlobalContext)
  const Logos = [<img className="aspire" src={Aspire} />, <Gig />, <Matrix />, <Playtech />, <Orxy />, <Pragmatic />, <img />, <Gogaming />, <img />]
  const { ref: myRef, inView: sectionIsVisible } = useInView();

  return (
    <Container gridArea={gridArea}>

      <HeaderSection>
        <Left />
        <TextContainer >
          <Header >Plug in to the leading platforms</Header>
          <Subheader>Our integration fully supports direct connection to nearly every iGaming platform on the market. </Subheader>
        </TextContainer>
        <Right />
      </HeaderSection>

      <PlugLogos ref={myRef}>
        {
          Logos.map((logo, index) => (
            <Logo
              key={index}
              index={index}
              className={sectionIsVisible ? 'fadeInLogo' : null}
            >{logo} </Logo>
          ))
        }
      </PlugLogos>

      <CTAText>
        Let us know which platform you’d like to connect
      </CTAText>

      <CustomActionButton
        onClick={() => {
          dispatch(
            pushCustomDataLayerEvent({
              event: "get_started",
              eventCategory: "get_started",
              eventAction: "open",
              eventLabel: "bottom_cta",
            })
          )
          globalContext.openGetStarted()
        }}
      >
        Get started
      </CustomActionButton>

    </Container>
  )
}
