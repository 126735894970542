import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { SwitchTransition, CSSTransition } from "react-transition-group"


import Next from "../../assets/images/fintech/arrow.inline.svg"
import Invalid from "../../assets/images/fintech/invalid.inline.svg"
import ConversionRa from "../../assets/images/travel/conversion_rayanair.inline.svg"
import Admiral from "../../assets/images/fintech/admiral.svg"
import Finance from "../../assets/images/fintech/finance.svg"
import Decta from "../../assets/images/fintech/decta.svg"
import Telexoo from "../../assets/images/fintech/telexoo.svg"


const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  text-align: left;
  justify-content: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  height: 620px;
  z-index: 1;

.sliders {

  @media screen and (max-width: 640px) {

    svg {
      width: 40px;
    }
  }

}

@media screen and (max-width: 640px) {
  padding: 0;
  height: auto;
}
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const ListImage = styled.div`

  display: block;
  margin-left: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

  @media screen and (max-width: 980px) {
   svg {
    width: 250px;
   } 
  }
  @media screen and (max-width: 640px) {
    height: auto;
    margin: 0 auto;
    svg {
      width: 260px;
    }
   }
  

`
const NextButton = styled.span`
  position: absolute;
  width: 44px;
  top: 245px;
  right: -16px;
  z-index:1;
  @media screen and (max-width: 640px) {
    right: -25px;
  }

`

const PreviousButton = styled.span`
  position: absolute;
  width: 44px;
  top: 245px;
  left: -33px;
  z-index:1;
  @media screen and (max-width: 640px) {
    left: -25px;
  }
`
const InvalidNext = styled(Invalid)`
  transform: rotate(180deg);
`
const Prev = styled(Next)`
  transform: rotate(180deg);
`
const StepContent = styled.div`
width: 100%;
padding: 34px 71px;
padding-left: 100px;
display: flex;
justify-content: space-between;
margin-bottom: 66px;
background-color: #FFFFFF;
border: 1px solid #000;
position: relative;
height: 475px;

@media screen and (max-width: 640px) {
  height: auto;
}

&.fade-enter {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}
&.fade-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-exit-active {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}
&.fade-enter-active,
&.fade-exit-active {
  transition: all 0.3s ease;
}

&.fade-enter-active,
&.fade-exit-active,
&.fade-exit,
&.fade-enter{
  &::after {
    display:none;
  }
}

&::after {
  content: "";
  border: 1px dashed #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 10px ;
  left: 10px ;
  z-index: -1;
  
}

@media screen and (max-width: 980px) {
  flex-direction: row;
  align-items: center;
  padding: 30px;
}

@media screen and (max-width: 640px) {
  grid-template-columns: 1fr;
  margin-top: 25px;
  height: auto;
  width: 100%;
  flex-direction: column;

  

}

`

const List = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
@media screen and (max-width: 980px) {
  width: 317px;
}
@media screen and (max-width: 640px) {
  width: auto;
  ul {
    margin-left: 16px;
    margin-left: 39px;
  }
}
img {
  width: 240px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
${H4}{
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  @media screen and (max-width: 980px) {
      font-size: 20px;
      line-height: 32px;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 15px;
    margin-top: 15px;
}
}
p {
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width: 980px) {
    font-size: 14px;
    line-height: 24px;
  }
 }
 ul {
  margin-top: 15px;
  margin-left: 14px;
 }




li {
  padding-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;

  @media screen and (max-width: 640px) {
    font-size: 18px;
  }

}


}

`
const Trusted = styled.p`
  letter-spacing: 2px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #979797;
`

export default function FastEasy({
  gridArea,
}) {

  const [activestep, setactivestep] = useState(0)
  const [prev, setPrev] = useState(false)
  const [next, setNext] = useState(true)

  const stepsContent = [
    {
      logo: Admiral,
      content: `
      <h4>One of the biggest trading platforms use Checkin.com to identify customers and prevent fraud globally.</h4>
      <br/>
      <p>"While even more important we have been able to open up new countries as a result of our ability to successfully verify clients from countries where previously we did not have such an option."</p>
      <br/>
      <p><b>Andreas Ioannou</b>, Head of Global Compliance</p>
      `,
      image: ConversionRa
    },
    {
      logo: Telexoo,
      content: `
      <h4>Swiss currency exchange Telexoo use Checkin.com to secure international bank transfers</h4>
      <br/>
      <p>“This is a service that gives us absolutely what we need. The integration is fantastically fast.<br/> Checkin.com is just a great find for companies in our industry.”</p>
      <br/>
      <p><b>Michael Desforges</b>, CEO of Telexoo</p>
      `,
      image: ConversionRa
    },
    {
      logo: Finance,
      content: `
      <h4>Finance Inc’s service which connects card issuance and e-wallets use Checkin.com for secure onboarding.</h4>
      <br/>
      <p>“Checkin.com has helped us build an onboarding process that’s perfect for all parties involved. It’s sufficient for the regulator, time- and cost-saving for the company, and results in smoother and faster sign-up for our customers. Checkin.com is a solution that gives value for money!”</p>
      <br/>
      <p><b>Noel Bartolo</b>, Chief Compliance Officer & MLRO

      </p>
      `,
      image: ConversionRa
    },
    {
      logo: Decta,
      content: `
      <h4>Decta is a global platform for processing global payments which uses Checkin.com to onboard and verify customers.</h4>
      <br/>
      <p>“Automation is something we should all strive for. As the pace of life is accelerating, and as a company that provides a licensed product, we must speed up all verification processes as much as possible. Together with Checkin.com, we’ve been able to achieve that.”</p>
      <br/>
      <p><b>Jevgenijs Godunovs</b>, Director, MLRO</p>
      `,
      image: ConversionRa
    }

  ]

  useEffect(() => {
    setPrev(activestep > 0)
    setNext(activestep < stepsContent.length - 1)

  }, [activestep]);

  return (
    <Container gridArea={gridArea}>
      <NextButton className="sliders" onClick={() => next ? setactivestep(activestep + 1) : null}> {next ? (<Next />) : (<InvalidNext />)}</NextButton>
      <PreviousButton className="sliders" onClick={() => prev ? setactivestep(activestep - 1) : null}>{prev ? (<Prev />) : (<Invalid />)}</PreviousButton>

      <SwitchTransition >
        <CSSTransition
          key={activestep}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="fade"
        >

          <StepContent>

            <List>
              <Trusted>Trusted by</Trusted>
              <img src={stepsContent[activestep].logo} />
              <div dangerouslySetInnerHTML={{ __html: stepsContent[activestep].content }} ></div>
            </List>
            <ListImage>
              <ConversionRa />
            </ListImage>
          </StepContent>
        </CSSTransition>
      </SwitchTransition>

    </Container>
  )
}
