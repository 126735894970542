import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import Spinner from "../../components/greenSpinner.js"


import SEO from "../seo"

const Container = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;

  height: ${props => (props.height ? props.height : "auto")};
  width: ${props => (props.width ? props.width : "100%")};

  @media screen and (max-width: 980px) {
    flex: none;
    height: 700px;
  }

  @media screen and (max-width: 640px) {
    height: 626px;
    height: ${props => props.mobileHeight};
    width: 100%;
    &.modalCalendar {
        position: absolute;
        top: 0px;
      }
  }

    

`


const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  svg {
    margin-bottom: 15px;
  }
`
export default function MeetingBooker({ width, height, debug, className, mobileHeight }) {
  const script = useRef()
  const iframe = useRef()
  const [loaded, setLoaded] = useState(false)

  const email = useSelector(
    state => state.beta.email
  )

  useEffect(() => {
    const addScript = d => {
      if (script.current) return
      script.current = d.createElement("script")
      script.current.type = "text/javascript"
      script.current.async = true
      script.current.onload = (a) => {
        setTimeout(() => {
          setLoaded(true)
        }, 500);
      }
      script.current.src =
        "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      d.getElementsByTagName("head")[0].appendChild(script.current)
    }

    const removeScript = d => {
      if (!script.current) return
      let head = d.getElementsByTagName("head")[0]
      head.removeChild(script.current)
      script.current = null
      let remainingScript = d.getElementById("hs-script-loader")
      let scriptParent = remainingScript && remainingScript.parentNode
      remainingScript && scriptParent.removeChild(remainingScript)
    }

    addScript(document)

    return () => removeScript(document)
  }, [])

  return (
    <Container
      width={width}
      height={height}
      className={className}
      mobileHeight={mobileHeight}
    >
      <div
        ref={iframe}
        className="meetings-iframe-container meeting-calender"
        data-src="https://meet.checkin.com/meetings/g0/your-intro?embed=true"
      >
        {!loaded ? (
          <SpinnerContainer>
            <Spinner />
            <p>Finding available times</p>
          </SpinnerContainer>
        ) : null}
      </div>
    </Container>
  )
}