import React from "react"
import styled from "styled-components"

import ExpandableContainer from "../product/expandaple-container"
import ActionButton from "../action-button"
import IllustratedFeature from "../product/illustrated-feature"
import CodeExamples from "../product/code-examples"


const H2 = styled.h2`
    font-weight: 800;
    font-size: 34px;
    line-height: 36px;
    text-align: center;
`
const Easy = styled.div`
  grid-area: easy;
  display: flex;
    flex-direction: column;
    align-items: center;
`

const MiddleText = styled.div`
    margin: 0 auto;
    padding: 0 180px;

    p{
      width: 700px;
      margin-top: 16px;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
  @media screen and (max-width: 980px) {
    padding: 0px;
   p {
    width: auto;
   }
  }
  @media screen and (max-width: 640px) {
    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  ${H2} {
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
  }
}
`
const IllustratedFeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 60px;
  row-gap: 60px;
  margin: 70px 0 0;

  
  @media screen and (max-width: 980px) {
    grid-template-columns: repeat(2, 250px);
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }


`

const SimpleFeaturesContainerontainer = styled(IllustratedFeaturesContainer)`
  width: 1010px;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 128px;
  margin: 120px 0 0;

  @media screen and (max-width: 980px) {
    grid-template-columns: repeat(2, 1fr);
    width: 620px;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    width: 334px;

    & > div {
      width: 100%;
    }

    h4 {
      width: 100%;
    }
  }
`

const ReadDoc = styled(ActionButton)`
width: 264px;
width: ${props => props.width};
margin-top: 24px;
font-size: 18px;

@media screen and (max-width: 980px) {
  margin: 30px auto;

}
`

const EasyComponent = () => {
  return (
    <Easy>
      <ExpandableContainer maxHeight={1670}>
        <MiddleText>
          <H2>Easy to implement in your site/app</H2>
          <p>
            Everything is prepared by us to fit your existing architecture. Once your partner key is active you can launch your flows from anywhere.
          </p>
        </MiddleText>

        <CodeExamples />
        <a href="https://dev.checkin.com/docs/home" target="_blank">
          <ReadDoc
            width='auto'
          >Read documentation</ReadDoc>
        </a>


        <SimpleFeaturesContainerontainer>
          <IllustratedFeature
            header={"Simple front-end integration"}
            text={
              "One line of code loads the framework and will return your data in JavaScript callbacks."
            }
          />
          <IllustratedFeature
            header={"Connects to every platform"}
            text={
              "Your data is easily sent and validated via any API or endpoint that you already use."
            }
          />
          <IllustratedFeature
            header={"Reuse your existing API endpoints"}
            text={
              "We configure your setup to use the same labels and format as your current solution."
            }
          />
          <IllustratedFeature
            header={"You get the same data as before"}
            text={
              "The user data collected will be the same as the data you collect today."
            }
          />
          <IllustratedFeature
            header={"We never store your data"}
            text={
              "Your customers’ privacy and data are fully protected, as no data  will be stored on our end."
            }
          />
          <IllustratedFeature
            header={"Built-in and custom validations"}
            text={
              "We have multiple out of the box validations, but you can also run your own validations on any data."
            }
          />
        </SimpleFeaturesContainerontainer>
      </ExpandableContainer>
    </Easy>
  )
}

export default EasyComponent