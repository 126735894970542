import React from 'react'
import styled, { css } from 'styled-components'
import GridElements from "../../components/common/grid-elements"
import Accuracy from "../../assets/images/face-recognition/accuracy.inline.svg"
import Oneton from "../../assets/images/face-recognition/oneton.inline.svg"
import Compatibility from "../../assets/images/face-recognition/compatibility.inline.svg"
import Spoofing from "../../assets/images/face-recognition/spoofing.inline.svg"

const Section = styled.section`
    flex-direction: column;
    align-items: center;
    grid-area: module;
    padding: 0 92px;

    @media screen and (max-width: 980px) {
        padding: 0px;
    }
`

export default function Module() {
    return (
        <Section >
            <GridElements
                gridTemlplateCol="1fr 1fr"
                columnGap='120px'
                data={[
                    {
                        image: <Accuracy />,
                        title: 'High accuracy',
                        text: 'Achieves extreme accuracy in face matching using convolutional neural networks (CNNs) for feature extraction.'
                    },
                    {
                        image: <Oneton />,
                        title: '1-to-N and 1-to-1',
                        text: 'Provides scalable face recognition solutions for both identification (1:N) and verification (1:1) scenarios.'
                    },
                    {
                        image: <Compatibility />,
                        title: 'Cross-Device Compatibility',
                        text: 'Supports deployment across multiple platforms, including mobile (iOS/Android) and web via RESTful APIs.'
                    },
                    {
                        image: <Spoofing />,
                        title: 'Spoofing Detection',
                        text: 'Our advanced technology identifies live faces and blocks fake images, masks, and videos. '
                    }

                ]} />
        </Section>
    )
}
