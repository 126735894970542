import React, { useContext, useEffect, createRef, useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import lottie from 'lottie-web/build/player/lottie_light.min.js'



import Workflow from "../../assets/images/fintech/workflow.inline.svg"
import Easy from "../../assets/images/fintech/easy.inline.svg"
import Secure from "../../assets/images/fintech/secure.inline.svg"
import Statistics from "../../assets/images/fintech/statistics.inline.svg"
import ActionButton from "../action-button"
import GlobalContext from "../../context/global-context"




const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  z-index: 1;

  &:last-child {
    margin-bottom: 80px;
  }
  &#delivery, &#fast-easy {
    @media screen and (max-width: 640px) {
      margin-bottom: 0px;
    }
  }

`

const H2 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;

  

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;
    br {
      display: none;
    }
  }
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`
const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
  }
`

const SectionDescription1 = styled(SectionDescription)`
  width: 679px;

  @media screen and (max-width: 980px) {
    width: 578px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const FeaturesContainer2 = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
margin-bottom: 50px;
flex-direction: column;


@media screen and (max-width: 980px) {
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  width: 296px;
  flex-direction: column;
}

`
const FeaturesColumn = styled.div`
display: flex;
justify-content: inherit;
@media screen and (max-width: 640px) {
  flex-direction: column;
}
`

const Feature2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  z-index: 1;
  margin-top: 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column !important;
  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }
  

  ${H4} {
    margin-bottom: 16px;
    margin-top: 15px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
    width: 424px;

    @media screen and (max-width: 980px) {
      width: 278px;
      text-align: left;
      font-size: 16px !important;
      line-height: 28px !important;

    }
    
  }

  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    p{
    text-align: center !important;
    }
  }


`

const ImageComtainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    height: auto;
  }
`

const Buttons = styled.div`
  display: flex;
  width: 55%;
  justify-content: space-around;
  bottom: 0px;

  

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  @media screen and (max-width:640px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;


    button, a {
      width: 100%;
    }
  }
`

const FeaturesButton = styled(ActionButton)`
  background: #fff;
  color: #000;
  border: 1px solid #000;
  outline: none;
  box-shadow: none;
  padding: 18px 40px;
  font-size: 18px;
  font-weight: 700;
  margin-top:30px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    border: 1px solid #A5A5A5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 5px ;
    left: 5px ;
    z-index: -1;
  }

  &:hover {
    box-shadow: 5px 5px 0 0 #000;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`

const Animation = styled.div`

display: block;

@media screen and (max-width: 640px) {
  display:none;
}

`
const AnimationMobile = styled.div`
display: none;

@media screen and (max-width: 640px) {
  display:block;
}

`

export default function FastEasy({
  gridArea,
}) {
  const globalContext = useContext(GlobalContext)
  let mobileAnimation = createRef();
  let desktopAnimation = createRef();
  let animDesktop, animMobile = null;
  const [isMobileOnly, setIsMobileOnly] = useState(false)


  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 640 ? setIsMobileOnly(true) : setIsMobileOnly(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    animDesktop = lottie.loadAnimation({
      container: window.innerWidth <= 640 ? mobileAnimation.current : desktopAnimation.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: window.innerWidth <= 640 ? "https://assets3.lottiefiles.com/packages/lf20_aa6ualwp.json" : "https://assets8.lottiefiles.com/packages/lf20_l1tjqn1o.json"

    });

  }, []);

  return (
    <Container gridArea={gridArea}>
      <Sectionheader primary>
        <H2>Backoffice with high level of<br /> control is always accessible</H2>
        <SectionDescription1>
          See your onboarding at a glance or dive into every detail you need and help your team fine-tune their workflow and make better decisions based on incoming data.
        </SectionDescription1>
      </Sectionheader>

      <Animation ref={desktopAnimation} />
      <AnimationMobile ref={mobileAnimation} />


      <FeaturesContainer2>
        <FeaturesColumn>
          <Feature2>
            <ImageComtainer>
              <Easy />
            </ImageComtainer>
            <H4>Easy-to-use dashboard </H4>
            <p>Navigate the dashboard to observe the incoming requests and their statuses, types of used checks. Advanced filtering comes handy for finding the user customer data.</p>
          </Feature2>
          <Feature2>
            <ImageComtainer>
              <Secure />
            </ImageComtainer>
            <H4>Secure Data storage</H4>
            <p>Personal Data is stored on Database servers in the EU situated in AWS or Google Cloud, compliant with ISO 27001 and GDPR.
              All personal data is heavily encrypted and can only be accessed by an authorized person.
            </p>
          </Feature2>

        </FeaturesColumn>
        <FeaturesColumn>

          <Feature2>
            <ImageComtainer>
              <Workflow />
            </ImageComtainer>
            <H4>Adjust your workflow</H4>
            <p>Back-office leaves you plenty of room for flexibility. Set preferrable verification rules for all types of checks, document quality, fraud assessment, quality and validity of extracted data, manage user roles, and much more.</p>
          </Feature2>
          <Feature2>
            <ImageComtainer>
              <Statistics />
            </ImageComtainer>
            <H4>Statistics</H4>
            <p>Visual representation of incoming data streams, verification results and statuses.
              Take better decisions for particular markets, types of documents, and improve your internal processes.
            </p>
          </Feature2>
        </FeaturesColumn>
      </FeaturesContainer2>

      <Buttons>
        <Link
          state={{
            modal: true,
          }}
          to={`/industry-fintech/features`} >
          <FeaturesButton>
            See all features
          </FeaturesButton>
        </Link>

        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Buttons>


    </Container>
  )
}
