import React from "react"
import styled from "styled-components"

import ExpandableContainer from "../product/expandaple-container"
import IllustratedFeature from "../product/illustrated-feature"

import Guaranteed from "../../assets/images/conversion.svg"
import Localized from "../../assets/images/common/drive.svg"
import Tested from "../../assets/images/common/lizzard.svg"
import Trusted from "../../assets/images/common/safe.svg"
import Adopted from "../../assets/images/common/custom.svg"
import Whitelabeled from "../../assets/images/common/global.svg"


const H2 = styled.h2`
    font-weight: 800;
    font-size: 34px;
    line-height: 36px;
    text-align: center;
`
const Proven = styled.div`
  grid-area: proven;
`

const MiddleText = styled.div`
    margin: 0 auto;
    padding: 0 180px;

    p{
      width: 700px;
      margin-top: 16px;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
  @media screen and (max-width: 980px) {
    padding: 0px;
   p {
    width: auto;
   }
  }
  @media screen and (max-width: 640px) {
    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  ${H2} {
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
  }
}
`

const IllustratedFeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 60px;
  row-gap: 60px;
  margin: 70px 0 0;

  
  @media screen and (max-width: 980px) {
    grid-template-columns: repeat(2, 250px);
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }


`
const ProvenComponent = () => {
  return (
    <Proven>
      <ExpandableContainer maxHeight={1500}>
        <MiddleText>
          <H2>Proven technology</H2>
          <p>
            Switching to Checkin.com is risk-free with instant and measurable
            value being added from day one
          </p>
        </MiddleText>

        <IllustratedFeaturesContainer>
          <IllustratedFeature
            image={(<img alt='illustration' src={Trusted} width="70" height="77" />)}
            header={"Trusted by millions of end-users"}
            text={
              "Checkin.com has successfully processed millions of sessions from end-users in more than 165 countries"
            }
          />
          <IllustratedFeature
            image={(<img alt='illustration' src={Adopted} width="70" height="77" />)}
            header={"Adopted by leading brands"}
            text={
              "Together with our customers, who operate in highly competitive industries, we have grown their user base in hundreds hundreds of countries."
            }
          />
          <IllustratedFeature
            image={(<img alt='illustration' src={Guaranteed} width="70" height="77" />)}
            header={"Guaranteed to outperform"}
            text={
              "Checkin.com guarantees that our framework will deliver a conversion increase when A/B-testing us vs your existing solution"
            }
          />
          <IllustratedFeature
            image={(<img alt='illustration' src={Localized} width="70" height="77" />)}
            header={"Thoroughly tested modules"}
            text={
              "Rigorous testing of all components ensures high conversion rates and a solid user experience"
            }
          />
          <IllustratedFeature
            image={(<img alt='illustration' src={Whitelabeled} width="70" height="77" />)}
            header={"Localized with global coverage"}
            text={
              "Correct translations, local user-tests, and relevant technical solutions have all been taken care of to benefit you from the start"
            }
          />
          <IllustratedFeature
            image={(<img alt='illustration' src={Tested} width="70" height="77" />)}
            header={"White-labeled and seamless"}
            text={
              "Every aspect of your checkin is branded with your assets to create a seamless experience for your customers"
            }
          />
        </IllustratedFeaturesContainer>
      </ExpandableContainer>
    </Proven>
  )
}

export default ProvenComponent