import React, { useContext, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled, { css, keyframes } from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { pushCustomDataLayerEvent } from "../state/actions"
import GlobalContext from "../context/global-context"

import RegilyBlackLogo from "../assets/images/regily-black-logo.svg"
import closeIcon from "../assets/images/close-icon.svg"

import MenuElements from "../components/common/sub-menu"
import MenuData from "../utils/menu.js"

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;

  }
`

const Collapse = keyframes`
  from {
    height: 300px;
  }

  to {
    height: 0px;

  }
`

const FadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`


const Logo = styled(Link)`
    width: 230px;
    height: 28px;
    text-indent: -9999px;
    background: url(${RegilyBlackLogo}) no-repeat center/contain;
    @media screen and (max-width: 980px) {
        display: none;
      }
    `

const Wrapper = styled.div`
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background: ${props => props.color};

    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 980px) {
      position: static;
    }

    ${props =>
    props.color &&
    css`

      // transition: background 0.5s ease;

      `}

    ${props =>
    props.hide &&
    css`
        @media screen and (max-width: 640px) {
          display: none;
        }
      `}
      transition: background 0.5s ease;
  `

const Container = styled.div`
    height: 72px;
    max-width: 1380px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media screen and (max-width: 1380px) {
      left: 0;
      width: 100%;
      right: 0;
    }

    @media screen and (max-width: 980px) {
      justify-content: center;

      &:after {
        margin: auto;
      }
    }

    @media screen and (max-width: 640px) {
      display: none;
    }
  `

const NavContainer = styled.div`
    display: flex;
    margin-left: auto;
    postion: relative;
    @media screen and (max-width: 980px) {
      display: none;
    }
  `

const NavLink = styled.div`

    padding: 0 17px;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px dashed transparent;
    font-size: 13px;
    transition: 0.4s border;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    cursor: default;
    span {
      height: 13px;
    }
    color: #0D0D0D;

    &.active,
    &:hover {
      color: #4A4A4A;
    }

    &.unhoverable {
      border: none;
    }

    &.hide {
      display: none;
    }
  `

const GetStartedButton = styled.span`
    display: flex;
    position: relative;
    height: 40px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 13px;
    transition: 0.4s border;
    margin-left: 5px;
    font-weight: 700;
    cursor: pointer;

    span {
      height: 13px;
    }

    &.active,
    &:hover {
      border: 1px dashed #000;
    }
    &:hover {
      box-shadow: 5px 5px 0 0 #000;
    }

    &.unhoverable {
      border: none;
    }

    &.hide {
      display: none;
    }

    border: 1px solid #000 !important;
    background: #fbd65d;
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
    padding: 15px 24px;
  `

const Banner = styled.div`
    width: calc(100% + 40px);
    height: 70px;
    background: #fbd63e;
    display: flex;
    color: #000;
    align-items: center;
    padding: 0 80px 0 20px;
    box-sizing: border-box;
    position: relative;
    justify-content: center;

    p {
      br {
        display: none;
      }

      a {
        color: #000;
        text-decoration: underline;
        font-weight: 700;
      }
    }

    @media screen and (max-width: 980px) {
      p {
        line-height: 28px;

        br {
          display: initial;
        }
      }
    }

    @media screen and (max-width: 640px) {
      p {
        font-size: 13px;
        line-height: 20px;
      }
    }
  `

const CloseButton = styled.button`
    height: 40px;
    width: 40px;
    border-radius: 2px;
    box-shadow: none;
    border: none;
    position: absolute;
    right: 20px;
    background: url(${closeIcon}) no-repeat center, rgba(255, 255, 255, 0.4);
    cursor: pointer;
  `

const SubMenu = styled.div`

    position: absolute;
    top: 72px;
    background: #FFFFFF;
    border: 0.5px solid #000000;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.2);

  &.fadeOut{
    // animation: ${FadeOut} 0.1s ease forwards 0s, ${Collapse} 0.5s ease forwards 1s;
    display: none;
  }
  
  &.fadeIn{
    // animation: ${FadeIn} 0.6s ease forwards 0s;
    display: block;
  }
  // ${props => props.active && css`
  //   opacity: 1;
  //   display: block;
  // `}

    &.Product{
      padding: 25px 25px;
      right: 0px;
      .image{
        width: 60px;
      }
    }

    &.Industries, &.Resources, &.Company{
      padding: 25px 25px;
      .feature{
        padding-left: 0px;
        height: 45px;
      }
      .image{
        height: auto;
        margin: 0px 16px 0px 6px;
      }
      h2{
        margin: 0px;
      }
    }

  
  `

const FakeDiv = styled.div`
  height: 30px;
  left: 0px;
  top: -26px;
  position: absolute;
  width: 100%;
  background:transparent;
  `

const NavBar = ({ color, location }) => {
  const dispatch = useDispatch()
  const globalContext = useContext(GlobalContext)
  const [showSubMenu, setShowSubMenu] = useState(false)
  const isMobile = useSelector(state => state.global.isMobileOnly)

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    const menuItems = document.querySelectorAll('.menu-item');

    menuItems.forEach((item) => {
      const index = item.id;
      const handleMouseEnter = () => {
        setSelected(index);
        globalContext.toggleSubMenu(true)
      };

      const handleMouseLeave = () => {
        globalContext.toggleSubMenu(false)
      };
      item.addEventListener('mouseenter', handleMouseEnter);
      item.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        item.removeEventListener('mouseenter', handleMouseEnter);
        item.removeEventListener('mouseleave', handleMouseLeave);
      };
    });
  }, []);


  return (
    <Wrapper color={color} hide={location === "/"}>
      {!["/industry-igaming/", "/meeting-booker/", "/industry-igaming/netherlands/"].includes(location) &&
        globalContext.showIPOBanner && (
          <Banner>
            <p>
              <span>
              </span>
              <a
                target="blank"
                href="https://www.finwire.tv/webcast/checkin-com/q3-2021/"
              >
                Watch our live Webcast for the Q3 report 2021 at 8:30 CET (Swedish only)  →
              </a>
            </p>
            <CloseButton onClick={() => globalContext.toggleIPOBanner(true)} />
          </Banner>
        )}

      <div></div>
      <Container>
        {!isMobile ? <Logo aria-label="Checkin" to="/" /> : null}
        {location !== "/meeting-booker/" && (
          <NavContainer >
            <React.Fragment>

              {MenuData.map((item, index) => {
                return (<NavLink
                  className="menu-item"
                  id={index}
                  key={index}
                  to={'#'}
                  hrefLang="en"

                >
                  {item.title}

                  <SubMenu
                    className={`${item.title} ${globalContext.showSubMenu && selected == index ? 'fadeIn' : 'fadeOut'}`}
                  >
                    <FakeDiv />
                    <MenuElements
                      width={item.width}
                      gridTemlplateCol={item.gridTemlplateCol}
                      columnGap='40px'
                      data={MenuData && selected !== null && MenuData[selected].items} />
                  </SubMenu>
                </NavLink>)
              })}


            </React.Fragment>

            <GetStartedButton
              className="unhoverable"
              onClick={e => {
                e.preventDefault()
                dispatch(
                  pushCustomDataLayerEvent({
                    event: "get_started",
                    eventCategory: "get_started",
                    eventAction: "open",
                    eventLabel: "topmenu",
                  })
                )
                globalContext.openGetStarted()
              }}
            >
              Get started
            </GetStartedButton>
          </NavContainer>
        )}


      </Container>

    </Wrapper >
  )
}

NavBar.defaultProps = {
  minimized: true,
  color: "#f9f9f8",
}

NavBar.prototypes = {
  minimized: PropTypes.bool,
  color: PropTypes.string,
}

export default NavBar