import React, { useState } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { SwitchTransition, CSSTransition } from "react-transition-group"

import arrow from "../../assets/images/arrow-next.svg"

import AngleIcon from "../../assets/images/angle.inline.svg"

import { DesktopView, MobileView, TabletView } from "../device-views"

const Angle = styled(AngleIcon)`
  width: 30px;
  transition: all 0.2s ease;
  opacity: 0;
  ${props =>
    props.active &&
    css`
      fill: #000;
      opacity: 1;
    `}
`

const Container = styled.div`
  display: flex;
  grid-area: partner-quotes;
  margin-bottom: 120px;
  margin-top: 70px;
`

const FeatureSelector = styled.div`
  display: flex;
  flex: 1;
`

const FeatureSelectorList = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 0 34px 0 68px;

  @media screen and (max-width: 980px) {
    padding: 0;
  }
`

const Arrow = styled.span`
  width: 27px;
  height: 18px;
  background: url(${arrow}) no-repeat center;
  fill: #000;
`

const FeatureItem = styled.li`
  font-size: 24px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  overflow: hidden;
  box-sizing: border-box;

  & > span {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 0 18px;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: normal;
  }

  ${Arrow} {
    opacity: 0;
    transition: all 0.2s ease;
  }

  @media screen and (max-width: 980px) {
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;
    font-size: 20px;
  }

  ${props =>
    props.isselected &&
    css`
      @media screen and (max-width: 980px) {
        max-height: 100%;
      }

      & > span {
        background: #fff;
        color: #000;
        border: 1px solid #040404;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.0853365);
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        @media screen and (max-width: 980px) {
          margin: 0;
        }
      }

      ${Arrow} {
        opacity: 1;
      }
    `}
`

const QuoteCardContainer = styled.div`
  flex: 1;
  z-index: 1;
  height: 400px;

  @media screen and (max-width: 980px) {
    box-sizing: border-box;
    height: auto;
    max-height: 100%;
    max-height: 0%;
    opacity: 0;
    overflow: hidden;
    transition: all 0.2s ease;
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;

    ${props =>
    props.active &&
    css`
        max-height: 100%;
        opacity: 1;
      `}
  }
`

const QuoteCard = styled.div`
  margin: 0 60px 0 0;
  background: #fff9f2;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
  display: flex;
  height: 430px;

  @media screen and (max-width: 980px) {
    margin: 20px 0;
    height: auto;
  }

  &::after {
    content: "";
    border: 1px dashed #000;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px;
    left: 10px;
    z-index: -1;
  }

  & > div {
    flex: 1;
    background: #fff9f2;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &.fade-enter {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.2s ease;
  }
`

const QuoteCardText = styled.p`
  font-size: 18px;
  line-height: 30px;

  span {
    font-weight: 700;
  }
  ul {
    margin-top: 20px;
    margin-left: 25px;
  }
`

const Header = styled.h1`
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 24px;
`



export default function PartnerQuotes() {
  const [selectedFeature, setSelectedFeature] = useState(0)
  const [isMobileOnly] = useSelector(state => [state.global.isMobile])

  const quotes = [
    {
      feature: "End-to-end solution",
      header: "No need to build dozens of solutions",
      quoteText: `With a single integration, the software is capable of handling every aspect of signup, identity, and login in the Netherlands. You gain immediate access to all mandatory solutions.`,
      author: "Tobias Carlsson",
      position: "CEO, Rhinoceros operations",
    },
    {
      feature: "Save time and resources",
      header: "Skip the hard work ",
      quoteText: `Due to a large number of technical requirements for compliant operations in the Netherlands, building and integrating every solution from scratch demands hundreds of hours of work – with no guarantees of performance. With Checkin.com, everything you need works out of the box and is updated globally.`,
      author: "Jhone Lucky",
      position: "CEO, Lucky Days",
    },
    {
      feature: "Flexible implementation",
      header: "Integrate in the way that suits you",
      quoteText: `You decide if you wish to let the software handle the entire registration flow as a part of your site/app or just improve individual aspects of signup, identity, and login. Either way, you can be sure that Checkin.com is easy to integrate in any way that suits your needs – be it front-end JS, SDK or direct API access.`,
      author: "Jane Hero",
      position: "CMO, Hero Gaming",
    }
  ]
  return (
    <Container>
      <FeatureSelector>
        <FeatureSelectorList>
          {quotes.map((quote, index) => (
            <FeatureItem key={index} isselected={index === selectedFeature}>
              <span onClick={() => setSelectedFeature(index)}>
                {quote.feature}
                {isMobileOnly ? (
                  <Angle active={index === selectedFeature} />
                ) : (
                  <Arrow />
                )}
              </span>

              <TabletView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>

                      <Header>{quotes[index].header}</Header>
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].quoteText,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </TabletView>

              <MobileView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>
                      <Header>{quotes[index].header}</Header>
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].quoteText,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </MobileView>
            </FeatureItem>
          ))}
        </FeatureSelectorList>
      </FeatureSelector>

      <DesktopView>
        <QuoteCardContainer>
          <SwitchTransition>
            <CSSTransition
              key={selectedFeature}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
            >
              <QuoteCard>
                <div>
                  <Header>{quotes[selectedFeature].header}</Header>

                  <QuoteCardText
                    dangerouslySetInnerHTML={{
                      __html: quotes[selectedFeature].quoteText,
                    }}
                  />
                </div>
              </QuoteCard>
            </CSSTransition>
          </SwitchTransition>
        </QuoteCardContainer>
      </DesktopView>
    </Container>
  )
}
