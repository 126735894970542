import React, { useRef, useEffect, useState, useContext } from "react"
import styled from "styled-components"

import ActionButton from "../../components/action-button.js"
import Botlens from '../../assets/images/backoffice1.png'
import Botlens2 from '../../assets/images/botlens/backoffice2.png'

const Expandable = styled.div`
    grid-area: backoffice;
    position: relative;
    display:flex;
    justify-content: center;
    margin-top: 32px;
    border: 1px solid #000; 
    margin-bottom: 12px;


    &::after {
    content: "";
    border: 1px dashed #000;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px ;
    left: 10px ;
    z-index: -1;
    
    }

    &.expandable-image {
    position: relative;
    }

    .image-container {
    width: 100%;
    height: 495px; 
    background-size: cover;
    transition: height 0.5s, width 0.5s, bottom 0.5s;

    }

    .backspot {
        position: absolute;
        bottom: 32px;
        width: 95%;
        z-index: -1;
        transition:height 0.5s, width 0.5s, bottom 0.5s;
        border: 1px solid #000; 

    }

    .frontspot {
        bottom: 0px;
        width: 100%;
        z-index: 1;
        transition: height 0.5s, width 0.5s, bottom 0.5s;
    }



    &.expandable-image.expanded .image-container.frontspot {
    height: 1255px; /* Set the expanded height to show the full image */
    }

    &.expandable-image.expanded .image-container.frontspot.background2 {
        height: 880px; /* Set the expanded height to show the full image */
        }

        &.expandable-image.expanded .image-container.backspot {
            top: -32px;
            }
    
    @media screen and (max-width: 980px) {
        .image-container.frontspot {
            border: none;
        }
        &.expandable-image.expanded .image-container.frontspot.background2 {
            height: 540px; /* Set the expanded height to show the full image */
            }
        &.expandable-image.expanded .image-container.frontspot {
            height: 765px; /* Set the expanded height to show the full image */
            }

    }
`

const Expand = styled.button`
    height: 56px;
    padding: 12px 40px; 
    border: 1px solid #000;
    background:  #FDFDFC;
    box-shadow: 4px 4px 0px 0px rgba(13, 13, 13, 0.10);
    position: absolute;
    bottom: 24px;
    font-size: 16px;
    font-weight: 700; 
    z-index: 2;
`

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px; /* Adjust the height of the gradient overlay as needed */
  background: linear-gradient(to bottom, rgba(249, 249, 248, 0.00) 1.76%, #F9F9F8 65.5%); 
  z-index: 1;
`;




const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin: 30px auto;

  }

  @media screen and (max-width: 640px) {
    margin-top: 22px;
  }
`

export default function ExpandableC({ location }) {
    const [expanded, setExpanded] = useState(false);
    const [selected, setSelected] = useState(true);


    const handleExpand = () => {
        setExpanded(!expanded);
    };

    return (




        <Expandable className={`expandable-image ${expanded ? 'expanded' : ''}`}>
            <div onClick={() => setSelected(prev => !prev)} className={`image-container ${selected ? 'frontspot' : 'backspot'}`} style={{ backgroundImage: `url(${Botlens})` }} />
            <div onClick={() => setSelected(prev => !prev)} className={`image-container background2 ${!selected ? 'frontspot' : 'backspot'}`} style={{ backgroundImage: `url(${Botlens2})` }} />
            {expanded ? null : (<GradientOverlay />)}
            <Expand onClick={handleExpand}>{expanded ? 'Collapse' : 'See More'}</Expand>
        </Expandable>

    )
}
