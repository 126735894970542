import styled from "styled-components"
import LayoutGrid from "../layout-grid"

const Grid = styled(LayoutGrid)`
  grid-template-columns: auto repeat(12, 68px) auto;
  grid-gap: 30px;
  padding-top: 120px;
  z-index:1;

  .travel {
   height: 100% !important;
  background-position: bottom left; 

  }

  .finance {
    height: 100% !important;
    background-size: 100%;
  }

  .finance, .igaming {
    height: 230px !important;
    
  }
  
 

  @media screen and (max-width: 980px) {

    .results-text {
      display: none;
    }

    .smallback{
    height: 230px !important;
    background-size: 50%;

    }

    .travel {
      background-size: 100%;
      background-position-y: bottom;
    }
  }

  @media screen and (max-width: 640px) {

    .finance, .igaming , .travel {
      height: 320px !important;
    }

    .finance {
      background-size: 193% !important;
    }

    .travel {
      background-size: 100% !important;
      background-position-y: bottom;
    }
    .smallback{
      background-size: 39%;
    }
  }

  @media screen and (max-width: 980px) {
    grid-template-columns: auto repeat(8, 68px) auto;
    grid-gap: 20px;
    overflow: hidden;
    padding-top: 50px;

  }

  @media screen and (max-width: 640px) {
    grid-template-columns: auto 147px 147px auto;
    grid-gap: 45px 25px;
    overflow: hidden;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: auto 147px 147px auto;
    grid-gap: 21px;
    grid-row-gap: 40px;
    overflow: hidden;
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: auto 132px 132px auto;
    grid-gap: 0px;
    grid-row-gap: 40px;
    overflow: hidden;
  }

  grid-template-areas:
    ". . . header header header header header header header header . . ."
    "slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine"
    ". . features-container features-container features-container features-container features-container features-container features-container features-container features-container features-container . ."
    ". . . . . . . . . . . . . ."
    ". . . . talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . ai-analysis-text ai-analysis-text ai-analysis-text ai-analysis-text ai-analysis-text ai-analysis-text . . . ."
    ". ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . every-text every-text every-text every-text every-text every-text . . . ."
    ". every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . how-works-text how-works-text how-works-text how-works-text how-works-text how-works-text how-works-text how-works-text . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". liveness liveness liveness liveness liveness liveness liveness liveness liveness liveness liveness liveness ."
    ". signature signature signature signature signature signature signature signature signature signature signature signature ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . results-text results-text results-text results-text results-text results-text results-text results-text . . ."
    ". results results results results results results results results results results results results ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . usecase-text usecase-text usecase-text usecase-text usecase-text usecase-text usecase-text usecase-text . . ."
    ". . . . . . . . . . . . . ."
     ". fintech fintech fintech fintech fintech fintech travel travel travel travel travel travel ."
    ". fintech fintech fintech fintech fintech fintech igaming igaming igaming igaming igaming igaming ."
    ". content content content ecom ecom ecom social social social social social social ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . talk-to-sales-2 talk-to-sales-2 talk-to-sales-2 talk-to-sales-2 talk-to-sales-2 talk-to-sales-2 . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ;

  @media screen and (max-width: 980px) {
    grid-template-areas:
    ". header header header header header header header header ."
    ". slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine ."
    ". . features-container features-container features-container features-container features-container features-container . ."
    ". . . talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 . . ."
    ". . . . . . . . . ."
    ". .  ai-analysis-text ai-analysis-text ai-analysis-text ai-analysis-text ai-analysis-text ai-analysis-text  . ."
    ". ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ai-analysis ."
    ". . . . . . . . . ."
    ". . every-text every-text every-text every-text every-text every-text . ."
    ". every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution ."
    ". . . . . . . . . ."
    ". . how-works-text how-works-text how-works-text how-works-text how-works-text how-works-text . ."
    ". liveness liveness liveness liveness liveness liveness liveness liveness ."
    ". signature signature  signature signature  signature signature  signature signature ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . usecase-text usecase-text usecase-text usecase-text usecase-text usecase-text . ."
    ". . . . . . . . . ."
    ". fintech fintech fintech fintech travel travel travel travel ."
    ". content content content content igaming igaming igaming igaming ."
    ". ecom ecom  ecom ecom social social social social ."

    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . talk-to-sales-2 talk-to-sales-2 talk-to-sales-2 talk-to-sales-2 . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . .";
  }

  @media screen and (max-width: 640px) {
    grid-template-areas:
      ". header header ."
      "slot-machine slot-machine slot-machine slot-machine"
      ". features-container features-container ."
      ". talk-to-sales-1 talk-to-sales-1 ."
      ". . . ."
      ". ai-analysis-text ai-analysis-text ."
      ". ai-analysis ai-analysis ."
      ". . . ."
      ". every-text every-text ."
      ". every-solution every-solution ."
      ". . . ."
      ". how-works-text how-works-text ."
      ". liveness liveness ."
      ". signature signature ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". usecase-text usecase-text ."
      ". . . . "
      ". fintech fintech ."
      ". travel travel ."
      ". igaming igaming ."
      ". content content ."
      ". ecom ecom ." 
      ". social social ."
      ". . . ."
      ". . . ."
      ". talk-to-sales-2 talk-to-sales-2 ."
      ". . . .";
  }
`

export default Grid
