import React, { forwardRef } from "react"
import styled from "styled-components"

const Container = styled.div`
  display: contents;
`

const Header = styled.h1`
  font-size: 48px;
  line-height: 56px;
  font-weight: 900;
  text-align: center;
  grid-area: header;
  margin: 0;
  white-space: pre-line;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media screen and (max-width: 640px) {
    font-size: 34px;
    line-height: 44px;
    white-space: normal;
  }
`

const Description = styled.p`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  line-height: 30px;
  grid-area: description;
  display: flex;
  justify-content: left;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
`

const SectionIntro = forwardRef(
  ({ header = "", description = "", className }, ref) => (
    <Container className={className}>
      <Header>{header}</Header>
      <Description ref={ref}>{description}</Description>
    </Container>
  )
)

export default SectionIntro
