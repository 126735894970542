import React, { useState } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { SwitchTransition, CSSTransition } from "react-transition-group"

import arrow from "../../assets/images/arrow-next.svg"
import herogamingLogo from "../../assets/images/igaming/logos-black/herogaming-black.svg"
import casumoLogo from "../../assets/images/igaming/logos-black/casumo-black.svg"
import forzaLogo from "../../assets/images/igaming/logos-black/forza-black.svg"
import luckydaysLogo from "../../assets/images/igaming/logos-black/luckydays-black.svg"
import wunderinoLogo from "../../assets/images/igaming/logos-black/wunderino-black.svg"

import AngleIcon from "../../assets/images/angle.inline.svg"

import { DesktopView, MobileView, TabletView } from "../device-views"

const Angle = styled(AngleIcon)`
  width: 30px;
  transition: all 0.2s ease;
  opacity: 0;
  ${props =>
    props.active &&
    css`
      fill: #000;
      opacity: 1;
    `}
`

const Container = styled.div`
  display: flex;
  grid-area: partner-quotes;
`

const FeatureSelector = styled.div`
  display: flex;
  flex: 1;
`

const FeatureSelectorList = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 0 34px 0 68px;

  @media screen and (max-width: 980px) {
    padding: 0;
  }
`

const Arrow = styled.span`
  width: 27px;
  height: 18px;
  background: url(${arrow}) no-repeat center;
  fill: #000;
`

const FeatureItem = styled.li`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  overflow: hidden;
  box-sizing: border-box;

  & > span {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 0 18px;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: normal;
  }

  ${Arrow} {
    opacity: 0;
    transition: all 0.2s ease;
  }

  @media screen and (max-width: 980px) {
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;
    font-size: 20px;
  }

  ${props =>
    props.isselected &&
    css`
      @media screen and (max-width: 980px) {
        max-height: 100%;
      }

      & > span {
        background: #fff;
        color: #000;
        border: 1px solid #040404;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.0853365);
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;

        @media screen and (max-width: 980px) {
          margin: 0;
        }
      }

      ${Arrow} {
        opacity: 1;
      }
    `}
`

const QuoteCardContainer = styled.div`
  flex: 1;
  z-index: 1;
  height: 400px;

  @media screen and (max-width: 980px) {
    box-sizing: border-box;
    height: auto;
    max-height: 100%;
    max-height: 0%;
    opacity: 0;
    overflow: hidden;
    transition: all 0.2s ease;
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;

    ${props =>
    props.active &&
    css`
        max-height: 100%;
        opacity: 1;
      `}
  }
`

const QuoteCard = styled.div`
  margin: 0 60px 0 0;
  background: #fff9f2;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
  display: flex;
  height: 400px;

  @media screen and (max-width: 980px) {
    margin: 20px 0;
    height: auto;
  }

  &::after {
    content: "";
    border: 1px dashed #000;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px;
    left: 10px;
    z-index: -1;
  }

  & > div {
    flex: 1;
    background: #fff9f2;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &.fade-enter {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.2s ease;
  }
`

const QuoteCardText = styled.p`
  font-size: 18px;
  line-height: 30px;

  span {
    font-weight: 700;
    margin: 0;
  }
  ul {
    margin-top: 20px;
    margin-left: 25px;
  }
`

const QuoteCardLogo = styled.div`
  height: 70px;
  min-height: 70px;
  width: 100%;
  background: url(${props => props.background}) no-repeat
    ${props => `${props.positionX}px`} ${props => `${props.positionY}px`} /
    ${props => `${props.size}px`};
`

export default function PartnerQuotes() {
  const [selectedFeature, setSelectedFeature] = useState(0)
  const [isMobileOnly] = useSelector(state => [state.global.isMobile])

  const quotes = [
    {
      feature: "See immediate results",
      partnerLogo: {
        logo: wunderinoLogo,
        size: 300,
        positionY: -55,
        positionX: -55,
      },
      quoteText: `“We have grown fast by giving our users a great experience in every part of our product. Partnering up with Checkin.com was a no-brainer, 
      we loved the flow and already <span>from day one 12% more users signed up</span>”`,
      author: "Tobias Carlsson",
      position: "CEO, Rhinoceros operations",
    },
    {
      feature: "Integrate with ease",
      partnerLogo: {
        logo: luckydaysLogo,
        size: 300,
        positionY: -48,
        positionX: -62,
      },
      quoteText: `“Lucky Days wanted to launch with a sign-up solution that had a unique and smart way to interact with their end-users. Through a <span>seamless and easy integration</span> they launched their site with Checkin.com and saw <span>excellent results from day one of their operations.</span>”`,
      author: "Jhone Lucky",
      position: "CEO, Lucky Days",
    },
    {
      feature: "Launch new brands",
      partnerLogo: {
        logo: herogamingLogo,
        size: 100,
        positionY: 0,
        positionX: 0,
      },
      quoteText: `“Hero Gaming were able to scale their operations and <span>grow with different brands in different markets by using localized sign-up flows</span>. This helped their brands dominate in their target markets and <span>optimize their flows much faster with Checkin data insights</span>”`,
      author: "Jane Hero",
      position: "CMO, Hero Gaming",
    },
    {
      feature: "Boost conversion",
      partnerLogo: {
        logo: forzaLogo,
        size: 200,
        positionY: -18,
        positionX: -42,
      },
      quoteText: `“Checkin is for everyone who does sign-ups, particularly on mobile. I believe in a focus on your core. If your core is not making sign-ups, you should get help doing it. We love their affiliate product Checkin Connect, where <span>players get to sign-up to a brand on the affiliate website without any redirects</span>.”`,
      author: "Patrik Arnesson",
      position: "CEO, Forza Football",
    },
    {
      feature: "Get a tailored solution",
      partnerLogo: {
        logo: casumoLogo,
        size: 250,
        positionY: -39,
        positionX: -60,
      },
      quoteText: `“Casumo received a <span>fully customized sign-up solution</span>. Multiple external <a href="/id-scanner/">ID verification tools</a> were embedded to their flow to support their operations in different markets. <span>With the support of a dedicated team, Casumo achieved the best ROI on their sign-ups while</span> reducing all integration complexities”`,
      author: "Jhone Casu",
      position: "CEO, Casumo",
    },
  ]
  return (
    <Container>
      <FeatureSelector>
        <FeatureSelectorList>
          {quotes.map((quote, index) => (
            <FeatureItem key={index} isselected={index === selectedFeature}>
              <span onClick={() => setSelectedFeature(index)}>
                {quote.feature}
                {isMobileOnly ? (
                  <Angle active={index === selectedFeature} />
                ) : (
                  <Arrow />
                )}
              </span>

              <TabletView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>
                      <QuoteCardLogo
                        background={quotes[index].partnerLogo.logo}
                        size={quotes[index].partnerLogo.size}
                        positionY={quotes[index].partnerLogo.positionY}
                        positionX={quotes[index].partnerLogo.positionX}
                      />
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].quoteText,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </TabletView>

              <MobileView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>
                      <QuoteCardLogo
                        background={quotes[index].partnerLogo.logo}
                        size={quotes[index].partnerLogo.size}
                        positionY={quotes[index].partnerLogo.positionY}
                        positionX={quotes[index].partnerLogo.positionX}
                      />
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].quoteText,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </MobileView>
            </FeatureItem>
          ))}
        </FeatureSelectorList>
      </FeatureSelector>

      <DesktopView>
        <QuoteCardContainer>
          <SwitchTransition>
            <CSSTransition
              key={selectedFeature}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
            >
              <QuoteCard>
                <div>
                  <QuoteCardLogo
                    background={quotes[selectedFeature].partnerLogo.logo}
                    size={quotes[selectedFeature].partnerLogo.size}
                    positionY={quotes[selectedFeature].partnerLogo.positionY}
                    positionX={quotes[selectedFeature].partnerLogo.positionX}
                  />
                  <QuoteCardText
                    dangerouslySetInnerHTML={{
                      __html: quotes[selectedFeature].quoteText,
                    }}
                  />
                </div>
              </QuoteCard>
            </CSSTransition>
          </SwitchTransition>
        </QuoteCardContainer>
      </DesktopView>
    </Container>
  )
}
