import React, { useContext, useEffect, useRef, useState } from "react"
import SEO from "../components/seo"
import loadable from '@loadable/component'

import CoreAnimation from "../components/travelv2/core-animation"
import Grid from "../components/travelv2/grid"
import MiddleText from "../components/common/middle-text"
import ELectricity from "../assets/images/electricity.inline.svg"
import Verify from "../assets/images/verify.inline.svg"
import Setting from "../assets/images/setting.inline.svg"
import Judge from "../assets/images/judge.inline.svg"
import Global from "../assets/images/global.inline.svg"
import Automate from "../assets/images/automate.inline.svg"
import Ghost from "../assets/images/ghost.inline.svg"
import Secure from "../assets/images/compliance.inline.svg"
import HeaderAnimation from "../components/travelv2/header-animation"
import Pattern from "../assets/images/pattern.inline.svg"
import Complete from "../assets/images/complete.inline.svg"
import Fraud from "../assets/images/fraud.inline.svg"
import Privacy from "../assets/images/privacy.inline.svg"
import Biometric from "../assets/images/biometric.inline.svg"
import Integration from "../assets/images/integration.inline.svg"
import Design from "../assets/images/design.inline.svg"
import Exchange from "../assets/images/exchange.inline.svg"
import Effortless from "../assets/images/effortless.inline.svg"
import Enhance from "../assets/images/enhance.inline.svg"
import Efficiency from "../assets/images/efficiency.inline.svg"

const Block = loadable(() => import("../components/travelv2/block"))


export default function TravelPageNew({ location }) {

  return (
    <>
      <SEO
        title="Experience the revolution in passenger onboarding"
        description="At Checkin.com, our mission is to streamline the check-in process, making it as effortless as possible for both airlines and passengers. Leveraging our expertise in advanced technologies, we've tackled the industry's complex challenges head-on, transforming the way passengers are identified and verified.        "
        canonical="/industry-airline/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />

      <Grid >
        <MiddleText
          h1
          primary
          gridArea="header-title"
          headerText="Experience the revolution in passenger onboarding"
        />
        <HeaderAnimation />
        <Block
          type="visa"
          gridArea="visa"
          header="Visa Immigration Checks<br/> from any device"
          subHeader="Automating visa and immigration checks with Checkin.com protects you from compliance risks and streamlines passenger processing, ensuring every traveler meets entry requirements before boarding."
          features={[
            {
              icon: <ELectricity />,
              header: "Direct Database Integration",
              content: "Access real-time visa requirements from authoritative sources like IATA."
            },
            {
              icon: <Verify />,
              header: "Automated Compliance",
              content: "Instantly verifies travelers' documents against global immigration policies."
            },
            {
              icon: <Setting />,
              header: "Customizable Solutions",
              content: "Adapts to various operational needs, enhancing the check-in experience."
            },
            {
              icon: <Judge />,
              header: "Risk and Fine Reduction",
              content: "Ensures compliance, minimizing the risk of fines and legal issues."
            }
          ]}
        />

        <Block
          reverse
          type="id"
          gridArea="id"
          header="ID Document Verification<br/> covering 13,000+ documents"
          subHeader="Streamline the check-in process with Checkin.com's ID Document Verification, ensuring passengers are quickly and accurately identified. Replace manual processes to simplify data capture and enhance security on a global scale. "
          features={[
            {
              icon: <Global />,
              header: "Global Reach",
              content: "Supports 13,000+ ID documents from over 190 countries, ensuring comprehensive coverage."
            },
            {
              icon: <Automate />,
              header: "Automated Data Extraction",
              content: "Utilizes Optical Character Recognition (OCR) for quick data capture, reducing manual entry errors."
            },
            {
              icon: <Ghost />,
              header: "AI-Driven Verification Flows",
              content: "Tailors the verification process to each user for maximum efficiency. "
            },
            {
              icon: <Secure />,
              header: "Secure and Compliant",
              content: "Adheres to GDPR and offers localized data processing for privacy assurance."
            }
          ]}
        />

        <Block
          type="signature"
          gridArea="signature"
          header="Non-biometric signature<br/> verification in seconds"
          subHeader="The Signature Verification solution offers a non-intrusive, privacy-conscious method for confirming passenger identity, ideal for quick, secure check-ins and reducing the risk of fraud."
          features={[
            {
              icon: <Pattern />,
              header: "Advanced Pattern Recognition",
              content: "Employs machine learning to accurately verify signature authenticity."
            },
            {
              icon: <Complete />,
              header: "Easy to complete",
              content: "Provides real-time verification, facilitating a smooth check-in process."
            },
            {
              icon: <Fraud />,
              header: "Fraud Prevention",
              content: "Identifies discrepancies to significantly reduce fraudulent activities."
            },
            {
              icon: <Privacy />,
              header: "Privacy-Friendly",
              content: "Requires minimal data storage, aligning with data minimization principles."
            }
          ]}
        />

        <Block
          reverse
          type="liveness"
          gridArea="liveness"
          header="Biometric liveness check<br/> for added security"
          subHeader="Checkin.com's Liveness Check ensures that the person checking in is physically present, offering an additional layer of security by preventing identity fraud with real-time biometric analysis."
          features={[
            {
              icon: <Biometric />,
              header: "Real-Time Biometric Analysis",
              content: "Confirms presence through dynamic actions and biometric data analysis."
            },
            {
              icon: <Fraud />,
              header: "Fraud Deterrence",
              content: "Enhances security by distinguishing live users from replicas."
            },
            {
              icon: <Integration />,
              header: "Seamless Integration",
              content: "Works alongside document verification for a robust identity check."
            },
            {
              icon: <Design />,
              header: "User-Centric Design",
              content: "Offers a quick, intuitive process for verifying user liveness."
            }
          ]}
        />

        <Block
          type="nfc"
          gridArea="nfc"
          header="NFC enabling fast and<br/> secure data transfer"
          subHeader="NFC technology in Checkin.com offers contactless ID verification through passengers' device, streamlining check-ins and boarding with enhanced security."
          features={[
            {
              icon: <Exchange />,
              header: "Contactless Data Exchange",
              content: "Rapid, secure data transfer from NFC-enabled documents via passengers' devices."
            },
            {
              icon: <Effortless />,
              header: "Effortless  Experience",
              content: "Simplifies check-in and boarding, reducing manual entry and physical checks."
            },
            {
              icon: <Enhance />,
              header: "Enhanced Security and Privacy",
              content: "Encrypts data transmission via NFC, ensuring protection against unauthorized access."
            },
            {
              icon: <Efficiency />,
              header: "Operational Efficiency",
              content: "Cuts document check and boarding times, improving passenger flow and reducing queues."
            }
          ]}
        />
        <Block
          reverse
          type="botlens"
          gridArea="botlens"
          header="Botlens detecting bots,<br/> automation, and OTA's"
          subHeader="With the rise of automated bookings, Checkin.com's Botlens empowers airlines to safeguard their booking systems, distinguishing genuine customer interactions from automated scripts and ensuring fair use of their digital platforms."
          features={[
            {
              icon: <Exchange />,
              header: "Comprehensive Control",
              content: "Distinguishes between human and automated sessions, customizing security measures."
            },
            {
              icon: <Effortless />,
              header: "Enhanced Security",
              content: "Protects booking systems from unauthorized bot activity and data scraping."
            },
            {
              icon: <Effortless />,
              header: "Insightful Analytics",
              content: "Provides additional statistics for informed decision-making"
            },
            {
              icon: <Efficiency />,
              header: "GDPR-Compliant",
              content: "Ensures privacy-friendly operation, even in automated traffic detection."
            }
          ]}
        />
        <CoreAnimation />

      </Grid >
    </>
  )
}
