import React from "react"

import styled, { css } from "styled-components"


import Left from "../../assets/images/igaming/left-plug.inline.svg"
import Right from "../../assets/images/igaming/right-plug.inline.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  justify-content: space-between;

`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 63px;
  grid-area: ${props => props.gridArea};
  width: 50%;
  @media screen and (max-width: 980px) {
    width:70%;
  }

  @media screen and (max-width: 640px) {
    margin-top : 20px;
    width:100%;

  }
`
const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 57px;
  @media screen and (max-width: 640px) {
  justify-content: center;

    svg {
      display: none;
    }
  }

`

const Header = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;
  white-space: pre-line;

  @media screen and (max-width: 640px) {
    font-size: 28px;
    line-height: 34px;
  }

  ${props =>
    props.primary &&
    css`
      font-size: 48px;
      line-height: 56px;
      width: 618px;
      margin-bottom: 16px;

      @media screen and (max-width: 980px) {
        width: 600px;
      }

      @media screen and (max-width: 640px) {
        line-height: 44px;
        font-size: 34px;
        width: 335px;
      }
    `}
`

const Subheader = styled.p`
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;

  @media screen and (max-width: 640px) {
    line-height: 32px;
    font-size: 18px;
  }
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`


const CTAText = styled(H4)`
  margin-top: 30px;
  text-align: left;

  @media screen and (max-width: 980px) {
    text-align: center;

  }

  @media screen and (max-width: 640px) {
    width: 333px;
    text-align: center;

  }
`

const PlugCards = styled.div`
display: grid;
gap: 40px 100px;
grid-template-columns: auto auto;

@media screen and (max-width: 980px) {
  grid-template-columns: auto auto;
  gap: 40px 60px;


  svg {
    width: 200px;
    height: auto;
  }
}

@media screen and (max-width: 640px) {
  grid-template-columns: auto;
  gap: 5px;

  svg {
    width: 164px;
    height: auto;
  }
}
  
`

const Card = styled.div`

text-align: left;
${H4}{
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}
p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  width: 325px;
}
@media screen and (max-width: 640px) {
  margin-bottom: 20px;
} 
`

export default function Plug({
  gridArea,
}) {
  const Cards = [
    {
      title: "Flexible integration",
      description: "Integrate through API, JS or SDK to any API, endpoint or platform you already use today."
    },
    {
      title: "Fully customizable flows",
      description: "You can jack in any part of your sign-up flow from collecting user's data to logins and ID verification. "
    },
    {
      title: "Built-in and custom<br/> validations",
      description: "A multitude of validations ships out of the box, but you can also run your own validations on any data."
    },
    {
      title: "Reuse your existing<br/> API endpoints",
      description: "We configure your setup to use the same labels and format as your current solution."
    },
  ]

  return (
    <Container gridArea={gridArea}>

      <HeaderSection>
        <Left />
        <TextContainer >
          <Header >Easy and customizable integration</Header>
          <Subheader>Our integration fully supports direct connection to nearly every platform. Choose from a range of pre-configured options or go with a fully customized integration.</Subheader>
        </TextContainer>
        <Right />
      </HeaderSection>

      <PlugCards>
        {
          Cards.map((card, index) => (
            <Card key={index}>
              <H4 dangerouslySetInnerHTML={{ __html: card.title }}></H4>
              <p dangerouslySetInnerHTML={{ __html: card.description }}></p>
            </Card>
          ))
        }
      </PlugCards>
    </Container>
  )
}
