import React from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
`

const HeaderH1 = styled.h1`

font-size: 48px;
line-height: 56px;
margin-bottom: 16px;
font-weight: 900;
white-space: pre-line;


@media screen and (max-width: 980px) {
  width: 600px;
}

@media screen and (max-width: 640px) {
  line-height: 44px;
  font-size: 34px;
  width: 335px;
  br {
    display: none;
  }
}
`

const Header = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;
  white-space: pre-line;

  @media screen and (max-width: 640px) {
    font-size: 28px;
    line-height: 34px;
  }

`

const Subheader = styled.p`
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;

  @media screen and (max-width: 640px) {
    line-height: 28px;
    font-size: 16px;
  }
`

export default function MiddleText({
  h1,
  primary = false,
  gridArea,
  headerText,
  subheaderText,
  className
}) {
  return (
    <Container className={className} gridArea={gridArea}>
      {h1 ? (<HeaderH1 primary={primary} dangerouslySetInnerHTML={{ __html: headerText }}  ></HeaderH1>) :
        (<Header primary={primary} dangerouslySetInnerHTML={{ __html: headerText }}  ></Header>)
      }

      <Subheader dangerouslySetInnerHTML={{ __html: subheaderText }}></Subheader>
    </Container>
  )
}
