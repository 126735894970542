import React from "react"
import styled from "styled-components"

import Identity2 from "../../assets/images/product/identity.inline.svg"
import Data from "../../assets/images/product/data.inline.svg"
import Kyc from "../../assets/images/product/kyc.inline.svg"



const H2 = styled.h2`
    font-weight: 800;
    font-size: 34px;
    line-height: 36px;
    text-align: center;
`

const Flows = styled.div`
  grid-area: flows;
`
const MiddleText = styled.div`
    margin: 0 auto;
    padding: 0 180px;

    p{
      width: 700px;
      margin-top: 16px;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
  @media screen and (max-width: 980px) {
    padding: 0px;
   p {
    width: auto;
   }
  }
  @media screen and (max-width: 640px) {
    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  ${H2} {
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
  }
}
`
const OverflowContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  @media screen and (max-width: 980px) {
    width: 115%;
  }
  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const ColoredBoxesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1050px;
  margin: 0 auto;
  position: relative;
  margin-top: 80px;
  margin-bottom: 20px;

  p {
    position: absolute;
    top: calc(100% + 30px);
    font-size: 18px;
    text-align: center;
    width: 100%;
  }

  @media screen and (max-width: 980px) {
    width: 1005px;
  }

  @media screen and (max-width: 640px) {
    width: 915px;
  }
`

const ColoredBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 317px;
  height: 380px;
  background: #fff;
  border: 1px solid #000000;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 40px;
  box-sizing: border-box;

  img{
    width: 70px;
    height: 70px;
  }

  span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    text-align: left;
    margin-top: 30px;
    margon-bottom: 9px;
    width: 117%;
  }

  &::after {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: none;
    transform: skewY(-30deg);
    transform-origin: left bottom;
    height: calc(100% + 2px);
    width: 14px;
    top: -1px;
    left: calc(100% + 1px);
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  &::before {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: 2px solid #000;
    border-bottom: none;
    transform: skew(-60deg);
    transform-origin: right bottom;
    height: 8px;
    width: calc(100% + 2px);
    bottom: calc(100% + 1px);
    left: -1px;
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  @media screen and (max-width: 980px) {
    width: 288px;
    max-width: 288px;
    flex: 50%;
    margin: 0 0 40px;


  }

  @media screen and (max-width: 640px) {
    flex: auto;
  }

  ul {
    margin-left: 16px;
  }

  li {
    padding-left: 7px;
    margin-top: 16px;
    font-weight: 400;
    font-size: 18px;
  }
`

const ImageContainer = styled.div`
  width: 70px;
`

const FlowsComponent = () => {
  return (
    <Flows>
      <MiddleText>
        <H2>Flows optimized with hundreds of solutions to choose from</H2>
        <p>Your flows are configured with instant access to the optimal local solutions for each market. We currently support 190+ countries.</p>
      </MiddleText>
      <OverflowContainer>
        <ColoredBoxesContainer>
          <ColoredBox color={"#FF977A"}>
            <ImageContainer>
              <Identity2 />
            </ImageContainer>
            <span>Identity</span>
            <ul>
              <li>eID’s</li>
              <li><a href="/id-scanner">Face matching</a></li>
              <li><a href="/id-scanner">Age verification</a></li>
              <li><a href="/id-scanner">Liveness detection</a></li>
              <li>Social logins</li>
            </ul>
          </ColoredBox>
          <ColoredBox color={"#C894D6"}>
            <ImageContainer>
              <Data />
            </ImageContainer>
            <span>Data</span>
            <ul>
              <li>Lookups</li>
              <li>Verification</li>
              <li>Validation</li>
              <li>Address</li>
              <li>Localized input</li>
            </ul>
          </ColoredBox>
          <ColoredBox color={"#74BE87"}>
            <ImageContainer>
              <Kyc />
            </ImageContainer>
            <span>KYC & Compliance</span>
            <ul>
              <li><a href="/product/aml-pep-sanction-checks/">PEP and sanction lists</a></li>
              <li>Bank verification</li>
              <li><a href="/product/aml-pep-sanction-checks/">Register check</a></li>
              <li>KYC tools</li>
              <li>Fraud detection</li>
            </ul>
          </ColoredBox>
        </ColoredBoxesContainer>
      </OverflowContainer>
    </Flows>
  )
}

export default FlowsComponent