import React, { useContext } from "react";
import styled from "styled-components"

import ActionButton from "../action-button"
import GlobalContext from "../../context/global-context"

import Verify1 from "../../assets/images/id-scanner/verify1.svg"
import Verify2 from "../../assets/images/id-scanner/verify2.svg"
import Verify3 from "../../assets/images/id-scanner/verify3.svg"


const TalkToSalesButton = styled(ActionButton)`
    width: 256px;
    height: 72px; 
    margin-top: 0px;
    font-size: 20px;
    padding: 12px 40px;
    background: #FFF;

    @media screen and (max-width: 980px) {
        margin: 0px auto;
        width: 180px;
        height: 56px;
        font-size: 14px;
        line-height: 14px;


    }

    @media screen and (max-width: 640px) {
    &.mobile{
        width: 312px;
        position: relative;
        left: -10px;
    }
    }
`
const Container = styled.div`
    grid-area: verify;
    border: 1px solid var(--Text-Primary, #000); 
    padding: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px; 
    row-gap: 16px;
    background: #FBD65D;

    .mobile { display: none; }
    .desktop {display: block}

    h1 {
        color: var(--Text-Primary, #000);
        font-family: "Object Sans";
        font-size: 42px;
        font-style: normal;
        font-weight: 800;
        line-height: 48px; 
    }

    .item {
        text-align: center;
        display: flex;
        align-items: center;

        img {
            width: 80px;
        }

        p {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
            margin-left: 4px;
        }
    }
        
    h1 {
        grid-column: span 2; /* Span 2 columns */
        margin: 0px;
        padding-right: 30px;
    }
        
    ${TalkToSalesButton} {
        grid-column: span 1; /* Span 1 column (default behavior) */
    }
    .item2 {
        align-items: baseline;
        justify-content: end;
    }
    .item3, .item4, .item5 {
        grid-column: span 1; /* Span 1 column (default behavior) */
    }


    @media screen and (max-width: 980px) {
        padding: 24px;

        h1{
            font-size: 28px;
            line-height: 36px;
        
        }

        .item {
            flex-direction: column;
            align-items: normal;
            p {
                font-size: 14px;
                line-height: 24px;
            }

            img {
                width: 57px;
            }
        }

        .item2 {
            justify-content: start;
        }

    }

    @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 359px;
        justify-self: center;
        h1 {padding-right: 0px; }

        .item {
            flex-direction: row;
            align-items: center;
            p { font-size: 16px; }

            img {
                width: 50px;
            }
        }

        .item.item5 {
            margin:8px 0;
        }

        .item.item6 {
            margin-bottom:8px;
        }

        .mobile { 
            display: block;

        }
        .desktop {display: none}
    }
`

const Verify = () => {
    const globalContext = useContext(GlobalContext)

    return (
        <Container>
            <h1>Get ready-to-go full solution compliant with Brazil regulations</h1>
            <div className="item item2 desktop">
                <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
                    Contact us
                </TalkToSalesButton>
            </div>
            <div className="item item4">
                <img alt="seemless" src={Verify1} />
                <p>All in one integration </p>
            </div>
            <div className="item item5">
                <img alt="documents" src={Verify2} />
                <p>Support for all Brazil documents</p>
            </div>
            <div className="item item6">
                <img alt="localized" src={Verify3} />
                <p>Localisation for Brazil - Portugese</p>
            </div>
            <TalkToSalesButton className="mobile" onClick={() => globalContext.openGetStarted()}>
                Contact us
            </TalkToSalesButton>

        </Container>
    )
}

export default Verify;