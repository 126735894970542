import styled from "styled-components"
import LayoutGrid from "../layout-grid"

const Grid = styled(LayoutGrid)`
  @media screen and (min-width: 981px) {
    // padding-top: 100px;
  }

  grid-template-areas:
    "post post post post post post post post post post post post post post"
    ". next-previous next-previous next-previous next-previous next-previous next-previous next-previous next-previous next-previous next-previous next-previous next-previous ."
    ". . newsletter newsletter newsletter newsletter newsletter newsletter newsletter newsletter newsletter newsletter . ."
    "footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta"
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . .";

  @media screen and (max-width: 980px) {
    grid-template-areas:
      ". post post post post post post post post ."
      ". next-previous next-previous next-previous next-previous next-previous next-previous next-previous next-previous ."
      "footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta footerCta"
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . .";
  }

  @media screen and (max-width: 640px) {
    padding-top: 0px;
    grid-template-areas:
      "post post post"
      ". next-previous ."
      "footerCta footerCta footerCta"
      ". . ."
      ". . .";
  }
`

export default Grid
