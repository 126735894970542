import { createStore, applyMiddleware } from "redux"
import persistState from "redux-sessionstorage"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

import reducers from "./reducers"

const store =
  typeof window === "undefined"
    ? createStore(reducers, composeWithDevTools(applyMiddleware(thunk)))
    : createStore(
        reducers,
        composeWithDevTools(applyMiddleware(thunk), persistState())
      )

export default store
