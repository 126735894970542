import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from "styled-components"
import { SwitchTransition, CSSTransition } from "react-transition-group"

import * as LottieInteractivity from '@lottiefiles/lottie-interactivity';
import ArrowSquare from "../../assets/images/meeting-booker/arrow-square.inline.svg"
if (typeof window !== 'undefined') import('@lottiefiles/lottie-player')

const fadeInOut = keyframes`
  0%   {opacity: 1;}
  50%  {opacity: 0;}
  100%  {topacityop: 1;}
`
const Container = styled.div`
grid-area: coreAnimation;

.slide-in {
  /* Initial state (hidden) */
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  width: 100vw; /* Ensure full width for each div */
}

.slide-in.visible {
  /* Animation on intersection */
  transform: translateX(0);
  opacity: 1;
}
`
const Path = styled.div`
display: flex;
flex-direction: row;
  @media screen and (max-width: 980px) {
    display: none;
  }
`
const BoxContainer = styled.div`
width: 50%;
 b{
  font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 28px;
 }
p {
  font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px;
}


`
const Box = styled.div`
margin-top: 100px;
color: #000;
font-family: "Object Sans";
font-size: 16px;
font-style: normal;
line-height: 28px;

@media screen and (max-width: 640px) {
text-align: center
}

&:first-of-type{
  margin-top: 50px;
}


@media screen and (max-width: 460px) {
  p {
    margin-bottom: 10px;
    text-align: left;
  }
}
`
const Title = styled.h2`
color: #000;
font-family: "Object Sans";
font-size: 34px;
font-style: normal;
font-weight: 800;
line-height: 36px;
`
const SubTitle = styled.h4`
color: #000;
font-family: "Object Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px; 
margin: 15px 0px;

@media screen and (max-width: 640px) {
  margin-bottom: 45px;
  margin-top  : 45px;
}
`

const AnimationContainer = styled.div`
grid-area: PathAnimation;
width: 50%;

`
const MobileBoxContainer = styled.div`
grid-area: mobilePath;
position: relative;
&.fadeInOut{
  animation: ${fadeInOut} 0.7s ease forwards;
}

  @media screen and (min-width: 981px) {
    display: none;
  }

`

const Animation = styled.div`
height : 185px;
`




const Left = styled(ArrowSquare)`
  position: relative;
  transform: rotate(180deg);
  box-shadow: 5px -5px 0px rgb(0 0 0 / 10%);

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
  }

`

const Right = styled(ArrowSquare)`
  box-shadow: 5px 5px 0px rgb(0 0 0 / 10%);
  position: relative;

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
    left: unset;
  }
`

const ImageContainerLeft = styled.div`
  background-color: white;
  position: relative;
  right: 30px;
  height: 57px;

  @media screen and (max-width: 980px){
    position: absolute;
    width: 57px;
    top: 193px;
    left: -27px;
    right: unset;

  }

`
const ImageContainerRight = styled.div`
  background-color: white;
  position: relative;
  left: 30px;
  height: 57px;
  @media screen and (max-width: 980px){
      position: absolute;
      left: unset;
      right: -27px;
      top: 193px;
      width: 57px;
  }
`


const AnimationStyle = {
  height: 'auto',
  width: '89%',
  float: 'right',
}

const Slider = styled.div`
&.fade-enter {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}
&.fade-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-exit-active {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}
&.fade-enter-active,
&.fade-exit-active {
  transition: all 0.3s ease;
}

&.fade-enter-active,
&.fade-exit-active,
&.fade-exit,
&.fade-enter{
  &::after {
    display:none;
  }
}
`


export default function TravelPageNew({ location }) {
  let animationContainer = useRef();
  let mobileAnimationContainer = useRef();
  let [index, setIndex] = useState(0);


  const Content = [

    {
      link: "https://lottie.host/ccc42c1f-d3ba-4a8a-bd93-daf997d8882c/KeF99JxlPP.json",
      html: (<><Title>Airlines</Title>
        <SubTitle>Revolutionizing Passenger Onboarding with Advanced KYC</SubTitle>
        <p><b>Reduce Costs</b>: Significantly lower operational expenses through automated check-in and verification processes.</p>
        <p><b>Improve Customer Satisfaction</b>: Offer a seamless, hassle-free travel experience with fast, digital check-ins and identity verification.</p>
        <p><b>Avoid Fines</b>: Ensure compliance with international travel regulations and avoid penalties with automated, up-to-date visa and document checks.</p>
        <p><b>Automate Processes</b>: Leverage AI-driven systems for efficient passenger processing, reducing manual interventions and accelerating boarding procedures</p>
      </>)
    },

    {
      link: "https://lottie.host/ef805b93-9dc0-4c0e-9ee4-71c372bf7fe0/SDNVzXtOj5.json",
      html: (<>
        <Title>Cruise Lines</Title>
        <SubTitle>Enhancing Passenger Experience at Sea with Streamlined Check-ins</SubTitle>
        <p><b>Enhance Security</b>: Implement biometric verification for enhanced onboard security and streamlined embarkation.</p>
        <p><b>Personalize Experiences</b>: Use AI-driven insights to offer personalized services and recommendations, enhancing guest satisfaction.</p>
        <p><b>Optimize Operations</b>: Automate document processing and verification to speed up boarding and improve operational efficiency.</p>
        <p><b>Expand Market Reach</b>: Utilize localization engines to cater to a global clientele, offering a tailored booking experience in multiple languages.</p>
      </>)
    },

    {
      link: "https://lottie.host/4c2b8e1e-451e-462b-8c0c-d1c234631c67/hyOosXeLBm.json",
      html: (<>
        <Title>Rail and Public Transport</Title>
        <SubTitle>Advancing Ticketing and Boarding with Smart Technology</SubTitle>
        <p><b>Automate Ticketing</b>: Implement OCR and NFC technologies for fast, effortless ticket verification and sales.</p>
        <p><b>Enhance Passenger Flow</b>: Use facial recognition for rapid boarding processes, decreasing queues and improving timetable adherence.</p>
        <p><b>Boost Security Measures</b>: Employ biometric verification for enhanced passenger identification, increasing safety across the network.</p>
        <p><b>Reduce Operational Expenses</b>: Streamline passenger processing with automated systems, cutting down on manual checks and staffing needs.</p>
      </>)
    },

    {
      link: "https://lottie.host/7685bed8-f258-4178-84da-ea751b95b33d/4vIjYPw3fr.json",
      html: (<>
        <Title>Car Rental Agencies</Title>
        <SubTitle>Streamlining Rental Processes with Efficient KYC Solutions</SubTitle>
        <p><b>Accelerate Pickup and Return</b>: Utilize NFC/MRZ technology for quick document verification, speeding up vehicle collection and drop-off.</p>
        <p><b>Improve Customer Experience</b>: Offer a frictionless rental process with digital check-in and automated verification, enhancing customer loyalty.</p>
        <p><b>Enhance Fraud Protection</b>: Implement advanced biometric checks to prevent identity theft and reduce fraudulent bookings.</p>
        <p><b>Optimize Fleet Utilization</b>: Leverage AI-driven insights for better demand forecasting and fleet management, reducing overheads.</p>
      </>)
    },

    {
      link: "https://lottie.host/ce10d8df-221d-4208-847a-4972c2861626/RlePgZY9Ij.json",
      html: (<>
        <Title>Hotels and Resorts</Title>
        <SubTitle>Transforming Guest Check-in with Digital Innovation</SubTitle>
        <p><b>Speed Up Check-In</b>: Use facial recognition for fast, secure guest identification, reducing wait times and improving first impressions.</p>
        <p><b>Enhance Guest Services</b>: Integrate biometric data with guest services for personalized experiences, from room access to personalized dining.</p>
        <p><b>Reduce Operational Costs</b>: Automate identity verification and document checks, minimizing the need for front desk staff intervention.</p>
        <p><b>Increase Security</b>: Ensure guest safety and regulatory compliance with advanced document verification and data protection measures.</p>

      </>)
    }

  ]

  const Slide = (mode) => {

    let newIndex
    if (index !== 0 && mode == 'backward') {
      newIndex = index - 1
      mobileAnimationContainer.current.load(Content[newIndex].link)

      setIndex(newIndex);
    }
    else if (index < 4 && mode == 'forward') {
      newIndex = index + 1
      mobileAnimationContainer.current.load(Content[newIndex].link)

      setIndex(newIndex);
    }

  }

  const listener = (e) => {
    // 4. configure the interactivity library
    LottieInteractivity.create({
      player: '#firstLottie',
      mode: "scroll",
      actions: [
        {
          visibility: [0.05, 0.85],
          type: "seek",
          frames: [0, 600]
        },

      ]
    });
  }

  useEffect(() => {
    animationContainer.current.addEventListener('load', listener);
    return () => {
      document.body.removeEventListener('load', listener)
    }

  }, []);

  return (
    <Container >


      <MobileBoxContainer >
        <ImageContainerLeft onClick={() => Slide('backward')}>
          <Left />
        </ImageContainerLeft>
        <ImageContainerRight onClick={() => Slide('forward')}>
          <Right />
        </ImageContainerRight>

        <SwitchTransition >
          <CSSTransition
            key={index}
            addEndListener={(node, done) =>
              node.addEventListener("transitionend", done, false)
            }
            classNames="fade"
          >
            <Slider>

              <Animation>
                <lottie-player
                  ref={mobileAnimationContainer}
                  id="mobileAnimation"
                  autoplay
                  loop
                  mode="normal"
                  auto
                  src={Content[index].link}
                ></lottie-player>

              </Animation>


              <Box>
                {Content[index].html}
              </Box>
            </Slider>

          </CSSTransition>
        </SwitchTransition>

      </MobileBoxContainer>

      <Path>
        <BoxContainer>
          {Content.map((box) => (<Box >{box.html}</Box>))}
        </BoxContainer>

        <AnimationContainer >
          <lottie-player
            ref={animationContainer} // 2. set the reference for the player
            id="firstLottie"
            mode="normal"
            style={AnimationStyle}
            src="https://lottie.host/5c34eb92-d672-4952-8d55-dfaeab9fd076/w5w9QEqIVB.json"
          ></lottie-player>
        </AnimationContainer>
      </Path>


    </Container >
  )
}