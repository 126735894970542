
const ModalsContent = [
    {
        heading: "ID Scanning",
        metaTitle: "AI-Powered Document Scanning for KYC | checkin.com",
        metaDescription: "Extract and verify information from identity documents instantly with our AI-powered document scanning technology. Simplify your KYC process and reduce manual effort.",
        subheading: "Verify identities with precision, supporting over 14,000 documents globally.",
        icons: [
            require('../../assets/images/id-scanner/feature1.svg').default,
            require('../../assets/images/id-scanner/feature2.svg').default,
            require('../../assets/images/id-scanner/feature3.svg').default,
        ],
        features: [
            {
                title: "Global Document Coverage",
                description: "Supports scanning and verification of over 14,000 types of IDs and passports from more than 190 countries."
            },
            {
                title: "Device Compatibility",
                description: "Seamlessly scans documents via mobile, desktop, and kiosk interfaces, ensuring flexibility and accessibility."
            },
            {
                title: "Authenticity Checks",
                description: "Automated, hybrid, or manual verification processes with database cross-checks to confirm documents."
            },
            {
                title: "Secure Backoffice",
                description: "All applications and responses are stored in a heavily encrypted backoffice with granular access controls."
            }
        ],
        benefits: [
            {
                title: "Make customer onboarding a breeze",
                description: "Quickly verify customer identities using a wide range of ID documents from all over the world. This process significantly reduces wait times and enhances the overall user experience, making onboarding seamless and efficient."
            },
            {
                title: "Keep your business secure and compliant",
                description: "Validate IDs with high-precision technology to ensure compliance and reduce fraud. Our advanced systems provide a secure and reliable way to confirm identities, protecting both the business and the user."
            },
            {
                title: "Verify customers quickly and easily",
                description: "Simplify verification processes, allowing for smoother interactions and faster service delivery. This approach improves operational efficiency and customer satisfaction by making identity verification effortless."
            }
        ]
    },
    {
        path: "/products/liveness",
        heading: "Liveness & Face matching",
        metaTitle: "3D Liveness Detection for Secure Identity Verification | checkin.com",
        metaDescription: "Prevent identity fraud with our advanced 3D liveness detection. Ensure the person behind the screen is real and safeguard your online platforms.",
        subheading: "Detect, analyse, and compare human faces without storing any images",
        icons: [
            require('../../assets/images/id-scanner/feature4.svg').default,
            require('../../assets/images/id-scanner/feature5.svg').default,
            require('../../assets/images/id-scanner/feature6.svg').default,
        ],
        features: [
            {
                title: "Real-Time Detection",
                description: "Instantly determine if the user is physically present during the verification process."
            },
            {
                title: "Spoofing Prevention",
                description: "Advanced algorithms to detect and prevent fraud attempts using photos or videos."
            },
            {
                title: "Seamless Integration",
                description: "Compatible with mobile, desktop, and kiosk interfaces for flexible implementation."
            },
            {
                title: "User-Friendly Experience",
                description: "Designed to be quick and intuitive, providing a smooth verification process for users."
            }
        ],
        benefits: [
            {
                title: "Boost your security measures",
                description: "Strengthen authentication processes by ensuring that users are physically present during verification. This prevents remote spoofing attacks and ensures that the person engaging with your system is genuinely present."
            },
            {
                title: "Works seamlessly with ID scanning",
                description: "Seamlessly integrates with ID scanning solutions to provide a comprehensive verification process. This confirms that the person presenting the ID is its rightful owner, enhancing the overall accuracy and reliability of identity verification"
            },
            {
                title: "Prevent fraud with confidence",
                description: "Protects against identity fraud by ensuring the person interacting with your system is who they claim to be. This reduces the risk of unauthorized access and fraudulent activities, safeguarding your platform and users."
            }
        ]
    },
    {
        heading: "NFC",
        subheading: "Quick and Secure Data Access",
        metaTitle: "Tap-and-Go Identity Verification with NFC | checkin.com",
        metaDescription: "Streamline your customer onboarding with our seamless NFC verification solution. Verify identities instantly and securely with a simple tap.",
        icons: [
            require('../../assets/images/id-scanner/feature7.svg').default,
            require('../../assets/images/id-scanner/feature8.svg').default,
            require('../../assets/images/id-scanner/feature9.svg').default,
        ],
        features: [
            {
                title: "Encrypted Data Exchange",
                description: "Uses AES-128 encryption for secure communication with NFC-enabled passports and ID cards."
            },
            {
                title: "Biometric Data Access",
                description: "Extracts and verifies digital photos and biometric data like fingerprints and iris scans."
            },
            {
                title: "Cross-Device Compatibility",
                description: "Supports NFC data reading on iOS and Android devices with native SDK integration."
            },
            {
                title: "Real-Time Verification",
                description: "Performs real-time data verification with an average processing time of under 2 seconds per transaction."
            }
        ],
        benefits: [
            {
                title: "Quick Identity Verification",
                description: "Strengthen authentication processes by ensuring that users are physically present during verification. This prevents remote spoofing attacks and ensures that the person engaging with your system is genuinely present."
            },
            {
                title: "Data Extraction and Verification",
                description: "Extract information from NFC-enabled IDs and verify it against official databases. Essential for ensuring the authenticity of the data and preventing identity fraud."
            },
            {
                title: "Prevent fraud with confidence",
                description: "Protects against identity fraud by ensuring the person interacting with your system is who they claim to be. This reduces the risk of unauthorized access and fraudulent activities, safeguarding your platform and users."
            }
        ]
    },
    {
        heading: "Face recognition",
        metaTitle: "Secure Face Recognition for Identity Verification | checkin.com",
        metaDescription: "Verify customer identities in real-time with our accurate and secure face recognition technology. Prevent fraud, enhance security, and improve user experience.",
        subheading: "Detect, analyse, and compare human faces without storing any images",
        icons: [
            require('../../assets/images/id-scanner/feature10.svg').default,
            require('../../assets/images/id-scanner/feature11.svg').default,
            require('../../assets/images/id-scanner/feature6.svg').default,
        ],
        features: [
            {
                title: "High accuracy",
                description: "Achieves extreme accuracy in face matching using convolutional neural networks (CNNs) for feature extraction."
            },
            {
                title: "1-to-N and 1-to-1",
                description: "Provides scalable face recognition solutions for both identification (1:N) and verification (1:1) scenarios."
            },
            {
                title: "Cross-Device Compatibility",
                description: "Prevent fraud with our face recognition spoofing detection, designed to block photo, video, and mask-based attacks."
            },
            {
                title: "Spoofing Detection",
                description: "Supports deployment across multiple platforms, including mobile (iOS/Android) and web via RESTful APIs."
            }
        ],
        benefits: [
            {
                title: "Enhanced Access Control",
                description: "Enhance access control for restricted areas like hotel rooms,, member areas, and boarding gates using face recognition. Ensure a seamless and secure entry experience for users, allowing authorized guests and residents to access their rooms or exclusive areas effortlessly. "
            },
            {
                title: "Enhanced Security Compliance",
                description: "Validate IDs with high-precision technology to ensure compliance and reduce fraud. Our advanced systems provide a secure and reliable way to confirm identities, protecting both the business and the user."
            },
            {
                title: "Efficient Customer Verification",
                description: "Speed up the user verification process with our face recognition technology that quickly confirms identities with high accuracy. Ideal for businesses aiming to enhance security while ensuring a smooth user experience."
            }
        ]
    },
    {
        heading: "Signature Comparison",
        subheading: "Collect and validate signatures",
        metaTitle: "Secure Signature Verification for Digital Documents | checkin.com",
        metaDescription: "Verify the authenticity of digital signatures with our reliable signature verification tool. Protect your documents from fraud and ensure legal validity.",
        icons: [
            require('../../assets/images/id-scanner/feature13.svg').default,
            require('../../assets/images/id-scanner/feature14.svg').default,
            require('../../assets/images/id-scanner/feature15.svg').default,
        ],
        features: [
            {
                title: "Improved user experience",
                description: "Confirmed by extensive user research. Less interruptions and context switches lead to increased conversion rates. "
            },
            {
                title: "Authenticate humans",
                description: "Interaction must occur digitally on the same device without the option for uploads."
            },
            {
                title: "Recorded Motion Patterns",
                description: "Enables comparison of signature interactions 1-1 and 1-N"
            },
            {
                title: "Enhanced Security",
                description: "Real-time tracking of movement, time, size, position, shape, and other parameters detects simulated activity"
            }
        ],
        benefits: [
            {
                title: "Identity verification without biometrics",
                description: "The signature module uses algorithms to analyze the unique style of a user's handwritten signature, providing a secure and privacy-conscious way to verify identity. This method is ideal for online services that need straightforward identity verification without collecting biometric data."
            },
            {
                title: "Verifying human interactions",
                description: "Use the signature module in multi-factor authentication to make sure that a real person is making a transaction or accessing sensitive data. The system analyzes how the signature is written to tell the difference between a real person and automated scripts or bots."
            },
            {
                title: "Efficient Customer Verification",
                description: "Simplify verification processes, allowing for smoother interactions and faster service delivery. This approach improves operational efficiency and customer satisfaction by making identity verification effortless."
            }
        ]
    },
    {
        heading: "Proof of address",
        subheading: "Detect, analyze, and validate addresses ",
        metaTitle: "Verify Proof of Address with checkin.com",
        metaDescription: "Ensure the accuracy of customer addresses with our efficient proof of address verification. Detect fraud, mitigate risk, and comply with regulatory requirements.",
        icons: [
            require('../../assets/images/id-scanner/feature16.svg').default,
            require('../../assets/images/id-scanner/feature17.svg').default,
            require('../../assets/images/id-scanner/feature18.svg').default,
        ],
        features: [
            {
                title: "Enhanced reporting tools",
                description: "Generate clear, comprehensive reports and downloadable PDFs with highlighted data for easy management."
            },
            {
                title: "Verify address instantly",
                description: "Quickly confirm the validity of a user's address during the verification process, ensuring the information is accurate."
            },
            {
                title: "High Security",
                description: "Implements end-to-end encryption for data transmission and storage, ensuring compliance with GDPR and CCPA."
            },
            {
                title: "Read, extract, check",
                description: "Extract and verify names and addresses from documents, ensuring data consistency and trust"
            }
        ],
        benefits: [
            {
                title: "Improve your security measures",
                description: "Strengthen your verification processes by ensuring that the address data matches real-world information. This helps prevent fraudulent activities and ensures that the person engaging with your system is accurately located."
            },
            {
                title: "Works seamlessly with KYC checks",
                description: "Integrates smoothly with existing KYC processes to provide a comprehensive verification process. This confirms that the person is accurately represented in your records, enhancing the overall accuracy and reliability of identity verification."
            },
            {
                title: "Prevent address fraud",
                description: "Protect against address fraud by ensuring the information provided is accurate and true. This reduces the risk of unauthorized access and fraudulent activities, safeguarding your platform and users."
            }
        ]
    },
    {
        heading: "Data crosscheck",
        subheading: "Verify and cross-check user data against official databases and records",
        metaTitle: "Data Crosscheck for Identity Verification | checkin.com",
        metaDescription: "Verify customer identities and information with our powerful data crosscheck tool. Ensure accuracy, detect fraud, and streamline your onboarding process.",
        icons: [
            require('../../assets/images/id-scanner/feature19.svg').default,
            require('../../assets/images/id-scanner/feature20.svg').default,
            require('../../assets/images/id-scanner/feature21.svg').default,
        ],
        features: [
            {
                title: "Multiple databases",
                description: "Connects with multiple official databases to validate user information in a matter of milliseconds."
            },
            {
                title: "Automated verification",
                description: "Automatically checks user data for consistency and accuracy across sources."
            },
            {
                title: "Detailed Reporting",
                description: "Provides comprehensive reports on verification outcomes for audit and compliance."
            },
            {
                title: "High Scalability",
                description: "Handles large volumes of verifications efficiently, suitable for enterprise needs."
            }
        ],
        benefits: [
            {
                title: "Improve customer data accuracy",
                description: "Ensure every piece of customer data is accurate and up to the standards required by regulators in any industry. This process helps your business stay compliant and reduces the risk of legal complications."
            },
            {
                title: "Enhanced security Compliance",
                description: "By cross-checking user information, our system helps identify discrepancies that could indicate fraud. This proactive approach prevents fraudulent activities, protecting your business and its customers across various applications."
            },
            {
                title: "Global coverage for data verification",
                description: "Our system offers a single connection for unlimited access to official databases worldwide, streamlining user data verification and simplifying compliance across multiple regions. This reduces complexity and enhances accuracy as you expand globally."
            }
        ]
    },
]

export default ModalsContent;