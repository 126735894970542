import React, { useState, useRef } from "react"
import styled, { keyframes, css } from "styled-components"

import ActionButton from "../../components/action-button"
import SEO from "../../components/seo"
import Calendar from "../../components/meeting-booker/calendar"
import GreenCircle from "../../components/green-circle"
import ArrowSquare from "../../assets/images/meeting-booker/arrow-square.inline.svg"
import Wonderino from "../../assets/images/meeting-booker/wonderino.inline.svg"
import Forza from "../../assets/images/meeting-booker/forza.inline.svg"
import Hero from "../../assets/images/meeting-booker/hero.inline.svg"
import KindredSvg from "../../assets/images/meeting-booker/kindred.inline.svg"
import Kindred from "../../assets/images/meeting-booker/kindred.png"
import Modal from "../../components/modal"
import CheckinAvatar from "../../assets/images/canada/CheckinAvatar.inline.svg"
import Angle from "../../assets/images/angle.inline.svg"



const collapse = keyframes`
  from {
    height: 100px;
  }

  to {
    height: 0px;
  }
`

const expand = keyframes`
  from {
    height: 0px;
  }

  to {
    height: 100px;
  }
`

const fadeInOut = keyframes`
  0%   {opacity: 1;}
  50%  {opacity: 0;}
  100%  {topacityop: 1;}
`

const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 1170px;
  box-sizing: border-box;
  padding: 135px 0 0;
  flex-direction: column;
  align-self: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 980px) {
    padding: 50px 0 0;
    width: 632px;
  }

  @media screen and (max-width: 640px) {
    width: 345px;
  }

  .meetings-iframe-container {
    position: absolute;
    top: -39px;
    bottom: 0;
    left: 0;
    right: 0;
  
    @media screen and (max-width: 980px) {
      top: -30px;
    }
    @media screen and (max-width: 460px) {
      top: -40px;
    }
    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 95% !important;
    }
  }
`

const Section = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 100px;


  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin-bottom: 30px;

    &.calendar {
      width: 110%;
    }
  }

  @media screen and (max-width: 640px) {
    &.calendar {
      width: 105%;
    }
  }
`



export const H2 = styled.h2`
  font-size: 42px;
  line-height: 52px;
  font-weight: 800;
  margin-bottom: 16px;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    font-weight: 800;
  }

  @media screen and (max-width: 640px) {

    font-weight: 800;
    font-size: 34px;
    line-height: 44px;
  }
`

const SideBar = styled.div`
  display: flex;
  width: 40%;
  height: 700px;
  flex-direction: column;


  p {
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    height: auto;

    p {
      text-align: center;
      br {
        display: none;
      }
    }
  }

  @media screen and (max-width:640px) {
    margin-bottom: 25px;
  }
}


`

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media screen and (max-width: 980px) {
    display: none;
  }
`

const FeaturesContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  display: none;

  @media screen and (max-width: 980px) {
    display: block;
  }
`


const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  margin-bottom: 26px;

  p {
    font-size: 20px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 500px;
    margin: 0 auto;
    margin-bottom: 40px;


    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }

    br {
      display: none;
    }
  }

  @media screen and (max-width: 640px) {
    width: 310px;
    p {
      margin-left: 0px;
    }
  }
`
const ImageComtainer = styled.div`

`


const QuoteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; 
  width: 950px;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    position: relative;
    width: 90%;
  }

`

const PartnerQuote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &.fadeInOut{
    animation: ${fadeInOut} 0.7s ease forwards;
  }

  @media screen and (max-width: 980px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    background: white;
    padding: 32px 21px;
  }
`
const PartnerLogo = styled.div`
  width: 209px;
  background: #fff;
  border: 1px solid #040404;
  height: 133px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 36px;
  position: relative;

  @media screen and (max-width: 980px) {
    width: 114px;
    height: 74px;
    margin-right: 20px;

    svg {
      width: 81px;
    }
  }
  &::after {
    content: "";
    border: 1px dashed #A5A5A5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 5px ;
    left: 5px ;
    z-index: -1;
  }
`
const Quote = styled.div`
  width: 515px;
  @media screen and (max-width: 980px) {
    width: 346px;
  }
  @media screen and (max-width: 640px) {
    width: auto;
    margin-top: 30px;
  }
`

const Right = styled(ArrowSquare)`
  box-shadow: 5px 5px 0px rgb(0 0 0 / 10%);

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
    top: 34px;
    right: -25px;
  }
`

const Left = styled(ArrowSquare)`
  transform: rotate(180deg);
  box-shadow: 5px -5px 0px rgb(0 0 0 / 10%);

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
    top: 34px;
    left: -25px;
  }

`

const StepContent = styled.div`
  width: 70%;
  height: 320px;
  padding: 34px 71px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 66px;
  background-color: #FFFFFF;
  border: 1px solid #000;
  position: relative;

  &::after {
    content: "";
    border: 1px dashed #000;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px ;
    left: 10px ;
    z-index: -1;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    padding: 30px;
    width: 90%;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    margin-top: 25px;
    height: auto;
    width: 80%;
    flex-direction: column;
  }

`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 240px;
  cursor: pointer;
  justify-content: center;

  &:last-of-type {
    width: 305px;
  }

  ${H4} {
    margin: 15px auto;
    font-size: 20px;
    line-height: 30px;
  }

  ${Text} {
    line-height: 24px;
  }

  @media screen and (max-width: 980px) {
    &:last-child {
      grid-column: span 2;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    margin-top: 0;
    margin-bottom: 20px;
    grid-column: 1;
  }
`


const ListImage = styled.div`
  height: 70px;
  min-height: 70px;
  display: block;

  @media screen and (max-width: 980px) {
   svg {
    width: 310px;
   } 
  }
  @media screen and (max-width: 640px) {
    height: auto;
    margin: 0 auto;
   }

`

const Situation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;


  ${Step} {
    @media screen and (max-width: 640px) {
      width: 150px;
    }
  }
  ${StepContent} {
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    height: auto;
    display: flex;
    flex-direction: column;

    &:after {
      content: none;
    }

    @media screen and (max-width: 980px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: flex-start;
      padding: 50px 32px;
    
    }
    @media screen and (max-width: 640px) {
      width: auto;
      padding: 0px 20px;
      flex-direction: row;
    }

    ${ListImage} {
      @media screen and (max-width: 980px) {
      display:block;
      width: 220px;
      }
    }
  }
`

const Sectionheader2 = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;

  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;
    text-align: center;
  }

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`

const TextContainer = styled.div`

`



const SectionDescription4 = styled.p`
  width: 650px;
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
    width: 460px;
  }

  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
    width: 334px;
  }
`

const CheckinAvatarCustom = styled(CheckinAvatar)`
@media screen and (max-width: 640px) {
  margin-bottom:40px;
}
`

const GridContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 30px;

  @media screen and (max-width: 980px) {
    grid-template-columns: auto auto;
    grid-gap: 19px;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: auto;
    grid-gap: 19px;
  }
`
const Card = styled.div`
  background: #fff;
  border: 1px solid #000;
  box-shadow: 5px 5px 0 0 #F2F2F2;
  padding: 32px 24px;
  position: relative;
  ${H4} {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
    @media screen and (max-width: 640px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 30px;
    width: 320px;
    overflow: hidden;
    @media screen and (max-width: 980px) {
      width: auto;
    }

    &.collapse{
      @media screen and (max-width: 640px) {

        animation: ${collapse} 1s ease forwards;
      }
    }

    &.expand{
      @media screen and (max-width: 640px) {

        animation: ${expand} 1s ease forwards;
      }
    }
  }
`
const Tag = styled.span`
  text-transform: uppercase;
  background: #BDE9FF;
  border: 1px solid #000000;
  border-radius: 12px;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  padding: 2px 10px;
  position: absolute;
  bottom: 20px;
`



const Arrow = styled(Angle)`
  width: 18px;
  height: 20px;
  position: relative;
  top: 3px;
  display: none;
  @media screen and (max-width: 640px) {
    display: inline;
  }
`

const ArrowUp = styled(Arrow)`
  transform: rotate(90deg);
`
const ArrowDown = styled(Arrow)`
  transform: rotate(-90deg);
`

const CTAText = styled(H4)`
  text-align: center;
  br {
    display: none;
  }

  @media screen and (max-width: 980px) {
    width: 389px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
    br {
      display: block;
    }
  }
`



const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`

const CustomModal = styled(Modal)`

${H2}{
  width : 600px;
  text-align: center;
  margin-bottom: 35px;

  @media screen and (max-width: 980px) {
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
  }

  @media screen and (max-width: 460px) {
    display: none;
  }

}

`

const CustomCalendar = styled(Calendar)`

@media screen and (max-width: 460px) {
  .meetings-iframe-container {
    top: -34px !important;
  }
}
`

export default function MeetingBooker({ location }) {

  const [selectedPartner, setSelectedPartner] = useState(0)
  const [fadeInOut, setFadeInOut] = useState(false)
  const [card, setCard] = useState(null)

  const [cards, setCards] = useState([
    true, true, true, true, true, true
  ])


  const situationContent = {
    list: [
      {
        title: '1. Get a walkthrough',
        description: 'Talk to one of our experts to let us know what your current challenges are and get an intro of the platform.',
        tag: 'Takes ~30 minutes'
      },
      {
        title: '2. See your improved setup',
        description: 'You will receive a custom demo of the improved experience in your site or app.',
        tag: 'No strings attached'
      },
      {
        title: '3. Select pricing & volume',
        description: 'We offer transparent off-the-shelf pricing for common use cases and custom for big volumes.',
        tag: 'Price match guarantee'
      },
      {
        title: '4. Tech onboarding',
        description: 'You get a custom integration based on your existing or planned architecture to make integration easy as pie.',
        tag: 'Direct tech support'
      },
      {
        title: '5. Go live',
        description: 'Once integrated and tested, you are ready to go live with your improved setup.',
        tag: 'We monitor performance'
      },
      {
        title: '6. Continuous improvements',
        description: 'You receive detailed reporting, global updates and new releases each month which improves your performance.',
        tag: 'Global updates'
      }
    ]
  }

  const PartnerQuotes = [
    {
      logo: Wonderino,
      quote: `
      <p>"Partnering up with Checkin.com was a no-brainer, we loved the flow and already from day one,
      12% more users signed up"</p>
      <br/>
      <p><b>Tobias Carlsson</b>, Rhinoceros</p>
      `
    },
    {
      logo: KindredSvg,
      quote: `
      <p>"With Checkin.com, the player registration process will not only be more interactive but also help us to understand the player better from the point of signup"</p>
      <br/>
      <p><b>Trille Levin</b>, Kindred Group</p>
      `
    },
    {
      logo: Hero,
      quote: `
      <p>"With Checkin.com, the player registration process will not only be more interactive but also help us to understand the player better from the point of signup"</p>
      <br/>
      <p><b>Georg Westin</b>, Hero Gaming</p>
      `
    },
    {
      logo: Forza,
      quote: `
      <p>"With Checkin.com, the player registration process will not only be more interactive but also help us to understand the player better from the point of signup"</p>
      <br/>
      <p><b>Patrik Arnesson</b>, Forza Football</p>
      `
    }
  ]

  const modalRef = useRef()

  const toggle = (index) => {
    setCard(index)
    const newCard = cards.slice()
    newCard[index] = !newCard[index]
    setCards(newCard)
  }

  async function Next() {

    if (selectedPartner < PartnerQuotes.length - 1) {
      setFadeInOut(true)
      setTimeout(() => {
        setFadeInOut(false)
      }, 700)
      setTimeout(() => {
        setSelectedPartner(selectedPartner + 1)
      }, 200)
    }
  }

  function Previous() {
    if (selectedPartner > 0) {
      setFadeInOut(true)
      setTimeout(() => {
        setFadeInOut(false)
      }, 700)
      setTimeout(() => {
        setSelectedPartner(selectedPartner - 1)
      }, 200)
    }
  }

  const Logo = () => {
    const Icon = PartnerQuotes[selectedPartner].logo
    return <Icon />
  }

  return (
    <Container>
      <SEO
        title="Schedule time with an onboarding expert here"
        description="Book time directly to discuss your use-case and get a complete solution for your KYC and customer onboarding challenges."
        canonical="/meeting-booker"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />
      <Section className="calendar">
        <SideBar>
          <H2>
            See your improved <br />
            onboarding in action
          </H2>
          <p>
            Request a demo from one of<br /> our conversion specialists.
          </p>
          <FeaturesContainer>
            <Feature>
              <ImageComtainer>
                <GreenCircle />
              </ImageComtainer>
              <p>See how Checkin.com works<br /> in your own site/app </p>
            </Feature>
            <Feature>
              <ImageComtainer>
                <GreenCircle />
              </ImageComtainer>
              <p>Fully working prototype with your<br /> branding and local adaptations </p>
            </Feature>
            <Feature>
              <ImageComtainer>
                <GreenCircle />
              </ImageComtainer>
              <p>Conversion increase<br /> guaranteed</p>
            </Feature>
          </FeaturesContainer>

        </SideBar>
        <Calendar
          debug={true}
        />
        <FeaturesContainerMobile>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>See how Checkin.com works<br /> in your own site/app </p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Fully working prototype with your<br /> branding and local adaptations </p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Conversion increase<br /> guaranteed</p>
          </Feature>
        </FeaturesContainerMobile>
      </Section>
      <Section>
        <QuoteContainer>
          <ImageComtainer>
            <Left onClick={() => Previous()} className={selectedPartner === 0 ? 'disabled' : null} />
          </ImageComtainer>
          <PartnerQuote className={fadeInOut ? "fadeInOut" : null} >
            <PartnerLogo>
              {selectedPartner === 1 ? (<img src={Kindred} />) : (<Logo />)}
            </PartnerLogo>
            <Quote
              dangerouslySetInnerHTML={{ __html: PartnerQuotes[selectedPartner].quote }}
            >
            </Quote>
          </PartnerQuote>
          <ImageComtainer>
            <Right onClick={() => Next()} className={selectedPartner == 3 ? 'disabled' : null} />
          </ImageComtainer>
        </QuoteContainer>

        <CustomModal ref={modalRef}>
          <H2>Schedule a meeting with our conversion specialist</H2>
          <CustomCalendar
            height='860px'
            width='100%'
            debug={true}
          />
        </CustomModal>



      </Section>

      <Section id="get-started">
        <Situation>
          <Sectionheader2>
            <TextContainer>
              <H2>Getting started is easy</H2>
              <SectionDescription4>
                We configure and customize the entire integration to fit into your existing architecture and minimize the work required on your end.
              </SectionDescription4>
            </TextContainer>
            <CheckinAvatarCustom />

          </Sectionheader2>

          <StepContent>
            <GridContainer>
              {situationContent.list.map((element, index) => (
                <Card
                  index={index}
                  key={index}
                  data-sal="fade"
                  data-sal-delay={200 * index}
                  data-sal-easing="ease-in"
                >
                  <H4 onClick={() => toggle(index)}>{element.title} {cards[index] ? (<ArrowUp />) : (<ArrowDown />)}  </H4>
                  <p
                    className={cards[index] ? 'collapse' : 'expand'}
                  >{element.description}</p>
                  <Tag>{element.tag}</Tag>
                </Card>
              ))}
            </GridContainer>
          </StepContent>

          <CTAText>See the complete solution in action</CTAText>
          <TalkToSalesButton onClick={() => modalRef.current.open(null, null, null, null, true)}>
            Get started
          </TalkToSalesButton>
        </Situation>
      </Section>

    </Container >
  )
}