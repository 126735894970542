import React, { useState } from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"


const DARKRED = "#A71D5D"
const LIGHTRED = "#DD1144"
const BLUE = "#0086B3"
const GREEN = "#63A35C"
const ORANGE = "#DF5000"
const PURPLE = "#795DA3"


const Container = styled.table`
  width: 1005px;
  height: 218px;
  margin: 60px 0 0;
  border: 1px solid #999999;
  border-left: none;
  border-collapse: collapse;

  @media screen and (max-width: 980px) {
    border-right: none;
    width: 100%;
    height: auto;
  }
`

const Body = styled.tbody``

const Row = styled.tr``

const ButtonContainer = styled.td`
  border: 1px solid #999999;
  border-left: none;
  width: 390px;

  @media screen and (max-width: 980px) {
    border-right: none;
    width: auto;
  }
`

const Button = styled.button`
  background: transparent;
  box-shadow: none;
  outline: none;
  border: none;
  height: 72px;
  width: 100%;
  font-size: 20px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 20px 20px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: ${props => (props.isselected ? 900 : 400)};
  transition: font-weight 0.2s ease;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
`

const Arrow = styled(Img)`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s ease;

  @media screen and (max-width: 980px) {
    display: none !important;
  }
`

const CodeContainer = styled.td`
  background: #ebedef;
  width: 617px;
  font-size: 13px;
  align-items: center;
  height: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  line-height: 32px;

  pre {
    white-space: nowrap;
  }

  @media screen and (max-width: 980px) {
    display: none;
  }
`

const MobileRow = styled.tr`
  display: none;
  @media screen and (max-width: 980px) {
    ${props =>
    props.isselected &&
    css`
        display: flex;
      `}
  }
`

const CodeContainerMobile = styled(CodeContainer)`
  border: 1px solid #999999;
  border-bottom: none;
  border-top: none;
  padding: 18px 15px;
  font-size: 12px;

  @media screen and (min-width: 981px) {
    display: none;
  }
  @media screen and (max-width: 980px) {
    display: flex;
    width: 100%;
  }

  @media screen and (max-width: 640px) {
    width: 334px;

    pre {
      white-space: pre-wrap;
    }
  }
`

const Code = styled.code`
  opacity: ${props => (props.isselected ? 1 : 0.2)};
  transition: opacity 0.2s ease;

  @media screen and (max-width: 980px) {
    opacity: 1;
  }
`

const LibraryCode = ({ isselected = false }) => (
  <pre>
    <Code isselected={isselected}>
      <span>{"<"}</span>
      <span style={{ color: GREEN }}>{"script "}</span>
      <span style={{ color: PURPLE }}>{"src"}</span>
      <span>{"="}</span>
      <span style={{ color: ORANGE }}>
        {'"https://[partner].checkin.com/[key].js" '}
      </span>
      <span style={{ color: PURPLE }}>{"async"}</span>
      <span>{">"}</span>
      <span>{"</"}</span>
      <span style={{ color: GREEN }}>{"script"}</span>
      <span>{">"}</span>
    </Code>
  </pre>
)

const LibraryCodeMobile = ({ isselected = false }) => (
  <pre>
    <Code isselected={isselected}>
      <span>{"<"}</span>
      <span style={{ color: GREEN }}>{"script "}</span>
      <span style={{ color: PURPLE }}>{"src"}</span>
      <span>{"="}</span>
      <span style={{ color: ORANGE }}>
        {'"https://\n[partner].checkin.com/[key].js" '}
      </span>
      <span style={{ color: PURPLE }}>{"async"}</span>
      <span>{">"}</span>
      <span>{"</"}</span>
      <span style={{ color: GREEN }}>{"script"}</span>
      <span>{">"}</span>
    </Code>
  </pre>
)

const TriggerCode = ({ isselected = false }) => (
  <pre>
    <Code isselected={isselected}>
      <span style={{ color: DARKRED }}>{"function "}</span>
      <span style={{ color: PURPLE }}>{"triggerRegily"}</span>
      <span>{"() "}</span>
      <span>{"{ "}</span>
      <span style={{ color: BLUE }}>{"window"}</span>
      <span>{".checkin.signUp.open() "}</span>
      <span>{"}"}</span>
    </Code>
  </pre>
)

const TriggerCodeMobile = ({ isselected = false }) => (
  <pre>
    <Code isselected={isselected}>
      <span style={{ color: DARKRED }}>{"function "}</span>
      <span style={{ color: PURPLE }}>{"triggerRegily"}</span>
      <span>{"() "}</span>
      <span>{"{ "}</span>
      <span style={{ color: BLUE }}>{"\nwindow"}</span>
      <span>{".checkin.signUp.open() "}</span>
      <span>{"\n}"}</span>
    </Code>
  </pre>
)

const ConfigCode = ({ isselected = false }) => (
  <pre>
    <Code isselected={isselected}>
      <span style={{ color: BLUE }}>{"document"}</span>
      <span>{".documentElement.dataset.checkinCountry = "}</span>
      <span style={{ color: LIGHTRED }}>{"'DE'"}</span>
    </Code>
  </pre>
)

const ConfigCodeMobile = ({ isselected = false }) => (
  <pre>
    <Code isselected={isselected}>
      <span style={{ color: BLUE }}>{"document"}</span>
      <span>{".documentElement.dataset\n.checkinCountry = "}</span>
      <span style={{ color: LIGHTRED }}>{"'DE'"}</span>
    </Code>
  </pre>
)

export default function CodeExample() {
  const [selectedExample, setSelectedExample] = useState(1)

  const data = useStaticQuery(graphql`
    query {
      arrow: file(relativePath: { eq: "product/arrow.png" }) {
        desktop: childImageSharp {
          fixed(height: 18, width: 27) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Body>
        <Row>
          <ButtonContainer>
            <Button
              onClick={() => setSelectedExample(1)}
              isselected={selectedExample === 1}
            >
              <span>Lightweight library</span>
              <Arrow
                fixed={data.arrow.desktop.fixed}
                isVisible={selectedExample === 1}
              />
            </Button>
          </ButtonContainer>
          <CodeContainer>
            <LibraryCode isselected={selectedExample === 1} />
          </CodeContainer>
        </Row>
        <MobileRow isselected={selectedExample === 1}>
          <CodeContainerMobile>
            <LibraryCodeMobile isselected={selectedExample === 1} />
          </CodeContainerMobile>
        </MobileRow>
        <Row>
          <ButtonContainer>
            <Button
              onClick={() => setSelectedExample(2)}
              isselected={selectedExample === 2}
            >
              <span>Trigger from anywhere</span>
              <Arrow
                fixed={data.arrow.desktop.fixed}
                isVisible={selectedExample === 2}
              />
            </Button>
          </ButtonContainer>
          <CodeContainer>
            <TriggerCode isselected={selectedExample === 2} />
          </CodeContainer>
        </Row>
        <MobileRow isselected={selectedExample === 2}>
          <CodeContainerMobile>
            <TriggerCodeMobile isselected={selectedExample === 2} />
          </CodeContainerMobile>
        </MobileRow>
        <Row>
          <ButtonContainer>
            <Button
              onClick={() => setSelectedExample(3)}
              isselected={selectedExample === 3}
            >
              <span>Configure with ease</span>
              <Arrow
                fixed={data.arrow.desktop.fixed}
                isVisible={selectedExample === 3}
              />
            </Button>
          </ButtonContainer>
          <CodeContainer>
            <ConfigCode isselected={selectedExample === 3} />
          </CodeContainer>
        </Row>
        <MobileRow isselected={selectedExample === 3}>
          <CodeContainerMobile>
            <ConfigCodeMobile isselected={selectedExample === 3} />
          </CodeContainerMobile>
        </MobileRow>
      </Body>
    </Container>
  )
}
