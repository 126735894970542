import React, { useContext, useRef, useEffect } from "react"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import SEO from "../components/seo"
import { Script } from "gatsby"

import Grid from "../components/id-scanner/grid-us"
import MiddleText from "../components/common/middle-text"
import ActionButton from "../components/action-button"
import Partnners from "../components/common/partners"
import loadable from '@loadable/component'
import Face from "../assets/images/id-scanner/face.svg"
import Localized from "../assets/images/id-scanner/localized.svg"
import Global from "../assets/images/id-scanner/global-compliance.svg"
import CheckinUs from "../assets/images/checkin-us.svg"


const HeaderAnimation = loadable(() => import("../components/id-scanner/header-animation-us"))




const TalkToSales = styled.div`
grid-area: ${props => props.gridArea};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const IllustratedTextContainer = styled.div`
  grid-area: ${props => props.gridArea};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 80px 0 0;

  &::last-child {
    margin-bottom: 170px;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin: 50px 0 0;
  }

  ${props =>
    props.medium &&
    css`
        width: 80%;
      `}

      @media screen and (max-width: 980px) {
        ${props =>
    props.reverse &&
    css`
            flex-direction: column-reverse;
            `}
        }
`

const FluidContainer = styled.object`
  width: 450px;
  height: auto;
  display: flex;
  justify-content: center;

  img {
    width: 315px;
    height: auto;
  }
  @media screen and (max-width: 980px) {
    width: 370px;
  }

`
const H4 = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const IndexIllustrationContainer = styled.div`
grid-area: header-animation;

  position: absolute;
  display: flex;
  justify-content: center;
  top: 132px;
  width: 100%;

  @media screen and (max-width: 980px) {
    top: 121px;
  }

  @media screen and (max-width: 640px) {
    top: 210px;
  }
`

const IllustratedText = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;

  ${H4} {
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    max-width: 440px;
  }

  @media screen and (max-width: 980px) {
    text-align: center;
    width: 506px;
    align-items: center;

    ${H4} {
      margin-bottom: 14px;
      margin-top: 32px;
    }
  }

  @media screen and (max-width: 640px) {
    width: 334px;

    ${H4} {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
    }
  }
`

const HubspotForm = styled.div`
  grid-area: hubspot;
  border: 1px solid var(--Text-Primary, #000); background: #FFF; 
  display: flex;
  padding: 65px 95px;
  
  h2 {
    font-size: 34px;
    font-weight: 800;
    line-height: 42px;
    margin-bottom: 16px;
  }
  .form {
    width: 50%;
  }
  .img {
    flex: 1;
    display: flex;
    justify-content: center;
    img {
    width: 355px;
    }
  }

  @media screen and (max-width: 980px) {
    display: flex;
    flex-direction: column;

    .form{
      width: 100%;
    }

    .img {
      matgin-top: 40px;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 20px 20px;

  }
`

export default function IGamingPage({ location }) {

  useEffect(() => {
    let script
    const createForm = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "5855020",
          formId: "01996b0f-6cbb-4fe4-a392-3bfbf8d0741f"
        });
      } else {
        console.error("HubSpot script failed to load");
      }
    };

    if (typeof window.hbspt !== 'undefined') {
      createForm();
      console.log('iff')
    } else {
      console.log('elsee')

      script = document.createElement('script');
      script.src = "https://js.hsforms.net/forms/v2.js";
      script.addEventListener('load', createForm);
      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        script.removeEventListener('load', createForm);
      }
    };
  }, []);

  return (
    <>
      <SEO
        title="Step into the future of identity verification in the US"
        description="Break new ground in verification with a user-first approach to security."
        canonical="/north-america/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />

      <Grid>
        <MiddleText
          h1
          primary
          gridArea="header"
          headerText="Step into the future of<br/> identity verification in the US"
          subheaderText="Break new ground in verification with a user-first approach to security."
        />

        <IndexIllustrationContainer>

          <HeaderAnimation />
        </IndexIllustrationContainer>

        <Partnners gridArea={"partners"} smallTitle />


        <IllustratedTextContainer reverse gridArea='face'>

          <IllustratedText>
            <H4>Face matching to phone verification</H4>
            <p>
              Our system combines biometrics, ID scanning, face matching, and liveness checks with traditional verifications like email and bank details. This integration streamlines operations, reduces friction, and enhances the onboarding experience, improving customer satisfaction and conversion rates.
            </p>

          </IllustratedText>
          <FluidContainer>
            <img src={Face} />
          </FluidContainer>
        </IllustratedTextContainer>

        <IllustratedTextContainer gridArea='localized'>
          <FluidContainer>
            <img src={Localized} />
          </FluidContainer>


          <IllustratedText>
            <H4>Localized for Success</H4>
            <p>
              Checkin.com provides a complete set of identity verification tools tailored for the US market, including SSN verification, database checks, and address verification. Our system optimizes for each customer’s device and location to ensure high conversion rates. It also adapts to local regulations and verification methods globally, making it versatile for international operations.
            </p>

          </IllustratedText>
        </IllustratedTextContainer>

        <IllustratedTextContainer reverse={true} gridArea='global'>



          <IllustratedText>
            <H4>Global and local compliance</H4>
            <p>
              Our platform complies with strict US regulations, ensuring all data processing stays within the country for top compliance. While it's tailored for the US market, it also handles global verification needs by adapting to international compliance standards. This allows your business to expand globally without needing multiple verification providers, ensuring seamless operations both domestically and internationally.
            </p>



          </IllustratedText>
          <FluidContainer>
            <img src={Global} />
          </FluidContainer>
        </IllustratedTextContainer>


        <HubspotForm>
          <div className="form">
            <h2>Want to see what we can
              do for you? Let's talk!</h2>
            <script
              dangerouslySetInnerHTML={{
                __html: `
hbspt.forms.create({
region: "na1",
portalId: "5855020",
formId: "01996b0f-6cbb-4fe4-a392-3bfbf8d0741f"
});
`,
              }}
            />
          </div>
          <div className="img">
            <img src={CheckinUs} />
          </div>
        </HubspotForm>



      </Grid >

    </>
  )
}
