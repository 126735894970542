import styled from "styled-components"
import LayoutGrid from "../layout-grid"

const Grid = styled(LayoutGrid)`
  z-index: 1;
  margin-top: 135px;
  grid-template-areas:
    ". . header header header header header header header header header header . ."
    ". . description description description description description description description description description description . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". video-container video-container video-container video-container video-container video-container video-container video-container video-container video-container video-container video-container ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . features-container features-container features-container features-container features-container features-container features-container features-container features-container features-container . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . get-remote get-remote get-remote get-remote . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". without-remote without-remote without-remote without-remote without-remote without-remote without-remote without-remote without-remote without-remote without-remote without-remote ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". with-remote with-remote with-remote with-remote with-remote with-remote with-remote with-remote with-remote with-remote with-remote with-remote ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description ."
    ". . . . . . . . . . . . . ."
    ". results-box results-box results-box results-box results-box results-box results-box results-box results-box results-box results-box results-box ."
    ". claims-text claims-text claims-text claims-text claims-text claims-text claims-text claims-text claims-text claims-text claims-text claims-text ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". features-description features-description features-description features-description features-description features-description features-description features-description features-description features-description features-description features-description ."
    ". feature-one feature-one feature-one feature-one feature-one feature-one feature-two feature-two feature-two feature-two feature-two feature-two ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". feature-three feature-three feature-three feature-three feature-three feature-three feature-four feature-four feature-four feature-four feature-four feature-four ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". usecases-description usecases-description usecases-description usecases-description usecases-description usecases-description usecases-description usecases-description usecases-description usecases-description usecases-description usecases-description ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    "colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes"
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". know-more know-more know-more know-more know-more know-more know-more know-more know-more know-more know-more know-more ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . talk-to-sales talk-to-sales talk-to-sales talk-to-sales . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . faq faq faq faq faq faq faq faq faq faq . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . .";

  @media screen and (max-width: 980px) {
    padding-top: 50px;
    margin-top: 0px;
    grid-template-areas:
      ". header header header header header header header header ."
      ". description description description description description description description description ."
      ". . . . . . . . . ."
      ". video-container video-container video-container video-container video-container video-container video-container video-container ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . features-container features-container features-container features-container features-container features-container . ."
      ". . . get-remote get-remote get-remote get-remote . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description without-remote-description ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". without-remote without-remote without-remote without-remote without-remote without-remote without-remote without-remote ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". with-remote with-remote with-remote with-remote with-remote with-remote with-remote with-remote ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description with-remote-description ."
      ". . . . . . . . . ."
      ". results-box results-box results-box results-box results-box results-box results-box results-box ."
      ". claims-text claims-text claims-text claims-text claims-text claims-text claims-text claims-text ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". features-description features-description features-description features-description features-description features-description features-description features-description ."
      ". . feature-one feature-one feature-one feature-two feature-two feature-two . ."
      ". . . . . . . . . ."
      ". . feature-three feature-three feature-three feature-four feature-four feature-four . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . usecases-description usecases-description usecases-description usecases-description usecases-description usecases-description . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      "colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes colored-boxes"
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". know-more know-more know-more know-more know-more know-more know-more know-more ."
      ". . . . . . . . . ."
      ". . . talk-to-sales talk-to-sales talk-to-sales talk-to-sales . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". faq faq faq faq faq faq faq faq ."
      ". . . . . . . . . ."
      ". . . . . . . . . .";
  }

  @media screen and (max-width: 640px) {
    grid-template-areas:
      ". header ."
      ". description ."
      ". . ."
      ". . ."
      ". video-container ."
      ". . ."
      ". . ."
      ". features-container ."
      ". . ."
      ". . ."
      ". get-remote ."
      ". . ."
      ". . ."
      ". without-remote-description ."
      ". without-remote ."
      ". . ."
      ". . ."
      ". with-remote ."
      ". . ."
      ". . ."
      ". . ."
      ". with-remote-description ."
      ". . ."
      ". results-box ."
      ". claims-text ."
      ". . ."
      ". . ."
      ". . ."
      ". features-description ."
      ". . ."
      ". feature-one ."
      ". feature-two ."
      ". feature-three ."
      ". feature-four ."
      ". . ."
      ". . ."
      ". usecases-description ."
      ". . ."
      ". colored-boxes ."
      ". . ."
      ". . ."
      ". . ."
      ". . ."
      ". faq ."
      ". . ."
      ". . ."
      ". know-more ."
      ". . ."
      ". talk-to-sales ."
      ". . ."
      ". . ."
      ". . ."
      ". . .";
  }
`

export default Grid
