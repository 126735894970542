import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../seo"
import Grid from "./grid"
import SectionIntro from "../section-intro"
import PostCard from "./post-card"
import Topics from "./topics"

const PostsContainer = styled.div`
  grid-area: posts;
  transform: translateX(-14px);

  @media screen and (max-width: 640px) {
    transform: translateX(-7px);
  }
`

const Index = ({ data, location, pageContext }) => {
  const posts = data.allGhostPost.edges
  return (
    <>
      <SEO
        title="Checkin.com Case Studies | Real Examples of Growth"
        description="Read our case studies to discover how Checkin.com helped clients increase their revenue, streamline their processes, and achieve their goals."
        canonical="/case-studies/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />

      <Grid>
        <SectionIntro
          header="Case Studies"
          description="Learn how improved customer onboarding makes our customers grow faster"
        />

        <PostsContainer>
          {posts.map(({ node }, index) => (
            <PostCard key={index} post={node} />
          ))}
        </PostsContainer>

      </Grid>
    </>
  )
}

Index.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
}

export default Index

export const pageQuery = graphql`
  query GhostCaseQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
      filter: {primary_tag: {slug: {eq: "case-study"}}}
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }

    allGhostTag(sort: { order: ASC, fields: name }) {
      edges {
        node {
          slug
          url
          postCount
        }
      }
    }
  }
`
