import React from "react"


const Menu = [
    {
        title: 'Product',
        gridTemlplateCol: "1fr 1fr",
        width: '630px',
        items: [
            {
                title: 'Document scanning',
                text: 'Identity verification via ID scanning',
                link: '/id-scanner'
            },
            {
                title: 'KYC & AML Compliance',
                text: 'Stay compliant with regulations',
                link: '/product/aml-pep-sanction-checks'

            },
            {
                title: 'Liveness',
                text: 'Identity fraud, deep fakes',
                link: '/product/liveness'
            },
            {
                title: 'Botlens',
                text: 'Discern real humans from bots',
                link: '/botlens'
            },
            {
                title: 'Face recognition',
                text: 'Face similarity 1:1 and 1:N',
                link: '/face-recognition'
            },
            {
                title: 'Proof of address',
                text: 'Confirm the user\'s place of permanent residence',
                link: '/product/proof-of-address'
            },
            {
                title: 'User onboarding',
                text: 'Framework for optimized sign-ups',
                link: '/user-onboarding'
            },
            {
                title: 'Signature',
                text: 'Comparison of signature interactions 1-1 and 1-N',
                link: '/product/signature'
            },


        ]
    },
    {
        title: 'Industries',
        gridTemlplateCol: "1fr",
        width: '190px',
        items:
            [
                {
                    title: 'iGaming',
                    link: '/industry-igaming'

                },
                {
                    title: 'iGaming - The Netherlands',
                    link: '/industry-igaming/netherlands',
                    secondary: true
                },
                {
                    title: 'iGaming - Canada (Ontario)',
                    link: '/industry-igaming/canada',
                    secondary: true
                },
                {
                    title: 'iGaming - Brazil',
                    link: '/industry-igaming/brazil',
                    secondary: true
                },
                {
                    title: 'Travel',
                    link: '/industry-travel'
                },
                {
                    title: 'Finance/Crypto',
                    link: '/industry-fintech'
                }
            ]
    },

    {
        title: 'Resources',
        gridTemlplateCol: "1fr",
        width: '190px',
        items:
            [
                {
                    title: 'Integration',
                    outsider: true,
                    link: 'https://dev.checkin.com/docs/home'
                },
                {
                    title: 'Blog',
                    link: '/blog'
                },
                {
                    title: 'Privacy Policy',
                    link: '/privacy-policy'
                },
            ]
    },

    {
        title: 'Company',
        gridTemlplateCol: "1fr",
        width: '215px',
        items:
            [
                {
                    title: 'Become a partner',
                    link: '/partnerships'
                },
                {
                    title: 'About us',
                    link: '/about-us'
                },
                {
                    title: 'Contacts',
                    link: '/contact-us'
                },
                {
                    title: 'Investor relations',
                    outsider: true,
                    link: 'https://group.checkin.com/investors'
                },
                {
                    title: 'Press and media',
                    outsider: true,
                    link: 'https://group.checkin.com/newsroom'
                },
                {
                    title: 'Careers',
                    outsider: true,
                    link: 'https://group.checkin.com/careers'
                },
            ]
    }
]

export default Menu
