import React, { useContext } from "react"
import { useDispatch } from "react-redux"

import styled, { css } from "styled-components"

import GlobalContext from "../../context/global-context"
import { pushCustomDataLayerEvent } from "../../state/actions"
import ActionButton from "../../components/action-button"

import Checkin from "../../assets/images/igaming/checkin.inline.svg"


const Container = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  justify-content: space-between;

  svg {
    width: 40% !important;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;
    align-items: center;

    svg {
      width: 200px !important;
    }

  }

`

const CustomActionButton = styled(ActionButton)`
  width: 263px;
  z-index: 20;
  margin-top: 30px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  grid-area: ${props => props.gridArea};
  width: 50%;
  @media screen and (max-width: 980px) {
    width:70%;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    align-items: center;
    margin-top : 20px;
    width: 100%;
    text-align: center;


  }
`

const Header = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;
  white-space: pre-line;

  @media screen and (max-width: 640px) {
    font-size: 28px;
    line-height: 34px;
  }

  ${props =>
    props.primary &&
    css`
      font-size: 48px;
      line-height: 56px;
      width: 618px;
      margin-bottom: 16px;

      @media screen and (max-width: 980px) {
        width: 600px;
      }

      @media screen and (max-width: 640px) {
        line-height: 44px;
        font-size: 34px;
        width: 335px;
      }
    `}
`

const Subheader = styled.p`
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;

  @media screen and (max-width: 640px) {
    line-height: 32px;
    font-size: 18px;
  }
`

export default function LeadingBrands({
  gridArea,
}) {
  const dispatch = useDispatch()
  const globalContext = useContext(GlobalContext)

  return (
    <Container gridArea={gridArea}>

      <TextContainer >
        <Header >Leading operators and millions of players trust Checkin.com</Header>
        <Subheader>Want to find out why?</Subheader>
        <CustomActionButton
          onClick={() => {
            dispatch(
              pushCustomDataLayerEvent({
                event: "get_started",
                eventCategory: "get_started",
                eventAction: "open",
                eventLabel: "bottom_cta",
              })
            )
            globalContext.openGetStarted()
          }}
        >
          Get started
        </CustomActionButton>
      </TextContainer>

      <Checkin />

    </Container>
  )
}
