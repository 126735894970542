import React, { useRef, useEffect } from "react"
import styled from "styled-components"

import HeroIllustration from "../../assets/images/fintech/hero-header.svg"
import Phones from "../../assets/images/ocr/phones.svg"
import animationData from "../../assets/images/fintech/fintech_animation.json"
import centerAnimationData from "../../assets/images/partnerships/center_animation.json"

import lottie from 'lottie-web/build/player/lottie_light.min.js'


const AnimationContainer = styled.div`
display: flex;
justify-content: center;
@media screen and (max-width: 460px){
  position: relative;
  width: 100%;
  height: 250px;
    display: flex;
}
`
const Container = styled.div`
grid-area: slot-machine;

display: flex;
width: 100%;
justify-content: center;
.mobileAnimation {
  z-index: 1;
  width: 30%;
}

.background{
  position: absolute;
  @media screen and (max-width: 460px) {
    display: none;
  }
}

@media screen and (max-width: 980px) {
  height: 360px;

}

@media screen and (max-width: 640px) {
  background: none;
  height: 250px;
  .mobileAnimation {
    align-self: center;
  }
}

img {}


`

const Animation = styled.div`
  @media screen and (max-width: 640px) {
    position: absolute;
  }

`
export default function SlotMachine() {


  let animationContainer = useRef();
  let CenterAnimation = useRef();
  let animation = null;

  useEffect(() => {
    if (window.innerWidth <= 640) {
      animation = lottie.loadAnimation({
        container: animationContainer.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData

      });
    }

    animation = lottie.loadAnimation({
      container: CenterAnimation.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: centerAnimationData

    });

    return () => animation.destroy()
  }, []);

  return (
    <Container>
      <AnimationContainer >
        <img className="background" src={HeroIllustration} />
        <Animation ref={animationContainer} />
        <Animation className="mobileAnimation" ref={CenterAnimation} />

        {/* <img className="mobileAnimation" src={Phones} /> */}
      </AnimationContainer>
    </Container>
  )
}
