import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { SwitchTransition, CSSTransition } from "react-transition-group"


import Next from "../../assets/images/ocr/next.inline.svg"
import Invalid from "../../assets/images/ocr/invalid.inline.svg"
import Conversion from "../../assets/images/igaming/conversion.inline.svg"
import Combine from "../../assets/images/igaming/combine.inline.svg"
import Control from "../../assets/images/igaming/control.inline.svg"



const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  padding: 0 68px;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  z-index: 1;

.sliders {
  display: none;
  @media screen and (max-width: 640px) {
    display:block;
  }

}

@media screen and (max-width: 640px) {
  padding: 0;
  height: auto;
}
`

const Mobilecontainer = styled.div`
@media screen and (max-width: 640px) {
  overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
}
`


const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const StepsContainer = styled.div`
  width: 895px;
  display: flex;
  justify-content: space-between;
  margin: 0px 0 40px 0;
  position: relative;
  border-bottom: 2px solid rgba(0, 0, 0, 0.04);


  @media screen and (max-width: 980px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 240px;
  cursor: pointer;
  justify-content: center;

  

  ${H4} {
    margin: 15px auto;
    font-size: 20px;
    line-height: 30px;
  }

  ${Text} {
    line-height: 24px;
  }

  @media screen and (max-width: 980px) {
    &:last-child {
      grid-column: span 2;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    margin-top: 0;
    margin-bottom: 20px;
    grid-column: 1;
  }
`

const CustomStep = styled(Step)`
${H4} {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}
${props =>
    props.active &&
    css`
    border-bottom: 2px solid #000000;
    ${H4} {
      font-weight: 700;
    }
  `}

img {
  width: 69px;
  height: 69px;
}

@media screen and (max-width: 980px) {

  ${H4} {
    font-size: 14px;
    line-height: 14px;
  }

  &:last-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 640px) {
  width: 230px;
  margin-bottom:0px;
  ${H4} {
    font-size: 14px;
    line-height: 22px;
  }
}
`

const CustomStepsContainer = styled(StepsContainer)`

@media screen and (max-width: 980px) {
  display: flex;
}

@media screen and (max-width: 640px) {
  width: 600px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
`

const ListImage = styled.div`
  height: 70px;
  min-height: 70px;
  display: block;
  margin-left: 40px;

  @media screen and (max-width: 980px) {
   svg {
    width: 250px;
   } 
  }
  @media screen and (max-width: 640px) {
    height: auto;
    margin: 0 auto;
    svg {
      width: 260px;
    }
   }
  

`
const NextButton = styled.span`
  position: absolute;
  width: 44px;
  top: 300px;
  right: -18px;
`

const PreviousButton = styled.span`
  position: absolute;
  width: 44px;
  top: 300px;
  left: -18px;
`
const InvalidNext = styled(Invalid)`
  transform: rotate(180deg);
`
const Prev = styled(Next)`
  transform: rotate(180deg);
`
const StepContent = styled.div`
height: 418px;
width: 890px;
padding: 34px 71px;
display: flex;
justify-content: space-between;
margin-bottom: 66px;
background-color: #FFFFFF;
border: 1px solid #000;
position: relative;

&.fade-enter {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-exit-active {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-enter-active,
&.fade-exit-active {
  transition: all 0.3s ease;

}
&.fade-enter-active,
&.fade-exit-active,
&.fade-exit,
&.fade-enter{
  &::after {
    display:none;
  }
}

&::after {
  content: "";
  border: 1px dashed #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 10px ;
  left: 10px ;
  z-index: -1;
  
}

@media screen and (max-width: 980px) {
  flex-direction: row;
  padding: 30px;
}

@media screen and (max-width: 640px) {
  grid-template-columns: 1fr;
  margin-top: 25px;
  height: auto;
  width: 100%;
  flex-direction: column;

  &.fade-enter {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.3s ease;
  }

  &.fade-enter-active,
  &.fade-exit-active,
  &.fade-exit,
  &.fade-enter{
    &::after {
      display:none;
    }
  }

}

`

const List = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
width: 390px;
@media screen and (max-width: 980px) {
  width: 317px;
}
@media screen and (max-width: 640px) {
  width: auto;
  ul {
    margin-left: 16px;
  }
}
${H4}{
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  @media screen and (max-width: 980px) {
      font-size: 20px;
      line-height: 32px;
  }
}
p {
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width: 980px) {
    font-size: 14px;
    line-height: 24px;
  }
 }
 ul {
  margin-top: 15px;
  margin-left: 14px;
 }




li {
  font-size: 16px;
  line-height: 32px;
  padding-left: 10px;
  font-weight: bold;
}
}

`

export default function FastEasy({
  gridArea,
}) {

  const [activestep, setactivestep] = useState(0)
  const [prev, setPrev] = useState(false)
  const [next, setNext] = useState(true)

  const stepsContent = [
    {
      header: "Conversion increase guaranteed",
      subheader: 'Checkin.com exists to enable faster growth on a global scale. The use of AI in combination with cutting-edge UX modules makes it faster, easier and more engaging to become your customer. All new customers are offered a conversion increase guarantee.',
      image: Conversion
    },
    {
      header: "Mix & match any data",
      subheader: 'Any data you collect during the session or already sits in your backend can be used at any point in the signup flow, allowing you to improve the user experience and automation while leveraging the data you already own.',
      image: Combine
    },
    {
      header: "100% control over branding and UX",
      subheader: 'Enable a seamless and engaging experience for your customers to ensure high conversion without compromising your brand. Every aspect of the flow is 100% customizable and easily adjusted through our powerful backoffice.',
      image: Control
    }

  ]

  useEffect(() => {
    setPrev(activestep > 0)
    setNext(activestep < stepsContent.length - 1)

  }, [activestep]);

  useEffect(() => {
    setPrev(activestep > 0)
    setNext(activestep < stepsContent.length - 1)

  }, [activestep]);

  const Icon = (props) => {

    const TheIcon = stepsContent[activestep].image
    return <TheIcon {...props} />
  }
  return (
    <Container gridArea={gridArea}>

      <Mobilecontainer className="InstantValue">
        <CustomStepsContainer>
          <CustomStep className="step1" active={activestep === 0} onClick={() => setactivestep(0)}>
            <H4>Increase conversion </H4>
          </CustomStep>
          <CustomStep className="step2" active={activestep === 1} onClick={() => setactivestep(1)}>
            <H4>Combine with any data</H4>
          </CustomStep>
          <CustomStep className="step3" active={activestep === 2} onClick={() => setactivestep(2)}>
            <H4>Control branding & UX</H4>
          </CustomStep>
        </CustomStepsContainer>
      </Mobilecontainer>

      <SwitchTransition >
        <CSSTransition
          key={activestep}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="fade"
        >

          <StepContent>

            <NextButton className="sliders" onClick={() => next ? setactivestep(activestep + 1) : null}> {next ? (<Next />) : (<InvalidNext />)}</NextButton>
            <PreviousButton className="sliders" onClick={() => prev ? setactivestep(activestep - 1) : null}>{prev ? (<Prev />) : (<Invalid />)}</PreviousButton>

            <List>
              <H4>{stepsContent[activestep].header}</H4>
              <p>{stepsContent[activestep].subheader}</p>
            </List>
            <ListImage>
              <Icon />
            </ListImage>
          </StepContent>
        </CSSTransition>
      </SwitchTransition>

    </Container>
  )
}
