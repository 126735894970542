import Liveness from "../assets/liveness.json"
import IdScan from "../assets/idscan.json"
import DocScan from "../assets/doc-scan.json"

import Liveness_nth from "../assets/livenes-net.json"
import IdScan_nth from "../assets/id-scan-net.json"
import DocScan_nth from "../assets/doc-scan-net.json"

const id_scanner = [
    IdScan,
    Liveness,
    DocScan
]

export const id_scanner_netherlands = [
    IdScan_nth,
    Liveness_nth,
    DocScan_nth
]


export default id_scanner