import styled from "styled-components"

import check from "../assets/images/check.svg"

const GreenCircle = styled.span`
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #000000;
  box-sizing: border-box;
  position: relative;
  background: #b0f1d4;
  background: url(${check}) center no-repeat, #b0f1d4;

  &::after {
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 1px dashed #000000;
    box-sizing: border-box;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: -1;
  }

  @media screen and (max-width: 980px) {
    margin-right: 15px;
    margin-top: -5px;
  }
`

export default GreenCircle
