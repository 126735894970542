import styled from "styled-components"
import LayoutGrid from "../layout-grid"

const Grid = styled(LayoutGrid)`
  grid-template-columns: auto repeat(12, 68px) auto;
  grid-gap: 30px;
  padding-top: 120px;

  @media screen and (max-width: 980px) {
    grid-template-columns: auto repeat(8, 68px) auto;
    grid-gap: 20px;
    overflow: hidden;
    padding-top: 50px;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: auto 147px 147px auto;
    grid-gap: 45px 25px;
    overflow: hidden;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: auto 147px 147px auto;
    grid-gap: 21px;
    grid-row-gap: 40px;
    overflow: hidden;
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: auto 132px 132px auto;
    grid-gap: 0px;
    grid-row-gap: 40px;
    overflow: hidden;
  }

  grid-template-areas:
    ". . . header header header header header header header header . . ."
    "slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine"
    ". . features-container features-container features-container features-container features-container features-container features-container features-container features-container features-container . ."
    ". . . . . . . . . . . . . ."
    ". . . . . talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". rayanair rayanair rayanair rayanair rayanair rayanair rayanair rayanair rayanair rayanair rayanair rayanair ."
    ". . . . . . . . . . . . . ."
    ". every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". back-office back-office back-office back-office back-office back-office back-office back-office back-office back-office back-office back-office ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . increase-conversion increase-conversion increase-conversion increase-conversion increase-conversion increase-conversion increase-conversion increase-conversion . . ."
    ". increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table ."
    ". get-started get-started get-started get-started get-started get-started get-started get-started get-started get-started get-started get-started ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . partner-quotes-header partner-quotes-header partner-quotes-header partner-quotes-header partner-quotes-header partner-quotes-header partner-quotes-header partner-quotes-header . . ."
    ". partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . bring-results-text bring-results-text bring-results-text bring-results-text bring-results-text bring-results-text . . . ."
    ". bring-results bring-results bring-results bring-results bring-results bring-results bring-results bring-results bring-results bring-results bring-results bring-results ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". plug plug plug plug plug plug plug plug plug plug plug plug ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . .";

  @media screen and (max-width: 980px) {
    grid-template-areas:
      ". header header header header header header header header ."
      "slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine"
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . features-container features-container features-container features-container features-container features-container . ."
      ". . . . . . . . . ."
      ". . .  talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 . . ."
      ". . . . . . . . . ."
      ". fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy fast-easy ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". rayanair rayanair rayanair rayanair rayanair rayanair rayanair rayanair ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". every-solution every-solution every-solution every-solution every-solution every-solution every-solution every-solution ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". back-office back-office back-office back-office back-office back-office back-office back-office ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". increase-conversion increase-conversion increase-conversion increase-conversion increase-conversion increase-conversion increase-conversion increase-conversion ."
      ". increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table increase-conversion-table ."
      ". your-app your-app your-app your-app your-app your-app your-app your-app ."
      ". get-started get-started get-started get-started get-started get-started get-started get-started ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . partner-quotes-header partner-quotes-header partner-quotes-header partner-quotes-header partner-quotes-header partner-quotes-header . ."
      ". partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes partner-quotes ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . bring-results-text bring-results-text bring-results-text bring-results-text bring-results-text bring-results-text . ."
      ". bring-results bring-results bring-results bring-results bring-results bring-results bring-results bring-results ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". plug plug plug plug plug plug plug plug ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands leading-brands ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . .";
  }

  @media screen and (max-width: 640px) {
    grid-template-areas:
      ". header header ."
      "slot-machine slot-machine slot-machine slot-machine"
      ". . . ."
      ". . . ."
      ". features-container features-container ."
      ". . . ."
      ". talk-to-sales-1 talk-to-sales-1 ."
      ". . . ."
      ". fast-easy fast-easy ."
      ". . . ."
      ". . . ."
      ". rayanair rayanair ."
      ". . . ."
      ". . . ."
      ". every-solution every-solution ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". back-office back-office ."
      ". . . ."
      ". . . ."
      ". . . ."
      "increase-conversion increase-conversion increase-conversion increase-conversion "
      ". get-started get-started ."
      ". . . ."
      ". . . ."
      ". partner-quotes-header partner-quotes-header ."
      ". partner-quotes partner-quotes ."
      ". . . ."
      ". . . ."
      ". bring-results-text bring-results-text ."
      ". bring-results bring-results ."
      ". . . ."
      ". . . ."
      ". plug plug ."
      ". . . . "
      ". . . . "
      ".leading-brands leading-brands ."
      ". . . ."
      ". . . .";
  }
`

export default Grid
