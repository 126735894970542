import React from "react"
import styled, { css } from "styled-components"

import GreenCircle from "../../components/green-circle"


const FeaturesContainer = styled.div`
  width: 1064px;
  display: flex;
  justify-content: space-between;
  margin: 355px 0 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    width: 523px;
    margin: 480px 0 40px;
  }

  @media screen and (max-width: 640px) {
    width: 296px;
    margin: 0px 0 40px;
    ${props => props.marginMobile && css`
    margin: ${props.marginMobile};
  `}
  }
`

const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 330px;
  z-index: 1;

  p {
    font-size: 20px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    width: 280px;
  }
`

const ImageComtainer = styled.div`
  width: 40px;
  height: 40px;
`

export default function Checks({ features, marginMobile }) {

  return (
    <FeaturesContainer marginMobile={marginMobile}>
      {features.map((feat) => (
        <Feature>
          <ImageComtainer>
            <GreenCircle />
          </ImageComtainer>
          <p>{feat}</p>
        </Feature>
      ))}

    </FeaturesContainer>
  )
}