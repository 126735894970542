import React, { useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import SEO from "../components/seo"

import Grid from "../components/igaming/grid"
import SlotMachine from "../components/igaming/slot-machine2"
import MiddleText from "../components/igaming/middle-text"
import ActionButton from "../components/action-button"
import PartnerQuotes from "../components/igaming/partner-quotes"
import CaseStudies from "../components/igaming/case-studies"
import GetReady from "../components/igaming/get-ready"
import GreenCircle from "../components/green-circle"
import BringResults from "../components/igaming/bring-results"
import LeadingBrands from "../components/igaming/leading-brands"
import PartnersRowFooter from "../components/igaming/partner-rows2"


import GlobalContext from "../context/global-context"
import { pushCustomDataLayerEvent } from "../state/actions"
import loadable from '@loadable/component'
const Plug = loadable(() => import('../components/igaming/plug'))
const FastEasy = loadable(() => import('../components/igaming/fast-easy'))
const GetStarted = loadable(() => import('../components/igaming/get-started'))


const CustomActionButton = styled(ActionButton)`
  width: 263px;
  margin: 0 auto;
  z-index: 20;
`



const FeaturesContainer = styled.div`
grid-area: features-container;
  width: 1010px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    width: 523px;
  }

  @media screen and (max-width: 640px) {
    width: 296px;
  }
`


const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 330px;
  z-index: 1;

  p {
    font-size: 20px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    width: 280px;
  }
`
const ImageComtainer = styled.div`
  width: 40px;
  height: 40px;
`

export default function IGamingPage({ location }) {
  const dispatch = useDispatch()
  const globalContext = useContext(GlobalContext)
  const [isMobileOnly, setIsMobileOnly] = useState(false)
  const [kw, setKw] = useState(null)
  const version = 1

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 640 ? setIsMobileOnly(true) : setIsMobileOnly(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])



  const DynamicCopy = {
    'default': {
      "Headline": "Convert more players with the easiest signup",
      "Subheading": "Checkin.com makes iGaming brands grow faster with the best-in-class solution for signup, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Flexible integration through JS, SDK or API"
    },
    'casino-software': {
      "Headline": "Signup solution that plugs into your iGaming software",
      "Subheading": "Checkin.com makes Casino brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Connect to your iGaming software via JS, SDK or API"
    },
    'betting-software': {
      "Headline": "Signup solution that plugs into your Betting software",
      "Subheading": "Checkin.com makes betting brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Connect to your betting software via JS, SDK or API"
    },
    'gambling-software': {
      "Headline": "Signup solution that plugs into your Gambling software",
      "Subheading": "Checkin.com makes gambling brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Connect to your gambling software via JS, SDK or API"
    },
    'igaming-software': {
      "Headline": "Connect your iGaming software to convert more players",
      "Subheading": "Checkin.com makes iGaming brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Flexible integration through JS, SDK or API"
    },
    'igaming-crm ': {
      "Headline": "Plug any iGaming CRM to convert more players and ensure compliance",
      "Subheading": "Checkin.com makes iGaming brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Gain full control over user sign-up journey",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Flexible integration through JS, SDK or API"
    },
    'igaming-kyc': {
      "Headline": "Global solution for KYC & signups in iGaming",
      "Subheading": "Checkin.com helps iGaming brands grow faster with the best-in-class solution for KYC & ID Verification in 165 markets",
      "Value prop 1": "Get every solution for fast & cost-efficient KYC",
      "Value prop 2": "Give your customers seamless experience",
      "Value prop 3": "Increase conversion across all devices"
    },
    'igaming-cro': {
      "Headline": "Conversion rate optimization on auto-pilot for iGaming",
      "Subheading": "Checkin.com makes iGaming brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Flexible integration through JS, SDK or API"
    },
    'casino-kyc-solution': {
      "Headline": "Global solution for KYC & signups in iGaming",
      "Subheading": "Checkin.com helps online casino brands stay compliant with the best-in-class solution for KYC & ID Verification in 165 markets",
      "Value prop 1": "Get every solution for fast & cost-efficient KYC",
      "Value prop 2": "Give your customers a seamless experience",
      "Value prop 3": "Increase conversion across all devices"
    },

    'casino-cro': {
      "Headline": "Instantly boost conversion with a better Casino signup",
      "Subheading": "Checkin.com helps online casino brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Flexible integration through JS, SDK or API"
    },

    'software-exact': {
      "Headline": "Convert more players with the easiest sign-up",
      "Subheading": "Checkin.com makes iGaming brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Flexible integration through JS, SDK or API"
    },

    'software-playtech': {
      "Headline": "Convert more players with the easiest sign-up",
      "Subheading": "Checkin.com makes iGaming brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Flexible integration through JS, SDK or API"
    },

    'software-pragmatic': {
      "Headline": "Convert more players with the easiest sign-up",
      "Subheading": "Checkin.com makes iGaming brands grow faster with the best-in-class solution for signups, identity and login in 165 markets.",
      "Value prop 1": "Increased conversion (+18% avg. uplift)",
      "Value prop 2": "Better experience across all locations and devices",
      "Value prop 3": "Flexible integration through JS, SDK or API"
    },

    'igaming-signup': {
      "Headline": "Instantly boost conversion with a better iGaming signup",
      "Subheading": "We help iGaming brands handle sign-ups and onboarding. Checkin.com lets your customers get the best possible checkin experience irrespective of country or device.",
      "Value prop 1": "Grow faster with optimized signup flows",
      "Value prop 2": "Verify and onboard players globally",
      "Value prop 3": "Let existing customers log in with ease"
    },
    'casino-signup': {
      "Headline": "Instantly boost conversion with a better casino signup",
      "Subheading": "We help online casinos handle sign-ups and onboarding. Checkin.com lets your customers get the best possible checkin experience irrespective of country or device.",
      "Value prop 1": "Grow faster with optimized signup flows",
      "Value prop 2": "Verify and onboard your customers globally",
      "Value prop 3": "Let existing customers log in with ease"
    }
  }

  useEffect(() => {

    const kwParam = new URLSearchParams(location.search).get("kw");
    if (kwParam) {
      setKw(kwParam)
    } else {
      setKw('default')
    }
  }, [])

  const getCopy = (key) => {
    return DynamicCopy.hasOwnProperty(kw) ? DynamicCopy[kw][key] : DynamicCopy['default'][key]
  }

  return (
    <>
      <SEO
        title="Signup + KYC software for iGaming: Convert more & stay compliant"
        description="KYC software made for iGaming operators. High-convertinfg and compliant flows for any regulated market. Integrate to Playtech, Aspire, and more!"
        canonical="/industry-igaming/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />

      <Grid version={version}>
        <MiddleText
          h1
          primary
          gridArea="header"
          headerText={kw ? getCopy("Headline") : null}
          subheaderText={kw ? getCopy("Subheading") : null}
        />
        <SlotMachine />

        <FeaturesContainer>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>{kw ? getCopy("Value prop 1") : null}</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>{kw ? getCopy("Value prop 2") : null}</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>{kw ? getCopy("Value prop 3") : null}</p>
          </Feature>
        </FeaturesContainer>

        <CustomActionButton
          onClick={() => {
            dispatch(
              pushCustomDataLayerEvent({
                event: "get_started",
                eventCategory: "get_started",
                eventAction: "open",
                eventLabel: "top_cta",
              })
            )
            globalContext.openGetStarted()
          }}
          style={{ gridArea: "talk-to-sales-1" }}
        >
          Get started
        </CustomActionButton>

        <PartnersRowFooter gridArea="partners-row-one" />

        <MiddleText
          gridArea="increase-conversion"
          headerText={
            isMobileOnly
              ? "Get high conversion\n and ensure compliance\n in any market"
              : "Get high conversion and ensure\n compliance in any market"}
          subheaderText={
            isMobileOnly
              ? "With Checkin.com in your site/app your\n onboarding flow is optimized for the device\n and location for each user."
              : "With Checkin.com in your site/app your onboarding flow is\n optimized for the device and location for each user."}
        />

        <GetReady
          gridArea="get-ready"
        />

        <MiddleText
          gridArea="partner-quotes-header"
          headerText={
            isMobileOnly
              ? "Proven track record\n with fast-growing\n operators"
              : "Proven track record with\n fast-growing operators"}
          subheaderText={
            isMobileOnly
              ? "Many of the fastest growing iGaming products use Checkin.com to ensure their brands succeed in their target markets"
              : "Many of the fastest growing iGaming products use Checkin.com\n to ensure their brands succeed in their target markets"}
        />

        <PartnerQuotes />

        <MiddleText
          gridArea="bring-results-text"
          headerText={
            isMobileOnly
              ? "Technology that\n bring results"
              : "Technology that bring results"}
          subheaderText={"Better customer experience with less development and product work generates measurable value to operators"}
        />
        <BringResults gridArea="bring-results" />


        <GetStarted gridArea="get-started" />



        <Plug gridArea="plug" />



        <FastEasy gridArea="fast-easy" />


        <CaseStudies />
        <LeadingBrands gridArea="leading-brands" />
        <PartnersRowFooter gridArea="partners-row-footer" />
      </Grid >
    </>
  )
}
