import React from "react"
import styled from "styled-components"

import Background from "../../assets/images/ocr/idscan.svg"
import Phones from "../../assets/images/ocr/phones.inline.svg"



const AnimationContainer = styled.div`
background: url(${Background}) no-repeat center;
display: flex;
width: 100%;
height: 700px;
justify-content: center;
svg {
  width: 100%;
  height: 75% !important;
  bottom: -30px;
  position: absolute;
}

@media screen and (max-width: 980px) {
  svg {
    height: 63% !important;
    bottom: 90px;
  }
}

@media screen and (max-width: 640px) {
  background: none;

  height: 490px;
  svg {
    bottom: 60px;
  }
}



`

export default function OcrAnimation() {

  return (
    <AnimationContainer>
      <Phones />
    </AnimationContainer>
  )
}