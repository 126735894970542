import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Finances from '../../assets/images/finances.svg';
import Igaming from '../../assets/images/igaming.svg';
import Travel from '../../assets/images/travel1.png';
import Others from '../../assets/images/others.svg';

import Klarna from '../../assets/images/id-scanner/new/klarna.svg'
import Ryanair from '../../assets/images/id-scanner/new/ryanair.svg'
import Telus from '../../assets/images/id-scanner/new/telus.svg'
import Entain from '../../assets/images/id-scanner/new/entain.svg'
import Borica from '../../assets/images/id-scanner/new/borica.svg'
import Decta from '../../assets/images/id-scanner/new/decta.svg'
import Pacaso from '../../assets/images/id-scanner/new/pacaso.svg'
import Playtech from '../../assets/images/id-scanner/new/playtech.svg'

const Container = styled.div`
    grid-area: enterprise;
    background: #FFF;
    padding: 40px 0px 50px 0px;
    border: 1px solid rgba(13, 13, 13, 0.20);
    box-shadow: 4px 4px 0px 0px rgba(13, 13, 13, 0.10);
`;

const Header = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 800;
  margin-bottom: 16px;
  white-space: pre-line;
  text-align: center;
  margin-bottom: 56px;

  @media screen and (max-width: 980px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media screen and (max-width: 640px) {
    font-size: 28px;
    line-height: 34px;
    width: 300px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

`

const Row = styled.div`
    display: flex;
    flex-direction: row;

    &.row1 {
        padding: 24px 40px;
        justify-content: space-between;

        img {
            margin-bottom: 8px;
            width: 48px;
            height: 48px;
        }
    }

    &.row2 {
        flex-direction: column;
    }


    .logo-container {
        overflow: hidden;
        margin-bottom: 32px;
        img {
            width: 180px;
            height: 45px; 
            margin-right: 16px;
            margin-left: 16px;
        }
      }
      
      .logo-row {
        display: flex;
        flex-wrap: nowrap;
        width: calc(14 * 212px); /* adjust the width to your needs, assuming 20vw logo width */
        animation: move-logos 60s linear infinite;
      }

      .logo-row-reversed {
        display: flex;
        flex-wrap: nowrap;
        width: calc(16 * 212px); /* adjust the width to your needs, assuming 20vw logo width */
        animation: move-logos-reversed 60s linear infinite;
      }
      
      @keyframes move-logos {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-50%); /* move the container to the left by half its width */
        }
      }

      @keyframes move-logos-reversed {
        0% {
          transform: translateX(-50%);
        }
        100% {
          transform: translateX(0); /* move the container to the left by half its width */
        }
      }

    @media (max-width: 980px) {
        .logo-container img {
            width: 144px;
            height: 36px; 
        }
        .logo-row {
            width: calc(14 * 176px);
        }

        .logo-row-reversed {
            width: calc(16 * 176px);
          }
        &.row1 {
            padding: 24px;
        }
    }

    @media (max-width: 640px) {
        &.row1 {
            display: grid;
            grid-template-columns: auto auto;
            padding: 24px 16px;
        }

        .logo-row {
            width: calc(14 * 176px);
        }

        .logo-row-reversed {
            width: calc(16 * 176px);
          }
    }
`


const List = styled.ul`
    list-style-type: none;
    padding-left: 0;
`;

const ListItem = styled.li`
    margin-bottom: 8px;
    color: rgba(13, 13, 13, 0.80);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px; 
    @media screen and (max-width: 980px) {
        font-size: 12px;
        line-height: 20px;
    }
`;
const data = [
    {

        iconSrc: <img src={Travel} />,
        header: "Travel",
        items: ["Airlines", "Car rentals", "Hotels", "Cruise lines", "OTAs"]
    },
    {
        iconSrc: <img src={Finances} />,
        header: "Finance",
        items: ["Currency exchanges", "Crypto exchanges", "Virtual currencies", "Trading platforms", "Payments", "Neobanks"]
    },
    {
        iconSrc: <img src={Igaming} />,
        header: "iGaming",
        items: ["Lottery", "Fantasy", "Sports betting", "Casino"]
    },
    {
        iconSrc: <img src={Others} />,
        header: "Other",
        items: ["Education platforms", "Recruitment agencies", "Communication services", "Marketing agencies", "E-commerce", "Events"]
    }
];
const logosrow1 = [
    { id: 1, src: require('../../assets/images/id-scanner/new/wunderino.svg').default, alt: 'Logo 4' },
    { id: 2, src: require('../../assets/images/id-scanner/new/betcity.svg').default, alt: 'Logo 4' },
    { id: 3, src: require('../../assets/images/id-scanner/new/casumo.svg').default, alt: 'Logo 4' },
    { id: 4, src: require('../../assets/images/id-scanner/new/comeon.svg').default, alt: 'Logo 4' },
    { id: 5, src: require('../../assets/images/id-scanner/new/friday.svg').default, alt: 'Logo 4' },
    { id: 6, src: require('../../assets/images/id-scanner/new/holland.svg').default, alt: 'Logo 4' },
    { id: 7, src: require('../../assets/images/id-scanner/new/betnation.svg').default, alt: 'Logo 4' },

];

const logosrow2 = [
    { id: 10, src: Playtech, alt: 'Logo 8' },
    { id: 8, src: require('../../assets/images/id-scanner/new/highroller.svg').default, alt: 'Logo 4' },
    { id: 9, src: require('../../assets/images/id-scanner/new/lucky.svg').default, alt: 'Logo 4' },
    { id: 11, src: require('../../assets/images/id-scanner/new/superbet.svg').default, alt: 'Logo 4' },
    { id: 12, src: require('../../assets/images/id-scanner/new/hommer.svg').default, alt: 'Logo 4' },
    { id: 13, src: require('../../assets/images/id-scanner/new/slot.svg').default, alt: 'Logo 4' },
    { id: 14, src: require('../../assets/images/id-scanner/new/betathome.svg').default, alt: 'Logo 4' },
    { id: 15, src: require('../../assets/images/id-scanner/new/magic.svg').default, alt: 'Logo 4' },
];


const Enterprise = () => {

    return (
        <Container>
            <Header>Helping igaming brands grow faster</Header>

            <Row className='row2'>
                <div className="logo-container">
                    <div className="logo-row">
                        {logosrow1.map((logo) => (
                            <img
                                key={logo.id}
                                src={logo.src}
                                alt={logo.alt}
                            />
                        ))}
                        {logosrow1.map((logo) => (
                            <img
                                key={`${logo.id}- duplicate`}
                                src={logo.src}
                                alt={logo.alt}
                            />
                        ))}
                    </div>
                </div>
                <div className="logo-container">
                    <div className="logo-row-reversed">
                        {logosrow2.map((logo) => (
                            <img
                                key={logo.id}
                                src={logo.src}
                                alt={logo.alt}
                            />
                        ))}
                        {logosrow2.map((logo) => (
                            <img
                                key={`${logo.id}- duplicate`}
                                src={logo.src}
                                alt={logo.alt}
                            />
                        ))}
                    </div>
                </div>
            </Row>
        </Container>
    );
}

export default Enterprise;