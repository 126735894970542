import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import signatureData from "../../assets/signature.json"
import livenessData from "../../assets/liveness-travelv2.json"
import nfcData from "../../assets/nfc.json"
import visaData from "../../assets/visa-check.json"
import idData from "../../assets/id.json"
import botlensData from "../../assets/botlens.json"

import lottie from 'lottie-web/build/player/lottie_light.min.js'

const Holder = styled.div`
grid-area: ${props => props.gridArea};
color: #0D0D0D;
h3 {
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px; 
    margin-bottom: 75px;

    @media screen and (max-width: 640px) {
        margin-bottom: 35px;
        br {
            display: none;
        }
    }
} 

p.mobileParagraphe {
    font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 80px;

        @media screen and (max-width: 980px) {
            margin-bottom: 0px;
        }
        @media screen and (min-width: 981px) {
                display: none;
        }
        @media screen and (max-width: 980px) {
        margin-bottom: 80px;

        }

}
`
const Container = styled.div`
    display: flex;
    flex-direction:${props => props.reverse ? 'row-reverse' : 'row'};    
    
    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }

    @media screen and (max-width: 640px) {
        flex-direction: column-reverse;
    }

`
const Features = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px;
    grid-column-gap: 40px; 
    margin-top: 80px;

    h4{
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        margin-top: 10px;
        min-height: 60px;
    }

@media screen and (max-width: 980px) {
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    h4{
        min-height: auto;
    }
}

`
const Feature = styled.div`
p {
    font-size: 16px;
    font-weight: 400; 
    margin-top: 10px;
}
`
const Side = styled.div`
width: 66%;
${props => props.reverse ? 'padding-left: 40px;' : 'padding-right: 40px;'}



@media screen and (max-width: 980px) {
    p.desktopParagraphe{
        display: none;
    }
}
@media screen and (max-width: 640px) {
    width: auto;
    padding-right: 0px;
    padding-left: 0px;
    p.desktopParagraphe{
        display: none;
    }

}

`

const AnimationContainer = styled.div`
    width: 375px;
    @media screen and (max-width: 980px) {
        display: flex;
    }
    @media screen and (max-width: 640px) {
        width: auto;
    }
`
const Animation = styled.div`
@media screen and (max-width: 980px) {
    align-self: center;
}
svg {
    border-radius: 40px;
border: 1px solid rgba(0, 0, 0, 0.10); 
}
`
const Block = ({ reverse, gridArea, header, subHeader, features, type }) => {

    let animationContainer = useRef();
    let animation = null;
    const animationDataLookup = {
        id: idData,
        visa: visaData,
        signature: signatureData,
        liveness: livenessData,
        nfc: nfcData,
        botlens: botlensData,
    };

    useEffect(() => {

        animation = lottie.loadAnimation({
            container: animationContainer.current, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationDataLookup[type] // the path to the animation json

        });
        return () => {
            animation.destroy();
        }

    }, []);

    return (
        <Holder gridArea={gridArea}>
            <h3 dangerouslySetInnerHTML={{ __html: header }} />
            <p className="mobileParagraphe">{subHeader}</p>
            <Container reverse={reverse} >
                <Side reverse={reverse}>
                    <p className="desktopParagraphe">{subHeader}</p>
                    <Features>
                        {features.map((feature, index) => (
                            <Feature>
                                {feature.icon}
                                <h4>{feature.header}</h4>
                                <p>{feature.content}</p>
                            </Feature>
                        ))}
                    </Features>
                </Side>
                <AnimationContainer>
                    <Animation ref={animationContainer} />
                </AnimationContainer>
            </Container>
        </Holder>
    )
}


export default Block;