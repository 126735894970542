import React, { useState, useRef } from "react"
import styled, { keyframes, css } from "styled-components"
import loadable from '@loadable/component'


import Check from "../components/common/check"
import Cta from "../components/common/cta"
import SEO from "../components/seo"
import HeaderAnimation from "../components/start/header-animation"

import VideoBackground from '../assets/images/video_placeholder.png'
import VideoBackgroundM from '../assets/images/video_placeholder_m.png'
import Fintech from '../assets/images/fintech_background.svg'
import Travel from '../assets/images/travel_background.svg'
import Igaming from '../assets/images/igaming_background.svg'
import Ecom from '../assets/images/ecom.svg'
import Health from '../assets/images/health.svg'
import Edtech from '../assets/images/edtech.svg'
import Social from '../assets/images/social.svg'
import Subsc from '../assets/images/subsc.svg'
import Fragmented from "../assets/images/product/fragmented.svg"
import DevicesLocation from "../assets/images/product/devicesLocation.svg"
import AttentionSpan from "../assets/images/product/attentionSpan.svg"
import Bad from "../assets/images/product/bad.svg"

const EasyComponent = loadable(() => import("../components/index/easy"))
const ProvenComponent = loadable(() => import("../components/index/proven"))
const FlowsComponent = loadable(() => import("../components/index/flows"))
const Video = loadable(() => import("../components/video"))
const Modal = loadable(() => import("../components/modal"))
const Card = loadable(() => import("../components/index/card"))
const FastComponent = loadable(() => import("../components/index/fast"))
const Calculator = loadable(() => import("../components/product/calculator"))



const Grid = styled.div`
    display: grid;
    flex: 1;
    grid-template-columns: auto repeat(12, 64px) auto;
    grid-gap: 24px 32px;
    grid-auto-rows: min-content;
    align-items: start;
    padding-top: 70px;
    min-height: 100%;

    @media screen and (max-width: 980px) {
        padding-top: 50px;
        grid-template-columns: repeat(8, 60px);
        grid-gap: 37px 20px;

    }


    @media screen and (max-width: 640px) {
      padding-top: 50px;
      grid-template-columns: auto 170px 170px auto;
      grid-gap: 37px 10px;

    }
  
    @media screen and (max-width: 375px) {
      grid-template-columns: 10px 340px 10px;
      grid-gap: 37px 0px;

    }

    grid-template-areas:
    "header header header header header header header header header header header header header header"
    ". . checks checks checks checks checks checks checks checks checks checks . ."
    ". . cta1 cta1 cta1 cta1 cta1 cta1 cta1 cta1 cta1 cta1 . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing ."
    ". fintech fintech fintech fintech fintech fintech travel travel travel travel travel travel ."
    ". fintech fintech fintech fintech fintech fintech igaming igaming igaming igaming igaming igaming ."
    ". more more ecom ecom  social social  subsc subsc edtech edtech health health  ."
    ". . cta3 cta3 cta3 cta3 cta3 cta3 cta3 cta3 cta3 cta3 . ."

    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."

    ". video video video video video video video video video video video video ."
    ". bad bad bad bad bad bad bad bad bad bad bad bad ."
    ". ask ask ask ask ask ask ask ask ask ask ask ask ."
    ". optimiz optimiz optimiz optimiz optimiz optimiz optimiz optimiz optimiz optimiz optimiz optimiz ."
    ". fragment fragment fragment fragment fragment fragment fragment fragment fragment fragment fragment fragment ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". flows flows flows flows flows flows flows flows flows flows flows flows ."
    ". . cta2 cta2 cta2 cta2 cta2 cta2 cta2 cta2 cta2 cta2 . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."

    ". proven proven proven proven proven proven proven proven proven proven proven proven ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."

    ". easy easy easy easy easy easy easy easy easy easy easy easy ."

    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    
    ;

    @media screen and (max-width: 980px) {

      grid-template-areas:
    "header header header header header header header header header header "
    ". . checks checks checks checks checks checks . ."
    ". . cta1 cta1 cta1 cta1 cta1 cta1 .  ."
    ". . . . . . . . . ."
    ". fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing fastGrowing ."
    ". fintech fintech fintech fintech travel travel travel travel ."
    ". fintech fintech fintech fintech igaming igaming igaming igaming ."
    ".  . more more  social social  subsc subsc . ."
    ". . health health edtech edtech ecom ecom  . ."
    ". . cta3 cta3 cta3 cta3 cta3 cta3 . ."

    ". . . . . . . . . ."

    ". video video video video video video video video ."
    ". . bad bad bad bad bad bad . . "
    ". . ask ask ask ask ask ask . ."
    ". . optimiz optimiz optimiz optimiz optimiz optimiz . ."
    ". . fragment fragment fragment fragment fragment fragment . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". flows flows flows flows flows flows flows . ."
    ". . cta2 cta2 cta2 cta2 cta2 cta2 . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."

    ". proven proven proven proven proven proven proven proven ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."

    ". . easy easy easy easy easy easy . ."

    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ;
    }

    @media screen and (max-width: 640px) {

      grid-template-areas:
      "header header header header"
      ". checks checks  ."
      ".  cta1 cta1 ."
      ". fastGrowing fastGrowing ."
      ". fintech fintech ."
      ". travel travel ."
      ". igaming igaming ."
      ". more more ."
      ". ecom social ."
      ". subsc edtech ."
      ". health .  ."
      ". cta3 cta3  ."
  
      ". . . ."
      ". . . ."
  
      ". video video ."
      ". bad bad . "
      ". . . ."
      ". ask ask ."
      ". . . ."
      ". optimiz optimiz ."
      ". . . ."
      ". fragment fragment ."
      ". . . ."
      ". . . ."
      ". flows flows ."
      ". cta2 cta2 ."
      ". . . ."
      ". . . ."
  
      ". proven proven ."
  
      ". easy easy ."
  
      ". . . ."
      ". . . ."
      
      ;
    }

    @media screen and (max-width: 375px) {

      grid-template-areas:
      ". header ."
      ". checks  ."
      ".  cta1 ."
      ". fastGrowing ."
      ". fintech ."
      ". travel ."
      ". igaming ."
      ". more ."
      ". ecom ."
      ". subsc ."
      ". health  ."
      ". cta3  ."
  
      ". . ."
      ". . ."
  
      ". video ."
      ". bad . "
      ". . ."
      ". ask ."
      ". . ."
      ". optimiz ."
      ". . ."
      ". fragment ."
      ". . ."
      ". . ."
      ". flows ."
      ". cta2 ."
      ". . ."
      ". . ."
  
      ". proven ."
  
      ". easy ."
  
        ". contacts ."
      ". . ."
      ". . ."
      
      ;
    }

    .perfect {
      grid-area: perfect;
      margin-bottom: 8px;
    }
`
const H1 = styled.h1`
  font-weight: 800;
  font-size: 48px;
  line-height: 50px;
  text-align: center;

  @media screen and (max-width: 640px) {
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
  }

`
const H2 = styled.h2`
    font-weight: 800;
    font-size: 34px;
    line-height: 36px;
    text-align: center;
`
const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`

const HeaderMiddleText = styled.div`
    width:  900px;
    margin: 0 auto;
    padding: 0 180px;
    position: relative;
    top: 80px;

    p{
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    }

  @media screen and (max-width: 980px) {
    width: auto;
    top: 0px;
  }

  @media screen and (max-width: 640px) {
    width: auto;
    padding: 0 10px;
    top: 0px;

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }
  }
`

const MiddleText = styled.div`
    margin: 0 auto;
    padding: 0 180px;

    p{
      width: 700px;
      margin-top: 16px;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
  @media screen and (max-width: 980px) {
    padding: 0px;
   p {
    width: auto;
   }
  }
  @media screen and (max-width: 640px) {
    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  ${H2} {
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
  }
}
`
const Header = styled.div`
    grid-area: header;
    
    p {
      margin-top: 20px;
      text-align: center;
    }
    img {
      width: 100%;
      position: relative;
      bottom: 20px;
    }

    
    @media screen and (max-width: 980px) {
      img {
      width: 130%;
      position: relative;
      right: 80px;
      bottom: 0px;
      }
    }
    @media screen and (max-width: 640px) {
      height: 450px;
    img {
      width: 238%;
      position: relative;
      right: 276px;
      bottom: 80px;
    }
  }
`

const Checks = styled.div`
    grid-area: checks;
    display: flex;
    justify-content: space-between;

  @media screen and (max-width: 980px) {

    flex-direction: column;
  }
  @media screen and (max-width: 640px) {
  align-items: center;
  }

`
const CtaContainer = styled.div`
    grid-area: ${props => props.gridArea};
    margin: 8px auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  @media screen and (max-width: 640px) {
    margin: 20px 0 0 0;
    ${props => props.noMargin && `
      margin-top: 0px;
    `};

  }
}

`

const VideoContainer = styled.div`
grid-area: video;
margin-bottom: 20px;
${H2}{
  margin-top: 20px;
}
`

const VideoPlaceholder = styled.div`
  width: 100%;
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url(${VideoBackground}) no-repeat center/99%;
  margin-top: 31px;

  @media screen and (max-width: 980px) {
    width: 100%;
    height: 430px;
  background: url(${VideoBackground}) no-repeat center;

  }
  @media screen and (max-width: 640px) {
    background: url(${VideoBackgroundM}) no-repeat center/94%;
    width: 100%;
    height: 480px;
  }
`
const PlayButton = styled.button`
  background: #fff;
  outline: none;
  box-shadow: none;
  width: 70px;
  height: 70px;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    height: 0;
    width: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 20px solid #000;
    position: absolute;
    margin-left: 6px;
  }
`

const IllustratedTextContainer = styled.div`
  grid-area: ${props => props.gridArea};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 80px 0 0;

  &::last-child {
    margin-bottom: 170px;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin: 50px 0 0;
  }

  ${props =>
    props.medium &&
    css`
        width: 80%;
      `}

      @media screen and (max-width: 980px) {
        ${props =>
    props.reverse &&
    css`
            flex-direction: column-reverse;
            `}
        }
`

const FluidContainer = styled.object`
  width: 450px;
  height: auto;

  @media screen and (max-width: 980px) {
    width: 370px;
  }

`

const IllustratedText = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;

  ${H4} {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 980px) {
    text-align: center;
    width: 506px;
    align-items: center;

    ${H4} {
      margin-bottom: 14px;
      margin-top: 32px;
    }
  }

  @media screen and (max-width: 640px) {
    width: 334px;

    ${H4} {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
    }
    ${Text} {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const CalculatorText = styled(H4)`
  text-align: center;

  @media screen and (max-width: 980px) {
    width: 360px;
  }

  @media screen and (max-width: 640px) {
    width: 270px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
`

const CalculatorContainer = styled.div`
  width: 100%;
  max-height: 0;
  max-height: ${props => (props.isExpanded ? "2000px" : "0")};
  overflow-y: scroll;
  transition: max-height 0.2s ease;

  @media screen and (max-width: 980px) {
    width: 720px;
  }

  @media screen and (max-width: 640px) {
    width: 360px;
  }
`

const CalculatorButton = styled.button`
color : #000;
display: flex;
flex-direction: row;
justify-content: center;
cursor: pointer;
align-items: center;
gap: 8px;
width: 171px;
height: 52px;
background: #FFFFFF;
border: 1px solid #000000;
filter: drop-shadow(4px 4px 0px rgba(13, 13, 13, 0.1));
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
font-weight: 700;
font-size: 16px;
line-height: 20px;
margin-top: 16px;
`

const More = styled.div`
grid-area: more;
font-weight: 800;
font-size: 24px;
line-height: 30px;
flex-direction: column;
justify-content: center;
display: flex;
height: 100%;

@media screen and (max-width: 980px) {

  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

@media screen and (max-width: 640px) {
  margin-top: 30px;
  text-align: center;
}
`
const HeaderAnimationContainer = styled.div`
width: 100%;
min-height: 400px;
@media screen and (max-width: 640px) {
  min-height: 300px;
}
`



const StartPage = () => {

  const [showVideo, setShowVideo] = useState(false)
  const ref = useRef()


  return (
    <>
      <SEO
        title="Grow faster with the easiest onboarding and KYC in any market"
        description="Grow faster with easy, secure, and high-performing ID verification flows that are guaranteed to increase conversion rates."
        canonical="/user-onboarding/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}

      />
      <Grid>
        <Header >
          <HeaderMiddleText>
            <H1 >Grow faster with
              the easiest onboarding</H1>
            <p>We replace your old solutions for signup, identity, and login with high-converting and fully compliant flows</p>
          </HeaderMiddleText>

          <HeaderAnimationContainer>
            <HeaderAnimation />
          </HeaderAnimationContainer>

        </Header>
        <Checks>
          <Check
            feature={{
              title: "Signup",
              text: "Grow faster with optimized signup flows"
            }} />
          <Check
            feature={{
              title: "Identity",
              text: "Securely identify the user behind the screen"
            }} />
          <Check
            noMargin={true}
            feature={{
              title: "Login",
              text: "Let existing customers log in with ease "
            }} />
        </Checks>

        <CtaContainer noMargin={true} gridArea="cta1">
          <Cta
            title="Conversion increase guaranteed" />
        </CtaContainer>

        <FastComponent />

        <Card
          href="/industry-fintech"
          className="fintech"
          background={Fintech}
          header='Fintech & Crypto'
          text='Avoid fines, secure your data and make complex applications easy to complete'
          gridArea='fintech' />
        <Card
          href="/industry-travel"
          className="travel"
          background={Travel}
          header="Travel"
          text="Replace outdated processes and increase security with automated customer onboarding"
          gridArea='travel' />
        <Card
          href="/industry-igaming"
          className="igaming"
          background={Igaming}
          header="iGaming"
          text="Comply with local gambling regulation and increase conversion with features tailored to iGaming brands"
          gridArea='igaming' />

        <More>
          And more...
        </More>
        <Card
          href="/#"
          className="small"
          background={Ecom}
          header="E-commerce"
          gridArea='ecom' />
        <Card
          href="/#"
          className="small"
          background={Social}
          header="Social media"
          gridArea='social' />
        <Card
          href="/#"
          className="small subsc"
          background={Subsc}
          header="Subscriptions"
          gridArea='subsc' />
        <Card
          href="/#"
          className="small edtech"
          background={Edtech}
          header="Edtech"
          gridArea='edtech' />
        <Card
          href="/#"
          className="small health"
          background={Health}
          header="Healthcare"
          gridArea='health' />

        <CtaContainer gridArea="cta3">
          <Cta
            title="Describe your use-case and we'll show you the perfect setup"
            ctaText="Schedule call" />
        </CtaContainer>

        <VideoContainer>
          <MiddleText>
            <H2>Why brands are switching to Checkin.com</H2>
            <p>Brands that deliver <a href="/blog/kyc-software-comparison/">optimal customer onboarding</a> for every user have a crucial advantage over those who rely on outdated solutions.</p>
          </MiddleText>
          <VideoPlaceholder >
            <PlayButton aria-label="play" onClick={() => { setShowVideo(true) }} />
          </VideoPlaceholder>
        </VideoContainer>

        <IllustratedTextContainer gridArea='bad'>
          <FluidContainer width="370" height="270" type="image/svg+xml" alt='fluidimage' data={Bad} />

          <IllustratedText>
            <H4>Bad onboarding means lost revenue</H4>
            <Text>
              Signup, identity and login are often overlooked as part of the onboarding process. This results in slower growth and lost revenue, which compe titors are happy to scoop up.
              <br />
              <br />
              Checkin.com generates the best possible experience for your users to ensure high conversion and a <a href="/blog/kyc-signup-flow-guide">truly localized experience</a> in every market you target.
            </Text>
            <CalculatorButton onClick={() => ref.current.open()}> Calculate ROI</CalculatorButton>

          </IllustratedText>
        </IllustratedTextContainer>

        <IllustratedTextContainer gridArea='ask' reverse={true}  >

          <IllustratedText>
            <H4>Asking too many questions is expensive</H4>
            <Text>
              Due to regulation, a range of information needs to be collected and verified before a visitor can become a customer. When the number of steps increase, so does the risk of the visitor failing to complete the process.
              <br />
              <br />
              Checkin.com uses data-driven UX modules to ensure your flows grab users' attention, and retain it until completion.
            </Text>
          </IllustratedText>

          <FluidContainer width="370" height="270" alt='fluidimage' type="image/svg+xml" data={AttentionSpan} />

        </IllustratedTextContainer>

        <IllustratedTextContainer gridArea='optimiz'>
          <FluidContainer width="370" height="270" alt='fluidimage' type="image/svg+xml" data={DevicesLocation} />

          <IllustratedText>
            <H4>Optimizing for everyone is difficult</H4>
            <Text>
              For optimal conversion, onboarding needs to be adapted to end-users and their preferences on a multitude of devices and languages. Testing, supplying and serving these versions without sufficient data and the right technology is simply not possible.
              <br />
              <br />
              Checkin.com will optimize and localize your flows on a granular level using machine learning and millions of data points to ensure that the experience for each user is as easy as possible.
            </Text>
          </IllustratedText>
        </IllustratedTextContainer>

        <IllustratedTextContainer gridArea='fragment' reverse={true}>
          <IllustratedText>
            <H4>Fragmented solutions add complexity</H4>
            <Text>
              There is a jungle of third party solutions, ranging from browser prefills to data lookups and open banking, which can help solve part of the onboarding puzzle in parts of the world. Integrating and maintaining these solutions are <a href="/blog/kyc-outsourcing">time-consuming and expensive</a>.
              <br />
              <br />
              Checkin.com combines the best third party integrations and identity solutions to create a seamless experience, out of the box and across the globe.
            </Text>
          </IllustratedText>
          <FluidContainer width="370" height="270" alt='fluidimage' type="image/svg+xml" data={Fragmented} />
        </IllustratedTextContainer>


        <FlowsComponent />

        <CtaContainer gridArea="cta2">
          <Cta
            title="Want to see the full list of solutions?" />
        </CtaContainer>

        <ProvenComponent />

        <EasyComponent />

      </Grid >

      <Modal ref={ref} autoWith={true}>
        <CalculatorText>
          Calculate the value of optimizing your checkin process
        </CalculatorText>

        <CalculatorContainer id="calculator" isExpanded={true}>
          <Calculator />
        </CalculatorContainer>
      </Modal>
      {
        showVideo && (
          <Video id="tb_aRFj4I7M" type="youtube" onClose={() => setShowVideo(false)} />
        )
      }
    </>
  )
}

export default StartPage