import React, { useState, useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import { useInView } from 'react-intersection-observer';


import Klarna from '../../assets/images/klarna.svg'
import Ryanair from '../../assets/images/rayanair.svg'
import Telus from '../../assets/images/telus.svg'
import Entain from '../../assets/images/entain.svg'


const Container = styled.div`
    grid-area: partners;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ryanair { width: 238px;}
    .klarna { width: 148px;}
    .entain { width: 129px;}
    .telus { width: 214px;}

    @media screen and (max-width: 980px) {
        .ryanair { width: 142px;}
        .klarna { width: 80px;}
        .entain { width: 70px;}
        .telus { width: 142px;}
    }

`

const PartnersRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 640px) {
            justify-content: center;
    }`
const Header = styled.h1`
    color: rgba(117, 117, 117, 0.70);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; 
    letter-spacing: 3.78px;
    text-transform: uppercase;
    margin-bottom:16px;

    @media screen and (max-width: 980px) {
        font-size: 16px;
        margin-bottom: 20px;


    }
`
const Progress = styled.div`
    height: 2px;
    width: 0;
    background-color: #FACD38;
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen and (max-width: 640px) {
    bottom: -16px;

    }
`

const Partner = styled.div`
width: 260px;
@media screen and (max-width: 980px) {
    width: 140px;
}

@media screen and (max-width: 640px) {
    width: 250px;
}
`
const Logo = styled.div`
width: 260px;
    height: 75px;
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
    img {
        max-width: 100%;
    }
    @media screen and (max-width: 980px) {
    width: 140px;
    height: 48px;
    }
    @media screen and (max-width: 640px) {
        width: auto;
        .ryanair, .klarna, .entain, .telus { width: auto;}
    }
`
const Description = styled.div`
    position: relative;

    p {
            text-align: center; 
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            overflow: hidden;
            padding: 0px 5px;
            margin-top: 16px;
            opacity: 0;
            transition: max-height 0.3s ease-out, opacity 1s ease-in-out;
        
            &.collapsed {
                max-height: 0;
                opacity: 0;
            }
            
            &.expanded {
                max-height: 200px; /* Adjust based on your content */
                opacity: 1;
            }
    }

    @media screen and (max-width: 980px) {
        p {
            font-size: 12px;
            line-height: 15px;
            padding: 0px;
        }
    
    }

    @media screen and (max-width: 640px) {
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 32px;
            margin-bottom: 20px;
        }
    }
`


const partners = [
    {
        id: 'partner1',
        logo: <img className="klarna" src={Klarna} />,
        description: 'Scalable, UX-focused verification with global reach.',
        next: 1
    },
    {
        id: 'partner2',
        logo: <img className="ryanair" src={Ryanair} />,
        description: 'Easy passenger identification from booking to check-in.',
        next: 2
    },
    {
        id: 'partner3',
        logo: <img className="entain" src={Entain} />,
        description: 'Fast + compliant onboarding for regulated environments.',
        next: 3
    },
    {
        id: 'pertner4',
        logo: <img className="telus" src={Telus} />,
        description: 'Global identity verification for secure platform access.',
        next: 0
    }

]

const Partners = () => {
    const [currentPartner, setCurrentPartner] = useState({ ...partners[0] });
    const [nextPartner, setNextPartner] = useState(null);
    const [progress, setProgress] = useState(50);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
    const intervalRef = useRef(null);
    const [progressRef, containerInView] = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setProgress((prev) => prev + 5);
        }, 500);

        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {

        if (containerInView) {
            intervalRef.current = setInterval(() => {
                setProgress((prev) => prev + 5);
            }, 500);
        } else {
            clearInterval(intervalRef.current);
        }

        return () => clearInterval(intervalRef.current);
    }, [containerInView]);

    useEffect(() => {
        if (progress >= 100) {
            setProgress(0);
            setCurrentPartner((prev) => partners[prev.next]);
        }
    }, [progress]);

    const handleClick = (partner) => {
        setCurrentPartner(partner);
        setProgress(0);
    };

    return (
        <Container>

            <Header>made for enterprises </Header>
            <PartnersRow ref={progressRef}>
                {isMobile ? (
                    <Partner >
                        <Logo>
                            {currentPartner.logo}
                            <Progress
                                className="progress-bar"
                                style={{
                                    width: `${progress}%`,
                                    transition: progress !== 0 ? 'width 1s linear' : 'none'
                                }}
                            />
                        </Logo>

                        <Description>

                            <p className='expanded' >{currentPartner.description}</p>
                        </Description>
                    </Partner>
                ) :

                    partners.map((partner, index) => {
                        let isSelected = partner.id == currentPartner.id
                        return (
                            <Partner key={index} onClick={() => handleClick({ ...partner })}>
                                <Logo>
                                    {partner.logo}
                                    <Progress
                                        className="progress-bar"
                                        style={{
                                            width: `${isSelected ? progress : 0}%`,
                                            transition: isSelected ? 'width 1s linear' : 'width 0.2s ease-out'
                                        }}
                                    />
                                </Logo>

                                <Description>

                                    <p className={` ${isSelected ? 'expanded' : 'collapsed'}`} >{partner.description}</p>
                                </Description>
                            </Partner>
                        )
                    })

                }



            </PartnersRow>

        </Container >
    );
};



export default Partners;
