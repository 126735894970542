export default function isAnimationInView(ref) {
    const animationBounds = ref.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    return (
        animationBounds.top >= 0 &&
        animationBounds.left >= 0 &&
        animationBounds.right <= viewportWidth &&
        animationBounds.bottom <= viewportHeight
    );
}