import React, { useRef, useEffect, useState, useContext } from "react"
import styled from "styled-components"
import Modal from "../../components/id-scanner/modal.js"
import ModalsContent from '../../components/id-scanner/modal-content.js'
import ActionButton from "../../components/action-button.js"
import GlobalContext from "../../context/global-context.js"
import check from '../../assets/images/checkCircle.svg'
import SEO from "../../components/seo.js"


const CustomModal = styled(Modal)`
    h3 {
        text-align: center;
        font-size: 40px;
        font-weight: 800;
        line-height: 40px;
    }

    h4{
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }

    span {
        width: 72px;
        height: 1px; 
        background: #000; 
        margin: 16px 0;
    }

    .subheading {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    .icons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        margin: 64px 0;
        img { width: 229px; }

    }

    .features {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 48px;

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            margin-top: 16px;
        }

        @media screen and (max-width: 640px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .benefits {
        width: 100%;
        padding: 56px 96px;
        background: #F9F9F8;
        border: 1px solid #000; 
        background: #FFF9F2; 
        margin: 64px 0;
        row-gap: 48px;
        display: flex;
        flex-direction: column;

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 28px; 
            margin-top: 16px;
            margin-left: 45px;

        }
        img {   
            width: 22px;
            height: 22px;
            margin-right: 16px;
            margin: block;
           }

        @media screen and (max-width: 640px) {
            flex-direction: column;
            padding: 16px 24px;
        }
    }

    @media screen and (max-width: 640px) {
        h3{
            font-size: 28px;
            font-weight: 800;
            line-height: 40px;
        }

        h4{
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
        }
        .subheading {
            text-align: center;
        }
        .icons {
            gap: 0px;
            margin-top: 24px;
            margin-bottom: 40px;
            img { width: 114px; }
        }

        .features {
            color: #0D0D0D;
            gap: 16px;
            p {
                margin-top: 4px;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .benefits {
            padding: 32px 24px;
            row-gap: 32px;
            margin-top: 56px;
            margin-bottom: 32px;
            img {
                display: none;
            }
             
            p {
            margin-top: 4px;
            margin-left: 0px;
            }
        }
    }

`
const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin: 30px auto;

  }

  @media screen and (max-width: 640px) {
    margin-top: 22px;
  }
`

export default function IdScanner({ location }) {
    const modalRef = useRef()
    const [modalIndex, setModaleIndex] = useState(0)
    const globalContext = useContext(GlobalContext)



    return (
        <>
            <SEO
                title={ModalsContent[modalIndex]?.metaTitle}
                description={ModalsContent[modalIndex]?.metaDescription}
                canonical="/product/document-scanning"
                ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
            />
            <CustomModal width='80%' autoHeight ref={modalRef}>
                <h3>{ModalsContent[modalIndex]?.heading}</h3>
                <span></span>
                <div className="subheading">{ModalsContent[modalIndex]?.subheading}</div>
                <div className="icons">
                    {ModalsContent[modalIndex]?.icons.map((icon, index) => (
                        <img key={index} src={icon} alt={`Feature ${index + 1}`} />
                    ))}
                </div>
                <div className="features">
                    {ModalsContent[modalIndex]?.features.map((feature, index) => (
                        <div key={index}>
                            <h4>{feature.title}</h4>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
                <div className="benefits">
                    {ModalsContent[modalIndex]?.benefits.map((benefit, index) => (
                        <div key={index}>
                            <h4><img src={check} alt="check" /> {benefit.title}</h4>
                            <p>{benefit.description}</p>
                        </div>
                    ))}
                </div>

                <h4>
                    Get a custom demo and pricings
                </h4>
                <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
                    Contact sales
                </TalkToSalesButton>

            </CustomModal>
        </>
    )
}