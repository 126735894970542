import React, { useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import SEO from "../../components/seo"

import Grid from "../../components/fintech/grid"
import MiddleText from "../../components/fintech/middle-text"
import ActionButton from "../../components/action-button"
import PartnerQuotes from "../../components/fintech/partner-quotes"
import GreenCircle from "../../components/green-circle"
import BringResults from "../../components/fintech/bring-results"
import LeadingBrands from "../../components/fintech/leading-brands"
import Plug from "../../components/fintech/plug"
import EverySolution from "../../components/fintech/every-solution"
import Testimonials from "../../components/fintech/testimonials"


import GlobalContext from "../../context/global-context"
import { pushCustomDataLayerEvent } from "../../state/actions"
import Loading from "../../components/loading"
import loadable from '@loadable/component'
const BackOffice = loadable(() => import("../../components/fintech/back-office"))
const FastEasy = loadable(() => import("../../components/fintech/fast-easy"))
const GetStarted = loadable(() => import("../../components/fintech/get-started"))
const SlotMachine = loadable(() => import("../../components/fintech/slot-machine"))

const CustomActionButton = styled(ActionButton)`
  width: 263px;
  margin: 0 auto;
  z-index: 20;
`



const FeaturesContainer = styled.div`
grid-area: features-container;
width: 1010px;
display: flex;
justify-content: space-between;
margin-top: 430px;


@media screen and (max-width: 980px) {
  flex-direction: column;
  width: 523px;
  margin-top: 440px;
  align-items: center;
}

@media screen and (max-width: 640px) {
  width: 100%;
  margin-top: 170px;

}
`

const H4 = styled.h4`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
    margin-bottom: -15px;
  }
`

const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 330px;
  z-index: 1;

  p {
    font-size: 20px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: auto;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    width: 280px;
  }
`
const ImageComtainer = styled.div`
  width: 40px;
  height: 40px;
`

export default function IGamingPage({ location }) {
  const dispatch = useDispatch()
  const globalContext = useContext(GlobalContext)
  const [isMobileOnly, setIsMobileOnly] = useState(false)
  const version = 1

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 640 ? setIsMobileOnly(true) : setIsMobileOnly(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <SEO
        title="Complete KYC Onboarding Software for Finance & Crypto"
        description="Securely onboard users for financial products with the easiest possible KYC signup flow. Flexible intgration via JS, SDK or API."
        canonical="/industry-fintech/"
        ogImage={require("/src/assets/images/og/og-fintech-lp-min.png").default}
      />

      <Grid version={version}>
        <MiddleText
          h1
          primary
          gridArea="header"
          headerText="Identify users for financial<br/> transactions globally"
          subheaderText="Get every feature you need to successfully handle onboarding<br/> and KYC compliance across 165 countries in a single solution."
        />

        <SlotMachine />

        <FeaturesContainer>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Grow faster with<br />
              AI-driven localization</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Stay compliant with<br />
              regulatory requirements</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Heavily encrypted & EU-<br />restricted data processing</p>
          </Feature>
        </FeaturesContainer>
        <CustomActionButton
          onClick={() => {
            dispatch(
              pushCustomDataLayerEvent({
                event: "get_started",
                eventCategory: "get_started",
                eventAction: "open",
                eventLabel: "top_cta",
              })
            )
            globalContext.openGetStarted()
          }}
          style={{ gridArea: "talk-to-sales-1" }}
        >
          Get started
        </CustomActionButton>

        <FastEasy gridArea="fast-easy" />

        <Testimonials gridArea="rayanair" />
        <EverySolution gridArea="every-solution" />

        <BackOffice gridArea="back-office" />

        <MiddleText
          gridArea="increase-conversion"
          headerText={
            isMobileOnly
              ? "Try the full experience\n and see the difference"
              : "Try the full experience\n and see the difference"}
          subheaderText={
            isMobileOnly
              ? "Whether you need a robust solution for scanning customer ID\n Documents or looking to add extra security steps and data\n lookups, we got you covered."
              : "See exactly how Checkin.com will look and feel with your own\n branding and data collection in an interactive prototype."}
        />

        <GetStarted gridArea="get-started" />

        <MiddleText
          gridArea="partner-quotes-header"
          headerText={
            isMobileOnly
              ? "Global solution with\n instant benefits"
              : "Global solution with\n instant benefits"}
          subheaderText={
            isMobileOnly
              ? "Improve every step of your customer onboarding, keep conversion rates high, and eliminate fraud with a single integration."
              : "Increasing regulation is creating barriers for onboarding new \n customers. Checkin.com solves this by using AI to generate\n engaging and compliant onboarding flows for customers globally."}
        />
        <PartnerQuotes />
        <MiddleText
          gridArea="bring-results-text"
          headerText={
            isMobileOnly
              ? "AI-driven optimization\n with full control"
              : "AI-driven optimization\n with full control"}
          subheaderText={"Leverage AI to improve your customer experience and boost conversion while staying in control over branding and data."}
        />
        <BringResults gridArea="bring-results" />
        <Plug gridArea="plug" />
        <LeadingBrands gridArea="leading-brands" />
      </Grid >
    </>
  )
}
