import React from "react";
import styled from "styled-components";

import SeamlessImg from "../../assets/images/brazil/seamless.svg";
import SeamlessMobileImg from "../../assets/images/brazil/seamless-mobile.svg";

const Container = styled.div`
  grid-area: seamless;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 100px;

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px; 
  }
  img.mobile {
    display: none;
  }
  img.desktop {
    display: block;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 50px;
    padding: 0 40px;
    text-align: center;

    img {
      width: auto;
    }
    img.desktop {
      display: none;
    }
    img.mobile {
      display: block;
    }
  }

  @media screen and (max-width: 640px) {

    padding: 0px;

  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 980px) {
    width: 100%;
    margin-bottom: 40px;
    br {
      display: none;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 0;
    width: 100%;
  }
`;
const Header = styled.h2`
  margin-bottom: 42px;

  @media screen and (max-width: 640px) {
    font-size: 28px;
    margin-bottom: 40px;
    text-align: center;
  }
`


export default function Seamless() {

  return (
    <Container>
      <Content>
        <Header>Seamless Migration Flow for Existing Users</Header>
        <p>We offer a smooth migration process that requires only a liveness check for existing users.
          <br />
          <br />
          We integrate the liveness verification directly into each user's existing profile, which already holds their verified documents and personal details.</p>
      </Content>
      <img className='desktop' src={SeamlessImg} />
      <img className="mobile" src={SeamlessMobileImg} />

    </Container>
  )
}