import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react"
import styled, { css } from "styled-components"
import Moustrap from "mousetrap"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #F9F9F8;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
  z-index: 9999;
  overflow: auto;
  height: 100vh;

  ${props =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

`

const ModalBox = styled.div`
  display: flex;
  width: ${props => (props.autoWith ? "auto" : props.oneColumn ? "430px" : "950px")};
  height: ${props => (props.vhHeight ? props.vhHeight : props.autoHeight ? "auto" : "880px")};
  background: #F5F8FA;
  border: 1px solid #000000;
  position: absolute;
  top: 10px;
  box-sizing: border-box;

  @media screen and (max-width: 980px) {
    width: auto;

  }
  @media screen and (max-width: 640px) {
    width: 98%;
    height: 86%;
    margin-top: 0%;
    padding: 10px;
    border: none;
    background: none;

  }
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: transparent;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 15px;
  box-shadow: none;
  border: none;
  top: 19px;


  &::after,
  &::before {
    content: "";
    height: 1.5px;
    width: calc(100% + 8px);
    background: #000;
    position: absolute;
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
  @media screen and (max-width: 640px) {
    top: -2px;

  }

`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-top: 10px;

  @media screen and (max-width: 640px) {
    padding-top: 0px;
  }
`

const Modal = forwardRef(
  ({ children, onDismiss, className, oneColumn, autoHeight, autoWith, vhHeight }, ref) => {
    const [active, setActive] = useState()
    const modalContainerRef = useRef()

    const open = () => setActive(true)
    const close = () => setActive(false)

    useImperativeHandle(ref, () => ({
      el: ref.current,
      open,
      close,
    }))

    useEffect(() => {
      const dismiss = () => {
        onDismiss && onDismiss()
        close()
      }

      const onWindowClick = e => {
        if (e.target === modalContainerRef.current) {
          onDismiss && onDismiss()
          close()
        }
      }

      Moustrap.bind(["esc"], dismiss)
      window.addEventListener("click", onWindowClick)

      return () => {
        Moustrap.unbind(["esc"])
        window.removeEventListener("click", onWindowClick)
      }
    }, [onDismiss])

    return (
      <Container className={className} active={active} ref={modalContainerRef}>
        <ModalBox vhHeight={vhHeight} oneColumn={oneColumn} autoWith={autoWith} autoHeight={autoHeight}>
          <CloseButton
            aria-label="close"
            onClick={() => {
              onDismiss && onDismiss()
              close()
            }}
          />
          <ModalContent>{children}</ModalContent>
        </ModalBox>
      </Container>
    )
  }
)

export default Modal
