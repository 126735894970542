import React from "react"
import styled from "styled-components"


const CardHeader = styled.h2`
font-weight: 800;
font-size: 24px;
line-height: 30px;
margin-bottom: 10px;


  @media screen and (max-width: 640px) {
  font-weight: 700;
  line-height: 36px;
}


`
const CardContainer = styled.a`
  grid-area: ${props => props.gridArea};
  height: 100%;
  background : url(${props => props.background});
  padding: 32px;
  background-position: bottom right; 
  background-repeat: no-repeat;
  border: 0.5px solid #0D0D0D;
  box-shadow: 3.28928px 3.28928px 0px rgba(13, 13, 13, 0.1);
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &.fintech, &.small {
    background-color: #FFF9F2;
  }
  &.travel,&.igaming {
    height: 230px;
  }
&.small {
  height: 111px;
  padding: 14px;
  ${CardHeader}{
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }

  @media screen and (max-width: 980px) {
  background-size: 59%;

  ${CardHeader}{

    font-weight: 700;
    font-size: 14.2px;
    line-height: 27px;
  }
}
}
@media screen and (max-width: 980px) {
  padding: 16px;
  background-size: 100%;

 

  ${CardHeader}{
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
  }
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }

  &.travel,&.igaming {
    height: 130px;
  }

}


@media screen and (max-width: 640px) {
  border-left: 0.7px solid #0D0D0D;

  height: 320px;
  padding: 15px;
   p{
    font-weight: 400;
font-size: 16px;
line-height: 24px;
   }
  
  background-size: 100%;
  ${CardHeader}{
    font-weight: 700;
font-size: 24px;
line-height: 36px;
  }

  &.travel,&.igaming {
    height: 320px;
  background-size: 150%;

  }


}
`
const CardComponent = ({ gridArea, header, text, background, className, href }) => {
  return (
    <CardContainer href={href} className={className} background={background} gridArea={gridArea}>
      <CardHeader>{header}</CardHeader>
      <p>{text}</p>

    </CardContainer>
  )
}

export default CardComponent