import React, { useContext } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import { pushCustomDataLayerEvent } from "../state/actions"

import SEO from "../components/seo"
import Grid from "../components/connect/grid"
import SectionIntro from "../components/section-intro"
import Feature from "../components/connect/feature"
import ActionButton from "../components/action-button"
import BigFeature from "../components/connect/big-feature"
import FAQ from "../components/connect/faq"
import Video from "../components/connect/video"

import GlobalContext from "../context/global-context"

import withoutRemote from "../assets/images/connect/without-remote-desktop.png"
import withoutRemoteMobile from "../assets/images/connect/without-remote-mobile.png"
import withRemote from "../assets/images/connect/with-remote-desktop.png"
import withRemoteMobile from "../assets/images/connect/with-remote-mobile.png"
import fasterIcon from "../assets/images/connect/faster.svg"
import compliantIcon from "../assets/images/connect/compliant.svg"
import controlIcon from "../assets/images/connect/control.svg"
import customIcon from "../assets/images/connect/custom.svg"

const FeaturesContainer = styled.div`
  grid-area: features-container;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    justify-content: unset;
    width: 280px;
    margin: 0 auto;
  }

  @media screen and (max-width: 640px) {
  }
`

const GetRemoteButton = styled(ActionButton)`
  margin: 0;
  grid-area: get-remote;
`

const TalkToSalesButton = styled(ActionButton)`
  margin: 0;
  grid-area: talk-to-sales;
`

const WithoutRemote = styled.div`
  grid-area: without-remote;
  height: 541px;
  background: url(${withoutRemote}) center/contain no-repeat;

  @media screen and (max-width: 980px) {
    height: 425px;
    background: url(${withoutRemoteMobile}) center/contain no-repeat;
  }

  @media screen and (max-width: 640px) {
    height: 228px;
  }
`

const WithRemote = styled.div`
  grid-area: with-remote;
  height: 541px;
  background: url(${withRemote}) center/contain no-repeat;

  @media screen and (max-width: 980px) {
    height: 425px;
    background: url(${withRemoteMobile}) center/contain no-repeat;
  }

  @media screen and (max-width: 640px) {
    height: 228px;
  }
`

const WithoutRemoteDescription = styled.div`
  grid-area: without-remote-description;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 16px;
    font-weight: 900;
  }

  p {
    font-size: 20px;
    line-height: 30px;
    width: 647px;
  }

  @media screen and (max-width: 980px) {
    h2 {
      font-size: 30px;
      line-height: 38px;
    }

    p {
      width: 540px;
      font-size: 18px;
      line-height: 32px;
    }
  }

  @media screen and (max-width: 980px) {
    h2 {
      font-size: 28px;
      line-height: 34px;
    }

    p {
      width: auto;
      font-size: 18px;
      line-height: 32px;
    }
  }
`

const WithRemoteDescription = styled(WithoutRemoteDescription)`
  grid-area: with-remote-description;

  p {
    width: 754px;
  }

  @media screen and (max-width: 980px) {
    p {
      width: 631px;
    }
  }

  @media screen and (max-width: 980px) {
    p {
      width: auto;
    }
  }
`

const FeaturesDescription = styled(WithoutRemoteDescription)`
  grid-area: features-description;
`

const UseCasesDescription = styled(WithoutRemoteDescription)`
  grid-area: usecases-description;

  @media screen and (max-width: 980px) {
    p {
      width: 357px;
    }
  }

  @media screen and (max-width: 980px) {
    p {
      width: auto;
    }
  }
`

const KnowMore = styled(WithRemoteDescription)`
  grid-area: know-more;
`

const FeatureOne = styled(BigFeature)`
  grid-area: feature-one;
`

const FeatureTwo = styled(BigFeature)`
  grid-area: feature-two;
`

const FeatureThree = styled(BigFeature)`
  grid-area: feature-three;
`

const FeatureFour = styled(BigFeature)`
  grid-area: feature-four;
`

const ResultsBox = styled.div`
  grid-area: results-box;
  position: relative;
  width: 947px;
  height: 305px;
  border: 1px solid #000000;
  background: #fff;
  display: flex;

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px dashed #000000;
    top: 12px;
    left: 12px;
    z-index: -1;
  }

  @media screen and (max-width: 980px) {
    width: 633px;
    height: 268px;
  }

  @media screen and (max-width: 640px) {
    width: auto;
    height: 400px;
    flex-direction: column;
  }
`

const BigTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }
`

const BigText = styled.span.attrs(props => ({
  color: props.color || "#000",
}))`
  font-size: 100px;
  font-weight: 900;
  color: ${props => props.color};
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000, 4px 4px 0 rgba(0, 0, 0, 0.1);
  justify-self: center;
  line-height: normal;

  @media screen and (max-width: 640px) {
    font-size: 110px;
  }

  @media screen and (max-width: 980px) and (min-width: 641px) {
    font-size: 80px;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 375px) {
    font-size: 80px;
  }
`

const ClaimsText = styled.p`
  grid-area: claims-text;
  font-size: 16px;
  line-height: 24px;
  color: #545454;
  margin-top: 12px;
  text-align: center;
`

const ColoredBoxesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  grid-area: colored-boxes;
  width: 1050px;
  margin: 0 auto;
  position: relative;

  p {
    position: absolute;
    top: calc(100% + 30px);
    font-size: 18px;
    text-align: center;
    width: 100%;
  }

  @media screen and (max-width: 980px) {
    flex-wrap: wrap;
    width: 622px;
    justify-content: center;

    p {
      position: absolute;
      top: calc(100%);
      font-size: 16px;
      text-align: center;
      width: 100%;
    }
  }

  @media screen and (max-width: 640px) {
    flex-wrap: nowrap;
    width: 100%;
    flex-direction: column;
  }
`

const ColoredBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 317px;
  height: 166px;
  background: #fff;
  border: 1px solid #000000;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  box-sizing: border-box;

  span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 900;
    text-align: center;
  }

  &::after {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: none;
    transform: skewY(-30deg);
    transform-origin: left bottom;
    height: calc(100% + 2px);
    width: 14px;
    top: -1px;
    left: calc(100% + 1px);
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  &::before {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    border-left: 2px solid #000;
    border-bottom: none;
    transform: skew(-60deg);
    transform-origin: right bottom;
    height: 8px;
    width: calc(100% + 2px);
    bottom: calc(100% + 1px);
    left: -1px;
    pointer-events: none;
    background: ${props => props.color};
    box-sizing: border-box;
  }

  @media screen and (max-width: 980px) {
    width: 288px;
    max-width: 288px;
    flex: 50%;
    margin: 0 0 40px;

    &:first-child {
      margin-right: auto;
    }
  }

  @media screen and (max-width: 640px) {
    flex: auto;
  }
`

const RemotePage = () => {
  const dispatch = useDispatch()
  const globalContext = useContext(GlobalContext)

  return (
    <>
      <SEO
        title="Launch your signup on any site to increase affiliate conversion"
        description="Checkin Connect is our compliant turnkey solution that enables users to sign-up to your brand directly on any trusted third-party website."
        canonical="/connect/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />
      <Grid>
        <SectionIntro
          header={`Convert more traffic\nwith Checkin Connect`}
          description="Enable leads to register with your brand directly on trusted third party websites by eliminating unnecessary redirections"
        />

        <Video />

        <FeaturesContainer>
          <Feature>Seamless onboarding</Feature>
          <Feature>Instant conversion</Feature>
          <Feature>Simple integration</Feature>
        </FeaturesContainer>

        <GetRemoteButton
          onClick={() => {
            dispatch(
              pushCustomDataLayerEvent({
                event: "get_started",
                eventCategory: "get_started",
                eventAction: "open",
                eventLabel: "page_remote",
              })
            )
            globalContext.openGetStarted("emailForm", null, true)
          }}
        >
          Get Checkin Connect
        </GetRemoteButton>

        <WithoutRemote />
        <WithoutRemoteDescription>
          <h2>Redirecting traffic causes drop-offs</h2>
          <p>
            When customers leave a third party site to sign up on the main brand
            page, a potential drop-off point is introduced
          </p>
        </WithoutRemoteDescription>

        <WithRemote />
        <WithRemoteDescription>
          <h2>Instant and measurable results</h2>
          <p>
            With Checkin Connect the sign-up can be completed directly on a
            trusted third party website which creates a better experience for
            the customer, leading to higher conversion rates for any marketing
            channel
          </p>
        </WithRemoteDescription>

        <ResultsBox>
          <BigTextContainer>
            <BigText color={"#B4DEEC"}>22%</BigText>
            <p>increase conversion rate</p>
          </BigTextContainer>
          <BigTextContainer>
            <BigText color={"#FBD65D"}>83%</BigText>
            <p>faster in signups</p>
          </BigTextContainer>
        </ResultsBox>

        <ClaimsText>
          *Claims and figures based on specific partners using Checkin Connect
        </ClaimsText>

        <FeaturesDescription>
          <h2>Checkin Connect increases conversions</h2>
          <p>
            Bring the checkin experience closer to the end-user to improve the
            time to sign-up and increase conversion
          </p>
        </FeaturesDescription>

        <FeatureOne
          illustration={fasterIcon}
          title="Faster sign-up"
          text="Minimizing the time and redirects required to complete the registration"
        />

        <FeatureTwo
          illustration={customIcon}
          title="Use on any third party website"
          text="Campaign, affiliate and brand protection websites are some examples"
        />

        <FeatureThree
          illustration={controlIcon}
          title="Control your data"
          text="No personal data is being stored by us or the third party, giving you full control over data collection"
        />

        <FeatureFour
          illustration={compliantIcon}
          title="GDPR-compliant"
          text="The solution is compliant in all jurisdictions to protect your operations and your users"
        />

        <UseCasesDescription>
          <h2>Use cases</h2>
          <p>
            Explore ways to use Checkin Connect for different types of traffic
          </p>
        </UseCasesDescription>

        <ColoredBoxesContainer>
          <ColoredBox color={"#FF977A"}>
            <span>Brand Protected Websites</span>
          </ColoredBox>
          <ColoredBox color={"#C894D6"}>
            <span>Campaign Landing Pages</span>
          </ColoredBox>
          <ColoredBox color={"#FACD38"}>
            <span>Lead Generation Websites</span>
          </ColoredBox>
          <p>And many more</p>
        </ColoredBoxesContainer>

        <KnowMore>
          <h2>Get started today</h2>
          <p>We’ve worked hard to let your team work less</p>
        </KnowMore>

        <TalkToSalesButton
          onClick={() => {
            dispatch(
              pushCustomDataLayerEvent({
                event: "get_started",
                eventCategory: "get_started",
                eventAction: "open",
                eventLabel: "page_remote",
              })
            )
            globalContext.openGetStarted("emailForm", null, true)
          }}
        >
          Get Checkin Connect
        </TalkToSalesButton>

        <FAQ />
      </Grid>
    </>
  )
}

export default RemotePage
