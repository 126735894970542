import React, { createRef, useEffect, useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"

import indexIllustration from "../../assets/images/product/product-outline.svg"
import MainBackground from "../../assets/start-page/main.svg"
import loadable from '@loadable/component'

const LottieAnimation = loadable(() => import('./lottieAnimation'))

const FadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Container = styled.div`
  position: relative;
  bottom: 20px;
  background: url(${MainBackground}) no-repeat center/100%;
  height: 700px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media screen and (min-width: 2501px) {
    height: 850px;
  }


  @media screen and (max-width: 1550px) {
    height: 450px;
  }

  @media screen and (max-width: 980px) {
    height: 365px;
  }

  @media screen and (max-width: 640px) {
  background: none;

  }

`


const IndexIllustration = styled.div`
width: 854px;
height: 100%;
margin: 0 auto;
  background: url(${indexIllustration}) no-repeat center/contain;
  background-size: 150px 150px;
  @media screen and (max-width: 980px) {
    width: 86%;
    height: 100%;
     background-size: 150px 150px;

  }
  @media screen and (max-width: 640px) {
    width: 84%;
    top: 20px;
    left: 79px;
    position: relative;
    height: 72%;
    margin-left: -45px;
    z-index: 0;
    background: url(${indexIllustration}) no-repeat center/cover;
    background-size: 150px 150px;
  }
  &.FadeOut{
    animation: ${FadeOut} 0.5s ease forwards 0.1s;
  }
`


export default function OcrAnimation() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {


    LottieAnimation.load().then(() => {
      setIsLoading(false)
    })
  }, []);



  return (
    <Container >
      <LottieAnimation />
      <IndexIllustration className={!isLoading ? 'FadeOut' : null} />
    </Container>
  )


}