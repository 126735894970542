import React, { createRef, useEffect, useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import lottie from 'lottie-web/build/player/lottie_light.min.js'


const FadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`


const Animation = styled.div`
display: flex;
position: absolute;
opacity: 0;
height: auto;
width: 100%;

@media screen and (max-width: 980px) {
  height: 100%;

}
    @media screen and (max-width: 640px) {
  width: 100%;
}
${props =>
    props.absolute &&
    css`
    opacity:0;
    position: absolute;
    top: 0px;
  `}

  justify-content: center;
  @media screen and (max-width: 980px) {
    left: 0px;
    top: 69px;

  }
  @media screen and (max-width: 980px) {
    top: 0px;

  }
  &.FadeIn{
    animation: ${FadeIn} 1s ease forwards 0.1s;
  }
  &.FadeOut{
    animation: ${FadeOut} 0.5s ease forwards 1s;
  }

  &.destroy{
    display: none;
  }

  


`

const AnimationMobile = styled(Animation)`

@media screen and (min-width: 641px) {
  display: none;
}

@media screen and (mmaxin-width: 375px) {
    height: 75% !important;
    width: 120%;
    position: relative;
    left: -30px;
  }

`

const AnimationDesktop = styled(Animation)`
@media screen and (max-width: 640px) {
  display: none;
}

&#earth {
    left: 0px;
    width: auto;
}

&#screen {
    right: 0px;
    width: auto;
}

&#face {
    right: 0px;
    left: 0px;
    height: 100%;
}
`

export default function OcrAnimation() {
  const earthContainer = useRef(null);
  const screenContainer = useRef(null);
  const faceContainer = useRef(null);
  const mobileContainer = useRef(null);
  const [animationLoaded, setAnimationLoaded] = useState(false);
  let earthAnimation, screenAnimation, faceAnimation, animMobile = null;

  useEffect(() => {
    import("../../assets/start-page/1.json").then((res) => {
      earthAnimation = lottie.loadAnimation({
        container: earthContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: res.default,
      });

      earthAnimation.addEventListener('DOMLoaded', () => {
        setAnimationLoaded(true);
      });
    });

    import("../../assets/start-page/2.json").then((res) => {
      screenAnimation = lottie.loadAnimation({
        container: screenContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: res.default,
      });
    });

    import("../../assets/start-page/3.json").then((res) => {
      faceAnimation = lottie.loadAnimation({
        container: faceContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: res.default,
      });
    });

    import("../../assets/start-page/mobile.json").then((res) => {
      animMobile = lottie.loadAnimation({
        container: mobileContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: res.default,
      });
    });

    return () => {
      animMobile.destroy();
      faceAnimation.destroy();
      screenAnimation.destroy();
      earthAnimation.destroy();
    }
  }, []);
  return (
    <>
      <AnimationMobile className={animationLoaded ? 'FadeIn' : null} ref={mobileContainer} />
      <AnimationDesktop id="earth" className={animationLoaded ? 'FadeIn' : null} ref={earthContainer} />
      <AnimationDesktop id="screen" className={animationLoaded ? 'FadeIn' : null} ref={screenContainer} />
      <AnimationDesktop id="face" className={animationLoaded ? 'FadeIn' : null} ref={faceContainer} />
    </>
  )
}