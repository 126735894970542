import React, { useState, useRef } from "react"
import styled, { keyframes } from "styled-components"

import SEO from "../../components/seo"
import Calendar from "../../components/meeting-booker/calendar"
import GreenCircle from "../../components/green-circle"
import ArrowSquare from "../../assets/images/meeting-booker/arrow-square.inline.svg"
import Wonderino from "../../assets/images/meeting-booker/wonderino.inline.svg"
import Forza from "../../assets/images/meeting-booker/forza.inline.svg"
import Hero from "../../assets/images/meeting-booker/hero.inline.svg"
import KindredSvg from "../../assets/images/meeting-booker/kindred.inline.svg"
import Kindred from "../../assets/images/meeting-booker/kindred.png"
import Modal from "../../components/modal"


const fadeInOut = keyframes`
  0%   {opacity: 1;}
  50%  {opacity: 0;}
  100%  {topacityop: 1;}
`

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 1170px;
  box-sizing: border-box;
  padding: 135px 0 0;
  flex-direction: column;
  align-self: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 980px) {
    padding: 50px 0 0;
    width: 632px;
  }

  @media screen and (max-width: 640px) {
    width: 345px;
  }

  .meetings-iframe-container {
    position: absolute;
    top: -36px;
    bottom: 0;
    left: 0;
    right: 0;
  
    @media screen and (max-width: 980px) {
      top: -30px;
    }
    @media screen and (max-width: 460px) {
      top: -40px;
    }
    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 95% !important;
    }
  }
`

const Section = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 45px;


  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin-bottom: 30px;

    &.calendar {
      width: 110%;
    }
  }

  @media screen and (max-width: 640px) {
    &.calendar {
      width: 105%;
    }
  }
`



export const H2 = styled.h2`
  font-size: 42px;
  line-height: 52px;
  font-weight: 800;
  margin-bottom: 16px;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    font-weight: 800;
  }

  @media screen and (max-width: 640px) {

    font-weight: 800;
    font-size: 34px;
    line-height: 44px;
  }
`

const SideBar = styled.div`
  display: flex;
  width: 40%;
  height: 700px;
  flex-direction: column;


  p {
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    height: auto;

    p {
      text-align: center;
      br {
        display: none;
      }
    }
  }

  @media screen and (max-width:640px) {
    margin-bottom: 25px;
  }
}


`

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media screen and (max-width: 980px) {
    display: none;
  }
`

const FeaturesContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  display: none;

  @media screen and (max-width: 980px) {
    display: block;
  }
`


const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  margin-bottom: 26px;

  p {
    font-size: 18px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 500px;
    margin: 0 auto;
    margin-bottom: 40px;


    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }

    br {
      display: none;
    }
  }

  @media screen and (max-width: 640px) {
    width: 310px;
    p {
      margin-left: 0px;
    }
  }
`
const ImageComtainer = styled.div`

`


const QuoteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; 
  width: 950px;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    position: relative;
    width: 90%;
  }

`

const PartnerQuote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &.fadeInOut{
    animation: ${fadeInOut} 0.7s ease forwards;
  }

  @media screen and (max-width: 980px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    background: white;
    padding: 32px 21px;
  }
`
const PartnerLogo = styled.div`
  width: 209px;
  background: #fff;
  border: 1px solid #040404;
  height: 133px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 36px;
  position: relative;

  @media screen and (max-width: 980px) {
    width: 114px;
    height: 74px;
    margin-right: 20px;

    svg {
      width: 81px;
    }
  }
  &::after {
    content: "";
    border: 1px dashed #A5A5A5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 5px ;
    left: 5px ;
    z-index: -1;
  }
`
const Quote = styled.div`
  width: 515px;
  @media screen and (max-width: 980px) {
    width: 346px;
  }
  @media screen and (max-width: 640px) {
    width: auto;
    margin-top: 30px;
  }
`

const Right = styled(ArrowSquare)`
  box-shadow: 5px 5px 0px rgb(0 0 0 / 10%);

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
    top: 34px;
    right: -25px;
  }
`

const Left = styled(ArrowSquare)`
  transform: rotate(180deg);
  box-shadow: 5px -5px 0px rgb(0 0 0 / 10%);

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
    top: 34px;
    left: -25px;
  }

`
const CustomModal = styled(Modal)`

${H2}{
  width : 600px;
  text-align: center;
  margin-bottom: 35px;

  @media screen and (max-width: 980px) {
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
  }

  @media screen and (max-width: 460px) {
    display: none;
  }

}

`

const CustomCalendar = styled(Calendar)`

@media screen and (max-width: 460px) {
  .meetings-iframe-container {
    top: -34px !important;
  }
}
`

export default function MeetingBooker({ location }) {

  const [selectedPartner, setSelectedPartner] = useState(0)
  const [fadeInOut, setFadeInOut] = useState(false)
  const debug = new URLSearchParams(location.search).get("debug") === 'true' ? true : false;

  const PartnerQuotes = [
    {
      logo: Wonderino,
      quote: `
      <p>"Partnering up with Checkin.com was a no-brainer, we loved the flow and already from day one,
      12% more users signed up"</p>
      <br/>
      <p><b>Tobias Carlsson</b>, Rhinoceros</p>
      `
    },
    {
      logo: KindredSvg,
      quote: `
      <p>"With Checkin.com, the player registration process will not only be more interactive but also help us to understand the player better from the point of signup"</p>
      <br/>
      <p><b>Trille Levin</b>, Kindred Group</p>
      `
    },
    {
      logo: Hero,
      quote: `
      <p>"With Checkin.com, the player registration process will not only be more interactive but also help us to understand the player better from the point of signup"</p>
      <br/>
      <p><b>Georg Westin</b>, Hero Gaming</p>
      `
    },
    {
      logo: Forza,
      quote: `
      <p>"With Checkin.com, the player registration process will not only be more interactive but also help us to understand the player better from the point of signup"</p>
      <br/>
      <p><b>Patrik Arnesson</b>, Forza Football</p>
      `
    }
  ]

  const modalRef = useRef()

  async function Next() {

    if (selectedPartner < PartnerQuotes.length - 1) {
      setFadeInOut(true)
      setTimeout(() => {
        setFadeInOut(false)
      }, 700)
      setTimeout(() => {
        setSelectedPartner(selectedPartner + 1)
      }, 200)
    }
  }

  function Previous() {
    if (selectedPartner > 0) {
      setFadeInOut(true)
      setTimeout(() => {
        setFadeInOut(false)
      }, 700)
      setTimeout(() => {
        setSelectedPartner(selectedPartner - 1)
      }, 200)
    }
  }

  const Logo = () => {
    const Icon = PartnerQuotes[selectedPartner].logo
    return <Icon />
  }

  return (
    <Container>
      <SEO
        title="Schedule time with an onboarding expert here"
        description="Book time directly to discuss your use-case and get a complete solution for your KYC and customer onboarding challenges."
        canonical="/meeting-booker/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />
      <Section className="calendar">
        <SideBar>
          <H2>
            See your improved <br />
            onboarding in action
          </H2>
          <p>
            Request a demo from one of<br /> our conversion specialists.
          </p>
          <FeaturesContainer>
            <Feature>
              <ImageComtainer>
                <GreenCircle />
              </ImageComtainer>
              <p>See how Checkin.com works<br /> in your own site/app </p>
            </Feature>
            <Feature>
              <ImageComtainer>
                <GreenCircle />
              </ImageComtainer>
              <p>Fully working prototype with your<br /> branding and local adaptations </p>
            </Feature>
            <Feature>
              <ImageComtainer>
                <GreenCircle />
              </ImageComtainer>
              <p>Conversion increase<br /> guaranteed</p>
            </Feature>
          </FeaturesContainer>

        </SideBar>
        <Calendar
          debug={debug}
          height="793px"
        />
        <FeaturesContainerMobile>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>See how Checkin.com works<br /> in your own site/app </p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Fully working prototype with your<br /> branding and local adaptations </p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Conversion increase<br /> guaranteed</p>
          </Feature>
        </FeaturesContainerMobile>
      </Section>
      <Section>
        <QuoteContainer>
          <ImageComtainer>
            <Left onClick={() => Previous()} className={selectedPartner == 0 ? 'disabled' : null} />
          </ImageComtainer>

          <PartnerQuote className={fadeInOut ? "fadeInOut" : null} >
            <PartnerLogo>
              {selectedPartner == 1 ? (<img src={Kindred} />) : (<Logo />)}
            </PartnerLogo>
            <Quote
              dangerouslySetInnerHTML={{ __html: PartnerQuotes[selectedPartner].quote }}
            >
            </Quote>
          </PartnerQuote>

          <ImageComtainer>
            <Right onClick={() => Next()} className={selectedPartner == 3 ? 'disabled' : null} />
          </ImageComtainer>
        </QuoteContainer>

        <CustomModal ref={modalRef}>
          <H2>Schedule a meeting with our conversion specialist</H2>
          <CustomCalendar
            height='860px'
            width='100%'
            debug={debug}
          />
        </CustomModal>
      </Section>

    </Container >
  )
}