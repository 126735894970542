import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from 'gatsby';

import EnFlag from "../../assets/images/brazil/gb.svg"
import BrFlag from "../../assets/images/brazil/br.svg"

const Container = styled.div`
    grid-area: switcher;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        margin-right: 8px;
    }
`
const Switch = styled(Link)`
    display: flex;
    padding: 8px 16px;
    align-items: center;
    border: 1px solid #000;
    background: rgba(255, 255, 255, 0.2);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    border: 1px solid rgba(0, 0, 0, 0.20);

    &.selected {
        border: 1px solid #000;
        font-weight: 700;
        background: #FFF;   
    }
`

const LangugageSwitcher = () => {
    const [isPt, setIsPt] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setIsPt(window.location.pathname.endsWith('-pt'));
        }
    }, []);

    return (
        <Container >
            <Switch className={!isPt && "selected"} to="/industry-igaming/brazil" ><img src={EnFlag} />English</Switch>
            <Switch className={isPt && "selected"} to="/industry-igaming/brazil-pt" ><img src={BrFlag} />Português</Switch>
        </Container>
    )
}

export default LangugageSwitcher