import React, { createRef, useEffect, useState } from "react"
import styled from "styled-components"

import animationData from "../../assets/images/brazil/header.json"
import animationDataMobile from "../../assets/images/brazil/mobile-header.json"
import Loader from "../../assets/images/id-scanner/header-loader.svg"
import LoaderMobiLe from "../../assets/images/id-scanner/header-loader-mobile.svg"
import lottie from 'lottie-web/build/player/lottie_light.min.js'


const AnimationContainer = styled.div`
grid-area: header-animation;
display: flex;
flex-direction: column;
width: 100%;
justify-content: center;
position: relative;
align-items: center;

.middle {
    max-width: 650px;
    margin: 0 auto;
    position: absolute;
    top: 0px;

    h1{
      margin-top: 0;
    }
  }


img {
  width: 63%;
  height: 52% !important;
  bottom: 163px;
  position: absolute;
}

@media screen and (max-width: 980px) {

}

@media screen and (max-width: 640px) {
  max-width: 640px;

  .middle p {
          padding: 0 16px;
  }

}

img {}


`

const Animation = styled.div`
width: 100%;
display: flex;
  justify-content: center;
svg {
  max-width: 1523px;
}

@media screen and (max-width: 640px) {
  svg {
    width: 85% !important;
  }
}
`

const LoaderContainer = styled.div`
width: 100%;
background: url(${Loader}) 50% 50% / 100% no-repeat;
opacity: 1; 
transition: opacity 0.5s ease-in-out;

&.fadeOut{
  opacity: 0; 
}
 @media screen and (min-width: 981px) {
    height: 500px;
    max-width: 1640px;
  }
  @media screen and (max-width: 980px) {
    margin-top: 160px;
    height: 250px;
  }
@media screen and (max-width: 640px) {
  background: url(${LoaderMobiLe}) 50% 50% / 97% no-repeat;
  height: 382px;
  margin-top: 205px;
}
`
export default function SlotMachine() {


  let animationContainer = createRef();
  const [animationLoaded, setAnimationLoaded] = useState(false)
  let animation = null;

  useEffect(() => {
    animation = lottie.loadAnimation({
      container: animationContainer.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: window.innerWidth > 640 ? animationData : animationDataMobile,

    });

    return () => { animation.destroy() }
  }, []);

  return (

    <AnimationContainer >
      <Animation className={animationLoaded ? "fadeIn" : null} ref={animationContainer} />
    </AnimationContainer>
  )
}
