import React, { useState, useContext, useEffect } from "react"
import styled, { css, keyframes } from "styled-components"
import { Link } from "gatsby"
import GreenCircle from "../../components/green-circle"
import ActionButton from "../../components/action-button"
import SEO from "../../components/seo"
import PartnerQuotes from "../../components/ocr/partner-quotes"
import GlobalContext from "../../context/global-context"
import Img from "gatsby-image"
import { SwitchTransition, CSSTransition } from "react-transition-group"



import Document from "../../assets/images/ocr/document.inline.svg"
import Compliance from "../../assets/images/ocr/compliance.inline.svg"
import Extract from "../../assets/images/ocr/extract.inline.svg"
import LivenessCheck from "../../assets/images/ocr/liveness.inline.svg"
import Countries from "../../assets/images/ocr/countries.inline.svg"
import Languages from "../../assets/images/ocr/languages.inline.svg"
import Doctype from "../../assets/images/ocr/doctype.inline.svg"
import Conversion from "../../assets/images/ocr/conversion.inline.svg"
import Combine from "../../assets/images/ocr/combine.inline.svg"
import Control from "../../assets/images/ocr/control.inline.svg"
import Custom from "../../assets/images/ocr/custom.inline.svg"
import Jumio from "../../assets/images/ocr/jumio.inline.svg"
import Onfido from "../../assets/images/ocr/onfido.inline.svg"
import Sumsub from "../../assets/images/ocr/sumsub.inline.svg"
import DataColl from "../../assets/images/ocr/data.inline.svg"
import TechSetup from "../../assets/images/ocr/techSetup.inline.svg"
import Compliance2 from "../../assets/images/ocr/compliane.inline.svg"
import Localization from "../../assets/images/ocr/localization.inline.svg"
import Next from "../../assets/images/ocr/next.inline.svg"
import Invalid from "../../assets/images/ocr/invalid.inline.svg"
import Angle from "../../assets/images/angle.inline.svg"
import Loading from "../../components/loading"

import loadable from '@loadable/component'
const HeaderAnimation = loadable(() => import("../../components/ocr/header-animation"))
const IdScanner = loadable(() => import("../../components/ocr/id-scanner"))


const collapse = keyframes`
  from {
    height: 100px;
  }

  to {
    height: 0px;
  }
`

const expand = keyframes`
  from {
    height: 0px;
  }

  to {
    height: 100px;
  }
`

const collapseSituation = keyframes`
  from {
    height: 677px;
  }

  to {
    height: 0px;
  }
`
const expandSituation = keyframes`
  0% {
    height: 0px;
  }

  80% {
    height: 677px;
  }

  100% {
    height: auto;
  }
`
const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const H1 = styled.h1`
  font-size: 48px;
  line-height: 56px;
  font-weight: 900;
  margin-bottom: 16px;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media screen and (max-width: 640px) {
    font-size: 34px;
    line-height: 44px;
    width: 320px;
  }
`

const H2 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;

  

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;
    br {
      display: none;
    }
  }
`
const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`

const CTAText = styled(H4)`
  text-align: center;
  br {
    display: none;
  }

  @media screen and (max-width: 980px) {
    width: 389px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
    br {
      display: block;
    }
  }
`
const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
  }
`

const SectionDescription1 = styled(SectionDescription)`
  width: 679px;

  @media screen and (max-width: 980px) {
    width: 578px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const SectionDescription4 = styled(SectionDescription)`
  width: 650px;

  @media screen and (max-width: 980px) {
    width: 460px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const Container = styled.div`
  flex: 1;
  width: 1170px;
  align-self: center;
  padding: 135px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 980px) {
    padding: 40px 0 0;
    width: 640px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
    padding: 50px 0 0;
  }


  .sliders {
    display: none;
    @media screen and (max-width: 640px) {
      display:block;
    }

  }

`

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  z-index:1;

  &:last-child {
    margin-bottom: 80px;
  }
  &#delivery, &#fast-easy {
    @media screen and (max-width: 640px) {
      margin-bottom: 0px;
    }
  }

`

const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`

const FeaturesContainer = styled.div`
  width: 1064px;
  display: flex;
  justify-content: space-between;
  margin: 525px 0 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    width: 523px;
    margin: 460px 0 40px;
  }

  @media screen and (max-width: 640px) {
    width: 296px;
    margin: 330px 0 40px;
  }
`


const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 330px;
  z-index: 1;

  p {
    font-size: 20px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    width: 280px;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`
const DocsButton = styled(ActionButton)`
  background: #fff;
  color: #000;
  border: 1px solid #000;
  outline: none;
  box-shadow: none;
  padding: 18px 40px;
  font-size: 18px;
  font-weight: 700;
  margin-top:60px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    border: 1px solid #A5A5A5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 5px ;
    left: 5px ;
    z-index: -1;
  }

  &:hover {
    box-shadow: 5px 5px 0 0 #000;
  }
`


const StepsContainer = styled.div`
  width: 895px;
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  position: relative;
  ${props =>
    props.border &&
    css`border-bottom: 2px solid rgba(0, 0, 0, 0.04);`
  }
  

  

  @media screen and (max-width: 980px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 240px;
  cursor: pointer;
  justify-content: center;

  &:last-of-type {
    width: 305px;
  }

  ${H4} {
    margin: 15px auto;
    font-size: 20px;
    line-height: 30px;
  }

  ${Text} {
    line-height: 24px;
  }

  @media screen and (max-width: 980px) {
    &:last-child {
      grid-column: span 2;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    margin-top: 0;
    margin-bottom: 20px;
    grid-column: 1;
  }
`

const CustomStep = styled(Step)`

  ${H4} {
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
  }
  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid #000000;
      ${H4} {
        font-weight: 700;
      }
    `}


  img {
    width: 69px;
    height: 69px;
  }

  @media screen and (max-width: 980px) {

    ${H4} {
      font-size: 14px;
      line-height: 14px;
    }

    &:last-child {
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 640px) {
    width: 230px;
    margin-bottom:0px;
    ${H4} {
      font-size: 14px;
      line-height: 22px;
    }
  }
`

const CustomStepsContainer = styled(StepsContainer)`

@media screen and (max-width: 980px) {
  display: flex;
}

@media screen and (max-width: 640px) {
  width: 600px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
`
const Mobilecontainer = styled.div`
@media screen and (max-width: 640px) {
  overflow-x: auto;
    width: 371px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
}
`

const SituationQST = styled(H4)`
  text-align: center;
  margin-top: 60px;
`

const ImageComtainer = styled.div`
  width: 40px;
  height: 40px;
`

const StepContent = styled.div`
width: 70%;
padding: 34px 71px;
display: flex;
justify-content: space-between;
margin-bottom: 66px;
background-color: #FFFFFF;
border: 1px solid #000;
position: relative;

&.fade-enter {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-exit-active {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-enter-active,
&.fade-exit-active {
  transition: all 0.3s ease;

}
&.fade-enter-active,
&.fade-exit-active,
&.fade-exit,
&.fade-enter{
  &::after {
    display:none;
  }
}

&::after {
  content: "";
  border: 1px dashed #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 10px ;
  left: 10px ;
  z-index: -1;
}

@media screen and (max-width: 980px) {
  flex-direction: row;
  padding: 30px;
  width: 90%;
}

@media screen and (max-width: 640px) {
  grid-template-columns: 1fr;
  margin-top: 25px;
  height: auto;
  width: 100%;
  height: auto;
  flex-direction: column;

  &.fade-enter {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.3s ease;
  }

  &.fade-enter-active,
  &.fade-exit-active,
  &.fade-exit,
  &.fade-enter{
    &::after {
      display:none;
    }
  }
}

&.collapse{
    animation: ${collapseSituation} 1s ease forwards;
}

&.expand{
    animation: ${expandSituation} 1s ease forwards;
}
`

const List = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
width: 390px;
@media screen and (max-width: 980px) {
  width: 273px;
}

${H4}{
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  @media screen and (max-width: 980px) {
      font-size: 20px;
      line-height: 32px;
  }
}
p {
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width: 980px) {
    font-size: 14px;
    line-height: 24px;
  }
 }
@media screen and (max-width: 640px) {
  ul {
    margin-left: 16px;
  }
}



li {
  font-size: 16px;
  line-height: 32px;
  padding-left: 10px;
}
}

`

const ListImage = styled.div`
  min-height: 70px;
  display: block;

  @media screen and (max-width: 980px) {
   svg {
    width: 220px;
   } 
  }
  @media screen and (max-width: 640px) {
    height: auto;
    margin: 0 auto;
   }
  

`


const IndexIllustrationContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 132px;
  width: 100%;

  @media screen and (max-width: 980px) {
    top: 121px;
  }

  @media screen and (max-width: 640px) {
    top: 270px;
  }
`


const FeaturesContainer2 = styled.div`

  display: flex;
  justify-content: space-around;
  width: 100%;

@media screen and (max-width: 980px) {
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  width: 296px;
  flex-direction: column;

  ${ImageComtainer} {
    width: auto;
  }
}

`
const FeaturesColumn = styled.div`
`

const Feature2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  z-index: 1;
  margin-top: 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column !important;
  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }
  

  ${H4} {
    margin-bottom: 16px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
    width: 424px;

    @media screen and (max-width: 980px) {
      width: 278px;
      text-align: left;
      font-size: 16px !important;
      line-height: 28px !important;

    }
    
  }

  ${ImageComtainer} {
    height: 90px;
  }

  }
  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    p{
    text-align: center !important;
    }
  }


`
const Numbers = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 93px;

  @media screen and (max-width: 980px) {
    grid-template-columns: auto auto ;
  }

  @media screen and (max-width: 640px) {
   display: block;
  }
`
const Number = styled.div`

  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  @media screen and (max-width: 980px) {
    &:last-child {
      grid-column-end: -2;
    }
  }

  @media screen and (max-width: 640px) {
    display: flex;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
  }
`
const CustomSituation = styled(CustomStep)`
  border: 1px solid #000000;
  margin: 0 10px;
  width: 315px;
  ${props =>
    props && props.active && `
    background: #FFF9F2;
    border: 1px solid #000000;
    box-shadow: 5px 5px 0 0 #000000;
  `}

      @media screen and (max-width: 980px) {
        ${H4} {
          font-size: 16px;
          line-height: 28px;
          width: auto;
        }
      }

      @media screen and (max-width: 640px) {
        ${H4} {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
        }
      }
`

const Situation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${CustomStepsContainer} {
    justify-content: center;
    width: auto;
  }

  ${Step} {
    @media screen and (max-width: 640px) {
      width: 150px
    }
  }
  ${StepContent} {
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    height: auto;
    display: flex;
    flex-direction: column;

    &:after {
      content: none;
    }

    @media screen and (max-width: 980px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: flex-start;
      padding: 50px 32px;
    
    }
    @media screen and (max-width: 640px) {
      width: auto;
      padding: 0px 20px;
      flex-direction: row;
    }

    ${ListImage} {
      @media screen and (max-width: 980px) {
      display:block;
      with: 220px;
      }
    }
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 30px;

  @media screen and (max-width: 980px) {
    grid-template-columns: auto auto;
    grid-gap: 19px;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: auto;
    grid-gap: 19px;
  }
`
const CardContainer = styled.div`
  opacity: 1;
  &.FadeIn{
    animation: ${FadeIn} 1s ease forwards 1s;
    ${props =>
    props && props.index + 1 && `
      animation-delay: ${props.index * 0.2}s;
      opacity: 0;
      `
  }
`
const Card = styled.div`
  background: #fff;
  border: 1px solid #000;
  box-shadow: 5px 5px 0 0 #F2F2F2;
  padding: 32px 24px;
  position: relative;

    
  }
  ${H4} {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
    @media screen and (max-width: 640px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 30px;
    width: 320px;
    overflow: hidden;

    @media screen and (min-width: 981px) {
      min-height: 80px;
    }

    @media screen and (max-width: 980px) {
      width: auto;
    }
    @media screen and (max-width: 980px) {
      width: auto;
    }

    &.collapse{
      @media screen and (max-width: 640px) {

        animation: ${collapse} 1s ease forwards;
      }
    }

    &.expand{
      @media screen and (max-width: 640px) {

        animation: ${expand} 1s ease forwards;
      }
    }
  }
`
const Tag = styled.span`
  text-transform: uppercase;
  background: #BDE9FF;
  border: 1px solid #000000;
  border-radius: 12px;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  padding: 2px 10px;
  position: absolute;
  bottom: 20px;
`

const StepHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
  margin-top: 40px;
  @media screen and (max-width: 980px) {
    align-items: baseline;
    flex-direction: column;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 40px;
  }

  ${H4} {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom:16px;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }

  svg {
    margin: 0 7px;
    @media screen and (max-width: 640px) {
      width: 118px;
      margin: 0 auto;
    }
  }
`
const Right = styled.div`
  @media screen and (max-width: 980px) {
    margin-top: 26px;
  }

  @media screen and (max-width: 640px) {
    margin: 0 auto;
    margin-top: 26px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 17px;
    grid-row-gap: 6px;
  }
`
const Left = styled.div`
width: 40%;
@media screen and (max-width: 980px) {
  width: 70%;
  p {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  width: auto;
  text-align: center;
  p {
    width: auto;
    
  }
}
  `

const NextButton = styled.span`
  position: absolute;
  width: 44px;
  top: 300px;
  right: -18px;
`

const PreviousButton = styled.span`
  position: absolute;
  width: 44px;
  top: 300px;
  left: -18px;
`
const InvalidNext = styled(Invalid)`
  transform: rotate(180deg);
`
const Prev = styled(Next)`
  transform: rotate(180deg);
`
const Arrow = styled(Angle)`
  width: 18px;
  height: 20px;
  position: relative;
  top: 3px;
  display: none;
  @media screen and (max-width: 640px) {
    display: inline;
  }
`
const ArrowUp = styled(Arrow)`
  transform: rotate(90deg);
`
const ArrowDown = styled(Arrow)`
  transform: rotate(-90deg);
`

const H4D = styled(H4)`
@media screen and (max-width: 640px) {
  display: none;
}
`

const H4M = styled(H4)`
display: none;
@media screen and (max-width: 640px) {
  display: block;
}
`
const Image = styled(Img)`
  width: 158%;
  bottom: 244px;
  @media screen and (max-width: 640px) {
    bottom: 65px;
  }
  ${props =>
    props.isSpaced &&
    css`
      margin: 60px 0;
    `}

    ${props =>
    props.isSpacedBottom &&
    css`
        margin: 0 0 60px;
      `}

    ${props =>
    props.isSpacedTop &&
    css`
        margin: 60px 0 0;
      `}
`


export default function ProductPage({ data, location }) {
  const globalContext = useContext(GlobalContext)
  const [activestep, setactivestep] = useState(0)
  const [activeSituation, setActiveSituation] = useState(1)
  const [fadeIn, setFadeIn] = useState(false)
  const [prev, setPrev] = useState(false)
  const [next, setNext] = useState(true)
  const [card, setCard] = useState(null)
  const [cards, setCards] = useState([
    true, true, true, true, true, true
  ])
  const [kw, setKw] = useState(null)

  const stepsContent = [
    {
      header: 'Increase conversion ',
      subheader: 'With data from millions of sessions across 190+ countries, Checkin.com optimizes every aspect of the user experience with AI-driven technology to ensure high conversion in every device and jurisdiction. ',
      image: Conversion,
    },
    {

      header: 'Combine with any data',
      subheader: 'Any data you collect during the session or already sits in your backend can be used at any point in the verification flow, allowing you to improve the user experience and automation while leveraging the data you already own.',
      image: Combine,

    },
    {
      header: '100% control over branding and UX',
      subheader: 'Enable a seamless and engaging experience for your customers to ensure high conversion without compromising your brand. Every aspect of the flow is 100% customizable and easily adjusted through our powerful backoffice.',
      image: Control,

    },
  ]
  const situationContent = [
    {
      header: {
        title: "Easy migration from your existing platform with no backend changes",
        description: "Your new setup will re-use the existing data mapping to make the switch effortless.",
        images: [<Onfido />, <Jumio />, <Sumsub />, <Custom />]
      },
      list: [
        {
          title: '1. Get a walkthrough',
          description: 'Talk to one of our experts to let us know what your current challenges are and get an intro of the platform.',
          tag: 'Takes ~30 minutes'
        },
        {
          title: '2. See your improved setup',
          description: 'You will receive a custom demo of the improved experience in your site or app.',
          tag: 'No strings attached'
        },
        {
          title: '3. Select pricing & volume',
          description: 'Our pricing is clear and transparent and if you have an existing contract we offer a price match guarantee.',
          tag: 'Price match guarantee'
        },
        {
          title: '4. Tech onboarding',
          description: 'We create a custom integration that re-uses the config from your existing setup, making it effortless to switch.',
          tag: 'Direct tech support'
        },
        {
          title: '5. Go live',
          description: 'Once integrated and tested, you are ready to go live with your improved setup.',
          tag: 'We monitor performance'
        },
        {
          title: '6. Continuous improvements',
          description: 'You receive detailed reporting, global updates and new releases each month which improves your performance.',
          tag: 'Global updates'
        }
      ]
    },
    {
      header: {
        title: "Get a solution that covers all your needs and works out of the box",
        description: "Expert help in every step of the way from the kickoff to launch.",
        images: [<Compliance2 />, <Localization />, <TechSetup />, <DataColl />]
      },
      list: [
        {
          title: '1. Get a walkthrough',
          description: 'Talk to one of our experts to let us know what your current challenges are and get an intro of the platform.',
          tag: 'Takes ~30 minutes'
        },
        {
          title: '2. See your new setup',
          description: 'You will receive a custom demo of the implementation of checkin.com in your site or app. ',
          tag: 'No strings attached'
        },
        {
          title: '3. Select pricing & volume',
          description: 'We offer transparent off-the-shelf pricing for common use cases and custom for big volumes.',
          tag: 'Price match guarantee'
        },
        {
          title: '4. Tech onboarding',
          description: 'You get a custom integration based on your existing or planned architecture to make integration easy as pie.',
          tag: 'Direct tech support'
        },
        {
          title: '5. Go live',
          description: 'Once integrated and tested, you are ready to go live with your improved setup.',
          tag: 'We monitor performance'
        },
        {
          title: '6. Continuous improvements',
          description: 'You receive detailed reporting, global updates and new releases each month which improves your performance.',
          tag: 'Global updates'
        }
      ]
    }
  ]

  const DynamicCopy = {
    "default": {
      "Headline": "Global solution for verifying<br /> documents & identities",
      "Subheading": "Speed up and secure your onboarding by letting your customers use the camera to take selfies and scan documents.",
      "Value prop 1": "Cover 13,000+ documents from over 190 countries",
      "Value prop 2": "Give your customers a seamless experience",
      "Value prop 3": "Increase conversion across all devices"
    },
    "age-verification-software": {
      "Headline": "Lightning-fast & secure<br /> Age Verification Software",
      "Subheading": "Speed up & secure your onboarding with AI-driven age verification.",
      "Value prop 1": "Cover 13,000+ documents from over 190 countries",
      "Value prop 2": "Set a minimum age limit of 18, 21 or any other age",
      "Value prop 3": "Give your customers a seamless experience"
    },
    "age-verification-api": {
      "Headline": "Powerful yet easy-to-use<br /> Age Verification API",
      "Subheading": "Easily integrate via API, collect needed data & start verifying your customers' age in no time",
      "Value prop 1": "Get every solution for accurate age verification",
      "Value prop 2": "Set minimum age limit of 18, 21 or any other age",
      "Value prop 3": "Customize the design of user journey"
    },
    "age-verification-service": {
      "Headline": "Age Verification Service<br /> that protects your business",
      "Subheading": "Speed up and secure your onboarding with AI-driven age verification.",
      "Value prop 1": "Get every solution for accurate age verification",
      "Value prop 2": "Set minimum age limit of 18, 21 or any other age",
      "Value prop 3": "Customize the design of user journey"
    },
    "age-verification-sdk": {
      "Headline": "Powerful and flexible<br /> Age Verification SDK",
      "Subheading": "Easy integration using SDK, collect needed data & start verifying your customers' age in no time",
      "Value prop 1": "Get every solution for accurate age verification",
      "Value prop 2": "Set a minimum age limit of 18, 21 or any other age",
      "Value prop 3": "Customize the design of user journey"
    },
    "document-scanning-api": {
      "Headline": "Powerful and flexible<br /> Document Verification API",
      "Subheading": "Speed up & secure your onboarding with AI-driven ID Document Scanning.",
      "Value prop 1": "Cover 13,000+ documents from over 190 countries",
      "Value prop 2": "Create multiple verification flows",
      "Value prop 3": "Increase conversion across all devices"
    },
    "id-scanning": {
      "Headline": "Powerful & Flexible<br /> ID Scanning Software",
      "Subheading": "Speed up and secure your onboarding by letting your customers use the camera to take selfies and scan documents.",
      "Value prop 1": "Cover 13,000+ documents from over 190 countries",
      "Value prop 2": "Give your customers a seamless experience",
      "Value prop 3": "Increase conversion across all devices"
    },
    "id-verification-api": {
      "Headline": "Powerful yet easy-to-use<br /> ID Verification API",
      "Subheading": "Integrate via API for AML KYC Checks, collect needed data & start verifying your customers in no time",
      "Value prop 1": "Cover 13,000+ documents from over 190 countries",
      "Value prop 2": "Create multiple verification flows",
      "Value prop 3": "Increase conversion across all devices"
    },
    "identity-verification-sdk": {
      "Headline": "Powerful and Flexible<br /> Identity Verification SDK",
      "Subheading": "Easily integrate Identity Verification using SDK for AML KYC Compliance, ID Checks, & Onboarding.",
      "Value prop 1": "Get every solution for fast & cost-efficient KYC",
      "Value prop 2": "Create multiple verification flows",
      "Value prop 3": "Customize the design of user journey"
    },
    "identity-verification": {
      "Headline": "Global solution for<br /> ID and Identity Verification",
      "Subheading": "Speed up your customer onboarding process, ensure AML compliance and reduce fraud.",
      "Value prop 1": "Get every solution for ID & Identity Verification",
      "Value prop 2": "Give your customers a seamless experience",
      "Value prop 3": "Increase conversion across all devices"
    },
    "kyc": {
      "Headline": "Powerful KYC Software for<br /> ID verification & Onboarding",
      "Subheading": "Speed up your customer onboarding process, ensure AML compliance and reduce fraud.",
      "Value prop 1": "Get every solution for fast & cost-efficient KYC",
      "Value prop 2": "Give your customers seamless experience",
      "Value prop 3": "Flexible integration through JS, SDK or API"
    },
    "kyc-api": {
      "Headline": "Easy-to-use KYC API for<br /> ID verification & Onboarding",
      "Subheading": "Integrate via API for AML KYC Checks, collect needed data & start verifying your customers in no time",
      "Value prop 1": "Get every solution for fast & cost-efficient KYC",
      "Value prop 2": "Create multiple verification flows",
      "Value prop 3": "Customize the design of user journey"
    },
    "kyc-sdk": {
      "Headline": "Powerful KYC SDK for<br /> ID verification & Onboarding",
      "Subheading": "Integrate using SDK for AML KYC Checks, collect needed data & start verifying your customers in no time",
      "Value prop 1": "Get every solution for fast & cost-efficient KYC",
      "Value prop 2": "Create multiple verification flows",
      "Value prop 3": "Customize the design of user journey"
    },
    "liveness": {
      "Headline": "Liveness Checks for<br /> fraud detection and prevention",
      "Subheading": "Make sure the users going through your onboarding process are \"live\" and provide their own IDs and selfies.",
      "Value prop 1": "Onboard more honest customers with confidence",
      "Value prop 2": "Give your customers a seamless experience",
      "Value prop 3": "Add extra security to you sign-up process"
    },
    "ocr-scanning": {
      "Headline": "OCR Scanning software<br /> that impress your customers",
      "Subheading": "Speed up your onboarding with AI-driven OCR Scanning software. Capture and extract data from any of ID type in seconds.",
      "Value prop 1": "Cover 13,000+ documents from over 190 countries",
      "Value prop 2": "Give your customers a seamless experience",
      "Value prop 3": "Increase conversion across all devices"
    }
  }

  const Icon = (props) => {

    const TheIcon = stepsContent[activestep].image
    return <TheIcon {...props} />
  }

  const toggle = (index) => {
    setCard(index)
    const newCard = cards.slice()
    newCard[index] = !newCard[index]
    setCards(newCard)
  }

  useEffect(() => {
    setPrev(activestep > 0)
    setNext(activestep < stepsContent.length - 1)
  }, [activestep]);

  useEffect(() => {
    const kwParam = new URLSearchParams(location.search).get("kw");
    if (kwParam) {
      setKw(kwParam)
    } else {
      setKw('default')
    }

    setActiveSituation(3)
  }, []);

  const getCopy = (key) => {
    return DynamicCopy.hasOwnProperty(kw) ? DynamicCopy[kw][key] : DynamicCopy['default'][key]
  }

  const selectSituation = (index) => {
    setFadeIn(false)
    setTimeout(() => {
      setFadeIn(true)
      setActiveSituation(index)
    }, 100)
  };

  return (
    <Container>
      <SEO
        title="Scan & verify 13,000+ ID documents in seconds"
        description="Get the fast and easy solution for scanning ID documents to verify your customers' identity. Flexible integration via front-end JS, SDK, or API to any platform."
        canonical="/id-scanner/"
        ogImage={require("/src/assets/images/og/og-id-scan-min.png").default}
      />
      <IndexIllustrationContainer>

        <HeaderAnimation />

      </IndexIllustrationContainer>

      <Section>
        <Sectionheader primary>
          <H1
            dangerouslySetInnerHTML={{ __html: kw ? getCopy("Headline") : null }}
          ></H1>
          <SectionDescription1>
            {kw ? getCopy("Subheading") : null}
          </SectionDescription1>
        </Sectionheader>

        <FeaturesContainer>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>{kw ? getCopy("Value prop 1") : null}</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>{kw ? getCopy("Value prop 2") : null}</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>{kw ? getCopy("Value prop 3") : null}</p>
          </Feature>
        </FeaturesContainer>
        <CTAText>
          See how it will work in your site or app
        </CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>

      <Section id="fast-easy">
        <Sectionheader primary>
          <H2>Fast and easy onboarding <br />
            with AI-driven ID scanning</H2>
          <SectionDescription1>
            Automate the optimal process for each customer to<br /> scale faster globally with <Link to={"/blog/kyc-outsourcing/"} >high cost-efficiency</Link>.
          </SectionDescription1>
        </Sectionheader>

        <FeaturesContainer2>
          <FeaturesColumn>
            <Feature2>
              <ImageComtainer>
                <Document />
              </ImageComtainer>
              <H4>Scan any ID or document</H4>
              <p>Online verification of ID cards, passports, driver’s licenses, residence permits. Over <Link to={"/id-scanner/documents"} >13,000+ document types</Link> supported.</p>
            </Feature2>
            <Feature2>
              <ImageComtainer>
                <LivenessCheck />
              </ImageComtainer>
              <H4>Face matching & liveness</H4>
              <p>Add security to prevent fraud by ensuring the user is “live” through completing a task via the camera.</p>
            </Feature2>
          </FeaturesColumn>
          <FeaturesColumn>
            <Feature2>
              <ImageComtainer>
                <Compliance />
              </ImageComtainer>
              <H4>AML & KYC Compliance</H4>
              <p>Let customers take selfies or perform live actions with their camera to instantly verify it’s a real person that matches their ID. </p>
            </Feature2>
            <Feature2>
              <ImageComtainer>
                <Extract />
              </ImageComtainer>
              <H4>Extract any data</H4>
              <p>Collect any data you need from documents your customer can easily access like
                ID cards, invoices or proof of residency.</p>
            </Feature2>
          </FeaturesColumn>
        </FeaturesContainer2>
      </Section>

      <Section id="coverage">
        <Numbers>
          <Number>
            <Countries />
            <p>Countries and Territories</p>
          </Number>
          <Number>
            <Languages />
            <p>Global Languages</p>
          </Number>
          <Number>
            <Doctype />
            <p>ID Document Types</p>
          </Number>
        </Numbers>
        <DocsButton><Link to={"/id-scanner/documents"} >See all supported documents</Link></DocsButton>
      </Section>


      <Section id="reliable-data">

        <IdScanner />

      </Section >

      <Section id="get-started">
        <Situation>
          <Sectionheader>
            <H2>Getting started is easy</H2>
            <SectionDescription4>
              We configure and customize the entire integration to fit into your existing architecture and minimize the work required on your end.
            </SectionDescription4>
          </Sectionheader>
          <Mobilecontainer>
            <SituationQST>Which situation are you in?</SituationQST>
            <CustomStepsContainer border={false}>
              <CustomSituation active={activeSituation === 0} onClick={() => selectSituation(0)}>
                <H4D>Already using a solution </H4D>
                <H4M>Using a solution </H4M>
              </CustomSituation>
              <CustomSituation active={activeSituation === 1} onClick={() => selectSituation(1)}>
                <H4D>No solution in place yet</H4D>
                <H4M>No solution yet</H4M>
              </CustomSituation>
            </CustomStepsContainer>
          </Mobilecontainer>
          {[0, 1].includes(activeSituation) && (
            <StepContent className={[0, 1].includes(activeSituation) ? "expand" : "collapse"}>
              <StepHeader>
                <Left>
                  <H4>{situationContent[activeSituation].header.title}</H4>
                </Left>
                <Right>
                  {situationContent[activeSituation].header.images.map(image => (image))}
                </Right>
              </StepHeader>
              <GridContainer>
                {situationContent[activeSituation].list.map((element, index) => (
                  <CardContainer
                    key={index}
                    index={index}
                    className={!!fadeIn ? 'FadeIn' : null}>
                    <Card
                      index={index}
                    >
                      <H4 onClick={() => toggle(index)}>{element.title} {cards[index] ? (<ArrowUp />) : (<ArrowDown />)}  </H4>
                      <p
                        className={cards[index] ? 'collapse' : 'expand'}
                      >{element.description}</p>
                      <Tag>{element.tag}</Tag>
                    </Card>
                  </CardContainer>
                ))}
              </GridContainer>
            </StepContent>
          )}

          <CTAText>Price match guarantee for new customers</CTAText>
          <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
            Get started
          </TalkToSalesButton>
        </Situation>
      </Section>

      <Section id="instant-value" >
        <Sectionheader>
          <H2>Instant value to your business</H2>
          <SectionDescription4>
            Checkin.com lets you leverage a wide array of<br /> technologies to <Link to={"/blog/kyc-outsourcing/"} >drive measurable business value</Link>
          </SectionDescription4>
        </Sectionheader>
        <Mobilecontainer className="InstantValue">
          <CustomStepsContainer border={true}>
            <CustomStep className="step1" active={activestep === 0} onClick={() => setactivestep(0)}>
              <H4>Increase conversion </H4>
            </CustomStep>
            <CustomStep className="step2" active={activestep === 1} onClick={() => setactivestep(1)}>
              <H4>Combine with any data</H4>
            </CustomStep>
            <CustomStep className="step3" active={activestep === 2} onClick={() => setactivestep(2)}>
              <H4>Control over branding and UX</H4>
            </CustomStep>
          </CustomStepsContainer>
        </Mobilecontainer>

        <SwitchTransition >
          <CSSTransition
            key={activestep}
            addEndListener={(node, done) =>
              node.addEventListener("transitionend", done, false)
            }
            classNames="fade"
          >

            <StepContent>

              <NextButton className="sliders" onClick={() => next ? setactivestep(activestep + 1) : null}> {next ? (<Next />) : (<InvalidNext />)}</NextButton>
              <PreviousButton className="sliders" onClick={() => prev ? setactivestep(activestep - 1) : null}>{prev ? (<Prev />) : (<Invalid />)}</PreviousButton>

              <List>
                <H4>{stepsContent[activestep].header}</H4>
                <p>{stepsContent[activestep].subheader}</p>
              </List>
              <ListImage>
                <Icon />
              </ListImage>
            </StepContent>

          </CSSTransition>
        </SwitchTransition>

      </Section>

      <Section id="every-team">
        <Sectionheader>
          <H2>Big wins for every team</H2>
          <SectionDescription4>
            AI-driven UX, easy integration and global overage creates value for all teams with a stake in your onboarding process.
          </SectionDescription4>
        </Sectionheader>

        <PartnerQuotes />

        <CTAText>Get a demo in your site or app</CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>

    </Container >
  )
}
