import React, { useState, useEffect, useContext } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"


import menuIcon from "../assets/images/menu-icon.svg"
import closeMenuIcon from "../assets/images/close-menu-icon.svg"
import CloseChatIcon from "../assets/images/close-chat-icon.svg"
import CheckinStartPage from "../assets/images/checkin_start_page.inline.svg"
import chatIcon from "../assets/images/chat-icon.svg"
import MenuData from "../utils/menu.js"
import Outsider from "../assets/images/outside_link.inline.svg"


import GlobalContext from "../context/global-context"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  pointer-events: none;
  overflow-y: scroll;
  ${props =>
    props.isOpen &&
    css`
      z-index: 2200;
    `}

  @media screen and (min-width: 981px) {
    display: none;
  }
`

const NavContainer = styled.div`
  padding: 100px 20px 0px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #b1f1d4;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  padding-bottom: 100px;
  box-sizing: border-box;
  z-index: -1;
  overflow-y: scroll;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      pointer-events: all;
    `};

  @media screen and (max-width: 980px) and (min-width: 641px) {
    padding-top: 200px;
    padding-bottom: 300px;
  }
`

const OpenButton = styled.button`
  display: flex;
  outline: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border: none;
  background: transparent;
  position: absolute;
  top: 0px;
  right: 20px;
  left: auto;
  pointer-events: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.2s ease;


  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: url(${menuIcon}) no-repeat center;
    box-sizing: border-box;
  }

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  ${props =>
    props.pushUp &&
    css`
      transform: translate3d(0, -75px, 0);
    `}
`

const CloseButton = styled(OpenButton)`
  justify-content: center;
  opacity: 0;
  pointer-events: none;

  &::after {
    background: url(${closeMenuIcon}) no-repeat center;
  }

  span {
    position: absolute;
    width: 80%;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`

const ChatButton = styled(OpenButton)`
  left: initial;
  right: 20px;
  bottom: 20px;
  top: auto;
  background: url(${chatIcon}) no-repeat center, #fff;

  &::after {
    content: "";
    display: none;
    background: none:
    
  }
`

const ChatCloseButton = styled.button`
  background: url(${CloseChatIcon}) no-repeat center, transparent;
  outline: none;
  box-shadow: none;
  border: none;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 10px;
  right: 10px;
`

const ChatWidgetContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: ${props => (props.chatOpen ? 1 : 0)};
  pointer-events: ${props => (props.chatOpen ? "all" : "none")};
  transition: opacity 0.2s ease;
  z-index: 9999999;

  #hubspot-conversations-inline-parent {
    height: 100%;
    width: 100%;
    display: none;

    iframe {
      height: 100%;
      width: 100%;
      border: none;
      display: ${props => (props.chatOpen ? "initial" : "none")};
    }

    &:first-of-type {
      display: initial;
    }
  }
`

const NavLink = styled.a`
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  width: 100%;
  padding: 8px 0;
  outline: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &.active {
    text-decoration: underline;
  }
  &:active {
    background: #FBD65D;
  }

  @media screen and (max-width: 375px) {
    padding: 10px 0;
  }
`

const GetStartedButton = styled.span`
  position: relative;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  outline: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &.active {
    text-decoration: underline;
  }

  @media screen and (max-width: 375px) {
    padding: 10px 0;
  }
  cursor: pointer;
  border: 1px solid #000 !important;
  background: #fbd65d;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  padding: 15px 24px;
  width: auto;

  @media screen and (max-width: 980px) and (min-width: 641px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 375px) {
    padding: 15px 24px;
  }
`

const CheckinContainer = styled.a`
position: absolute;
right: auto;
padding-left: 16px;
width: 100%;
height: 45px;
background: ${props => props.color};
transition: background 0.5s ease;
display: flex;
align-items: center;
pointer-events: all;
`

const MenuSection = styled.div`

  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  h4{
    margin-bottom: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
  }
`
const Outsider1 = styled(Outsider)`
margin-left: 6px;
position: relative;
top: 2px;
`

const FullScreenNav = ({ hideOnFullScroll, location }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const windowHeight = useSelector(state => state.global.windowHeight)
  const isMobile = useSelector(state => state.global.isMobile)
  const globalContext = useContext(GlobalContext)
  const [chatOpen, setChatOpen] = useState(false)

  const showButton = () => {
    debugger
    return location.includes('contact-us')
  }

  const hideMenu = () =>
    ["calculator"].find(v => location.includes(v))

  useEffect(() => {
    if (!isMobile) return
    if (window.HubSpotConversations) {
      if (!window.HubSpotConversations.widget.status().loaded) {
        window.HubSpotConversations.widget.load()
      }
    } else {
      window.hsConversationsOnReady = [
        () => {
          if (!window.HubSpotConversations.widget.status().loaded)
            window.HubSpotConversations.widget.load()
        },
      ]
    }

    return () => {
      if (
        window.HubSpotConversations &&
        window.HubSpotConversations.widget.status().loaded
      )
        window.HubSpotConversations.widget.remove()
    }
  }, [isMobile])

  useEffect(() => {
    const menuButton = document.querySelector('#mobile-menu-open-button');
    const handleClick = () => {
      setOpen(true)
    };
    // Add event listeners
    menuButton.addEventListener('click', handleClick);
    // Cleanup event listeners on unmount
    return () => {
      menuButton.removeEventListener('click', handleClick);
    };

  }, []);


  return (
    <>
      <Container style={{ minHeight: windowHeight }} isOpen={open}>
        <NavContainer visible={open}>
          {MenuData.map((section, index) => (
            <MenuSection key={index}>
              <h4>{section.title}</h4>
              {section.items.map((item, i) => (
                <NavLink
                  key={i}
                  href={item.link}
                  target={item.outsider && '_blank'}
                  onClick={() => setOpen(false)}>
                  {item.title} {item.outsider && <Outsider1 />}
                </NavLink>
              ))}
            </MenuSection>
          ))}

          <GetStartedButton
            data-cy="mobile-menu-get-started"
            aria-label="getStarted"
            onClick={() => {
              setOpen(false)
              globalContext.openGetStarted()
            }}
          >
            Get started
          </GetStartedButton>
        </NavContainer>
        <CheckinContainer
          aria-label="Checkin"
          href='/' color={open ? '#b1f1d4' : '#F9F9F8'}>
          <CheckinStartPage />
        </CheckinContainer>

        {isMobile && (
          <ChatWidgetContainer
            id="hs-chat-widget-container"
            chatOpen={globalContext.chatOpen}
          >
            <ChatCloseButton aria-label="toggle" onClick={() => globalContext.toggleChat()} />
          </ChatWidgetContainer>
        )}

        {!hideMenu() && (
          <>
            <OpenButton
              id="mobile-menu-open-button"
              aria-label="open"
              data-cy="mobile-menu-open-button"
              visible={!open}
            >
              <span />
              <span />
              <span />
            </OpenButton>

            <CloseButton aria-label="menu" visible={open} onClick={() => setOpen(false)}>
              <span />
              <span />
            </CloseButton>
          </>
        )}
        {/* <ChatButton
          visible={true}
          onClick={() => {
            dispatch(
              pushCustomDataLayerEvent({
                event: "chat_widgetbutton_click",
                eventCategory: "chat",
                eventAction: "open",
                eventLabel: "widget",
              })
            )
            globalContext.toggleChat()
          }}
        /> */}
      </Container>
    </>
  )
}

FullScreenNav.defaultProps = {
  hideOnFullScroll: true,
}

FullScreenNav.propTypes = {
  hideOnFullScroll: PropTypes.bool.isRequired,
}

export default FullScreenNav
