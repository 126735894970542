import React, { useState, useEffect, createRef, useContext, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import { useSelector } from "react-redux"
import lottie from 'lottie-web/build/player/lottie_light.min.js'
import { id_scanner_netherlands } from "../../utils/ocr"


import AngleInline from "../../assets/images/angle.inline.svg"
import GlobalContext from "../../context/global-context"
import ActionButton from "../action-button"

const FadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    display:none;
  }
`

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Angle = styled(AngleInline)`
  width: 30px;
  transition: all 0.2s ease;
  opacity: 0;
  ${props =>
    props.active &&
    css`
      fill: #000;
      opacity: 1;
    `}
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
`

const SideBar = styled.div`
  flex: 50%;
  align-self: baseline;
  @media screen and (max-width: 980px) {

    display: flex;
    flex-direction: column;
  }

`
const Header = styled.div`
  font-weight: 800;
  font-size: 34px;
  line-height: 42px;  
  @media screen and (max-width: 980px) {
    text-align: center;
  }
`
const Description = styled.div`
  margin: 20px 0 40px 0;
  @media screen and (max-width: 980px) {
    text-align: center;
  }
  @media screen and (max-width: 640px) {

    font-size: 16px;
    line-height: 28px;
  }
`
const Nav = styled.div``
const Animation = styled.div`
  flex: 50%;
  opacity: 0;
  display: flex;
  justify-content: center;
  

  &.fadeIn{
    animation: ${FadeIn} 1s ease forwards 1s;
  }

  svg {
    max-height: 609px;
    width: auto !important;
    float: right;
  }

  height: 625px;

  @media screen and (max-width: 980px) {
    display: flex;
    margin: 29px 0px;
    justify-content: center;
    align-self: center;
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    max-width: 320px;
  }
`

const AnimationMobile = styled(Animation)`
  background-size: 100% 97% !important;
`

const FeatureSelectorList = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 980px) {
    padding: 0;
  }
`



const FeatureItem = styled.li`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  overflow: hidden;
  box-sizing: border-box;
  & > span {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 0 18px;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: normal;
    margin: 0;
  }

  ${Angle} {
    opacity: 0;
    transition: all 0.2s ease;
  }

  @media screen and (max-width: 980px) {
    width: calc(100% + 20px);
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;
    font-size: 20px;
  }

  ${props =>
    props.isselected &&
    css`
      border: none;
      @media screen and (max-width: 980px) {
        max-height: 100%;
        min-height: 650px;

      }

      @media screen and (max-width: 640px) {
        max-height: 100%;
        min-height: 540px;

      }

      & > span {
        background: #fff;
        color: #000;
        border: 1px solid #040404;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.0853365);
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
      }

      ${Angle} {
        opacity: 1;
      }
    `}
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const CTAText = styled(H4)`
  margin-top: 30px;
  text-align: left;

  @media screen and (max-width: 980px) {
    width: 389px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin: 30px auto;

  }
`

const AnimationContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 50%;
  width: 585px;
  @media screen and (max-width: 640px) {
    width: auto;
  }
`

const CircleAnimationContainer = styled.div`
@media screen and (min-width: 981px) {
  display: none;
}
position: absolute;
display: flex;
align-self: center;
width: 100%;
align-items: center;
justify-content: center;
margin: 0 auto;
top: ${props => (props.rootMargin * 75) + 170}px;

&.fadeOut{
  animation: ${FadeOut} 1s ease forwards 1s;
}

&.fadeIn{
  animation: ${FadeIn} 1s ease forwards 1s;
}

@media screen and (max-width: 640px) {

  top: ${props => (props.rootMargin * 75) + 190}px;

}

svg {
  width: 20% !important;
  height: 100%;
  align-self: center;
}
`

const CircleAnimationContainerDesktop = styled.div`
@media screen and (max-width: 980px) {
  display: none;
}

position: absolute;
margin: 0 auto;
width: 100px;

&.fadeOut{
  animation: ${FadeOut} 1s ease forwards 1s;
}

&.fadeIn{
  animation: ${FadeIn} 1s ease forwards 1s;
}
`
export default function FastEasy({
  gridArea,
}) {
  const [selectedFeature, setSelectedFeature] = useState(0)
  let animationContainer = createRef();
  const globalContext = useContext(GlobalContext)
  const isMobile = useSelector(state => state.global.isMobile)
  const [isMobileOnly, setIsMobileOnly] = useState(false)
  const [animationLoaded, setAnimationLoaded] = useState(false)

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const circleAnimationRef = useRef(null);
  const circleAnimationDesktopRef = useRef(null);

  const openFeature = (index, ref) => {
    setSelectedFeature(index)
    if (isMobile)
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

      }, 400);
  }

  let anim, CircleAnimation = null;

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 640 ? setIsMobileOnly(true) : setIsMobileOnly(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    CircleAnimation = lottie.loadAnimation({
      container: isMobile ? circleAnimationRef.current : circleAnimationDesktopRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://assets6.lottiefiles.com/packages/lf20_jtgcoguf.json"
    });

    return () => CircleAnimation.destroy(); // optional clean up for unmounting
  }, []);

  useEffect(() => {
    setAnimationLoaded(false);
    anim = lottie.loadAnimation({
      container: isMobile ? document.getElementById('fastAnimation' + selectedFeature) : animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: id_scanner_netherlands[selectedFeature]
    });

    anim.addEventListener('DOMLoaded', () => {
      setAnimationLoaded(true);
    });

    return () => anim.destroy(); // optional clean up for unmounting
  }, [selectedFeature]);

  return (
    <Container gridArea={gridArea}>
      <SideBar>
        <Header>
          {
            isMobileOnly
              ? "Fast and easy onboarding\n with your customers camera"
              : "Fast and easy onboarding\n with your customers camera"}
        </Header>
        <Description
          dangerouslySetInnerHTML={{
            __html: isMobileOnly
              ? "Scan, verify and extract data from selfies or documents in engaging flows that your customer will actually enjoy."
              : "Scan, verify and extract data from selfies or documents in engaging flows that your customer will actually enjoy."
          }}>
        </Description>
        <Nav>
          <FeatureSelectorList>
            <CircleAnimationContainer className={animationLoaded ? "fadeOut" : null} rootMargin={selectedFeature} ref={circleAnimationRef} />
            <FeatureItem ref={ref1} isselected={0 === selectedFeature} >
              <span onClick={() => openFeature(0, ref1)}>
                ID & Document scanning
                <Angle />
              </span>
              {isMobile ? (<AnimationMobile className={animationLoaded ? "fadeIn" : null} background={animationLoaded ? "BackgroundCircle" : null} isselected={0 === selectedFeature} ref={animationContainer} id='fastAnimation0' />) : null}


            </FeatureItem>
            <FeatureItem ref={ref2} isselected={1 === selectedFeature}>
              <span onClick={() => openFeature(1, ref2)}>
                Face matching
                <Angle />
              </span>
              {isMobile ? (<AnimationMobile className={animationLoaded ? "fadeIn" : null} background={animationLoaded ? "BackgroundCircle" : null} isselected={1 === selectedFeature} ref={animationContainer} id='fastAnimation1' />) : null}

            </FeatureItem>
            <FeatureItem ref={ref3} isselected={2 === selectedFeature}>

              <span onClick={() => openFeature(2, ref3)}>
                Proof of address
                <Angle />
              </span>
              {isMobile ? (<AnimationMobile className={animationLoaded ? "fadeIn" : null} background={animationLoaded ? "BackgroundCircle" : null} isselected={2 === selectedFeature} ref={animationContainer} id='fastAnimation2' />) : null}
            </FeatureItem>
          </FeatureSelectorList>
        </Nav>

        <CTAText>
          Enable ID scanning in your product
        </CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>

      </SideBar>
      <AnimationContainer >
        <CircleAnimationContainerDesktop className={animationLoaded ? "fadeOut" : null} ref={circleAnimationDesktopRef} />
        {isMobile ? null : (<Animation background={animationLoaded ? "BackgroundCircle" : null} className={animationLoaded ? "fadeIn" : null} ref={animationContainer} />)}
      </AnimationContainer>


    </Container>
  )
}
