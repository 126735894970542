import styled, { keyframes } from "styled-components"

import Loader from "../assets/images/loader.inline.svg"

const loaderAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(Loader)`
  animation: ${loaderAnimation} 1s linear infinite;
`

export default Spinner
