import React, { useState, useEffect, createRef, useContext, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import { useSelector } from "react-redux"
import lottie from 'lottie-web/build/player/lottie_light.min.js'



import AngleInline from "../../assets/images/angle.inline.svg"
import GlobalContext from "../../context/global-context"
import ActionButton from "../action-button"
import Dotted from "../../assets/images/igaming/dotted-line.inline.svg"

const Angle = styled(AngleInline)`
  width: 30px;
  transition: all 0.2s ease;
  opacity: 0;
  ${props =>
    props.active &&
    css`
      fill: #000;
      opacity: 1;
    `}
`

const collapse = keyframes`
  from {
    height: auto;
  }

  to {
    height: 0px;
  }
`

const expand = keyframes`
  from {
    height: 0px;
  }

  to {
    height: auto;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  text-align: left;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
line-height: 30px;
height: auto;
`

const SideBar = styled.div`
  flex: 50%;
  align-self: baseline;
  @media screen and (max-width: 980px) {

    display: flex;
    flex-direction: column;
  }
`
const Nav = styled.div`
  margin-top: 50px;
`
const Animation = styled.div`
  flex: 50%;
  display: flex;
  justify-content: center;
  
  svg {
    max-height: 609px;
    width: auto !important;
    float: right;
  }

  @media screen and (max-width: 980px) {

    display: flex;
    margin: 29px 0px;
    justify-content: center;
    height: 487px;
  }

  @media screen and (max-width: 640px) {
    max-width: 300px;
    min-height: 300px;
    height: auto;

  }
`

const FeatureSelectorList = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  @media screen and (max-width: 980px) {
    padding: 0;
  }
`

const FeatureItem = styled.li`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  overflow: hidden;
  box-sizing: border-box;

  & > span {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 0 18px;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: normal;
    margin: 0;

  }

  ${Angle} {
    opacity: 0;
    transition: all 0.2s ease;
  }

  @media screen and (max-width: 980px) {
    width: calc(100% + 37px);
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;
    font-size: 20px;
  position: relative;

  }

  ${props =>
    props.isselected &&
    css`
      border-top: none;
      @media screen and (max-width: 980px) {
        max-height: 100%;
      }

      
      & > span {
        background: #fff;
        color: #000;
        border: 1px solid #040404;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.0853365);
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
      }

      ${Angle} {
        opacity: 1;
      }
    `}
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin: 30px auto;
  }
`
const FeatureDescription = styled.div`
  
 
  ${H4} {
    margin-bottom: 17px;
    font-size: 24px;
    width: 75%;

    @media screen and (max-width: 980px) {
      width: 60%;
    }
    @media screen and (max-width: 980px) {
      width: 85%;
    }
  }
  p {
    font-size: 16px;
    width: 81%;
    @media screen and (max-width: 980px) {
      width: 90%;
    }
  }
  &.collapse{
    animation: ${collapse} 0.2s ease forwards;
  }

  &.expand{
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    animation: ${expand} 0.2s ease forwards;
  }
`
const CustomDotted = styled(Dotted)` 
  width: 100%;
  height: 4px;
  @media screen and (max-width: 980px) {
    position: absolute;
    bottom: 0px;
  }
`
export default function FastEasy({
  gridArea,
}) {
  const [selectedFeature, setSelectedFeature] = useState(0)
  const ocrStepsContent = ["https://assets5.lottiefiles.com/packages/lf20_mk9tzmdi.json", "https://assets9.lottiefiles.com/packages/lf20_6ap9errk.json", "https://assets7.lottiefiles.com/packages/lf20_e55cscz8.json"]
  let animationContainer = createRef();
  const globalContext = useContext(GlobalContext)
  const isMobile = useSelector(state => state.global.isMobile)
  const [isMobileOnly, setIsMobileOnly] = useState(false)

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const openFeature = (index, ref) => {
    setSelectedFeature(index)
    if (isMobile)
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

      }, 300);
  }

  let anim = null;

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 640 ? setIsMobileOnly(true) : setIsMobileOnly(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {

    anim = lottie.loadAnimation({
      container: isMobile ? document.getElementById('animation' + selectedFeature) : animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: ocrStepsContent[selectedFeature]
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, [selectedFeature]);

  return (
    <Container gridArea={gridArea}>
      <SideBar>
        <Nav>
          <FeatureSelectorList>
            <FeatureItem ref={ref1} isselected={0 === selectedFeature} >
              <span onClick={() => openFeature(0, ref1)}>
                1. Request demo
                <Angle />
              </span>
              <FeatureDescription
                className={selectedFeature !== 0 ? 'collapse' : 'expand'}
              >
                <H4
                  dangerouslySetInnerHTML={{
                    __html: isMobileOnly
                      ? "Let us prepare a demo<br /> with your own branding<br /> and data collection"
                      : "Let us prepare a demo with your<br /> own branding and data collection"
                  }}
                >

                </H4>

                <p>
                  Forget about generic demos and PDF's - we will show you exactly how Checkin.com will look with your own branding, features and local adaptations for any market you target.
                  <br />
                  <br />
                  Just supply us with a URL to your existing site/app, or let us know the requirements for what you are about to build.
                </p>

                {isMobile ? (<Animation ref={animationContainer} id='animation0' />) : null}



              </FeatureDescription>
              {selectedFeature == 1 ? null : (<CustomDotted />)}
            </FeatureItem>
            <FeatureItem ref={ref2} isselected={1 === selectedFeature}>
              <span onClick={() => openFeature(1, ref2)}>
                2. Try the new experience
                <Angle />
              </span>
              <FeatureDescription
                className={selectedFeature !== 1 ? 'collapse' : 'expand'}
              >

                <H4
                  dangerouslySetInnerHTML={{
                    __html: isMobileOnly
                      ? "Try the improved flow<br /> and see why your<br /> customers will love it"
                      : "Try the improved flow and see<br /> why your customers will love it"
                  }}
                ></H4>

                <p>
                  Based on your existing (or planned) onboarding flow, we configure an improved version based on our specialized technologies and millions of data points.
                  <br />
                  <br />
                  The demo is an interactive prototype with all the steps, data collection and localization you need.
                </p>

                {isMobile ? (<Animation ref={animationContainer} id='animation1' />) : null}

              </FeatureDescription>
              {selectedFeature == 2 ? null : (<CustomDotted />)}
            </FeatureItem>
            <FeatureItem ref={ref3} isselected={2 === selectedFeature}>
              <span onClick={() => openFeature(2, ref3)}>
                3. Select pricing and launch
                <Angle />
              </span>
              <FeatureDescription
                className={selectedFeature !== 2 ? 'collapse' : 'expand'}
              >
                <H4>

                </H4>

                <H4
                  dangerouslySetInnerHTML={{
                    __html: isMobileOnly
                      ? "Save weeks of<br /> integration work<br /> by letting us do the<br /> heavy lifting"
                      : "Save weeks of integration work<br /> by letting us do the heavy lifting"
                  }}
                ></H4>
                <p>
                  Our integration is flexible and connects through API, JS or our SDK to any system you use.  If you’re on any of the larger platforms like Aspire or Everymatrix we offer a pre-configured integration. Talk to us for a full list.
                  <br />
                  <br />
                  You will receive a custom integration guide and we map the data to any endpoints you require.
                </p>

                {isMobile ? (<Animation ref={animationContainer} id='animation2' />) : null}

              </FeatureDescription>
              <CustomDotted />
            </FeatureItem>
          </FeatureSelectorList>
        </Nav>

        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Request demo
        </TalkToSalesButton>

      </SideBar>
      {isMobile ? null : (<Animation ref={animationContainer} />)}


    </Container>
  )
}
