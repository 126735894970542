import React, { useContext, useState } from "react";
import styled from "styled-components"

import ActionButton from "../action-button"
import GlobalContext from "../../context/global-context"
import Add from "../../assets/images/add.svg"
import Tick from "../../assets/images/tick.svg"


const Container = styled.div`
    grid-area: volume;

    .column-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 32px;
    }

    .checkbox-container {
        height: 41px;
        padding: 7px 15px; 
        border-radius: 4px; 
        border: 1px solid rgba(0, 0, 0, 0.20); 
        background: #FFF;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center; 
    }

    .label-container {
        display: flex;
        align-items: center;
    }

    .checkbox-container:hover {
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #F9F9F8;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.95); 
    }

    
    .checked {
        border: 1px solid #35D6A6;
        background: #E9FFF8; 
        box-shadow: none;
    }

    label {
        display: flex;
        flex-direction: row;
        align-items: center;
        color:  #000020;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        
    }
  .checkbox-label {
    margin-left: 5px; /* Add spacing between checkbox and label */
  }

  @media screen and (max-width: 980px) {
    .column-container {
        grid-template-columns: 1fr;
    }
}

  @media screen and (max-width: 640px) {


    width: 100vw;
    justify-self: center;
    h2,p {
    padding: 0 16px;
    }
    
    
}
  
`
const FlexWrap = styled.div`
    display: flex;
    flex-direction: column;
    color: #0D0D0D;

    h2{
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 24px;
    }
     p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 24px;
        height: 85px;
     }
    .checks {
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 16px;
    }

    @media screen and (max-width: 980px) {
        h2{
            margin-bottom: 16px;
        }
        p {
            height: auto;
            margin-bottom: 0px;
        }
        .checks {
            margin: 32px 0;
        }
    }
    @media screen and (max-width: 640px) {

    .checks{
        flex-wrap: nowrap;
        width: 100vw;
        overflow: scroll;
        padding: 10px 16px;
         -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .checks::-webkit-scrollbar {
        display: none;
    }

    .checks > * {  /* Target all child elements */
        flex-shrink: 0;
    }
}
`
const TalkToSalesButton = styled(ActionButton)`
    height: 56px; 
    margin-top: 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding: 12px 40px;

    ${props => props.nobackground && `background-color: #FFF;`}

    @media screen and (max-width: 980px) {
        margin: 30px auto;

    }
`
const Scanning = styled(FlexWrap)``
const Lookups = styled(FlexWrap)``
const Addons = styled(FlexWrap)``
const CTAs = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    color: rgba(13, 13, 13, 0.80);
    text-align: center;
    font-size: 20px;
    font-weight: 400; 
  }

  .buttons {
    margin-top: 16px;
    column-gap: 32px;
    display: flex;
  }

  @media screen and (max-width: 980px) {
    margin-top: 0px;
    align-items: baseline;

    .buttons {
        margin-top: 0px;
    }
  }

  @media screen and (max-width: 640px) {
    .buttons {
        flex-direction: column;
        width: 100%;
        padding: 0 16px;
        button {
            margin: 16px 0;
            margin-bottom: 0px;
        }
    }
  }

  
`

const scanning = [
    'ID check',
    'Liveness',
    'OCR',
    'Face matching',
    'MRZ',
    'Analytics',
    'Barcode reader',
    'Age estimation'
]

const lookups = [
    'AML Watchlists',
    'PEP and sanctions',
    'SSN',
    'Official records',
    'Adverse media',
    'Credit check',
]

const addons = [
    'Proof of address',
    'NFC',
    'Deepfake protection',
    'Signature',
    'Bot detection',
    'Storage vault',
    'Face similarity search',
    'Geo-block'
]

const Checkbox = ({ name }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <div className={`checkbox-container ${isChecked ? 'checked' : ''}`}>
            <input
                hidden
                type="checkbox"
                id={name}
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
            <label htmlFor={name}>
                <div className="label-container">
                    {isChecked ? (<img alt="tick" src={Tick} />) : (<img alt="add" src={Add} />)} <span>{name}</span>
                </div>
            </label>
        </div >
    );
};


const Volume = () => {
    const globalContext = useContext(GlobalContext)

    return (
        <Container>
            <div className="column-container">
                <Scanning>
                    <h2>ID scanning and liveness</h2>
                    <p>Scanning and verify any of 14,000+ documents from 190+ countries with native localization and liveness.</p>
                    <div className="checks">{scanning.map((name, index) => (<Checkbox key={name + index} name={name} />))}</div>
                </Scanning>
                <Lookups>
                    <h2>Database and registry lookups</h2>
                    <p>Validate and cross-check data from your flow against government and third-party databases. </p>
                    <div className="checks">{lookups.map((name, index) => (<Checkbox key={name + index} name={name} />))}</div>
                </Lookups>
                <Addons>
                    <h2>Add-ons</h2>
                    <p>Signature checks, NFC, proof of address, 1-1 or 1-n face matching</p>
                    <div className="checks">{addons.map((name, index) => (<Checkbox key={name + index} name={name} />))}</div>
                </Addons>
            </div>
            <CTAs>
                <p> Submit your details to calculate the optimal pricing for your project!</p>
                <div className="buttons">
                    <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
                        Get pricing
                    </TalkToSalesButton>
                    <TalkToSalesButton nobackground onClick={() => globalContext.openGetStarted()}>
                        Talk to sales
                    </TalkToSalesButton>
                </div>
            </CTAs>
        </Container>
    )
}

export default Volume;