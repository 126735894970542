import styled from "styled-components"
import LayoutGrid from "../layout-grid"

const Grid = styled(LayoutGrid)`
  grid-template-columns: auto repeat(12, 68px) auto;
  grid-gap: 30px;
  padding-top: 120px;
  z-index:1;
 
 
  @media screen and (max-width: 980px) {
    grid-template-columns: auto repeat(8, 68px) auto;
    grid-gap: 20px;
    overflow: hidden;
    padding-top: 50px;

  }

  @media screen and (max-width: 640px) {
    grid-template-columns: auto 147px 147px auto;
    grid-gap: 45px 25px;
    overflow: hidden;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: auto 147px 147px auto;
    grid-gap: 21px;
    grid-row-gap: 40px;
    overflow: hidden;
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: auto 132px 132px auto;
    grid-gap: 0px;
    grid-row-gap: 40px;
    overflow: hidden;
  }

  grid-template-areas:
    ". . .  header header header header header header header header  . . ."
    "header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation"
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    " . . partners partners partners partners partners partners partners partners partners partners  . ."
    " . . face face face face face face face face face face . ."
    " . . localized localized localized localized  localized localized  localized localized  localized localized  . ."
    " . . global global global global global global global global global global . ."
    ". . . . . . . . . . . . . ."

    " . hubspot hubspot hubspot hubspot hubspot hubspot hubspot hubspot hubspot hubspot hubspot hubspot ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ;

  @media screen and (max-width: 980px) {
    grid-template-areas:
    ". . header header header header header header . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    "header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation"
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    " .  partners partners partners partners partners partners partners partners  ."
    " . face face face face face face face face  ."
    " .  localized localized  localized localized  localized localized  localized localized ."
    " . global global global global global global global global ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    " . hubspot hubspot hubspot hubspot hubspot hubspot hubspot hubspot ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ;
   
  }

  @media screen and (max-width: 640px) {
    grid-template-areas:
    ".  header header  ."
    " . . . ."
    " . . . ."
    " . . . ."
    " . . . ."
    " . . . ."
    "header-animation header-animation header-animation header-animation"
    " . . . ."
    " . . . ."
    ". partners partners ."
    ". face face  ."
    " . localized localized ."
    " . global global ."
    " . . . ."
    " . . . ."
    " . . . ."
    " hubspot hubspot hubspot hubspot "
    " . . . ."
    " . . . ."
    " . . . ."
    " . . . ."
    ;
  }
  
`

export default Grid
