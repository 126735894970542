import React, { createRef, useEffect } from "react"
import styled from "styled-components"

import animationData from "../../assets/images/botlens/botlens-animation.json"

import lottie from 'lottie-web/build/player/lottie_light.min.js'


const AnimationContainer = styled.div`
grid-area: slot-machine;
display: flex;
width: 100%;
justify-content: center;
img {
  width: 63%;
  height: 52% !important;
  bottom: 163px;
  position: absolute;
}

@media screen and (max-width: 980px) {

}

@media screen and (max-width: 640px) {

}

img {}


`

const Animation = styled.div`
  @media screen and (max-width: 640px) {

  }

`
export default function SlotMachine() {


  let animationContainer = createRef();
  let animation = null;

  useEffect(() => {
    animation = lottie.loadAnimation({
      container: animationContainer.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData

    });
  }, []);

  return (
    <AnimationContainer >
      <Animation ref={animationContainer} />
    </AnimationContainer>
  )
}
