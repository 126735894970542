import React from "react";
import styled from "styled-components";
import Kyc from "../../assets/images/kyc.inline.svg";
import Outcome from "../../assets/images/outcome.inline.svg";
import Secure from "../../assets/images/secure.inline.svg";


const Outer = styled.div`
grid-area: gateway;
`
const Container = styled.div`
display: flex;
@media screen and (max-width: 980px){
    flex-direction: column;
}
@media screen and (max-width: 640px){
    display: flex;
    flex-direction: column;
}
`
const Column = styled.div`
flex: 1;
color: rgba(0, 0, 0, 0.70);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px;
margin: 0px 15px;
&:last-of-type {
    margin-right: 0px;

}

@media screen and (max-width: 980px){
    margin-bottom: 40px;
    text-align: center;
}
`
const ColumnHeader = styled.h2`
margin-top: 10px;
color: #000000;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 30px;
margin-top: 32px;
margin-bottom: 16px;
`
const SubHeader = styled.h3`
color: #000000;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 28px;

@media screen and (max-width: 980px){
    margin-top:10px;
}
`
const Description = styled.div`
color: #0D0D0D;
margin: 0px 15px 80px 15px;
// margin-bottom: 80px;
// margin-right: 15px;
// margin-left: 15px;

h3 {
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 42px;
    margin-bottom: 15px;

    @media screen and (max-width: 460px){
        margin-bottom: 50px;
    }
}
p {
    max-width: 900px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
`

const Gateway = () => {
    return (
        <Outer>
            <Description>
                <h3>Your Gateway to Frictionless KYC</h3>
                <p>Checkin.com enables businesses to replace cumbersome, fragmented KYC processes with one streamlined, adaptive flow. Leveraging our modular platform, companies can effortlessly integrate comprehensive verification services – from ID scanning to bank verification – that adapt to each market with precise localization.</p>
            </Description>

            <Container>

                <Column>
                    <Kyc />
                    <ColumnHeader>End-to-end KYC flow</ColumnHeader>
                    <SubHeader>Comprehensive ID Verification</SubHeader> Quick document scan, facial match and liveness detection.
                    <SubHeader>Fraud PreventionAdvanced</SubHeader> bot and anti-fraud measures for secure transactions.
                    <SubHeader>Efficient Data Capture</SubHeader> Precise OCR and proof of address verification.
                    <SubHeader>Enhanced Security</SubHeader> Biometric scans an secure OTP for user authentication.
                </Column>
                <Column>
                    <Outcome />
                    <ColumnHeader>Real Business Outcomes</ColumnHeader>
                    <SubHeader>Increased Conversion Rates</SubHeader> Our optimized flows are designed to convert visitors into verified users effortlessly.
                    <SubHeader>Expansive Global Reach</SubHeader> Enable compliant KYC across 190+ countries in an instant
                    <SubHeader>Peak Customer Experience</SubHeader> We ensure a seamless user experience, optimized across all devices and touchpoints.
                    <SubHeader>Unmatched Security</SubHeader>With Checkin.com, protect your user data and comply with global security standards.
                </Column>
                <Column>
                    <Secure />
                    <ColumnHeader>Secure and scaleable solution</ColumnHeader>
                    <SubHeader>ISO 27001 Certified</SubHeader> Our commitment to security is backed by the highest standards.
                    <SubHeader>EU/US-based data processing</SubHeader> Choose the jurisdiction that best fits your needs, with the assurance of compliance and security.
                    <SubHeader>Proven at Scale</SubHeader> Millions of sessions processed, each one secured with end-to-end encryption, emphasizing our trusted platform's reliability.
                    <SubHeader>99.99% uptime</SubHeader> Reliable service that works 24/7/365.
                </Column>
            </Container>
        </Outer>
    );
}

export default Gateway; 