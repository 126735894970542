import React from 'react'
import styled, { css } from 'styled-components'
import GridElements from "../../components/common/grid-elements"
import Detect from "../../assets/images/face-recognition/detect.inline.svg"
import Compare from "../../assets/images/face-recognition/comparison.inline.svg"
import Analyze from "../../assets/images/face-recognition/analysis.inline.svg"

const Section = styled.section`
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
    grid-area: platform;

    @media screen and (max-width: 980px) {
        margin-bottom: 10px;
    }



    #compare {
        @media screen and (max-width: 980px) {
            grid-column: span 2;  /* Let the last div span both columns */
            justify-self: center;  /* Center the last div horizontally */
            width: auto;
        }
        @media screen and (max-width: 640px) {
            grid-column: span 1;
        }
      }
`

export default function Platform() {
    return (
        <Section >
            <GridElements
                gridTemlplateCol="1fr 1fr 1fr"
                gridTemlplateColTablet="1fr 1fr"

                columnGap='60px'
                data={[
                    {
                        image: <Detect />,
                        title: 'Detect face',
                        text: 'Our face recognition technology detects and analyses human faces from a visual input - document images, selfies, video, real-time streams and creates face vectors'
                    },
                    {
                        image: <Analyze />,
                        title: 'Facial analysis ',
                        text: 'The algorithm converts visual data into digital face vectors, which allows it to perform precise comparisons and estimations within millions of users without a need to store actual images'
                    },
                    {
                        image: <Compare />,
                        id: 'compare',
                        title: 'Face comparison',
                        text: '1-1 one face can be instantly compared to another face and get a comparison score; 1-N one face is compared to all the faces in your database'
                    }

                ]} />
        </Section>
    )
}
