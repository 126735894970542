import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import SEO from "../components/seo"

const Container = styled.div`
  flex: 1;
  background: #fff;
`

const List = styled.ul`
  padding: 40px;

  li {
    padding: 5px 0;
  }

  a {
    color: blue;

    &:hover {
      text-decoration: underline;
    }
  }
`

export default function FilesPage() {
  return (
    <>
      <Helmet
        meta={[
          {
            name: "robots",
            content: "noindex",
          },
        ]}
      />
      <SEO
        title="Regily - Documents"
        description="Regily Documents"
        canonical="/files"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />
      <Container>
        <List>
          <li>
            <a
              target="__blank"
              href="/documents/Regily_AB_-_Form_of_power_of_attorney.DOCX"
            >
              Power of attorney
            </a>
          </li>
          <li>
            <a
              target="__blank"
              href="/documents/Regily_AB_-_Fullmaktsformulär.DOCX"
            >
              Fullmaktsformulär
            </a>
          </li>
          <li>
            <a
              target="__blank"
              href="/documents/Regily_AB_-_Kallelse_årsstämma_2021_(slutversion).DOCX"
            >
              Kallelse årsstämma 2021 (slutversion)
            </a>
          </li>
          <li>
            <a
              target="__blank"
              href="/documents/ÅR-Regily-AB-med-revisionsberättelse.pdf"
            >
              ÅR Regily AB med revisionsberättelse
            </a>
          </li>
        </List>
      </Container>
    </>
  )
}
