
import React, { useState, useContext, useEffect, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import Bubble from "../../assets/images/id-scanner/bubble2.svg"
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import { useInView } from 'react-intersection-observer';


const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Container = styled.div`
    grid-area: integration;
    display: flex;
    justify-content: space-between;

    .buttons {
        display: flex;
        flex-direction: column;
        margin-right: 27px;
        width: 350px;
    }

    .button {
        display: flex;
        margin-bottom: 10px;
        padding-left: 0px;
        cursor: pointer;
        position: relative;
        margin-bottom: 32px;
        
        &.notSelected{
            opacity:0.5;
        }

        &.selected ${Icon} {
            background: #FACD38;
        }
    }

    .button:last-of-type {
        margin-bottom: 0px;
    }
    .text-container{
    }

    .progress-bar {
        height: 2px;
        width: 0;
        background-color: #FACD38;
        position: absolute;
        bottom: 0;
        left: 0;
        
    }

    .code-container {
        width: 736px;
        height: 390px;    
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #010101;
        pre {
            background: #010101;
            height: 100%;
            padding: 66px 52px;
            font-size: 16px; 
            code[class*="language-"], pre[class*="language-"] {
                text-shadow: none;
                }

            .token.operator {
            background: none;
            }
        }
    }

    @media screen and (max-width: 980px) {
        flex-direction: column-reverse;

        .buttons {
            width: 100%;
        }
        .button.notSelected{ 
            border-bottom: 1px solid rgba(0, 0, 0, 0.15); 
        }

        .code-container {
            width: 100%;
            height: 317px;
            margin-bottom: 40px;
            pre { 
            padding: 40px; 
            font-size: 14px;
            font-weight: 400;
            line-height: 143.6%;
            
            }
        }
    }

    @media screen and (max-width: 640px) {
        .code-container {
            height: 408px;
        }
    }

`

const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;  
    margin-bottom: 12px;

    @media screen and (max-width: 980px) {
        margin-bottom: 24px;
    }

    @media screen and (max-width: 640px) {
        font-size: 18px;
        line-height: 28px;
}

`
const Description = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    overflow: hidden;
    

    &.collapsed {
        max-height: 0;
        opacity: 0;
        transition: max-height 0.3s ease-out;
    }
    
    &.expanded {
        max-height: 200px; /* Adjust based on your content */
        opacity: 1;
        margin-bottom: 24px;
        transition: max-height 0.5s ease-out;
    }

    img {
        border-radius: 50%;
        height: 19px;
        margin-right: 8px;
        margin-top: 5px;
    }

    li {
        display: flex;
        margin-bottom: 5px;

    }
`


const integrations = [
    {
        id: 'integ1',
        title: 'Multiple SDKs',
        list: [
            ['Web with full device coverage for mobile and desktop', '#D098D7'],
            ['Native Android', '#B4EDFB'],
            ['React Native', '#FFC724'],
            ['Native iOS', '#B0F1D4']
        ],
        code: `1 import Checkin.com
2 ...
3 Checkin.comSDK.startVerificationFlow(
4         	apiUrl: "https://casinobarriere.sb.checkin.com.dev",
5         	auth: .jwt("eyJhbGciOiJIUzI1NiIsInR...jcnJUU"),
6         	flowName: "checkin.com-doc-selfie-liveness"
7 )
8 Checkin.comSDK.delegate = self

        `,
        next: 1
    },
    {
        id: 'integ2',
        title: 'API',
        list: [
            ['You collect the documents and send for verification', '#B0F1D4'],
            ['Cloud-hosted verifications via send-outs', '#D098D7']
        ],
        code: `'{ "application": { documents": [ { "issuingCountry":
"JPN", "documentType": "passport", "files": [ { "kind":
"front", "uri": "data:image/png;base64,aGVsbG8=" } ] } ]},
"verificationTypes": [ "data-extraction" ]}'        
        `,
        next: 2
    },
    {
        id: 'integ3',
        title: 'Verification results',
        list: [
            ['Fetch via API', '#B0F1D4'],
            ['Receive via Webhooks', '#FFC724'],
            ['Manually via your Backoffice', '#B4EDFB'],
            ['Fully customizable user journey', '#D098D7']
        ],
        code: `{
    "id": "5e62110968e3fb4d655756d1",
    "application": {
        "fields": [],
        "documents": [
            {
                "issuingCountry": "unknown",
                "documentType": "unknown",
                "files": [   
                    {
                        "id": "645cae2c6a9ba02a2daa1021",
                        "kind": "front",
                        "mediaType": "image/jpeg",
                        "uri": "https://[PARTNER].checkin.com.ee/files/proxy/images/17f70.....jpeg?t=168....442"
                    },
                    {
                        "id": "645cae2c6a9ba02a2daa1022",
                        "kind": "back",
                        "mediaType": "image/jpeg",
                        "uri": "https://[PARTNER].checkin.com.ee/files/proxy/images/fb133......jpeg?t=1683796112029&s=4fb...b247"
                    }
                ]
            }
        ],
        "selfie": {
            "files": []
        }
    },
    "processingState": "done",
    "verificationTypes": [
        "data-extraction"
    ],
    "metadata": {
        "platform": "API",
        "labels": {
            "detectionModel": "https://cdn.checkin.com.cloud/gecko/doc-detector/v15_4_10/uint8/model.json"
        },
        "ipAddress": "85.253.24.175",
        "country": "SWE",
        "city": "Stockholm",
        "userAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36",
        "savedRequestId": "0092b2d23215236b9e1c876efabadb8ab9b67eb8ebc29edf0ed27e1cadc2c3ed",
        "createdAt": "2023-05-11T08:58:15.605Z",
        "serverVersion": "v1.4.0",
        "livenessSchemaVersion": "0.5",
        "locale": "en",
        "flowName": "[FLOW]"
    },
    "additionalFiles": [],
    "overallResult": {
        "status": "approved",
        "comments": [
            {
                "service": "doc-check",
                "status": "approved",
                "comment": "No issues found."
            }
        ],
        "concerns": [],
        "validationDate": "2023-05-11T08:58:19.562Z"
    },
    "servicesResults": {
        "docCheck": {
            "serviceType": "doc-check",
            "verifier": "Doc-checker",
            "comment": "No issues found.",
            "processingState": "done",
            "status": "approved",
            "extracted": {
                "ocr": [
                    {
                        "category": "Document number",
                        "content": "SPECI2021",
                        "contentType": "string"
                    },
                    {
                        "category": "Date of expiry",
                        "content": "2031-08-02",
                        "contentType": "date"
                    }
                ],
                "mrz": [
                    {
                        "category": "Issue country",
                        "content": "NLD",
                        "contentType": "country",
                        "valid": true
                    },
                    {
                        "category": "Document number",
                        "content": "SPECI2021",
                        "contentType": "string",
                        "valid": true
                    }
                ],
                "nfc": [],
                "images": [
                    {
                        "kind": "front",
                        "uri": "https://[PARTNER].checkin.com.ee/files/proxy/images/d73c......jpeg?t=1683796112030&s=659....b53"
                    },
                    {
                        "kind": "back",
                        "uri": "https://[PARTNER].checkin.com.ee/files/proxy/images/d2132.....jpeg?t=1683796112030&s=3d33...4ed"
                    },
                    {
                        "kind": "barcode",
                        "uri": "https:/[PARTNER].checkin.com.ee/files/proxy/images/3dcb.....jpeg?t=1683796112030&s=a6a02...2c4"
                    },
                    {
                        "kind": "portrait",
                        "uri": "https://[PARTNER].checkin.com.ee/files/proxy/images/c9d2....jpeg?t=1683796112031&s=70da61...1a8"
                    },
                    {
                        "kind": "signature",
                        "uri": "https://[PARTNER].checkin.com.ee/files/proxy/images/e000....jpeg?t=1683796112031&s=e2359d...627"
                    },
                    {
                        "kind": "ghost-portrait",
                        "uri": "https://[PARTNER].checkin.com.ee/files/proxy/images/84ae....jpeg?t=1683796112031&s=25f472...f55"
                    },
                    {
                        "kind": "mrz",
                        "uri": "https://[PARTNER].checkin.com.ee/files/proxy/images/13f4....jpeg?t=1683796112031&s=1708b1...9f7"
                    }
                ]
            },
            "documentDataChecking": [
                {
                    "equal": true,
                    "valid": true,
                    "category": "Document number",
                    "conflicts": [],
                    "message": "Value is ok",
                    "status": "approved",
                    "ocr": "SPECI2021",
                    "mrz": "SPECI2021",
                    "nfc": "",
                    "barcode": ""
                },
                {
                    "equal": true,
                    "valid": true,
                    "category": "Date of expiry",
                    "conflicts": [],
                    "message": "Value is ok",
                    "status": "approved",
                    "ocr": "2031-08-02",
                    "mrz": "2031-08-02",
                    "nfc": "",
                    "barcode": ""
                }
            ],
            "dataExtractionConsistency": {
                "group": "dataExtractionConsistency",
                "description": "Consistency and validity of extracted data",
                "considers": [
                    {
                        "name": "documentValidation",
                        "type": "clear",
                        "status": "approved",
                        "description": "Data extracted from the document is consistent and valid."
                    }
                ]
            },
            "documentPhotoQuality": {
                "group": "documentPhotoQuality",
                "description": "Document photo quality",
                "considers": [
                    {
                        "name": "supportedDocument",
                        "type": "clear",
                        "status": "approved",
                        "description": "Document is supported for check."
                    }
                ]
            }
        }
    },
    "archivePolicy": {},
    "logs": [
        {
            "action": "application_created",
            "time": "2023-05-11T08:58:15.982Z",
            "user": "System",
            "comment": "The application is created"
        },
        {
            "action": "verifications_completed",
            "time": "2023-05-11T08:58:19.767Z",
            "user": "System",
            "comment": "Verification(s) are completed"
        },
        {
            "action": "application_processed",
            "time": "2023-05-11T08:58:20.406Z",
            "user": "System",
            "comment": "The application is processed"
        }
    ],
    "responseCode": 200
}     
        `,
        next: 0
    }
]

const IntegrationPlayer = () => {
    const [currentInteg, setCurrentInteg] = useState({ ...integrations[0] });
    const [nextInteg, setNextInteg] = useState(null);
    const [progress, setProgress] = useState(0);
    const intervalRef = useRef(null);
    const [progressRef, containerInView] = useInView({
        threshold: 0.5,
    });
    // useEffect(() => {
    //     intervalRef.current = setInterval(() => {
    //         setProgress((prev) => prev + 5);
    //     }, 500);

    //     return () => clearInterval(intervalRef.current);
    // }, []);

    // useEffect(() => {

    //     if (containerInView) {
    //         intervalRef.current = setInterval(() => {
    //             setProgress((prev) => prev + 5);
    //         }, 500);
    //     } else {
    //         clearInterval(intervalRef.current);
    //     }

    //     return () => clearInterval(intervalRef.current);
    // }, [containerInView]);

    // useEffect(() => {
    //     if (progress >= 100) {
    //         setProgress(0);
    //         setCurrentInteg((prev) => integrations[prev.next]);
    //     }
    // }, [progress]);

    useEffect(() => {
        Prism.highlightAll();
    }, [currentInteg]);

    const handleClick = (integ) => {
        setCurrentInteg(integ);

        // setProgress(0);
    };

    return (
        <Container>
            <div ref={progressRef} className="buttons">
                {integrations.map(integ => {
                    let isSelected = integ.id == currentInteg.id
                    return (
                        <div
                            key={integ.id}
                            className={`button ${isSelected ? 'selected' : 'notSelected'}`}
                            onClick={() => handleClick(integ)}
                        >

                            <div className="text-container">
                                <Title><p>{integ.title}</p></Title>
                                <Description className={` ${isSelected ? 'expanded' : 'collapsed'}`}>
                                    <ul>
                                        {integ.list.map((element, index) => (
                                            <li key={index} ><img style={{ backgroundColor: `${element[1]}` }} src={Bubble} /><p>{element[0]}</p> </li>
                                        ))}
                                    </ul>
                                </Description>
                                <div
                                    className="progress-bar"
                                    style={{
                                        width: `${isSelected ? progress : 0}%`,
                                        transition: isSelected ? 'width 1s linear' : 'width 0.2s ease-out'
                                    }}
                                ></div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="code-container">
                <pre >
                    <code className="language-javascript">
                        {currentInteg.code}
                    </code>
                </pre>
            </div>
        </Container>
    );
};



export default IntegrationPlayer;
