import React, { useState, useRef, useContext, useEffect } from "react"
import styled, { css } from "styled-components"
import { useSelector } from "react-redux"


import MiddleText from "./middle-text"
import "./styles.css";
import { useDetectOutsideClick } from "../../hooks/use-detect-outside-click";
import { Link } from "gatsby"

import FlagNether from "../../assets/images/igaming/flag-nether.inline.svg"
import FlagUk from "../../assets/images/igaming/flag-uk.inline.svg"
import FlagDk from "../../assets/images/igaming/flag-dk.inline.svg"
import FlagCa from "../../assets/images/igaming/flag-ca.inline.svg"
import FlagDe from "../../assets/images/igaming/flag-de.inline.svg"
import SelectorArrow from "../../assets/images/igaming/selector-arrow.inline.svg"
import ActionButton from "../../components/action-button"
import GlobalContext from "../../context/global-context"

import Kyc from "../../assets/images/canada/KYC.inline.svg"
import Pep from "../../assets/images/canada/PEP.inline.svg"
import Geo from "../../assets/images/canada/GEO.inline.svg"

import DataThumb from "../../assets/images/netherland/datathumb.inline.svg"
import PepThumb from "../../assets/images/netherland/pepthumb.inline.svg"
import MobileThumb from "../../assets/images/netherland/mobilethumb.inline.svg"

import Germany1 from "../../assets/images/igaming/germany-1.inline.svg"
import Germany3 from "../../assets/images/igaming/germany-3.inline.svg"
import MitIdLogo from "../../assets/images/igaming/mittid.png"





const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
`

const StepsContainer = styled.div`
  width: 978px;
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  position: relative;


  @media screen and (max-width: 980px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`


const CustomStepsContainer2 = styled(StepsContainer)`
border-bottom: 2px solid rgba(0, 0, 0, 0.04);

@media screen and (max-width: 980px) {
  display: flex;
}

@media screen and (max-width: 640px) {
  width: 610px;
  margin-bottom: 0px;
}
`
const CustomStepsContainer3 = styled(StepsContainer)`

  border-bottom: 2px solid rgba(0,0,0,0.04);

`

const Mobilecontainer = styled.div`
@media screen and (max-width: 640px) {
  overflow-x: auto;
    width: 371px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
}
`

const StepContent2 = styled.div`
  position: relative;
  width: 85%;
  height: 500px;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  margin-bottom: 66px;
  text-align: left;
  // border-bottom: 2px solid rgba(0, 0, 0, 0.04);

  @media screen and (max-width: 980px) {
    display: grid;
    width: 100%;
    height: 620px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: flex-start;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 25px;
    height: auto;
  }

`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`
const List = styled.div`

display: flex;
flex-direction: column;
justify-content: flex-start;
line-height: 30px;
width: 505px;
@media screen and (max-width: 980px) {
  width: 273px;
}

${H4}{
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  @media screen and (max-width: 980px) {
      font-size: 20px;
      line-height: 32px;
  }
}
p {
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width: 980px) {
    font-size: 14px;
    line-height: 24px;
  }
 }
 ul {
  margin-top: 15px;
  margin-left: 14px;
 }
@media screen and (max-width: 640px) {
  width: 100%;
  height: auto;
  ul {
    margin-left: 16px;
  }
}



li {
  font-size: 16px;
  line-height: 30px;
  padding-left: 10px;
}
}

`

const ListImage = styled.div`
  height: 70px;
  min-height: 70px;
  display: block;

  @media screen and (max-width: 980px) {
   svg {
    width: 310px;
   } 
  }
  @media screen and (max-width: 640px) {
    height: auto;
    margin: 0 auto;
   }
  

`

const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 240px;
  cursor: pointer;
  justify-content: center;

  

  ${H4} {
    margin: 15px auto;
    font-size: 20px;
    line-height: 30px;
  }

  ${Text} {
    line-height: 24px;
  }

  @media screen and (max-width: 980px) {
    &:last-child {
      grid-column: span 2;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    margin-top: 0;
    margin-bottom: 20px;
    grid-column: 1;
  }
`

const CustomStep2 = styled(Step)`
${H4} {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}
${props =>
    props.active &&
    css`
    border-bottom: 2px solid #000000;
    ${H4} {
      font-weight: 700;
    }
  `}


img {
  width: 69px;
  height: 69px;
}

@media screen and (max-width: 980px) {

  ${H4} {
    font-size: 14px;
    line-height: 22px;
    width: 174px;
  }

  &:last-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 640px) {
  width: 188px;
  margin-bottom:0px;
  ${H4} {
    font-size: 14px;
    line-height: 22px;
  }
}
`

const FeaturesButton = styled(ActionButton)`
  background: #fff;
  color: #000;
  border: 1px solid #000;
  outline: none;
  box-shadow: none;
  padding: 18px 40px;
  font-size: 18px;
  font-weight: 700;
  margin-top:30px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    border: 1px solid #A5A5A5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 5px ;
    left: 5px ;
    z-index: -1;
  }

  &:hover {
    box-shadow: 5px 5px 0 0 #000;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`

const Buttons = styled.div`
  display: flex;
  width: 55%;
  position: absolute;
  justify-content: space-around;
  bottom: 0px;

  

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  @media screen and (max-width:640px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;


    button, a {
      width: 100%;
    }
  }
`

export default function GetReady({
  gridArea,
}) {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [country, setCountry] = useState(1);
  const [activestep1, setactivestep1] = useState(0)
  const globalContext = useContext(GlobalContext)
  const [isMobileOnly] = useSelector(state => [state.global.isMobile])


  const steps1Content = [
    {
      country: "Germany",
      flag: FlagDe,
      steps: [
        {
          image: Germany1,
          description: `
            <p>Operators are responsible for ascertaining the player identification at the time of player registration. </p><br/>
            <b>KJM-approved verification methods offered:</b><br/>
            <ul>
              <li>Bank login + SCHUFA check</li>
              <li> Document scan + selfie + liveness check</li>
            </ul>
          `,
        },
        {
          image: DataThumb,
          description: `
            <p>Accurate user data is required to collect when a player signs up for an online casino in the Germany. </p><br/>
            <b>Required user data supported by Checkin.com:</b><br/>
            <ul>
              <li>Full name + birth/maiden name</li>
              <li>Date of birth</li>
              <li>Full address</li>
              <li>Nationality</li>
              <li>Bank login</li>
              <li>Deposit limits</li>
              <li>Consent to terms of use</li>
              <li>Logins (email / phone)</li>
            </ul>
          `,
        },
        {
          image: Germany3,
          description: `
            <p>Address enrichment by Deutsche post increase Schufa checks approval rate by enriching the address. It can enrich incomplete or misspelled addresses to improve Schufa’s match score. </p><br/>
            <b>Common use cases:</b><br/>
            <ul>
              <li>Double of misspelled house number</li>
              <li>Misspelled or incomplete street</li>
            </ul> 
          `
        }
      ]
    },
    {
      country: "Netherlands",
      flag: FlagNether,
      steps: [
        {
          image: DataThumb,
          description: `
            <p>Accurate user data is required to collect when a player signs up for an <a href='/industry-igaming/netherlands/'>online casino in the Netherlands</a>.</p><br/>
            <b>Required user data supported by Checkin.com:</b><br/>
            <ul>
              <li>Full name</li>
              <li>Date of birth</li>
              <li>Address of residence</li>
              <li>Type, number, date, and place of issue of a valid identity document</li>
              <li>BSN number (NL residents) or Place of birth (non-NL residents)</li>
              <li>IBAN</li>
              <li>Daily, monthly, and weekly deposit limits</li>
            </ul> 
          `
        },
        {
          image: PepThumb,
          description: `
            <p>Besides collecting data, Dutch regulation also states that the supplied user data needs to be checked and verified through trusted systems.</p><br/>
            <b>Required verifications supported by Checkin.com:</b><br/>
            <ul>
              <li>CRUKS check (returns unique CRUKS code)</li>
              <li>IBAN account holder verification</li>
              <li>Authentication (e.g. bank login or selfie, liveness check)</li>
              <li>Image of a valid ID document</li>
              <li>PEP check (Sanctions lists and politically exposed persons screening)</li>
            </ul> 
          `
        },
        {
          image: MobileThumb,
          description: `
            <p>Secure login is required to protect the player.</p><br/>
            <b>Required authentications/checks supported by Checkin.com:</b><br/>
            <ul>
              <li>2-factor authentication</li>
              <li>CRUKS code check</li>
            </ul> 
          `
        }
      ]
    },

    {
      country: "Canada",
      flag: FlagCa,
      steps: [
        {
          image: Kyc,
          description: `
            <p>Operators are responsible for ascertaining the player identification at the time of <a href='/industry-igaming/canada/'>player registration in Ontario, Canada.</a><br/><br/> The identification must be completed within 48 hours of opening an account. </p><br/>
            <b>Acceptable verification methods supported by Checkin.com:</b><br/>
            <ul>
              <li>Government-issued photo identification</li>
              <li>Credit file in Canada that has existed for at least 3 years</li>
            </ul> 
          `
        },
        {
          image: Pep,
          description: `
            <p>Players must attest at the time of registration if they are a PEP/HIO, a family member of PEP/HIO, or a close associate of PEP/HIO. </p><br/>
            <b>If they attest positive, the following data must be collected additionally: </b><br/>
            <ul>
              <li>Source of funds</li>
              <li>Source of wealth</li>
              <li>Office/position of the PEP</li>
              <li>Organization or institution name of the PEP</li>
              <li>Record of the nature of the relationship</li>
            </ul> 
          `
        },
        {




          image: Geo,
          description: `
          <p>Possibility to check the device location and set rules for allowed and restricted users. </p><br/>
          <b>Advantages of geo-location check:</b><br/>
          <ul>
            <li>Verified user current location</li>
            <li>Set the rules for restricted countries</li>
          </ul> 
        `
        }
      ]
    },
    {
      country: "Denmark",
      flag: FlagDk,
      steps: [
        {
          tab: 'MittID for<br/> signup & login',
          image: MitIdLogo,
          description: `
            <p>MitID enables a seamless flow, used for both signups and logins.  Based on if the user's UUID is recognized or not, the flow can dynamically change to continue registration or login the user.</p><br/>
            <p>MitID can be used as data enrichment and provide the user's name and date of Birth. The user's CPR number can be verified with the UUID from MitID. </p><br/>
            <b>From a verified CPR number, further data can be extracted:</b><br/>
            <ul>
              <li>Name</li>
              <li>Date of birth</li>
              <li>Address</li>
              <li>Citizenship</li>
              <li>Gender</li>
            </ul> 
          `
        }
      ]
    },
    {
      country: "United Kingdom",
      flag: FlagUk,
      steps: [
        {
          tab: 'Requirements<br/> for UK',
          image: Kyc,
          description: `
            <p>Accurate user data, identity verification and registry checks are required by the operator when a player signs up for an online casino in the UK.</p><br/>
            <b>Required solutions supported by Checkin.com:</b><br/>
            <ul>
              <li>Gambling commission notification</li>
              <li>Affordability check</li>
              <li>IDV check</li>
              <li>ID scan, in case of IDV check fails</li>
              <li>PEP and Sanctions screening</li>
              <li>Phone number verification via OTP SMS</li>
              <li>Address data enrichment Royal Mail's PAF</li>
            </ul> 
          `
        }
      ]
    }

  ]

  const Icon = (props) => {

    const TheIcon = steps1Content[country]['steps'][activestep1].image
    return <TheIcon {...props} />
  }

  const Flag = ({ country }) => {

    const TheIcon = steps1Content[country].flag
    return <TheIcon />
  }

  useEffect(() => {


    const onPageClick = e => {
      if (!e.target.className.includes('menu')) {
        setIsActive(false)
      }
    }

    window.addEventListener("click", onPageClick)

    return () => window.removeEventListener("click", onPageClick)
  }, [])

  return (
    <Container gridArea={gridArea}>

      <MiddleText
        headerText={
          isMobileOnly
            ? "Every solution for\n regulated markets in\n a single integration"
            : "Every solution for regulated\n markets in a single integration"}
      />

      <div className="container">
        <div className="menu-container">
          <button onClick={() => setIsActive(!isActive)} className="menu-trigger">
            <Flag country={country} />
            <span className="menu-country">{steps1Content[country].country}</span>
            <SelectorArrow className="menu-arrow" style={{
              "position": "absolute",
              "right": "15px"
            }}

            />
          </button>
          <nav
            ref={dropdownRef}
            className={`menu ${isActive ? "active" : "inactive"}`}
          >
            <ul>
              {steps1Content.map((listElement, index) => (
                <li
                  className={index === country ? "selected" : null}
                  onClick={() => { setCountry(index); setIsActive(!isActive) }}
                >
                  <Flag country={index} />
                  <span >{listElement.country}</span>
                </li>
              ))}

            </ul>
          </nav>
        </div>
      </div>

      <Mobilecontainer>
        {country <= 2 ? (
          <CustomStepsContainer2>
            <CustomStep2 active={activestep1 === 0} onClick={() => setactivestep1(0)}>
              {
                country == 1 ? (<H4>Required<br /> user data</H4>) : (<H4>KYC verification required <br /> during sign-up</H4>)
              }
            </CustomStep2>
            <CustomStep2 active={activestep1 === 1} onClick={() => setactivestep1(1)}>

              {
                country == 1 ? (<H4>Required user<br /> verifications</H4>) : (<H4>PEP/HIO screening <br /> required during sign-up</H4>)
              }
            </CustomStep2>
            <CustomStep2 active={activestep1 === 2} onClick={() => setactivestep1(2)}>

              {
                country == 1 ? (<H4>Login <br />  requirements</H4>) : (<H4>Geo-location <br /> check</H4>)
              }
            </CustomStep2>
          </CustomStepsContainer2>
        ) :
          <CustomStepsContainer3>
            <CustomStep2 active={activestep1 === 0} onClick={() => setactivestep1(0)}>
              {
                <H4 dangerouslySetInnerHTML={{ __html: steps1Content[country]['steps'][activestep1].tab }}></H4>
              }
            </CustomStep2>
          </CustomStepsContainer3>

        }

      </Mobilecontainer>

      <StepContent2>
        <List>
          <div dangerouslySetInnerHTML={{ __html: steps1Content[country]['steps'][activestep1].description }} ></div>
          <Buttons>
            {
              [1, 2].includes(country) ?
                (<Link
                  state={{
                    modal: true,
                  }}
                  to={`/igaming/features?country=${steps1Content[country].country}`} >
                  <FeaturesButton>
                    See all features
                  </FeaturesButton>
                </Link>) :
                null
            }

            <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
              Get started
            </TalkToSalesButton>
          </Buttons>

        </List>
        <ListImage>
          {country == 3 ? <img src={MitIdLogo} /> : <Icon />}
        </ListImage>
      </StepContent2>

    </Container>
  )
}
