import React, { useState, useContext } from "react"
import styled, { css } from "styled-components"
import { navigate } from "gatsby"
import closeIcon from "../../assets/images/close-icon.svg"
import GreenCircle from "../../components/green-circle"

import SEO from "../../components/seo"

import GlobalContext from "../../context/global-context"

import {
  H2,
  PageContainer,
} from "../../components/common"

const CustomPageContainer = styled(PageContainer)`
  width: 945px !important;

  ${H2}{
    text-align: center;
  }

  @media screen and (max-width: 980px) {
    width: 614px !important;
  }

  @media screen and (max-width: 640px) {
    width: 345px !important;
  }
`

const CloseButton = styled.button`
  outline: none;
  box-shadow: none;
  background: url(${closeIcon}) no-repeat center, transparent;
  border: none;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 40px;
  top: 34px;
  height: 16px;
  width: 21px;
  cursor: pointer;
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  background: #fff;
  width: 750px;
  margin: 0px auto 0 auto;
  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    font-size: 18px;
    margin: 32px auto 0 auto;
  }

  @media screen and (max-width: 640px) {
    flex-direction: row;
    font-size: 18px;
    width: 99%;
  }
`
const DisclaimerContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f8f8f7;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Disclaimer = styled.div`
  width: 946px;
  height: 861px;
  background: #fff;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 40px;
  box-sizing: border-box;
  position: relative;

  ${H2} {
    margin-bottom: 34px;

    @media screen and (max-width: 980px) {

    margin-top: 20px;
    }

  }

  @media screen and (max-width: 980px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 640px) {
    padding: 60px 0px;
  }

  table, th, td {
    border: 1px solid #D3D3D3;
    border-collapse: collapse;
  }

  table {
    width: 100%;
  }

  th {
    background: #F0F0F0;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    height: 54px;
  }

  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    padding-left: 20px;
    height: 54px;
  }
`

const DisclaimerButtonsContainer = styled.div`
  display: flex;
  margin-top: 40px;

  @media screen and (max-width: 640px) {
    flex-direction: column;

  }

  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }
`

const DisclaimerButton = styled.button`
  width: 215px;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid #000;
  outline: none;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  margin: 0 22px;
  cursor: pointer;
  background: #fff;
  font-size: 15px;
  font-weight: 700;

  &:hover {
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 1);
  }

  @media screen and (max-width: 640px) {
    margin: 10px 0;
  }
`

const Tab = styled.div`
  width: auto;
  overflow: hidden;
  font-size: 16px;
  height: 73px;
  border-bottom: 1px solid #C8C8C7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 60px;
  @media screen and (max-width: 640px) {
    padding-left: 20px;
    padding-right: 10px;
  }
  ${props =>
    props.header &&
    css`
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
      height: 73px;
      justify-content: center;
    `}

  ${props =>
    props.green &&
    css`
      background: rgba(176, 241, 212, 0.2);
      border-right: 1px solid #C8C8C7;
      border-left: 1px solid #C8C8C7;
      align-items: center;
      justify-content: center;
      padding-left: 0px;
      `}

`

const OverflowContainer = styled.div`
  overflow-y: auto;
`

const InHouse = styled.div`
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #C8C8C7;
  border-left: 1px solid #C8C8C7;
  

  & ${Tab}:first-child {
    background: #F0F0F0;
    display: flex;
    align-items: center;
    padding-left: 0px;
  }

`

const EndToEnd = styled.div`
  width: 100%;
  overflow: hidden;
  border-top: 1px solid transparent;

  & ${Tab}:first-child {
    background: #B0F1D4;
    display: flex;
    align-items: center;
    padding-left: 0px;
    border: 1px solid #040404;
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.0853365);
  }

  & ${Tab}:nth-child(2) {
    background: #B0F1D4;
    display: flex;
    align-items: center;
    padding-left: 0px;
    border: 1px solid #040404;
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.0853365);
  }

  @media screen and (max-width: 640px) {
    width:47%;
  }
`

const MobileTab = styled(Tab)`
display:none !important;
@media screen and (max-width: 640px) {
  display: flex !important;
  position: absolute;
  -ms-flex-align: center;
  right: 0px;
  z-index: 11111111;
  width: 100%;
}
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  margin-top:30px;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

export default function Features({ location }) {
  const globalContext = useContext(GlobalContext)

  const arrayContent = [
    "User data",
    "Document & ID scanning",
    "Biometric/liveness check",
    "Proof of Address check",
    "2-FA authentication",
    "Bank authentication",
    "Age Verification",
    "Localization (80+ global languages)",
    "Admin Portal / Backoffice",
    "Statistics & Analytics",
    "Personalized verification flows",
    "Fully Automated verification flow",
    "Manual verification flow",
    "Hybrid verification flow (automated + manual review)"
  ]

  return (
    <>
      <SEO
        title="ID Technology for travel brands - Identify passengers in seconds"
        description="Capture user data, verify identities with ID document scanning and biometric/liveness checks. Secure and user-friendly features for growing travel brands."
        canonical="/industry-travel/features/"
        ogImage={require("/src/assets/images/og/og-travel-lp-min.png").default}
      />

      <>

        <CustomPageContainer>
          <DisclaimerContainer>
            <Disclaimer>
              <CloseButton
                onClick={() => {
                  navigate("/industry-travel")
                }}
              />
              <OverflowContainer>
                <TabsContainer>

                  <InHouse>
                    <Tab header >Requirements</Tab>
                    {arrayContent.map((tab, index) => (
                      <Tab key={index}>{tab}</Tab>
                    ))}
                  </InHouse>
                  <EndToEnd>
                    <MobileTab header> Checkin.com support </MobileTab>
                    <Tab header> Checkin.com support </Tab>

                    {arrayContent.map((element, index) => (
                      <Tab key={index} green >
                        <GreenCircle
                          index={index}
                        />
                      </Tab>
                    ))}
                  </EndToEnd>
                </TabsContainer>
              </OverflowContainer>
              <H4>Go live in record speed with Checkin.com </H4>
              <DisclaimerButtonsContainer>
                <DisclaimerButton
                  style={{ background: "#FFD63E" }}
                  onClick={() => globalContext.openGetStarted()}
                >
                  Talk to sales
                </DisclaimerButton>
              </DisclaimerButtonsContainer>
            </Disclaimer>
          </DisclaimerContainer>
        </CustomPageContainer>
      </>
    </>
  )
}
