import React, { useContext } from "react"
import styled, { css } from "styled-components"


import Verify from "../../assets/images/fintech/verify.inline.svg"
import ActionButton from "../../components/action-button"
import GlobalContext from "../../context/global-context"
import Connect from "../../assets/images/common/lizzard.svg"
import Driven from "../../assets/images/common/drive.svg"
import Star from "../../assets/images/common/custom.svg"



const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  z-index: 1;

  &:last-child {
    margin-bottom: 80px;
  }
  &#delivery, &#fast-easy {
    @media screen and (max-width: 640px) {
      margin-bottom: 0px;
    }
  }

`

const H2 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;

  

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;
    br {
      display: none;
    }
  }
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`
const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
  }
`

const SectionDescription1 = styled(SectionDescription)`
  width: 679px;

  @media screen and (max-width: 980px) {
    width: 578px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const FeaturesContainer2 = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
margin-bottom: 50px;
flex-direction: column;


@media screen and (max-width: 980px) {
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  width: 296px;
  flex-direction: column;
}

`
const FeaturesColumn = styled.div`
display: flex;
justify-content: inherit;
@media screen and (max-width: 640px) {
  flex-direction: column;
}
`

const Feature2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  z-index: 1;
  margin-top: 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column !important;
  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }
  

  ${H4} {
    margin-bottom: 16px;
    margin-top: 15px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
    width: 424px;

    @media screen and (max-width: 980px) {
      width: 278px;
      text-align: left;
      font-size: 16px !important;
      line-height: 28px !important;

    }
    
  }

  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    p{
    text-align: center !important;
    }
  }


`

const ImageComtainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    height: auto;
  }
  object {
    width: 80px;
  height: 80px;

  }
`

const Buttons = styled.div`
  display: flex;
  width: 55%;
  justify-content: space-around;
  bottom: 0px;

  

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  @media screen and (max-width:640px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;


    button, a {
      width: 100%;
    }
  }
`

const FeaturesButton = styled(ActionButton)`
  background: #fff;
  color: #000;
  border: 1px solid #000;
  outline: none;
  box-shadow: none;
  padding: 18px 40px;
  font-size: 18px;
  font-weight: 700;
  margin-top:30px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    border: 1px solid #A5A5A5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 5px ;
    left: 5px ;
    z-index: -1;
  }

  &:hover {
    box-shadow: 5px 5px 0 0 #000;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`

export default function FastEasy({
  gridArea,
}) {
  const globalContext = useContext(GlobalContext)


  return (
    <Container gridArea={gridArea}>

      <FeaturesContainer2>
        <FeaturesColumn>
          <Feature2>
            <ImageComtainer>
              <Verify />
            </ImageComtainer>
            <H4>Clarity in every interaction</H4>
            <p>Discover the true nature of each user session and make informed decisions with clear, precise insights.
            </p>
          </Feature2>
          <Feature2>

            <ImageComtainer>
              <object type="image/svg+xml" data={Connect}></object>
            </ImageComtainer>
            <H4>Connect to any process</H4>
            <p>Our flexible integration allows you to connect Botlens into any process to improve your decision-making at scale.</p>
          </Feature2>

        </FeaturesColumn>
        <FeaturesColumn>
          <Feature2>
            <ImageComtainer>
              <object type="image/svg+xml" data={Star} ></object>
            </ImageComtainer>
            <H4>Push UX to the next level</H4>
            <p>Adapt the user experience and let your users enjoy easy onboarding without unnecessary verifications and security measures.</p>
          </Feature2>


          <Feature2>
            <ImageComtainer>
              <object type="image/svg+xml" data={Driven} ></object>
            </ImageComtainer>
            <H4>Data-driven insights</H4>
            <p>It's not just about detection; it's about understanding. Gain actionable insights from sophisticated, data-driven analyses.
            </p>
          </Feature2>
        </FeaturesColumn>
      </FeaturesContainer2>

    </Container>
  )
}
