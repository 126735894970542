import styled from "styled-components"
import LayoutGrid from "./layout-grid2"

const Grid = styled(LayoutGrid)`
grid-template-areas:
    ". . . header header header header header header header header . . ."
    "header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation"
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . platform-header platform-header platform-header platform-header platform-header platform-header platform-header platform-header . . ."
    ". platform platform platform platform platform platform platform platform platform platform platform platform ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . videos-header videos-header videos-header videos-header videos-header videos-header videos-header videos-header . . ."
    ". videos videos videos videos videos videos videos videos videos videos videos videos ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header . . ."
    ". backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . module-header module-header module-header module-header module-header module-header module-header module-header . . ."
    ". module module module module module module module module module module module module ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . biometric-header biometric-header biometric-header biometric-header biometric-header biometric-header biometric-header biometric-header . . ."
    ". . . . . . . . . . . . . ."
    ". biometric biometric biometric biometric biometric biometric biometric biometric biometric biometric biometric biometric ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". verify verify verify verify verify verify verify verify verify verify verify verify ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". faq faq faq faq faq faq faq faq faq faq faq faq ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ;

  @media screen and (max-width: 980px) {
    grid-template-areas:
    ". header header header header header header header header ."
    " header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation"
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ".  platform-header platform-header platform-header platform-header platform-header platform-header platform-header platform-header ."
    ". platform platform platform platform platform platform platform platform ."
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ".  videos-header videos-header videos-header videos-header videos-header videos-header videos-header videos-header  ."
    ". videos videos videos videos videos videos videos videos ."
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header ."
    ". backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice ."
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". . . . . . . . . . "
    ". module-header module-header module-header module-header module-header module-header module-header module-header ."
    ". module module module module module module module module ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". biometric-header biometric-header biometric-header biometric-header biometric-header biometric-header biometric-header biometric-header ."
    ". . . . . . . . . ."
    ". biometric biometric biometric biometric biometric biometric biometric biometric ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". verify verify verify verify verify verify verify verify ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". faq faq faq faq faq faq faq faq ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ;
  }

  @media screen and (max-width: 640px) {
    grid-template-areas:
    "header header header header"
    "header-animation header-animation header-animation header-animation"
    ". . . ."
    ". . . ."
    ". . . ."
    ". platform-header platform-header ."
    ". platform platform  ."
    ". . . . "
    ". . . . "
    ". . . . "
    ". videos-header videos-header ."
    ". . . . "
    ". videos videos ."
    ". . . . "
    ". . . . "
    ". . . . "
    ". . . . "
    ". . . . "
    ". backoffice-header backoffice-header ."
    ". backoffice backoffice ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". module-header module-header ."
    ". module module ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". biometric-header biometric-header ."
    ". . . ."
    ". biometric biometric ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". verify verify ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". faq faq ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ;
  }

  @media screen and (max-width: 375px) {
    grid-template-areas:
    ". switcher switcher ."
    ". header header ."
    "header-animation header-animation header-animation header-animation "
    ". . . ."
    ". bento-header bento-header ."
    ". bento bento ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". enterprise enterprise ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". video video ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". verify verify ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". faq faq ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". talk-to-sales talk-to-sales . "
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ;
  }
`
export default Grid