import React, { useRef, useEffect, useState, useContext } from "react"
import styled from "styled-components"
import loadable from '@loadable/component'

import Grid from "../../../components/brazil/grid.js"
import MiddleText from "../../../components/common/middle-text.js"
import Header from '../../../components/brazil/header-animation.js'
import Video from '../../../components/brazil/video-pt.js'
import SEO from "../../../components/seo.js"
import LangugageSwitcher from '../../../components/brazil/language-switcher.js'


const Enterprise = loadable(() => import('../../../components/brazil/enterprise-pt.js'))
const Bento = loadable(() => import('../../../components/brazil/bento-pt.js'))
const FAQ = loadable(() => import('../../../components/brazil/faq-pt.js'))
const Verify = loadable(() => import('../../../components/brazil/verify-pt.js'))
const Sales = loadable(() => import('../../../components/brazil/talk-sales-pt.js'))
const Seamless = loadable(() => import('../../../components/brazil/seamless-pt.js'))


export default function IdScanner({ location }) {
    const [isMobileOnly, setIsMobileOnly] = useState(false)

    return (
        <>
            <SEO
                title="Soluções KYC para iGaming no Brasil | Checkin.com"
                description="Atenda às regulamentações de iGaming no Brasil com Checkin.com. Verifique usuários por CPF, ID, geolocalização e vivacidade. Comece em uma semana!"
                canonical="/industry-igaming/brazil-pt"
                ogImage={require("/src/assets/images/og-startpage.jpg").default}
            />

            <Grid >
                <LangugageSwitcher />
                <MiddleText
                    h1
                    primary
                    gridArea="header"
                    className={"middle"}
                    headerText="Conformidade com as novas leis de iGaming no Brasil."
                    subheaderText="Leia documentos e verifique identidades em segundos em qualquer dispositivo equipado com câmera e conexão à Internet."
                />

                <Header />
                <MiddleText
                    gridArea="bento-header"
                    headerText="Integração Tudo-em-Um"
                    subheaderText={"Todos os serviços são integrados em uma plataforma, tornando a conformidade fácil e eficiente para suas operações de iGaming no Brasil."}
                />
                <Verify />
                <Video />

                <Bento />
                <Enterprise />
                <Seamless />
                <FAQ />
                <Sales />

            </Grid >

        </>
    )
}
