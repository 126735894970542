import React, { useEffect, createRef, useRef } from "react"
import styled from "styled-components"
import lottie from 'lottie-web/build/player/lottie_light.min.js'
import isAnimationInView from "../../utils/isInView"





const AnimationContainer = styled.div`
${props =>
    props.background && `
      background: url(${props.background}) no-repeat center;
    `}
display: flex;
width: 100%;
height: 700px;
justify-content: center;
svg {
  width: 100%;
  height: 51% !important;
  bottom: 129px;  
  position: absolute;
}

@media screen and (max-width: 980px) {
  svg {
    height: 63% !important;
    bottom: 90px;
  }
}

@media screen and (max-width: 640px) {
  background: none;

  height: 490px;
  svg {
    bottom: 60px;
  }
}
`

const Animation = styled.div`
height: 94%;
position: absolute;
width: 100%;

`

export default function OcrAnimation({ background, forground }) {

  let animationContainer = createRef();
  let animation = null;
  const animationRef = useRef(null);


  useEffect(() => {
    animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: forground

    });
  }, []);

  return (
    <AnimationContainer ref={animationRef} background={background}>
      <Animation ref={animationContainer} />
    </AnimationContainer>
  )
}