import React, { useRef } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import Helmet from "react-helmet"

import { pushCustomDataLayerEvent } from "../../state/actions"

import faqPlus from "../../assets/images/connect/faq-plus.svg"
import faqMinus from "../../assets/images/connect/faq-minus.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: faq;
  align-items: center;
  z-index: 1;
`

const Header = styled.h2`
  font-size: 34px;
  margin-bottom: 42px;

  @media screen and (max-width: 640px) {
    font-size: 30px;
    margin-bottom: 40px;
    text-align: center;
  }
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  position: relative;
  background: #fff;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px dashed #000;
    top: -10px;
    left: 10px;
    z-index: -1;
    box-sizing: border-box;
  }
`

const TabLabelIcon = styled.div`
  height: 40px;
  width: 40px;
  background: url(${faqPlus}) no-repeat center/100%;
`

const TabLabel = styled.label.attrs(props => ({
  onClick: e => {
    props.dispatch(
      pushCustomDataLayerEvent({
        event: "funnel_engagement",
        eventCategory: "remote",
        eventAction: "faq_click",
        eventLabel: e.target.innerText,
      })
    )
  },
}))`
  font-size: 18px;
  font-weight: 700;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #f9f9f8;
  cursor: pointer;
  border-top: 1px solid #000;

  p {
    flex: 1;
  }

  @media screen and (max-width: 980px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }
`

const Tab = styled.div`
  width: 100%;
  overflow: hidden;

  &:first-child {
    ${TabLabel} {
      border-top: none;
    }
  }
`

const TabContent = styled.div`
  box-sizing: border-box;
  max-height: 0;
  opacity: 0;
  background: #fff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: rgba(0, 0, 0, 0.1);
    border-top: 1px solid #000;
  }
`

const TabContentContainer = styled.div`
  padding: 30px;
  font-size: 16px;
  line-height: 28px;

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }

  ul {
    margin: 30px 0 30px 30px;

    li {
      margin: 10px 0;
    }
  }

  h3 {
    font-size: 16px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const TabSelector = styled.input.attrs({
  type: "radio",
  name: "faq",
})`
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked ~ ${TabContent} {
    max-height: 100vh;
    opacity: 1;
  }

  &:checked ~ ${TabLabel} {
    background: #ddeff4;

    ${TabLabelIcon} {
      background: url(${faqMinus}) no-repeat center/100%;
    }
  }
`

const faqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What types of verification checks for KYC/AML does Checkin.com offer?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>We provide a comprehensive suite of verification services including ID Document Verification, Face Matching, Liveness Detection, <a href='https://checkin.com/product/aml-pep-sanction-checks'>AML Screening</a> (including <a href='https://checkin.com/product/aml-pep-sanction-checks'>PEPs and Sanctions Lists</a>), Age Verification, Proof of Address, and more, ensuring thorough compliance and security measures. </p>",
      },
    },
    {
      "@type": "Question",
      name: "What is your pricing structure?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Our pricing is designed to be flexible and scalable, catering to various business sizes and needs. We offer competitive rates based on the volume of verifications and the specific services required. For detailed pricing information and to get a tailored quote, please <a href='https://checkin.com/contact-us'>contact our sales team</a>.</p>",
      },
    },
    {
      "@type": "Question",
      name: "How large volumes can you handle?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Checkin.com is built to scale with your business. Our robust infrastructure can handle large volumes of verifications smoothly, ensuring reliability and performance even during peak times. Whether you're processing hundreds or millions of verifications, our system is equipped to manage and maintain high efficiency.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Are you ISO certified?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Yes, Checkin.com adheres to the highest industry standards and is ISO 27001 certified. This certification underscores our commitment to managing information security and providing our partners with a trusted and secure service.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Is the service secure?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Security is paramount at Checkin.com. We implement state-of-the-art security measures including data encryption, secure data storage, and comprehensive access controls to protect all information processed through our platform. Our security protocols are continuously updated to guard against emerging threats and vulnerabilities.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Is the identity verification solution GDPR compliant?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Absolutely. Compliance with GDPR is at the core of our operations. We ensure that all personal data handled by Checkin.com is processed in strict adherence to GDPR guidelines. We provide transparency, data subject rights, and data minimization to safeguard user privacy and comply with regulatory requirements.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Is Checkin.com's solution exclusively for businesses? Which companies can benefit from using this service?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Checkin.com is specifically designed for business use, particularly beneficial for companies that require robust customer identity verification during the onboarding process. It is ideal for entities operating under stringent AML/KYC regulations.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Is the service suitable for small businesses or startups?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Checkin.com is designed to cater to a broad spectrum of businesses, including startups that anticipate a need for robust identity verification processes. It's important to note that we have a minimum monthly commitment that might be higher than what very small businesses or early-stage startups require. However, for startups expecting to scale quickly or that already have a considerable demand for KYC processes, our service offers an ideal solution. This commitment ensures that all our partners can fully leverage the high-performance capabilities of our platform right from the start.</p>",
      },
    },
    {
      "@type": "Question",
      name: "What documents does Checkin.com support?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Our platform supports an extensive range of documents, including ID cards, passports, driver's licenses, and residence permits. We cover over 14,000 document types from more than 190 countries and territories.<br/><a href='https://checkin.com/id-scanner/documents'>View full list here</a>.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Does Checkin.com have an API/SDK for integration?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Absolutely. We offer highly customizable SDKs for iOS and Android, as well as a Web SDK that provides ready-made solutions for automated onboarding with quick setup and straightforward configuration. For deeper integration, our API allows for efficient data collection and result retrieval. More details on our integration capabilities can be found here.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Can Checkin.com provide a KYC solution without requiring integration?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Yes, Checkin.com also offers a plug-and-play setup that does not require any integration. Partners gain private access to our platform where they can activate their company's account, manage customer data securely, and monitor verification results through a cloud-based verification page and an administrative portal.</p>",
      },
    },
    {
      "@type": "Question",
      name: "How does the ID verification process work at Checkin.com?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Our process begins with a robust database of ID document templates, which includes detailed information about the data points specific to each document type and version, such as relative coordinates, types of data, borders, and fonts. An AI engine processes the ID photo, classifying the document type and applying the appropriate template with predefined verification rules. Data is extracted from the visual and Machine Readable Zones (MRZ) of the ID, ensuring all details are cross-checked for consistency and authenticity. We also verify document features like signatures and barcodes to detect any irregularities. All findings are accessible in the back office for review and management by authorized personnel only. This systematic approach ensures high accuracy and fraud prevention in real-time.</p>",
      },
    },
  ],
}

const FAQ = () => {
  const dispatch = useDispatch()
  const openTab = useRef()

  const onTabClick = e => {
    if (e.target === openTab.current) {
      openTab.current = null
      e.target.checked = false
      return
    }

    openTab.current = e.target
  }

  return (
    <Container>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>
      <Header>Frequently asked questions</Header>
      <TabsContainer>
        {faqSchema.mainEntity.map((item, index) => (
          <Tab key={index}>
            <TabSelector id={`faq-${index}`} onClick={onTabClick} />
            <TabLabel htmlFor={`faq-${index}`} dispatch={dispatch}>
              <p>{item.name}</p>
              <TabLabelIcon />
            </TabLabel>
            <TabContent>
              <TabContentContainer
                dangerouslySetInnerHTML={{ __html: item.acceptedAnswer.text }}
              ></TabContentContainer>
            </TabContent>
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export default FAQ
