import React from "react"
import styled, { css } from "styled-components"

import Tag from "./tag"

import tags from "./tags"

const CustomTag = styled(Tag)`
  transition: opacity 0.5s ease;
  margin-right: 8px;
  font-weight: 700;
font-size: 10px;
line-height: 22px;  
white-space: nowrap;

  ${props =>
    props.selected &&
    css`
      opacity: 1 !important;
    `}

  &:hover {
    opacity: 1 !important;
  }

  @media screen and (max-width: 980px) {
  }

  @media screen and (max-width: 640px) {
    margin: 0 4px;
    width: auto !important;
    display: inline-block;
    margin-top: 5px;

    &:first-child, &:last-child {
      margin-left: 0;
    }

  }
`

const AllTag = styled(CustomTag)`
  background: #fff !important;
`

const Container = styled.div`
  grid-area: topics;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 100px;
  align-self: center;

  &:hover {
    ${CustomTag} {
      opacity: 0.3;
    }
  }

  ${props =>
    props.highLightSelected &&
    css`
      ${CustomTag} {
        opacity: 0.3;
      }
    `}

  @media screen and (max-width: 980px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: static;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Title = styled.p`
  margin-right: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;

  @media screen and (max-width: 980px) {
    margin: 0 16px 0 0;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 12px;
    font-size: 16px;
  }
`

const TagsContainer = styled.div`
  display: contents;

  @media screen and (max-width: 640px) {
    display: block;
    width: 100%;
    overflow: scroll;
  }
`

const Topics = ({ selected = null, availableTags = [] }) => {
  return (
    <Container highLightSelected={selected !== null}>
      <Title>Topics</Title>

      <TagsContainer>
        {Object.keys(tags)
          .filter(tagName => availableTags.indexOf(tagName) !== -1)
          .map((tagName, index) => (
            <CustomTag
              key={index}
              selected={selected === tagName.toLowerCase()}
            >
              {tagName}
            </CustomTag>
          ))}
        {selected !== null && <AllTag to="/blog">All</AllTag>}
      </TagsContainer>
    </Container>
  )
}

export default Topics
