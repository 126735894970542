import React, { useRef, useEffect, useState, useContext } from "react"
import loadable from '@loadable/component'

import Grid from "../../../components/brazil/grid.js"
import MiddleText from "../../../components/common/middle-text.js"
import Header from '../../../components/brazil/header-animation.js'
import Video from '../../../components/brazil/video.js'
import SEO from "../../../components/seo.js"

const Enterprise = loadable(() => import('../../../components/brazil/enterprise.js'))
const Bento = loadable(() => import('../../../components/brazil/bento.js'))
const FAQ = loadable(() => import('../../../components/brazil/faq.js'))
const Verify = loadable(() => import('../../../components/brazil/verify.js'))
const Sales = loadable(() => import('../../../components/brazil/talk-sales.js'))
const Seamless = loadable(() => import('../../../components/brazil/seamless.js'))
const LangugageSwitcher = loadable(() => import('../../../components/brazil/language-switcher.js'))


export default function IdScanner({ location }) {
    const [isMobileOnly, setIsMobileOnly] = useState(false)

    return (
        <>
            <SEO
                title="iGaming KYC Solutions for Brazil - Stay Compliant with New Regulations | Checkin.com"
                description="Ensure your iGaming business in Brazil meets new regulatory requirements with Checkin.com 's KYC solutions. Verify users with CPF validation, ID scanning, geolocation, and liveness checks, all in one integrated platform. Go live in just one week!"
                canonical="/industry-igaming/brazil"
                ogImage={require("/src/assets/images/og-startpage.jpg").default}
            />

            <Grid >
                <LangugageSwitcher />
                <MiddleText
                    h1
                    primary
                    gridArea="header"
                    className={"middle"}
                    headerText="Stay Compliant with Brazil's New iGaming Regulations"
                    subheaderText="Read documents and verify identities in seconds from any device equipped with a camera and internet connection."
                />

                <Header />
                <MiddleText
                    gridArea="bento-header"
                    headerText="All-In-One Integration"
                    subheaderText={" All services are integrated into one platform, making compliance easy and efficient for your iGaming operations in Brazil."}
                />
                <Verify />
                <Video />

                <Bento />
                <Enterprise />
                <Seamless />
                <FAQ />
                <Sales />

            </Grid >

        </>
    )
}
