import React, { useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"

import { pushCustomDataLayerEvent } from "../../state/actions"
import useWaypoint from "../../hooks/use-waypoint"

import VideoPlaceholderMobile from "../../assets/images/connect/video-placeholder-mobile.png"
import playIcon from "../../assets/images/connect/play.svg"

const Container = styled.div`
  grid-area: video-container;
  height: 571px;
  width: calc(100% - 5px);
  border: 1px solid #000;
  position: relative;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  background: #e3f0f6;

  @media screen and (max-width: 980px) {
    height: 390px;
    width: 100%;
    background: url(${VideoPlaceholderMobile}) center/contain no-repeat;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 640px) {
    height: 200px;
  }
`

const VideoFrame = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 980px) {
    display: none;
  }
`

const VideoFrameMobile = styled.video`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 981px) {
    display: none;
  }
`

const PlayButton = styled.button`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: url(${playIcon}) no-repeat center/100%;
  outline: none;
  box-shadow: none;
  position: absolute;
  border: none;

  @media screen and (min-width: 981px) {
    display: none;
  }
`

const Video = () => {
  const dispatch = useDispatch()
  const videoRef = useRef()
  const mobileVideoRef = useRef()
  const videoReached = useWaypoint(videoRef, 200)
  const isMobile = useSelector(state => state.global.isMobile)

  useEffect(() => {
    if (videoReached && !isMobile) videoRef.current.play()
  }, [videoReached, isMobile])

  return (
    <Container>
      <VideoFrame ref={videoRef} src="/videos/remote-d-op.mp4" muted loop />
      <VideoFrameMobile
        ref={mobileVideoRef}
        src="/videos/remote-m-op.mp4"
        muted
      />
      <PlayButton
        aria-label="play"
        onClick={() => {
          if (!mobileVideoRef.current) return
          if (mobileVideoRef.current.requestFullscreen) {
            mobileVideoRef.current.requestFullscreen()
            mobileVideoRef.current.play()
          } else if (mobileVideoRef.current.mozRequestFullScreen) {
            mobileVideoRef.current.mozRequestFullScreen()
            mobileVideoRef.current.play()
          } else if (mobileVideoRef.current.webkitRequestFullscreen) {
            mobileVideoRef.current.webkitRequestFullscreen()
            mobileVideoRef.current.play()
          } else if (mobileVideoRef.current.msRequestFullscreen) {
            mobileVideoRef.current.msRequestFullscreen()
            mobileVideoRef.current.play()
          } else if (mobileVideoRef.current.webkitEnterFullscreen) {
            mobileVideoRef.current.webkitEnterFullscreen()
            mobileVideoRef.current.play()
          }

          dispatch(
            pushCustomDataLayerEvent({
              event: "funnel_engagement",
              eventCategory: "remote",
              eventAction: "video_play",
              eventLabel: "page_remote",
            })
          )
        }}
      />
    </Container>
  )
}

export default Video
