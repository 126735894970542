import React, { useRef, useEffect, useState, useContext } from "react"
import styled from "styled-components"
import SEO from "../components/seo.js"
import loadable from '@loadable/component'

import ActionButton from "../components/action-button.js"
import GlobalContext from "../context/global-context.js"
import Grid from "../components/id-scanner/grid.js"
import MiddleText from "../components/common/middle-text.js"
import Header from '../components/id-scanner/header-animation.js'
import Video from '../components/id-scanner/video.js'

const Enterprise = loadable(() => import('../components/id-scanner/enterprise.js'))
const Bento = loadable(() => import('../components/id-scanner/bento.js'))
const Integration = loadable(() => import('../components/id-scanner/integration.js'))
const Partners = loadable(() => import('../components/id-scanner/partners.js'))
const FAQ = loadable(() => import('../components/id-scanner/faq.js'))
const Volume = loadable(() => import('../components/id-scanner/volume.js'))
const Verify = loadable(() => import('../components/id-scanner/verify.js'))
const Expandable = loadable(() => import('../components/id-scanner/expandable.js'))


export default function IdScanner({ location }) {
    const [isMobileOnly, setIsMobileOnly] = useState(false)

    return (
        <>
            <SEO
                title="Fast Identity Verification | Superior User Experience"
                description="Verify identities in seconds with Checkin.com: document scanning, face recognition, and complete KYC onboarding. Trusted by Ryanair, Klarna, and Entain."
                canonical="/"
                ogImage={require("/src/assets/images/og-startpage.jpg").default}
            />

            <Grid >


                <Header />
                <Partners />
                <Verify />
                <Video />

                <Bento />

                <MiddleText
                    primary
                    gridArea="header-integration"
                    headerText="Seamless integration"
                    subheaderText="Effortlessly integrate advanced ID scanning into your applications with minimal coding. Our solution is compatible with all common frameworks."
                />
                <Integration />


                <MiddleText
                    gridArea="backoffice-header"
                    className="backoffice-header"
                    headerText={
                        isMobileOnly
                            ? "Easy-to-use backoffice with full control"
                            : "Easy-to-use backoffice with full control"}
                    subheaderText={"Navigate the dashboard to observe the incoming requests and their statuses, types of used checks. Advanced filtering comes handy for finding the user customer data."}
                />


                <Expandable />

                <MiddleText
                    gridArea="enterprise-header"
                    headerText="Designed for enterprise"
                    subheaderText={"We work with innovative leaders in a wide range of industries to provide tailored solutions based on your specific needs."}
                />
                <Enterprise />

                <MiddleText
                    gridArea="volume-header"
                    className="volume-header"
                    headerText={"Calculate your price"}
                    subheaderText={"Based on your monthly usage and which features you need to run."}
                />
                <Volume />
                <FAQ />


            </Grid >

        </>
    )
}
