import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import _get from "lodash.get"

import SEO from "../components/seo"
import Spinner from "../components/greenSpinner.js"
import { useSelector } from "react-redux"

const Container = styled.div`
  flex: 1;
  position: relative;

  .meetings-iframe-container {
    position: absolute;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f8fa;

    @media screen and (max-width: 980px) {
      top: 30px;
       height: 100%;
    }

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100% !important;
    }
  }
`

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: 15px;
  }
`

export default function MeetingBooker() {
  const script = useRef()

  const [email, setEmail] = useState('');
  const [calendarLink, setCalendarLink] = useState('');
  const betaEmail = useSelector(state => state.beta.email)

  useEffect(() => {
    const addScript = d => {
      if (script.current) return
      script.current = d.createElement("script")
      script.current.type = "text/javascript"
      script.current.async = true
      script.current.onload = () => { }
      script.current.src =
        "//static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      d.getElementsByTagName("head")[0].appendChild(script.current)
    }

    const removeScript = d => {
      if (!script.current) return
      let head = d.getElementsByTagName("head")[0]
      head.removeChild(script.current)
      script.current = null
      let remainingScript = d.getElementById("hs-script-loader")
      let scriptParent = remainingScript && remainingScript.parentNode
      remainingScript && scriptParent.removeChild(remainingScript)
    }

    if (localStorage.getItem('email')) {
      setEmail(localStorage.getItem('email'))
    }

    addScript(document)

    return () => removeScript(document)
  }, [])

  return (
    <Container>
      <SEO
        title="Schedule time with an onboarding expert here"
        description="Book time directly to discuss your use-case and get a complete solution for your KYC and customer onboarding challenges."
        canonical="/meeting-booker"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />
      <div
        className="meetings-iframe-container"
        data-src={`https://meet.checkin.com/meetings/g0/your-intro?email=${betaEmail}`}
      >
        <SpinnerContainer>
          <Spinner />
          <p>Finding available times</p>
        </SpinnerContainer>
      </div>
    </Container>
  )
}
