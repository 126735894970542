import React, { useContext, useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { Link } from "gatsby"
import GlobalContext from "../../context/global-context"
import Outsider from "../../assets/images/outside_link.inline.svg"

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;


  }
`
const FadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const H4 = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;

  ${props => props.headerFontSize && `
    font-size: ${props.headerFontSize};
  `}

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const ImageComtainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 20px;
  @media screen and (max-width: 640px) {
    height: auto;
  }

  &.fadeOut{
    opacity: 0;
    // animation: ${FadeOut} 0.2s ease forwards 0s;
  }
  
  &.fadeIn{
    opacity: 1;
    // animation: ${FadeIn} 0.2s ease forwards 0s;
  }
`

const Feature = styled.a`
  display: flex;
  flex-direction: row;
  z-index: 1;
  padding: 0px 10px;
  position: relative;
  align-items: center;

  &#Proof, &#Signature {

  ${ImageComtainer}:first-of-type  {
    width: 95px;
  }
    
  }

  :hover{
    background :#EBEBEB;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column !important;
  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }

  ${H4} {
    margin-bottom: 5px;
  }
  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    p{
    text-align: center !important;
    }
  }


`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.secondary ${H4} {
    margin-left: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }


  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #9A9C9F;

    @media screen and (max-width: 980px) {
      width: 278px;
      text-align: left;
      font-size: 16px !important;
      line-height: 28px !important;

    }
    
  }
`



const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  ${props =>
    props.gridTemlplateCol && `
    grid-template-columns: ${props.gridTemlplateCol};
      `}
  
  ${props =>
    props.justify && `
    justify-content: ${props.justify};
      `}

  ${props =>
    props.rowGap && `
    row-gap: ${props.rowGap};
      `}

  ${props =>
    props.columnGap && `
    column-gap: ${props.columnGap};
      `}
      

  ${props =>
    props.width && `
        width: ${props.width};
      `}


  @media screen and (max-width: 460px) {
    grid-template-columns: 1fr;
    row-gap: unset;
  }
`

const Outsider1 = styled(Outsider)`
margin-left: 6px;
position: relative;
top: 2px;
`
export default function GridElements({
  data,
  width,
  rowGap,
  columnGap,
  gridTemlplateCol,
  headerStyle,
  justify,
  featureStyle,
  to
}) {
  const globalContext = useContext(GlobalContext)
  const [selected, setSelected] = useState(null)
  const [timeoutId, setTimeoutId] = useState(null)

  useEffect(() => {

  }, [selected])

  const isHovered = (index) => selected === index
  const handleHover = (index) => {
    clearTimeout(timeoutId)
    setSelected(index)
    let timeout = setTimeout(() => {
      setSelected(null)
    }, 2000);
    setTimeoutId(timeout)
  }

  return (

    <GridContainer
      rowGap={rowGap}
      columnGap={columnGap}
      width={width}
      gridTemlplateCol={gridTemlplateCol}
      justify={justify}

    >
      {data != [] && data.map((element, index) => (
        <Feature
          // onMouseOverCapture={() => handleHover(index)}
          onMouseOut={() => setSelected(null)}
          onClick={() => globalContext.toggleSubMenu(false)}
          href={element.link}
          target={element.outsider && '_blank'}
          className="feature"
          key={index}
          id={element.title.split(' ')[0]}
          style={featureStyle}>
          {element.logo && (
            <>
              <ImageComtainer className={`image  ${isHovered(index) ? 'fadeIn' : 'fadeOut'} `}>
                {element.logoHover}
              </ImageComtainer>
              <ImageComtainer style={{ position: 'absolute' }} className={`image  ${isHovered(index) ? 'fadeOut' : 'fadeIn'} `}>
                {element.logo}
              </ImageComtainer>
            </>
          )}

          <Description className={element.secondary && 'secondary'}>
            <H4 style={headerStyle}>{element.title} {element.outsider && <Outsider1 />}</H4>
            <p>{element.text}</p>
          </Description>
        </Feature>
      ))
      }
    </GridContainer >
  )
}
