import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { rgba } from "polished"
import PropTypes from "prop-types"
import Player from "@vimeo/player"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${rgba("#000", 0.8)};
  z-index: 3000;
`

const VideoHolder = styled.div`
  width: 80%;
  position: relative;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
  iframe{
    width: 100%;
    height: 680px;
    @media screen and (max-width: 980px) {
      height: 380px;
    }
    @media screen and (max-width: 640px) {
      height: 250px;
    }
`

const CloseButton = styled.div`
  position: absolute;
  z-index: 100;
  top: -30px;
  right: -30px;
  cursor: pointer;

  @media (max-width: 640px) {
    display: none;
  }

  &:before,
  &:after {
    content: "";
    height: 20px;
    border-radius: 2px;
    width: 2px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const Video = ({ id, onClose, type }) => {
  const holderRef = useRef()

  useEffect(() => {
    if (type === 'vimeo') {
      const player = new Player(holderRef.current, {
        id,
        responsive: true,
        autoplay: true,
      })

      player.on("loaded", player.play)
    }
  }, [id])

  useEffect(() => {
    if (type === 'vimeo') {

      const onEscape = e => {
        if (
          (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) &&
          e.target.nodeName === "BODY"
        ) {
          e.preventDefault()
          onClose && onClose()
          return false
        }
      }
      window.addEventListener("keydown", onEscape, true)

      return () => window.removeEventListener("keydown", onEscape, true)
    }
  }, [onClose])

  const onClick = e => {
    if (e.target !== holderRef.current && onClose) onClose()
  }

  return (
    <Container onClick={onClick}>
      <VideoHolder ref={holderRef}>
        <CloseButton onClick={onClose} />
        {type === "youtube" ? <iframe
          src={`https://www.youtube.com/embed/${id}`}>
        </iframe> : null}

      </VideoHolder>
    </Container >
  )
}

Video.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Video
