import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"


import LivenessCheck from "../../assets/images/ocr/liveness.inline.svg"
import Poa from "../../assets/images/travel/poa.inline.svg"
import Extract from "../../assets/images/ocr/extract.inline.svg"
import Document from "../../assets/images/ocr/document.inline.svg"
import ActionButton from "../../components/action-button"
import GlobalContext from "../../context/global-context"




const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  z-index: 1;

  &:last-child {
    margin-bottom: 80px;
  }
  &#delivery, &#fast-easy {
    @media screen and (max-width: 640px) {
      margin-bottom: 0px;
    }
  }

`

const H2 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;

  

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;
    br {
      display: none;
    }
  }
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`
const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
  }
`

const SectionDescription1 = styled(SectionDescription)`
  width: 679px;

  @media screen and (max-width: 980px) {
    width: 578px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const FeaturesContainer2 = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
margin-bottom: 50px;

@media screen and (max-width: 980px) {
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  width: 296px;
  flex-direction: column;
}

`
const FeaturesColumn = styled.div`
`

const Feature2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  z-index: 1;
  margin-top: 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column !important;
  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }
  

  ${H4} {
    margin-bottom: 16px;
    margin-top: 15px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
    width: 424px;

    @media screen and (max-width: 980px) {
      width: 278px;
      text-align: left;
      font-size: 16px !important;
      line-height: 28px !important;

    }
    
  }

  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    p{
    text-align: center !important;
    }
  }


`

const ImageComtainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    height: auto;
  }
`

const Buttons = styled.div`
  display: flex;
  width: 55%;
  justify-content: space-around;
  bottom: 0px;

  

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  @media screen and (max-width:640px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;


    button, a {
      width: 100%;
    }
  }
`

const FeaturesButton = styled(ActionButton)`
  background: #fff;
  color: #000;
  border: 1px solid #000;
  outline: none;
  box-shadow: none;
  padding: 18px 40px;
  font-size: 18px;
  font-weight: 700;
  margin-top:30px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    border: 1px solid #A5A5A5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 5px ;
    left: 5px ;
    z-index: -1;
  }

  &:hover {
    box-shadow: 5px 5px 0 0 #000;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`

export default function FastEasy({
  gridArea,
}) {
  const globalContext = useContext(GlobalContext)


  return (
    <Container gridArea={gridArea}>
      <Sectionheader primary>
        <H2>Global identity solution<br /> for the travel industry</H2>
        <SectionDescription1>
          With  the help of <a href="/blog/kyc-software-comparison">AI-driven optimization</a>, Checkin.com lets<br /> you identify customers anywhere in the world with ease.
        </SectionDescription1>
      </Sectionheader>

      <FeaturesContainer2>
        <FeaturesColumn>
          <Feature2>
            <ImageComtainer>
              <Document />
            </ImageComtainer>
            <H4>Self-service identity check</H4>
            <p>Let your customers easily and securely verify their identity in a matter of moments by <a href="/id-scanner">checking their ID documents</a>. We support <a href="/id-scanner/documents">13,000+ ID documents</a>. </p>
          </Feature2>
          <Feature2>
            <ImageComtainer>
              <LivenessCheck />
            </ImageComtainer>
            <H4>Face matching & liveness</H4>
            <p>Allow customers to take selfies or perform live actions with their camera to instantly verify it's a real person that matches their supplied ID document. </p>
          </Feature2>
        </FeaturesColumn>
        <FeaturesColumn>
          <Feature2>
            <ImageComtainer>
              <Poa />
            </ImageComtainer>
            <H4>Proof of address</H4>
            <p>Let users confirm their address of permanent residence by checking acceptable POA documents like utility bills, bank statements, and others. </p>
          </Feature2>
          <Feature2>
            <ImageComtainer>
              <Extract />
            </ImageComtainer>
            <H4>OCR & Data Extraction</H4>
            <p>Read, validate and collect data from any type of ID document like passport, drivers license or ID card to save time for your customers when registering.</p>
          </Feature2>
        </FeaturesColumn>
      </FeaturesContainer2>

      <Buttons>
        <Link
          state={{
            modal: true,
          }}
          to={`/industry-travel/features`} >
          <FeaturesButton>
            See all features
          </FeaturesButton>
        </Link>

        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Buttons>


    </Container>
  )
}
