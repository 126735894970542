import styled from "styled-components"
import LayoutGrid from "../layout-grid"

const Grid = styled(LayoutGrid)`
  grid-template-columns: auto repeat(12, 64px) auto;
  grid-gap: 32px;
  row-gap: 24px;
  padding-top: 120px;
  z-index:1;


  @media screen and (max-width: 980px) {
    grid-template-columns: auto repeat(8, 68px) auto;
    grid-gap: 20px;
    overflow: hidden;
    padding-top: 50px;

  }

  @media screen and (max-width: 640px) {
    grid-template-columns: auto 160px 160px auto;
    grid-gap: 16px 24px;
    overflow: hidden;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: auto 171px 172px auto;
    grid-column-gap: 0px;
    grid-row-gap: 8px;
    overflow: hidden;
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: auto 132px 132px auto;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    overflow: hidden;
  }

  grid-template-areas:
    "header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation"
    ". partners partners partners partners partners partners partners partners partners partners partners partners ."
    ". . . . . . . . . . . . . ."
    ". verify verify verify verify verify verify verify verify verify verify verify verify ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". video video video video video video video video video video video video ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". bento bento bento bento bento bento bento bento bento bento bento bento ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". .  header-integration header-integration header-integration header-integration header-integration header-integration header-integration header-integration header-integration header-integration . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". integration integration integration integration integration integration integration integration integration integration integration integration ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header . ."
    ". . . . . . . . . . . . . ."
    ". backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice  ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . enterprise-header enterprise-header enterprise-header enterprise-header enterprise-header enterprise-header enterprise-header enterprise-header enterprise-header enterprise-header . ."
    ". . . . . . . . . . . . . ."
    ". enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . volume-header volume-header volume-header volume-header volume-header volume-header volume-header volume-header . . ."
    ". volume volume volume volume volume volume volume volume volume volume volume volume ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". faq faq faq faq faq faq faq faq faq faq faq faq ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ;

  @media screen and (max-width: 980px) {
    grid-template-areas:
    "header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation header-animation"
    ". partners partners partners partners partners partners partners partners ."
    ". . . . . . . . . ."
    ". verify verify verify verify verify verify verify verify ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". video video video video video video video video ."
    ". . . . . . . . . ."
    ". bento bento bento bento bento bento bento bento ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . header-integration header-integration header-integration header-integration header-integration header-integration . ."
    ". integration integration integration integration integration integration integration integration ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header backoffice-header . ."
    ". backoffice backoffice backoffice backoffice backoffice backoffice backoffice backoffice  ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . enterprise-header enterprise-header enterprise-header enterprise-header enterprise-header enterprise-header . ."
    ". . . . . . . . . ."
    ". enterprise enterprise enterprise enterprise enterprise enterprise enterprise enterprise ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . volume-header volume-header volume-header volume-header volume-header volume-header . ."
    ". volume volume volume volume volume volume volume volume ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". faq faq faq faq faq faq faq faq ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ;
  }

  @media screen and (max-width: 640px) {
    grid-template-areas:
    "header-animation header-animation header-animation header-animation"
    ". partners partners ."
    ". . . . "
    ". verify verify ."
    ". . . . "
    ". . . . "
    ". . . . "
    ". video video ."
    ". . . ."
    ". bento bento ."
    ". . . ."
    ". . . ."
    ". header-integration header-integration ."
    ". integration integration ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". backoffice-header backoffice-header ."
    ". backoffice backoffice . "
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". enterprise-header enterprise-header ."
    ". . . ."
    ". enterprise enterprise ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". volume-header volume-header ."
    ". volume volume ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". faq faq ."
    ". . . ."
    ". . . ."
    ". . . ."
    ;
  }

  @media screen and (max-width: 375px) {
    grid-template-areas:
    "header-animation header-animation header-animation header-animation"
    ". partners partners ."
    ". . . . "
    ". . . . "
    ". . . . "
    ". verify verify ."
    ". . . . "
    ". . . . "
    ". . . . "
    ". . . . "
    ". video video ."
    ". . . ."
    ". . . ."
    ". bento bento ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". header-integration header-integration ."
    ". integration integration ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". backoffice-header backoffice-header ."
    ". backoffice backoffice . "
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". enterprise-header enterprise-header ."
    ". . . ."
    ". . . ."
    ". enterprise enterprise ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". volume-header volume-header ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". volume volume ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". . . ."
    ". faq faq ."
    ". . . ."
    ". . . ."
    ". . . ."
    ;
  }
`

export default Grid
