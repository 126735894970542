import React, { useEffect, createRef, useRef } from "react"
import styled from "styled-components"
import lottie from 'lottie-web/build/player/lottie_light.min.js'

import mobileNimation from '../../assets/images/travel/mobileAnimation.json'


const AnimationContainer = styled.div`
grid-area: header;
display: flex;
  flex-direction: column;
  align-items: center;
video {
  width: 100%;
  margin-bottom: 60px;

  @media screen and (max-width: 640px){
    display: none
  }
}
`
const Animation = styled.div`
@media screen and (min-width: 641px){
  display: none;
}
`
const Description = styled.div`
text-align: center;
font-family: "Object Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px;
width: 80%;
@media screen and (max-width: 640px){
  width: 100%;
  margin-top: 60px;
}

`

export default function OcrAnimation() {

  let animationContainer = useRef();
  let animation = null;


  useEffect(() => {
    animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: mobileNimation

    });
    return () => { animation.destroy() }
  }, []);

  return (
    <AnimationContainer >
      <video autoPlay loop muted >
        <source src="/videos/travel-header.mp4" type="video/mp4" />
      </video>
      <Animation ref={animationContainer} />
      <Description>
        At Checkin.com, our mission is to streamline the check-in process, making it as effortless as possible for both airlines and passengers. Leveraging our expertise in advanced technologies, we've tackled the industry's complex challenges head-on, transforming the way passengers are identified and verified.

      </Description>
    </AnimationContainer>
  )
}