import auth0 from "auth0-js"
import { navigate } from "gatsby"

import serviceURL from "../utils/service-url"

const isBrowser = typeof window !== "undefined"

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: serviceURL().AUTH0_DOMAIN,
      clientID: serviceURL().AUTH0_CLIENTID,
      redirectUri: serviceURL().AUTH0_CALLBACK,
      responseType: "token id_token",
      scope: "openid profile email",
    })
  : {}

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
  if (!isBrowser) {
    return
  }

  return localStorage.getItem("isLoggedIn") === "true"
}

export const login = (email, cb = () => {}) => {
  if (!isBrowser) {
    return
  }

  //   auth.authorize()

  auth.passwordlessStart(
    {
      connection: "email",
      send: "link",
      email,
    },
    cb
  )
}

export const logout = () => {
  localStorage.setItem("isLoggedIn", false)
  auth.logout({ returnTo: serviceURL().AUTH0_LOGOUT_REDIRECT })
}

const setSession = (cb = () => {}, redirect = true) => (err, authResult) => {
  if (err) {
    navigate("/backoffice")
    cb()
    return
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    tokens.accessToken = authResult.accessToken
    tokens.idToken = authResult.idToken
    tokens.expiresAt = expiresAt
    user = authResult.idTokenPayload
    localStorage.setItem("isLoggedIn", true)
    redirect && navigate("/backoffice")
    cb()
  }
}

export const handleAuthentication = () => {
  if (!isBrowser) {
    return
  }

  auth.parseHash(setSession())
}

export const getTokens = () => {
  return tokens
}

export const getProfile = () => {
  return user
}

export const silentAuth = callback => {
  if (!isAuthenticated()) return callback()
  auth.checkSession({}, setSession(callback, false))
}
