import React, { createRef, useEffect } from "react"
import styled from "styled-components"

import HeroIllustration from "../../assets/images/travel/hero-header.svg"
import Phones from "../../assets/images/ocr/phones.svg"
import animationData from "../../assets/images/travel/animated_phones.json"

import lottie from 'lottie-web/build/player/lottie_light.min.js'



const AnimationContainer = styled.div`
background: url(${HeroIllustration}) no-repeat center;
grid-area: slot-machine;
position: absolute;
top: 180px;
display: flex;
width: 100%;
height: 700px;
justify-content: center;
img {
  width: 100%;
  height: 75% !important;
  bottom: -30px;
  position: absolute;
}

@media screen and (max-width: 980px) {
  height: 835px;
  img {
    height: 63% !important;
    bottom:33px;
  }
}

@media screen and (max-width: 640px) {
  background: none;
  top: 200px;
  height: 490px;
  img {
    bottom: -30px;
  }
}

img {}

`

const Animation = styled.div`
  position: relative;
  top: 65px;

  @media screen and (max-width: 980px) {
    top: 70px;
  }

  @media screen and (max-width: 640px) {
    top: 78px;
  }
`


export default function SlotMachine() {

  let animationContainer = createRef();
  let animation = null;

  useEffect(() => {
    if (window.innerWidth <= 640) {
      animation = lottie.loadAnimation({
        container: animationContainer.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData

      });
    }

  }, []);

  return (
    <AnimationContainer >
      <Animation ref={animationContainer} />
      <img src={Phones} />
    </AnimationContainer>
  )
}
