import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import ActionButton from "../components/action-button"
import Sad from "../assets/images/face.svg"
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0D0D0D;
  background: #FBD65D;
  margin-top: 72px;
  height: calc(100vh - 72px);

  img {
    margin-top: 100px;
  }

  @media screen and (max-width: 980px) {
    margin-top: 0;
    height: 100vh;
  }

  @media screen and (max-width: 640px) {
    padding: 0 16px;
    img {
      margin-top: 100px;
      width: 80%;
    }
  }

  p {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`
const H1 = styled.h1`
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 108px;
  font-weight: 800;
  line-height: normal;
`
const H2 = styled.h2`
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px;
  margin: 16px 0 20px 0;
`
const Navigate = styled(ActionButton)`
  height: 52px;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  border: 1px solid #000;
  background:  #FFF;
  box-shadow: 4px 4px 0px 0px rgba(13, 13, 13, 0.10);
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 32px;

`

const NotFoundPage = () => {

  return (
    <Container>
      <H1>404</H1>
      <H2>Page not found</H2>
      <p>We can't seem to find the page you're looking for</p>
      <Link to="/">  <Navigate>back to home</Navigate></Link>
      <img src={Sad} />
    </Container>
  )
}

export default NotFoundPage
