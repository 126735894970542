import React, { useState, useContext, useEffect, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import GreenCircle from "../../../components/green-circle"
import ActionButton from "../../../components/action-button"
import SEO from "../../../components/seo"
import PartnerQuotes from "../../../components/canada/partner-quotes"
import GlobalContext from "../../../context/global-context"
import Img from "gatsby-image"
import { SwitchTransition, CSSTransition } from "react-transition-group"

import indexIllustration from "../../../assets/images/canada/main.svg"
import Conversion from "../../../assets/images/ocr/conversion.inline.svg"
import Combine from "../../../assets/images/ocr/combine.inline.svg"
import Control from "../../../assets/images/ocr/control.inline.svg"
import Custom from "../../../assets/images/ocr/custom.inline.svg"
import Jumio from "../../../assets/images/ocr/jumio.inline.svg"
import Onfido from "../../../assets/images/ocr/onfido.inline.svg"
import Sumsub from "../../../assets/images/ocr/sumsub.inline.svg"
import DataColl from "../../../assets/images/ocr/data.inline.svg"
import TechSetup from "../../../assets/images/ocr/techSetup.inline.svg"
import Compliance2 from "../../../assets/images/ocr/compliane.inline.svg"
import Localization from "../../../assets/images/ocr/localization.inline.svg"
import Next from "../../../assets/images/ocr/next.inline.svg"
import Invalid from "../../../assets/images/ocr/invalid.inline.svg"
import Angle from "../../../assets/images/angle.inline.svg"
import Kyc from "../../../assets/images/canada/KYC.inline.svg"
import Pep from "../../../assets/images/canada/PEP.inline.svg"
import Geo from "../../../assets/images/canada/GEO.inline.svg"
import CheckinAvatar from "../../../assets/images/canada/CheckinAvatar.inline.svg"
import Loading from "../../../components/loading"
import loadable from '@loadable/component'
const IdScanner = loadable(() => import('../../../components/canada/id-scanner'))


const collapse = keyframes`
  from {
    height: 100px;
  }

  to {
    height: 0px;
  }
`

const expand = keyframes`
  from {
    height: 0px;
  }

  to {
    height: 100px;
  }
`

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const H1 = styled.h1`
  font-size: 48px;
  line-height: 56px;
  font-weight: 900;
  margin-bottom: 16px;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media screen and (max-width: 640px) {
    font-size: 34px;
    line-height: 44px;
    width: 320px;
  }
`

const H2 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;
  br {
    display: block;
  }
  

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;
    br {
      display: none;
    }
  }
`
const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`

const CTAText = styled(H4)`
  text-align: center;
  br {
    display: none;
  }

  @media screen and (max-width: 980px) {
    width: 389px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
    br {
      display: block;
    }
  }
`
const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
  }
`

const SectionDescription1 = styled(SectionDescription)`
  width: 679px;

  @media screen and (max-width: 980px) {
    width: 578px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const SectionDescription4 = styled(SectionDescription)`
  width: 650px;

  @media screen and (max-width: 980px) {
    width: 460px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const Container = styled.div`
  flex: 1;
  width: 1170px;
  align-self: center;
  padding: 135px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 980px) {
    padding: 40px 0 0;
    width: 640px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
    padding: 50px 0 0;
  }

  .InstantValue{

  }

  .sliders {
    display: none;
    @media screen and (max-width: 640px) {
      display:block;
    }

  }

`

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  z-index:1;

  &:last-child {
    margin-bottom: 80px;
  }
  &#delivery {
    @media screen and (max-width: 640px) {
      margin-bottom: 0px;
    }
  }
`

const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`

const FeaturesContainer = styled.div`
  width: 1064px;
  display: flex;
  justify-content: space-between;
  margin: 500px 0 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    width: 523px;
    margin: 400px 0 40px;
  }

  @media screen and (max-width: 640px) {
    width: 296px;
    margin: 260px 0 40px;
  }
`


const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 330px;
  z-index: 1;

  p {
    font-size: 20px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    width: 280px;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`

const StepsContainer = styled.div`
  width: 895px;
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  position: relative;
  border-bottom: 2px solid rgba(0, 0, 0, 0.04);


  @media screen and (max-width: 980px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 240px;
  cursor: pointer;
  justify-content: center;

  &:last-of-type {
    width: 305px;
  }

  ${H4} {
    margin: 15px auto;
    font-size: 20px;
    line-height: 30px;
  }

  ${Text} {
    line-height: 24px;
  }

  @media screen and (max-width: 980px) {
    &:last-child {
      grid-column: span 2;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    margin-top: 0;
    margin-bottom: 20px;
    grid-column: 1;
  }
`

const CustomStep = styled(Step)`

${H4} {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}
${props =>
    props.active &&
    css`
    border-bottom: 2px solid #000000;
    ${H4} {
      font-weight: 700;
    }
  `}

img {
  width: 69px;
  height: 69px;
}

@media screen and (max-width: 980px) {

  ${H4} {
    font-size: 14px;
    line-height: 14px;
  }

  &:last-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 640px) {
  width: 230px;
  margin-bottom:0px;
  ${H4} {
    font-size: 14px;
    line-height: 22px;
  }
}
`

const CustomStepsContainer = styled(StepsContainer)`

@media screen and (max-width: 980px) {
  display: flex;
}

@media screen and (max-width: 640px) {
  width: 600px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
`
const Mobilecontainer = styled.div`
@media screen and (max-width: 640px) {
  overflow-x: auto;
    width: 371px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
}
`

const ImageComtainer = styled.div`
  width: 40px;
  height: 40px;
`

const StepContent = styled.div`
width: 70%;
padding: 34px 71px;
display: flex;
justify-content: space-between;
margin-bottom: 66px;
background-color: #FFFFFF;
border: 1px solid #000;
position: relative;

&.fade-enter {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-exit-active {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-enter-active,
&.fade-exit-active {
  transition: all 0.3s ease;

}
&.fade-enter-active,
&.fade-exit-active,
&.fade-exit,
&.fade-enter{
  &::after {
    display:none;
  }
}

&::after {
  content: "";
  border: 1px dashed #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 10px ;
  left: 10px ;
  z-index: -1;
}

@media screen and (max-width: 980px) {
  flex-direction: row;
  padding: 30px;
  width: 90%;
}

@media screen and (max-width: 640px) {
  grid-template-columns: 1fr;
  margin-top: 25px;
  height: auto;
  width: 100%;
  flex-direction: column;

  &.fade-enter {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.3s ease;
  }

  &.fade-enter-active,
  &.fade-exit-active,
  &.fade-exit,
  &.fade-enter{
    &::after {
      display:none;
    }
  }
}

`

const StepContent2 = styled.div`
width: 78%;
height: 320px;
display: flex;
justify-content: space-between;
margin-bottom: 66px;
// border-bottom: 2px solid rgba(0, 0, 0, 0.04);

@media screen and (max-width: 980px) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  justify-items: flex-start;
}

@media screen and (max-width: 640px) {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 25px;
  height: auto;
}

`

const List = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
width: 390px;
@media screen and (max-width: 980px) {
  width: 273px;
}

${H4}{
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  @media screen and (max-width: 980px) {
      font-size: 20px;
      line-height: 32px;
  }
}
p {
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width: 980px) {
    font-size: 14px;
    line-height: 24px;
  }
 }
 ul {
  margin-top: 15px;
  margin-left: 14px;
 }
@media screen and (max-width: 640px) {
  ul {
    margin-left: 16px;
  }
}



li {
  font-size: 16px;
  line-height: 32px;
  padding-left: 10px;
  font-weight: bold;
}
}

`

const ListImage = styled.div`
  min-height: 70px;
  display: block;

  @media screen and (max-width: 980px) {
   svg {
    width: 310px;
   } 
  }
  @media screen and (max-width: 640px) {
    height: auto;
    margin: 0 auto;
    svg {
      width: 260px;
     } 
   }
  

`


const IndexIllustrationContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 182px;

  @media screen and (max-width: 980px) {
    top: 121px;
  }

  @media screen and (max-width: 640px) {
    top: 270px;
  }
`

const IndexIllustration = styled.div`
  width: 2560px;
  height: 662px;
  background: url(${indexIllustration}) no-repeat center/contain;

  @media screen and (max-width: 980px) {
    width: 2000px;
    height: 640px;
  }

  @media screen and (max-width: 640px) {
    width: 500px;
    height: 371px;
    margin-left: 23px;
    background: url(${indexIllustration}) no-repeat center/cover;
  }
`

const Situation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${CustomStepsContainer} {
    justify-content: center;
    width: auto;
  }

  ${Step} {
    @media screen and (max-width: 640px) {
      width: 150px
    }
  }
  ${StepContent} {
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    height: auto;
    display: flex;
    flex-direction: column;

    &:after {
      content: none;
    }

    @media screen and (max-width: 980px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: flex-start;
      padding: 50px 32px;
    
    }
    @media screen and (max-width: 640px) {
      width: auto;
      padding: 0px 20px;
      flex-direction: row;
    }

    ${ListImage} {
      @media screen and (max-width: 980px) {
      display:block;
      with: 220px;
      }
    }
  }
`

const GridContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 30px;

  @media screen and (max-width: 980px) {
    grid-template-columns: auto auto;
    grid-gap: 19px;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: auto;
    grid-gap: 19px;
  }
`
const Card = styled.div`
  background: #fff;
  border: 1px solid #000;
  box-shadow: 5px 5px 0 0 #F2F2F2;
  padding: 32px 24px;
  position: relative;

    
  }
  ${H4} {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
    @media screen and (max-width: 640px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 30px;
    width: 320px;
    overflow: hidden;
    @media screen and (max-width: 980px) {
      width: auto;
    }

    &.collapse{
      @media screen and (max-width: 640px) {

        animation: ${collapse} 1s ease forwards;
      }
    }

    &.expand{
      @media screen and (max-width: 640px) {

        animation: ${expand} 1s ease forwards;
      }
    }
  }
`
const Tag = styled.span`
  text-transform: uppercase;
  background: #BDE9FF;
  border: 1px solid #000000;
  border-radius: 12px;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  padding: 2px 10px;
  position: absolute;
  bottom: 20px;
`

const NextButton = styled.span`
  position: absolute;
  width: 44px;
  top: 300px;
  right: -18px;
`

const PreviousButton = styled.span`
  position: absolute;
  width: 44px;
  top: 300px;
  left: -18px;
`
const InvalidNext = styled(Invalid)`
  transform: rotate(180deg);
`
const Prev = styled(Next)`
  transform: rotate(180deg);
`
const Arrow = styled(Angle)`
  width: 18px;
  height: 20px;
  position: relative;
  top: 3px;
  display: none;
  @media screen and (max-width: 640px) {
    display: inline;
  }
`
const ArrowUp = styled(Arrow)`
  transform: rotate(90deg);
`
const ArrowDown = styled(Arrow)`
  transform: rotate(-90deg);
`

const Image = styled(Img)`
  width: 158%;
  bottom: 340px;
  @media screen and (max-width: 980px) {
    bottom: 170px;
  }
  @media screen and (max-width: 640px) {
    bottom: 65px;
  }
  ${props =>
    props.isSpaced &&
    css`
      margin: 60px 0;
    `}

    ${props =>
    props.isSpacedBottom &&
    css`
        margin: 0 0 60px;
      `}

    ${props =>
    props.isSpacedTop &&
    css`
        margin: 60px 0 0;
      `}
`

const CustomGreenCircle = styled(GreenCircle)`
  opacity: 0;
  &::after {
    content: none
  }
  &.FadeIn{
    animation: ${FadeIn} 1s ease forwards 1s;
    ${props =>
    props && props.index + 1 && `
          animation-delay: ${props.index * 0.2}s;
          `
  }
  }
`

const CustomStepsContainer2 = styled(StepsContainer)`
border-bottom: 2px solid rgba(0, 0, 0, 0.04);

@media screen and (max-width: 980px) {
  display: flex;
}

@media screen and (max-width: 640px) {
  width: 610px;
  margin-bottom: 0px;
}
`

const CustomStep2 = styled(Step)`
${props =>
    props.active &&
    css`
    border-bottom: 2px solid #000000;
  `}
${H4} {
  font-size: 18px;
  line-height: 24px;
}

img {
  width: 69px;
  height: 69px;
}

@media screen and (max-width: 980px) {

  ${H4} {
    font-size: 14px;
    line-height: 22px;
    width: 174px;
  }

  &:last-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 640px) {
  width: 188px;
  margin-bottom:0px;
  ${H4} {
    font-size: 14px;
    line-height: 22px;
  }
}
`



const OverflowContainer = styled.div`
  overflow-y: auto;
  margin-bottom: 60px;
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  background: #fff;
  width: 750px;
  margin: 63px auto 0 auto;
  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    font-size: 18px;
    margin: 32px auto 0 auto;
  }

  @media screen and (max-width: 640px) {
    flex-direction: row;
    font-size: 18px;
    width: 320px;
  }
`
const Tab = styled.div`
  width: auto;
  overflow: hidden;
  font-size: 16px;
  height: 73px;
  border-bottom: 1px solid #C8C8C7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 60px;
  @media screen and (max-width: 640px) {
    padding-left: 20px;
    padding-right: 10px;
  }
  ${props =>
    props.header &&
    css`
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
      height: 73px;
      justify-content: center;
    `}

  ${props =>
    props.green &&
    css`
      background: rgba(176, 241, 212, 0.2);
      border-right: 1px solid #C8C8C7;
      border-left: 1px solid #C8C8C7;
      align-items: center;
      justify-content: center;
      padding-left: 0px;
      `}
`

const InHouse = styled.div`
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #C8C8C7;
  border-left: 1px solid #C8C8C7;
  

  & ${Tab}:first-child {
    background: #F0F0F0;
    display: flex;
    align-items: center;
    padding-left: 0px;
  }

`

const EndToEnd = styled.div`
  width: 100%;
  overflow: hidden;

  & ${Tab}:first-child {
    background: #B0F1D4;
    display: flex;
    align-items: center;
    padding-left: 0px;
    border: 1px solid #040404;
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.0853365);
  }

  & ${Tab}:nth-child(2) {
    background: #B0F1D4;
    display: flex;
    align-items: center;
    padding-left: 0px;
    border: 1px solid #040404;
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.0853365);
  }

  @media screen and (max-width: 640px) {
    width:47%;
  }
`

const MobileTab = styled(Tab)`
display:none !important;
@media screen and (max-width: 640px) {
  display: flex !important;
  position: absolute;
  -ms-flex-align: center;
  right: 0px;
  z-index: 11111111;
  width: 100%;
}
`

const Sectionheader2 = styled(Sectionheader)`
  width: 100%;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;

  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`

const TextContainer = styled.div`
`

const CheckinAvatarCustom = styled(CheckinAvatar)`
@media screen and (max-width: 640px) {
  margin-bottom:40px;
}
`


export default function ProductPage({ data }) {
  const globalContext = useContext(GlobalContext)
  const [activestep1, setactivestep1] = useState(0)
  const [activestep2, setactivestep2] = useState(0)
  const [activeSituation, setActiveSituation] = useState(0)
  const [fadeIn, setFadeIn] = useState(false)
  const [fadeIn2, setFadeIn2] = useState(false)
  const [prev, setPrev] = useState(false)
  const [next, setNext] = useState(true)
  const [card, setCard] = useState(null)
  const [isMobileOnly, setIsMobileOnly] = useState(false)


  const [cards, setCards] = useState([
    true, true, true, true, true, true
  ])

  // start useIntersect 
  const ref = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setFadeIn2(entry.isIntersecting);
          observer.unobserve(ref.current);
        }
      }, { rootMargin: '-10px' }
    );

    ref && observer.observe(ref.current);

    return () => observer.unobserve(ref.current);
  }, []);

  // end useIntersect
  const steps1Content = [
    {
      subheader: 'Operators are responsible for ascertaining the player identification at the time of player registration. The identification must be completed within 48 hours of opening an account. Acceptable verification methods:',
      image: Kyc,
      list: [
        'Government-issued photo identification',
        'Credit file in Canada that has existed for at least 3 years'
      ],
    },
    {

      subheader: 'Players must attest at the time of registration if they are a PEP/HIO, a family member of PEP/HIO, or a close associate of PEP/HIO. If they attest positive, the following data must be collected additionally: ',
      image: Pep,
      list: [
        'Source of funds',
        'Source of wealth',
        'Office/position of the PEP',
        'Organization or institution name of the PEP',
        'Record of the nature of the relationship',
      ],

    },
    {
      subheader: 'Possibility to check the device location and set rules for allowed and restricted users. The advantages of geo-location check:',
      image: Geo,
      list: [
        'Users current location',
        'Set the rules for restricted countries'
      ],
    },
  ]

  const steps2Content = [
    {
      header: 'Increase conversion ',
      subheader: 'With data from millions of sessions across 190+ countries, Checkin.com optimizes every aspect of the user experience with AI-driven technology to ensure high conversion in every device and jurisdiction. ',
      image: Conversion,
    },
    {

      header: 'Combine with any data',
      subheader: 'Any data you collect during the session or already sits in your backend can be used at any point in the verification flow, allowing you to improve the user experience and automation while leveraging the data you already own.',
      image: Combine,

    },
    {
      header: '100% control over branding and UX',
      subheader: 'Enable a seamless and engaging experience for your customers to ensure high conversion without compromising your brand. Every aspect of the flow is 100% customizable and easily adjusted through our powerful backoffice.',
      image: Control,

    },
  ]
  const situationContent = [
    {
      header: {
        title: "Easy migration from your existing platform with no backend changes",
        description: "Your new setup will re-use the existing data mapping to make the switch effortless.",
        images: [<Onfido />, <Jumio />, <Sumsub />, <Custom />]
      },
      list: [
        {
          title: '1. Get a walkthrough',
          description: 'Talk to one of our experts to let us know what your current challenges are and get an intro of the platform.',
          tag: 'Takes ~30 minutes'
        },
        {
          title: '2. See your improved setup',
          description: 'You will receive a custom demo of the improved experience in your site or app.',
          tag: 'No strings attached'
        },
        {
          title: '3. Select pricing & volume',
          description: 'Our pricing is clear and transparent and if you have an existing contract we offer a price match guarantee.',
          tag: 'Price match guarantee'
        },
        {
          title: '4. Tech onboarding',
          description: 'We create a custom integration that re-uses the config from your existing setup, making it effortless to switch.',
          tag: 'Direct tech support'
        },
        {
          title: '5. Go live',
          description: 'Once integrated and tested, you are ready to go live with your improved setup.',
          tag: 'We monitor performance'
        },
        {
          title: '6. Continuous improvements',
          description: 'You receive detailed reporting, global updates and new releases each month which improves your performance.',
          tag: 'Global updates'
        }
      ]
    },
    {
      header: {
        title: "Get a solution that covers all your needs and works out of the box",
        description: "Expert help in every step of the way from the kickoff to launch.",
        images: [<Compliance2 />, <Localization />, <TechSetup />, <DataColl />]
      },
      list: [
        {
          title: '1. Get a walkthrough',
          description: 'Talk to one of our experts to let us know what your current challenges are and get an intro of the platform.',
          tag: 'Takes ~30 minutes'
        },
        {
          title: '2. See your new setup',
          description: 'You will receive a custom demo of the implementation of checkin.com in your site or app. ',
          tag: 'No strings attached'
        },
        {
          title: '3. Select pricing & volume',
          description: 'We offer transparent off-the-shelf pricing for common use cases and custom for big volumes.',
          tag: 'Price match guarantee'
        },
        {
          title: '4. Tech onboarding',
          description: 'You get a custom integration based on your existing or planned architecture to make integration easy as pie.',
          tag: 'Direct tech support'
        },
        {
          title: '5. Go live',
          description: 'Once integrated and tested, you are ready to go live with your improved setup.',
          tag: 'We monitor performance'
        },
        {
          title: '6. Continuous improvements',
          description: 'You receive detailed reporting, global updates and new releases each month which improves your performance.',
          tag: 'Global updates'
        }
      ]
    }
  ]

  const Icon = (props) => {

    const TheIcon = steps1Content[activestep1].image
    return <TheIcon {...props} />
  }

  const Icon2 = (props) => {

    const TheIcon = steps2Content[activestep2].image
    return <TheIcon {...props} />
  }


  const toggle = (index) => {
    setCard(index)
    const newCard = cards.slice()
    newCard[index] = !newCard[index]
    setCards(newCard)
  }

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 640 ? setIsMobileOnly(true) : setIsMobileOnly(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])


  useEffect(() => {
    setPrev(activestep1 > 0)
    setNext(activestep1 < steps1Content.length - 1)

  }, [activestep1]);

  useEffect(() => {
    setPrev(activestep2 > 0)
    setNext(activestep2 < steps2Content.length - 1)

  }, [activestep2]);

  return (
    <Container>
      <SEO
        title="Full solution for iGaming KYC onboarding in Ontario, Canada"
        description="Get the go-to solution for launching iGaming products with compliant and top-performing signup, identity and login in the regulated Canadian market."
        canonical="/industry-igaming/canada/"
        ogImage={require("/src/assets/images/og/og-igaming-ontario-lp-min.png").default}
      />
      <IndexIllustrationContainer>
        <IndexIllustration />
      </IndexIllustrationContainer>
      <Section>
        <Sectionheader primary>
          {isMobileOnly ?
            (<H1>Launching in<br /> Ontario has never<br /> been easier</H1>)
            : (<H1>Launching in Ontario<br /> has never been easier</H1>)
          }
          <SectionDescription1>
            Checkin.com is the go-to solution for operators looking to launch with compliant signup, identity and login in Canada.
          </SectionDescription1>
        </Sectionheader>

        <FeaturesContainer>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Access every required solution from a single point</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Optimal signup, identity & login out of the box </p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Flexible integration through JS, SDK or API</p>
          </Feature>
        </FeaturesContainer>
        <CTAText>
          Go live in record speed with Checkin.com
        </CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>

      <Section id="requirements" >
        <Sectionheader>
          <H2>Meet all the requirements for <br /> registering players in Ontario, Canada</H2>
          <SectionDescription4>
            To register new players in the Canada, specific features in your signup and login flow are required to satisfy regulatory requirements
          </SectionDescription4>
        </Sectionheader>
        <Mobilecontainer>
          <CustomStepsContainer2>
            <CustomStep2 active={activestep1 === 0} onClick={() => setactivestep1(0)}>
              <H4>KYC verification required <br /> during sign-up</H4>
            </CustomStep2>
            <CustomStep2 active={activestep1 === 1} onClick={() => setactivestep1(1)}>
              <H4>PEP/HIO screening <br /> required during sign-up</H4>
            </CustomStep2>
            <CustomStep2 active={activestep1 === 2} onClick={() => setactivestep1(2)}>
              <H4>Geo-location <br /> check</H4>
            </CustomStep2>
          </CustomStepsContainer2>
        </Mobilecontainer>

        <StepContent2>
          <List>
            <p>{steps1Content[activestep1].subheader}</p>
            <ul>
              {steps1Content[activestep1].list.map((listElement, index) => (
                <li key={index}>{(listElement)}</li>
              ))}
            </ul>
          </List>
          <ListImage>
            <Icon />
          </ListImage>
        </StepContent2>

        <CTAText>Get every solution out of the box</CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>

      <Section
        id="go-live-fast" >
        <Sectionheader primary>
          <H2>Save months by getting everything at once</H2>
          <SectionDescription1>
            All our solutions for the Canadian jurisdiction helps you stay 100% compliant while ensuring the best in class customer experience.
          </SectionDescription1>
        </Sectionheader>
        <OverflowContainer>
          <TabsContainer
            ref={ref}
          >
            <InHouse>
              <Tab header >Requirements</Tab>
              <Tab >User data</Tab>
              <Tab >Job details</Tab>
              <Tab ><a href="/product/aml-pep-sanction-checks/">KYC: Document and credit file</a></Tab>
              <Tab ><a href="/product/aml-pep-sanction-checks/">PEP/HIO check</a></Tab>
              <Tab >Spending, time and balance limits</Tab>
              <Tab >Geo-location check</Tab>
              <Tab ><a href="/id-scanner/">Document & ID scanning</a></Tab>
              <Tab ><a href="/id-scanner/">Biometric/liveness check</a></Tab>
              <Tab >2-FA authentication</Tab>
            </InHouse>
            <EndToEnd>
              <MobileTab header> Checkin.com support </MobileTab>
              <Tab header> Checkin.com support </Tab>

              {[...Array(9)].map((index) => (
                <Tab green key={index}>
                  <CustomGreenCircle
                    index={index}
                    className={!!fadeIn2 ? 'FadeIn' : null}
                  />
                </Tab>
              ))}

            </EndToEnd>
          </TabsContainer>
        </OverflowContainer>


        <CTAText>Go live in no-time with Checkin.com</CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>

      <Section id="reliable-data" >

        <IdScanner />

      </Section>

      <Section id="instant-value" >
        <Sectionheader>
          <H2>Instant value for operators</H2>
          <SectionDescription4>
            Checkin.com lets you leverage a wide array of technologies to drive measurable business value
          </SectionDescription4>
        </Sectionheader>
        <Mobilecontainer className="InstantValue">
          <CustomStepsContainer>
            <CustomStep className="step1" active={activestep2 === 0} onClick={() => setactivestep2(0)}>
              <H4>Increase conversion </H4>
            </CustomStep>
            <CustomStep className="step2" active={activestep2 === 1} onClick={() => setactivestep2(1)}>
              <H4>Combine with any data</H4>
            </CustomStep>
            <CustomStep className="step3" active={activestep2 === 2} onClick={() => setactivestep2(2)}>
              <H4>Control over branding and UX</H4>
            </CustomStep>
          </CustomStepsContainer>
        </Mobilecontainer>

        <SwitchTransition >
          <CSSTransition
            key={activestep2}
            addEndListener={(node, done) =>
              node.addEventListener("transitionend", done, false)
            }
            classNames="fade"
          >

            <StepContent>

              <NextButton className="sliders" onClick={() => next ? setactivestep2(activestep2 + 1) : null}> {next ? (<Next />) : (<InvalidNext />)}</NextButton>
              <PreviousButton className="sliders" onClick={() => prev ? setactivestep2(activestep2 - 1) : null}>{prev ? (<Prev />) : (<Invalid />)}</PreviousButton>

              <List>
                <H4>{steps2Content[activestep2].header}</H4>
                <p>{steps2Content[activestep2].subheader}</p>
              </List>
              <ListImage>
                <Icon2 />
              </ListImage>
            </StepContent>

          </CSSTransition>
        </SwitchTransition>

      </Section>

      <Section id="every-team">
        <Sectionheader>
          <H2>Big wins for every team</H2>
          <SectionDescription4>
            AI-driven UX, easy integration and global overage creates value for all teams with a stake in your onboarding process.
          </SectionDescription4>
        </Sectionheader>

        <PartnerQuotes />
      </Section>

      <Section id="get-started">
        <Situation>
          <Sectionheader2>
            <TextContainer>
              <H2>Getting started is easy</H2>
              <SectionDescription4>
                We configure and customize the entire integration to fit into your existing architecture and minimize the work required on your end.
              </SectionDescription4>
            </TextContainer>
            <CheckinAvatarCustom />

          </Sectionheader2>

          <StepContent>
            <GridContainer>
              {situationContent[activeSituation].list.map((element, index) => (
                <Card
                  key={index}
                  className={!!fadeIn ? 'FadeIn' : null}
                  index={index}
                >
                  <H4 onClick={() => toggle(index)}>{element.title} {cards[index] ? (<ArrowUp />) : (<ArrowDown />)}  </H4>
                  <p
                    className={cards[index] ? 'collapse' : 'expand'}
                  >{element.description}</p>
                  <Tag>{element.tag}</Tag>
                </Card>
              ))}
            </GridContainer>
          </StepContent>

          <CTAText>See the complete solution in action</CTAText>
          <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
            Get started
          </TalkToSalesButton>
        </Situation>
      </Section>
    </Container >
  )
}