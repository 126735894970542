import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import ArrowSquare from "../../assets/images/arrow.inline.svg"


const Container = styled.div`
    grid-area: videos;

    @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column-reverse;
    }

    .disabled {
        opacity: 0.5;
    }
`
const Navigation = styled.div`
    display: flex;
    gap: 8px;

    @media screen and (max-width: 640px) {
        flex-direction: column;
        margin-top: 16px;
    }
`
const VideoHolder = styled.div`
    border: 1px solid #000;
    box-shadow: 4px 4px 0px 0px rgba(13, 13, 13, 0.10);
    height: 600px;
    margin-top: 16px;
    position: relative;
    background: #FFF;
    video {
        width: 100%;
        transition: opacity 0.5s ease-in-out; /* Smooth fade transition */
    }

    video.fade-out {
        opacity: 0;
    }
      
    video.fade-in {
        opacity: 1;
    }

    @media screen and (max-width: 980px) {
        height: 370px   ;
    }

    @media screen and (max-width: 640px) {
        height: 250px;
    }
`
const Button = styled.div`
    border: 1px solid #000;
    background: #FFF;
    cursor: pointer;
    padding: 32px 39px 32px 24px;
    

    &.unselected {
        border: 1px solid rgba(0, 0, 0, 0.20);
        opacity: 0.7;
    }
    h3 {
        color: #0D0D0D;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
    }
    p {
        color: rgba(13, 13, 13, 0.80);
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
    }

    @media screen and (max-width: 980px) {
        padding :  9px 21px 26px 13px;
        h3 {    
            font-size: 16px;
            line-height: 30px;
        }
        p {
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
        }
`

const Navigator = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3;
  display: flex;
  gap: 16px;

  @media screen and (max-width: 640px) {
    display: none;
    position: relative;
  }

    &.mobile {
        top: 8px;
        display: none;
        right: 0;
        left: 0;
        @media screen and (max-width: 640px) {
            display: flex;
            justify-content: center;
        }
    }
`

const customArrow = styled(ArrowSquare)`
height: 44px;
width: 44px;
position: relative;   
cursor: pointer;
padding: 10px;

  &.disabled {
    opacity: 0.3;
  }
  &.able {
    opacity: 1;
  }
`

const Right = styled(customArrow)`

`

const Left = styled(customArrow)`
transform: rotate(180deg);

`

const Flex = styled.div`
display: flex;
border: 1px solid #000;
box-shadow: 5px 5px 0px rgb(0 0 0 / 10%);

`

export default function Videos() {
    const [selected, setSelected] = useState(1)
    const videoRef = useRef(null);


    const videos = [
        {
            id: 1,
            title: "Face comparison",
            description: "Get a similarity score of user's selfie and document portrait",
            src: "/videos/face-recognition/comparison.mp4"
        },
        {
            id: 2,
            title: "Age estimate",
            description: "Estimates user's age based on facial features and appearance",
            src: "/videos/face-recognition/age-est.mp4"

        },
        {
            id: 3,
            title: "Face similarity",
            description: "Search for previous applications across historic data",
            src: "/videos/face-recognition/similarity.mp4"

        }

    ]
    const handleCanPlayThrough = () => {
        videoRef.current.play();
    };

    const hScrollLeft = () => {
        if (selected > 1)
            setSelected(selected - 1)
    }

    const hScrollRight = () => {
        if (selected < 3)
            setSelected(selected + 1)
    }

    useEffect(() => {
        if (selected) {
            videoRef.current.addEventListener('canplaythrough', handleCanPlayThrough, { once: true });
            videoRef.current.classList.add('fade-out');
            setTimeout(() => {
                videoRef.current.load();
                videoRef.current.classList.remove('fade-out');
                videoRef.current.classList.add('fade-in');
                setTimeout(() => {
                    videoRef.current.classList.remove('fade-in');
                }, 500);
            }, 500); // Duration should match the fade-out transition time
        }
    }, [selected]);
    return (
        <Container>
            <Navigation>
                {videos.map(video => (
                    <Button onClick={() => setSelected(video.id)} className={selected !== video.id && 'unselected'} key={video.id}>
                        <h3>{video.title}</h3>
                        <p>{video.description}</p>
                    </Button>
                ))
                }
                <Navigator className="mobile"> <Flex><Left className={selected === 1 ? 'disabled' : 'able'} onClick={hScrollLeft} /></Flex> <Flex><Right className={selected === 3 ? 'disabled' : 'able'} onClick={hScrollRight} /></Flex> </Navigator>

            </Navigation>
            <VideoHolder>
                <Navigator> <Flex><Left className={selected === 1 ? 'disabled' : 'able'} onClick={hScrollLeft} /></Flex> <Flex><Right className={selected === 3 ? 'disabled' : 'able'} onClick={hScrollRight} /></Flex>  </Navigator>

                <video
                    webkit-playsinline="true"
                    playsInline
                    autoPlay loop width="100%" height="100%"
                    ref={videoRef}>
                    <source src={videos[selected - 1].src} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </VideoHolder>
        </Container >
    )
}