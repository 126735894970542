import React, { useRef, useEffect } from "react"

import Bot from '../../assets/images/botlens/bot.inline.svg'
import Comparison from '../../assets/images/botlens/comparison.inline.svg'
import animationData from "../../assets/images/botlens/Signature.json"
import lottie from 'lottie-web/build/player/lottie_light.min.js'
import * as Styled from './explainer-style'




export default function Explainer({
    gridArea,
}) {

    let animationContainer = useRef();
    let animation = null;

    useEffect(() => {
        animation = lottie.loadAnimation({
            container: animationContainer.current, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData

        });

        return () => animation.destroy();
    }, []);
    return (
        <Styled.Container gridArea={gridArea}>

            <Styled.H4>Non-biometric: Signature module</Styled.H4>

            <Styled.ExplainerBody>

                <Styled.AnimationContainer>
                    <Styled.Animation ref={animationContainer} />
                    <Styled.Head>Motion pattern analysis</Styled.Head>
                    <p>Signature pattern recognition allows for automated analysis via machine learning and statistical analysis
                    </p>
                </Styled.AnimationContainer>
                <Styled.ExplainerText>
                    <Styled.ExplainerBlock>
                        <Comparison />
                        <Styled.Head>Comparison score</Styled.Head>
                        <Styled.Notice>Are signatures the same?
                            Have we seen this signature before?</Styled.Notice>
                        <p>Enables comparison of signature interactions 1-1 and 1-N</p>
                    </Styled.ExplainerBlock>
                    <Styled.ExplainerBlock>

                        <Bot />
                        <Styled.Head>Bot-like behaviour</Styled.Head>
                        <Styled.Notice>Is signature good enough to accept?Is it done by a human or bot?</Styled.Notice>
                        <p>Real-time tracking of movement, acceleration, time, size, position, shape, and other parameters detects simulated activity
                        </p>
                    </Styled.ExplainerBlock>
                </Styled.ExplainerText>

            </Styled.ExplainerBody>

        </Styled.Container>

    );
}