import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../seo"
import Grid from "./grid"
import SectionIntro from "../section-intro"
import PostCard from "./post-card"
import Topics from "./topics"
import Load from "../../assets/images/load.inline.svg"
import ArrowSquare from "../../assets/images/meeting-booker/arrow-square.inline.svg"
import loadable from "@loadable/component"
const Card = loadable(() => import('./card'))




const H4 = styled.h2`
font-weight: 700;
font-size: 40px;
line-height: 40px;
`
const PostsContainer = styled.div`
  grid-area: posts;
  transform: translateX(-14px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;


  @media screen and (max-width: 980px) {
    grid-template-columns: repeat(1, 1fr);
    transform: translateX(-7px);

  }
    @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    transform: translateX(-7px);
    display: flex;
    flex-direction: column;
    p {
      width: 100%;
    }
  }
`

const Showmore = styled.button`
  width: 219px;
  height: 56px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 4px 4px 0px rgba(13, 13, 13, 0.1);
  grid-area: more;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  span {
    margin-left: 16px;
  }

  &.spin svg {
    transform: rotateZ(360deg);
    transition-duration: 1s;
  }
`

const Popular = styled.div`
grid-area: popular ;
`
const Cards = styled.div`
  display: flex;
  overflow: scroll;
  animation-duration: 3s;
  scroll-behavior: smooth;
`
const Search = styled.div`
  grid-area: search ;
  border: 1px solid #757575;
  box-shadow: 4px 4px 0px rgba(13, 13, 13, 0.1);
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    border: none;
    background: none;
    width: 90%;
  }

`

const Right = styled(ArrowSquare)`
  box-shadow: 5px 5px 0px rgb(0 0 0 / 10%);
  position: relative;

  &.disabled {
    opacity: 0.3;
  }

`

const Left = styled(ArrowSquare)`
  position: relative;
  transform: rotate(180deg);
  box-shadow: 5px -5px 0px rgb(0 0 0 / 10%);

  &.disabled {
    opacity: 0.3;
  }


`

const Scroll = styled.div`
`

const Control = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 50px;

@media screen and (max-width: 640px) {
  flex-direction: column;
  align-items: end;
}
`

const TagIndex = ({ data, location, pageContext }) => {
  const posts = data.allGhostPost.edges
  const selectedTag = pageContext.slug
  const availableTags = data.allGhostTag.edges.map(({ node }) => node.slug)
  const [previewPosts, setPreviewPosts] = useState(posts.slice(0, 8))
  const [previewnbr, setPreviewNbr] = useState(1)
  const [showmore, setShowmore] = useState(true)
  const inputReference = useRef(null);
  const populatRef = useRef(null);

  const loadMore = () => {
    if ((previewnbr * 8) < posts.length - 1) {
      setPreviewNbr(previewnbr + 1)
      setPreviewPosts(posts.slice(0, (8 * (previewnbr + 1))))
    }
  }
  const filterBySearch = (e) => {
    const query = e.target.value;
    var updatedPosts = [...posts];
    // Include all elements which includes the search query
    updatedPosts = updatedPosts.filter((item) => {
      return item.node.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setPreviewPosts(updatedPosts);

    if (query || updatedPosts.length == 0) setShowmore(false)

  }

  const searchClick = () => {
    inputReference.current.focus()
  }

  const hScrollLeft = () => {
    populatRef.current.scrollBy({
      left: 575,
      behavior: 'smooth'
    });
  }

  const hScrollRight = () => {
    populatRef.current.scrollBy({
      left: -575,
      behavior: 'smooth'
    });
  }
  return (
    <>
      <SEO
        title="Checkin.com updates"
        description="Insights, news and updates"
        canonical="/blog"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />

      <Grid>
        <SectionIntro
          header="Checkin.com updates"
          description="Insights, news and updates"
        />

        <PostsContainer>
          {previewPosts.map(({ node }, index) => (
            <PostCard posts={posts} key={index} post={node} />

          ))}
        </PostsContainer>
        {showmore && (
          <Showmore
            className={loadMore ? 'spin' : null}
            onClick={loadMore}
          > <Load /> <span>Show more</span> </Showmore>
        )}


        <Topics selected={selectedTag} availableTags={availableTags} />

        <Search onClick={searchClick}><input ref={inputReference} placeholder="Search" type="text" onChange={filterBySearch} /> </Search>
        <Popular>
          <Control>
            <H4>Popular articles</H4>
            <Scroll> <Left onClick={hScrollRight} /> <Right onClick={hScrollLeft} /> </Scroll>

          </Control>

          <Cards ref={populatRef}>
            {posts.map((post, index) =>
              (<Card key={index} thumbnail={true} id={"card" + (index + 1)} data={post.node} />)

            )}

          </Cards>
        </Popular>
      </Grid>
    </>
  )
}

TagIndex.propTypes = {
  data: PropTypes.shape({
    ghostTag: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
}

export default TagIndex

export const pageQuery = graphql`
  query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostTag(slug: { eq: $slug }) {
      ...GhostTagFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }

    allGhostTag(sort: { order: ASC, fields: name }) {
      edges {
        node {
          slug
          url
          postCount
        }
      }
    }
  }
`
