import React, { useContext } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"
import styled from "styled-components"
import { rgba } from "polished"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

import Grid from "./post-grid-privacy"
import PostHTML from "./post-html-privacy"
import ActionButton from "../action-button"
import SEO from "../seo"

import GlobalContext from "../../context/global-context"

import arrowBack from "../../assets/images/arrow-back.svg"

const Overlay = styled.div`
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  background: ${rgba("#F9F9F8", 0.9)};

  @media screen and (max-width: 640px) {
    background: #f9f9f8;
    backdrop-filter: none;
  }
`

const CustomLink = styled(Link)`
  width: 27px;
  height: 18px;
  background: url(${arrowBack}) no-repeat center;
  position: absolute;
  top: 40px;
  left: 40px;

  @media screen and (max-width: 640px) {
    left: 20px;
    top: 20px;
  }
`

const CustomButton = styled(ActionButton)`
  width: 263px;
`

const Container = styled.div`
  grid-area: post;
  padding: 70px 0;
  background: #fff;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  pointer-events: all;

  @media screen and (max-width: 640px) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
    border: none;
    padding-bottom: 40px;
    overflow-y: auto;
  }
`

const PostContainer = styled.div`
  width: 555px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 980px) {
    width: 500px;
  }

  @media screen and (max-width: 640px) {
    width: 336px;
  }

  @media screen and (max-width: 320px) {
    width: 320px;
    padding: 0 5px;
    box-sizing: border-box;
  }

  table {
    margin-top: 32px;
    border: 1px solid #000;
    border-collapse: collapse;
  td {
    padding: 16px 12px;
    vertical-align: top;
    border: 1px solid #000;
  }

  th {
    text-align: left;
    padding: 16px 12px;
    vertical align: middle;
    background: #FEF2CD;
    border: 1px solid #000;
  }

@media screen and (max-width: 640px) {
  max-width: 336px;
  display: block;
  overflow: scroll;
}
}
`

const SmallTitle = styled.p`
  font-size: 18px;
  text-align: center;
`

const Title = styled.h1`
  font-size: 40px;
  line-height: 48px;
  margin-top: 24px;
  text-align: center;

  @media screen and (max-width: 640px) {
    font-size: 30px;
    line-height: 40px;
  }
`

const Separator = styled.div`
  width: 68px;
  height: 1px;
  background: #000;
  margin: 24px 0;
`

const GetStartedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 250px;
`

const GetStartedText = styled.p`
  font-size: 24px;
  text-align: center;
`

const Page = ({ data, location }) => {
  const page = data.ghostPage
  const globalContext = useContext(GlobalContext)

  return (
    <>
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
      </Helmet>
      <SEO
        title={page.title}
        description={page.meta_description}
        ogTitle={page.og_title}
        ogDescription={page.og_description}
        ogImage={page.og_image}
        twitterTitle={page.twitter_title}
        twitterDescription={page.twitter_description}
        twitterImage={page.twitter_image}
        canonical={`/${page.slug}`}
      />

      <ModalRoutingContext.Consumer>
        {({ modal }) => (
          <>
            {!modal && <Overlay />}

            <Grid style={{ zIndex: "6000" }}>
              <Container>
                <CustomLink
                  to="/"
                  onClick={e => {
                    if (modal) {
                      e.preventDefault()
                      window.history.back()
                    }
                  }}
                />
                <PostContainer>
                  {page.primary_tag &&
                    page.primary_tag.slug === "case-study" && (
                      <SmallTitle>{page.excerpt}</SmallTitle>
                    )}
                  <Title>{page.title}</Title>
                  <Separator />
                  <div>
                    <PostHTML dangerouslySetInnerHTML={{ __html: page.html }} />
                  </div>

                  {page.primary_tag && page.primary_tag.slug === "case-study" && (
                    <GetStartedContainer>
                      <GetStartedText>Want to become a partner?</GetStartedText>
                      <CustomButton
                        onClick={() => {
                          globalContext.openGetStarted()
                        }}
                      >
                        Get started
                      </CustomButton>
                    </GetStartedContainer>
                  )}
                </PostContainer>
              </Container>
            </Grid>
          </>
        )}
      </ModalRoutingContext.Consumer>
    </>
  )
}

Page.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default Page

export const postQuery = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      ...GhostPageFields
    }
  }
`
