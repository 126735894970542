import React, { useRef } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import Helmet from "react-helmet"

import { pushCustomDataLayerEvent } from "../../state/actions"

import faqPlus from "../../assets/images/connect/faq-plus.svg"
import faqMinus from "../../assets/images/connect/faq-minus.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: faq;
  align-items: center;
  z-index: 1;
`

const Header = styled.h2`
  font-size: 34px;
  margin-bottom: 42px;

  @media screen and (max-width: 640px) {
    font-size: 30px;
    margin-bottom: 40px;
    text-align: center;
  }
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  position: relative;
  background: #fff;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px dashed #000;
    top: -10px;
    left: 10px;
    z-index: -1;
    box-sizing: border-box;
  }
`

const TabLabelIcon = styled.div`
  height: 40px;
  width: 40px;
  background: url(${faqPlus}) no-repeat center/100%;
`

const TabLabel = styled.label.attrs(props => ({
  onClick: e => {
    props.dispatch(
      pushCustomDataLayerEvent({
        event: "funnel_engagement",
        eventCategory: "remote",
        eventAction: "faq_click",
        eventLabel: e.target.innerText,
      })
    )
  },
}))`
  font-size: 18px;
  font-weight: 700;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #f9f9f8;
  cursor: pointer;
  border-top: 1px solid #000;

  p {
    flex: 1;
  }

  @media screen and (max-width: 980px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }
`

const Tab = styled.div`
  width: 100%;
  overflow: hidden;

  &:first-child {
    ${TabLabel} {
      border-top: none;
    }
  }
`

const TabContent = styled.div`
  box-sizing: border-box;
  max-height: 0;
  opacity: 0;
  background: #fff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: rgba(0, 0, 0, 0.1);
    border-top: 1px solid #000;
  }
`

const TabContentContainer = styled.div`
  padding: 30px;
  font-size: 16px;
  line-height: 28px;

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }

  ul {
    margin: 30px 0 30px 30px;

    li {
      margin: 10px 0;
    }
  }

  h3 {
    font-size: 16px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const TabSelector = styled.input.attrs({
  type: "radio",
  name: "faq",
})`
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked ~ ${TabContent} {
    max-height: 100vh;
    opacity: 1;
  }

  &:checked ~ ${TabLabel} {
    background: #ddeff4;

    ${TabLabelIcon} {
      background: url(${faqMinus}) no-repeat center/100%;
    }
  }
`

const faqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What services does <a href='https://checkin.com/'>Checkin.com</a> offer in Brazil?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Checkin.com provides ID verification, liveness checks, age verification, CPF validation, PEP & sanctions screening, AML screening, and secure storage of customer data for iGaming operators in Brazil.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Is your flow fully compliant with the new regulations?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Yes, the goal for us is that you don’t have to care about the KYC process, because you will know you have all the correct information about your players.</p>",
      },
    },
    {
      "@type": "Question",
      name: "What is the average time for ID verification?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>The average verification time for documents using Checkin.com's ID scan software is approximately 5 seconds.</p>",
      },
    },
    {
      "@type": "Question",
      name: "What is the difference between Passive Liveness and Active Liveness?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Passive Liveness check is all happening in the background, so the player will not even know they are performing a liveness check compared to the active liveness where the players performs actions provided on the screen. </p>",
      },
    },
    {
      "@type": "Question",
      name: "What is the CPF validation feature?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>CPF validation checks the customer's Brazilian CPF directly with government databases, adding an extra layer of security for operators.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Can <a href='https://checkin.com/'>Checkin.com's</a> software handle our current database and verify all our players even though we haven’t worked with you before?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Yes, all existing players will go through a passive liveness check and we will then search your database to be able to find the right face among your existing users. </p>",
      },
    },
    {
      "@type": "Question",
      name: "Can our flow be customised in any way?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Checkin.com's solution is fully white-labeled, which means that the flow is customised after how your website is designed and it is configured based on the requirements you have, e.g. languages, supported documents, age restriction etc.</p>",
      },
    },
    {
      "@type": "Question",
      name: "How long does <a href='https://checkin.com/'>Checkin.com</a> store customer data?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Checkin.com securely stores customer data for up to 10 years, ensuring compliance with regulatory requirements.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Does <a href='https://checkin.com/'>Checkin.com</a> provide ongoing monitoring of customer profiles?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Yes, Checkin.com offers ongoing monitoring and reassessment of risk profiles by screening against thousands of updated sanctions and PEP (Politically Exposed Persons) watchlists and also confirming if a player is associated with any sports organisation in Brazil. <a href='https://checkin.com/id-scanner/documents/'>View full list here</a>.</p>",
      },
    },
    {
      "@type": "Question",
      name: "What is deep fake detection, and why is it important?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>The amount of Deep fakes in Brazil is growing, so Checkin.com's Deep fake detection ensures that a players identity is authentic and haven been manipulated, helping operators prevent identity fraud.</p>",
      },
    },
    {
      "@type": "Question",
      name: "How many types of identification documents are supported?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Checkin.com supports over 14,000 identification documents from more than 80 languages.</p>",
      },
    },
  ],
}

const FAQ = () => {
  const dispatch = useDispatch()
  const openTab = useRef()

  const onTabClick = e => {
    if (e.target === openTab.current) {
      openTab.current = null
      e.target.checked = false
      return
    }

    openTab.current = e.target
  }

  return (
    <Container>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>
      <Header>Frequently asked questions</Header>
      <TabsContainer>
        {faqSchema.mainEntity.map((item, index) => (
          <Tab key={index}>
            <TabSelector id={`faq-${index}`} onClick={onTabClick} />
            <TabLabel htmlFor={`faq-${index}`} dispatch={dispatch}>
              <p dangerouslySetInnerHTML={{ __html: item.name }}></p>
              <TabLabelIcon />
            </TabLabel>
            <TabContent>
              <TabContentContainer
                dangerouslySetInnerHTML={{ __html: item.acceptedAnswer.text }}
              ></TabContentContainer>
            </TabContent>
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export default FAQ
