import React, { useContext } from "react";
import { useDispatch } from "react-redux"
import { pushCustomDataLayerEvent } from "../../state/actions"
import styled from "styled-components";
import GlobalContext from "../../context/global-context"

import ActionButton from "../../components/action-button"


const H4 = styled.h4`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
    margin-bottom: -15px;
  }
`

const CTAText = styled(H4)`
  margin-top: 30px;
  text-align: center;

  @media screen and (max-width: 980px) {
    text-align: center;

  }

  @media screen and (max-width: 640px) {
    text-align: center;
  margin-bottom: 10px;


  }
`

const TalkToSales = styled.div`
grid-area: ${props => props.gridArea};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const CustomActionButton = styled(ActionButton)`
  width: 263px;
  margin: 20px auto;
  z-index: 20;
`

export default function Sales() {
    const globalContext = useContext(GlobalContext)
    const dispatch = useDispatch()
    return (
        <TalkToSales gridArea="talk-to-sales">
            <CTAText>
                See the complete solution in action
            </CTAText>
            <CustomActionButton
                onClick={() => {
                    dispatch(
                        pushCustomDataLayerEvent({
                            event: "get_started",
                            eventCategory: "get_started",
                            eventAction: "open",
                            eventLabel: "top_cta",
                        })
                    )
                    globalContext.openGetStarted()
                }}

            >
                Get started
            </CustomActionButton>
        </TalkToSales>
    )
}