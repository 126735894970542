import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { SwitchTransition, CSSTransition } from "react-transition-group"

import arrow from "../../assets/images/arrow-next.svg"

import AngleIcon from "../../assets/images/angle.inline.svg"

import { DesktopView, MobileView, TabletView } from "../device-views"

const Angle = styled(AngleIcon)`
  width: 30px;
  transition: all 0.2s ease;
  opacity: 0;
  ${props =>
    props.active &&
    css`
      fill: #000;
      opacity: 1;
    `}
`

const Container = styled.div`
  display: flex;
  grid-area: partner-quotes;
`

const FeatureSelector = styled.div`
  display: flex;
  flex: 1;
`

const FeatureSelectorList = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 0 34px 0 68px;

  @media screen and (max-width: 980px) {
    padding: 0;
  }
`

const Arrow = styled.span`
  width: 27px;
  height: 18px;
  background: url(${arrow}) no-repeat center;
  fill: #000;
`

const FeatureItem = styled.li`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  overflow: hidden;
  box-sizing: border-box;

  & > span {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 0 18px;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: normal;
  }

  ${Arrow} {
    opacity: 0;
    transition: all 0.2s ease;
  }

  @media screen and (max-width: 980px) {
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;
    font-size: 20px;
  }

  ${props =>
    props.isselected &&
    css`
      @media screen and (max-width: 980px) {
        max-height: 100%;
      }

      & > span {
        background: #fff;
        color: #000;
        border: 1px solid #040404;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.0853365);
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        @media screen and (max-width: 980px) {
          margin: 0;
        }
      }

      ${Arrow} {
        opacity: 1;
      }
    `}
`

const QuoteCardContainer = styled.div`
  flex: 1;
  z-index: 1;
  height: 400px;

  @media screen and (max-width: 980px) {
    box-sizing: border-box;
    height: auto;
    max-height: 100%;
    max-height: 0%;
    opacity: 0;
    overflow: hidden;
    transition: all 0.2s ease;
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;

    ${props =>
    props.active &&
    css`
        max-height: 100%;
        opacity: 1;
      `}
  }
`

const QuoteCard = styled.div`
  margin: 0 60px 0 0;
  background: #fff9f2;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
  display: flex;
  height: 400px;

  @media screen and (max-width: 980px) {
    margin: 20px 0;
    height: auto;
  }

  &::after {
    content: "";
    border: 1px dashed #000;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px;
    left: 10px;
    z-index: -1;
  }

  & > div {
    flex: 1;
    background: #fff9f2;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &.fade-enter {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.2s ease;
  }
`

const QuoteCardText = styled.div`
  font-size: 18px;
  line-height: 30px;

  span {
    font-weight: 700;
    margin: 0;
  }
  ul {
    margin-top: 20px;
    margin-left: 25px;

    li {
      margin-bottom: 25px;
    }
  }

  h1 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
  }
`

export default function PartnerQuotes() {
  const [selectedFeature, setSelectedFeature] = useState(1)
  const [isMobileOnly] = useSelector(state => [state.global.isMobile])

  const quotes = [
    {
      feature: "Higher conversion",
      header: `
      <h1>More customers complete the process when it's easy and intuitive</h1>
      <div>Checkin.com uses machine learning and data-driven UX modules to optimize the flow for each customer <a href='/blog/localized-id-verification/'>based on their location and behavior</a>. The outcome is a verification flow that will grab your customers' attention and keep it throughout the process.</div>
      `
    },
    {
      feature: "Easier identification",
      header: `
      <div>
      <h1>Customers prefer an easy experience</h1>
      <div>User verification is a multi-step process that can make the customer journey way too complicated. This results in users abandoning the process and never becoming your customer.
      <br/>
      <br/>With Checkin.com your users get an easy-to-follow, natively looking, and <a href='/blog/kyc-signup-flow-guide/'>optimized verification flow</a> which makes it effortless for honest users to become your customers and keeps your business compliant.
      </div>
      </div>
      `
    },
    {
      feature: "Global coverage",
      header: `
      <div>
      <h1>Verify and onboard customers globally</h1>
      <div>Thousand of ID types, dozens of languages, and multitude of devices quickly make it challenging to verify your customers' identities across the globe.
      <br/><br/>
      With 13,000+ supported ID types from 190+ countries, Checkin.com makes it easy for you to verify your customers wherever they are. We're also able to guide your clients through the verification process in 80+ languages.
      </div>
      </div>
      `
    },
    {
      feature: "Secure data handling",
      header: `
      <div>
      <h1>You keep the full control of user data</h1>
      <div>Personal Data is stored on Database servers in the EU situated in AWS or Google Cloud, compliant with ISO 27001:2013 and GDPR.
      <br/><br/>
      All personal data is heavily encrypted and can only be accessed by an authorized person.</div>
      </div>
      `
    },
    {
      feature: "Flexible integration",
      header: `
      <div>
      <h1>Easy integration with your tech stack</h1>
      <div>We offer a wide range of possibilities for flexible integration into any existing architecture through front-end JS, customizable SDK, or API access.
      <br/><br/>
      Our team prepares a fully customized integration based on your company's technical and visual requirements.
      </div>
      </div>
      `
    },




  ]

  useEffect(() => {
    setSelectedFeature(0)
  }, [])
  return (
    <Container>
      <FeatureSelector>
        <FeatureSelectorList>
          {quotes.map((quote, index) => (
            <FeatureItem key={index} isselected={index === selectedFeature}>
              <span onClick={() => setSelectedFeature(index)}>
                {quote.feature}
                {isMobileOnly ? (
                  <Angle active={index === selectedFeature} />
                ) : (
                  <Arrow />
                )}
              </span>

              <TabletView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].header,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </TabletView>

              <MobileView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].header,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </MobileView>
            </FeatureItem>
          ))}
        </FeatureSelectorList>
      </FeatureSelector>

      <DesktopView>
        <QuoteCardContainer>
          <SwitchTransition>
            <CSSTransition
              key={selectedFeature}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
            >
              <QuoteCard>
                <div>
                  <QuoteCardText
                    dangerouslySetInnerHTML={{
                      __html: quotes[selectedFeature].header,
                    }}
                  />
                </div>
              </QuoteCard>
            </CSSTransition>
          </SwitchTransition>
        </QuoteCardContainer>
      </DesktopView>
    </Container>
  )
}
