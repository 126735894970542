import styled from "styled-components"

const PostHTML = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 42px;
  }

  h4 {
    margin-top: 32px;
  }


  table {
    td {
      padding: 10px 0;
    }

    th {
      text-align: left;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    align-self: center;
    position: relative;
  }

  pre {
    margin: 0.4em 0 1.8em;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-wrap;
    padding: 20px;
    background: #15181a;
    color: #fff;
    border-radius: 12px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  h2,
  h3 {
    margin: 48px 0 24px;

    & + p {
      margin-top: 5px;
    }
  }

  a {
    text-decoration: underline;
  }

  .kg-image-card.kg-card-hascaption {
    figcaption {
      text-align: center;
      opacity: 0.54;
      margin-top: 24px;
    }
  }

  .kg-image-card {
    margin: 50px -150px;
    text-align: center;

    @media screen and (max-width: 980px) {
      margin: 50px -45px;
    }

    @media screen and (max-width: 640px) {
      margin: 50px 0;
      width: 100%;
      margin-left: -1000px;

      img {
        width: 100vw;
      }
    }

    &:first-child {
      margin: 0;
    }
  }

  ul,
  ol {
    margin-left: 30px;

    li {
      margin: 30px 0;
    }
  }

  blockquote {
    background: #fff9f2;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 20px;
    margin-left: -20px;
    margin-right: -20px;
    border-left: 2px solid #000;
    margin-top: 40px;
    margin-bottom: 40px;

    & + p {
      margin-top: 5px;
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  /* Koenig Styles
/* ---------------------------------------------------------- */

  .kg-bookmark-card {
    width: 100%;
    margin-top: 0;
  }

  .kg-bookmark-container {
    display: flex;
    min-height: 148px;
    text-decoration: none;
    border-radius: 3px;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
  }

  .kg-bookmark-container:hover {
    text-decoration: none;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
  }

  .kg-bookmark-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
  }

  .kg-bookmark-title {
    font-size: 1.6rem;
    line-height: 1.5em;
    font-weight: 700;
    transition: color 0.2s ease-in-out;
  }

  .kg-bookmark-container:hover .kg-bookmark-title {
  }

  .kg-bookmark-description {
    display: -webkit-box;
    overflow-y: hidden;
    margin-top: 12px;
    max-height: 48px;
    font-size: 1.5rem;
    line-height: 1.5em;
    font-weight: 400;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .kg-bookmark-thumbnail {
    position: relative;
    min-width: 33%;
    max-height: 100%;
  }

  .kg-bookmark-thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 3px 3px 0;

    object-fit: cover;
  }

  .kg-bookmark-metadata {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 14px;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .kg-bookmark-icon {
    margin-right: 8px;
    width: 22px;
    height: 22px;
  }

  .kg-bookmark-author {
    line-height: 1.5em;
  }

  .kg-bookmark-author:after {
    content: "•";
    margin: 0 6px;
  }

  .kg-bookmark-publisher {
    overflow: hidden;
    max-width: 240px;
    line-height: 1.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Gallery Styles
/* ---------------------------------------------------------- */
  .kg-gallery-container {
    display: flex;
    flex-direction: column;
    max-width: 1040px;
    width: 100%;
  }

  .kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0 0;
  }

  .kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
  }

  .kg-gallery-card + .kg-image-card.kg-width-wide,
  .kg-gallery-card + .kg-gallery-card,
  .kg-image-card.kg-width-wide + .kg-gallery-card,
  .kg-image-card.kg-width-wide + .kg-image-card.kg-width-wide {
    margin: -2.25em 0 3em;
  }

  .kg-embed-card {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export default PostHTML
