import React, { useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import SEO from "../../components/seo"

import Grid from "../../components/botlens/grid"
import MiddleText from "../../components/fintech/middle-text"
import ActionButton from "../../components/action-button"
import GreenCircle from "../../components/green-circle"
import AiAnalysis from "../../components/botlens/ai-analysis"
import EverySolution from "../../components/botlens/every-solution"
import Finance from '../../assets/images/botlens/Finance.svg'
import Travel from '../../assets/images/botlens/travel.svg'
import Igaming from '../../assets/images/botlens/igaming.svg'
import Botlens from '../../assets/images/botlens/botlens.png'
import Ecom from '../../assets/images/ecom.svg'
import Social from '../../assets/images/social.svg'
import ExplainerLiveness from '../../components/botlens/explainerLiveness'
import ExplainerSignature from '../../components/botlens/explainerSignature'



import GlobalContext from "../../context/global-context"
import { pushCustomDataLayerEvent } from "../../state/actions"
import loadable from '@loadable/component'
const Scaner = loadable(() => import("../../components/botlens/header-animation"))
const Card = loadable(() => import("../../components/index/card"))

const CustomActionButton = styled(ActionButton)`
  width: 263px;
  margin: 20px auto;
  z-index: 20;
`



const FeaturesContainer = styled.div`
grid-area: features-container;
width: 1010px;
display: flex;
justify-content: space-between;
margin-top: 30px;


@media screen and (max-width: 980px) {
  flex-direction: column;
  width: 523px;
  align-items: center;
}

@media screen and (max-width: 640px) {
  width: 100%;

}
`

const H4 = styled.h4`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
    margin-bottom: -15px;
  }
`

const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 330px;
  z-index: 1;

  p {
    font-size: 20px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 252px;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    width: 280px;
  }
`
const ImageComtainer = styled.div`
  width: 40px;
  height: 40px;
`

const CTAText = styled(H4)`
  margin-top: 30px;
  text-align: center;

  @media screen and (max-width: 980px) {
    text-align: center;

  }

  @media screen and (max-width: 640px) {
    text-align: center;
  margin-bottom: 10px;


  }
`

const TalkToSales = styled.div`
grid-area: ${props => props.gridArea};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Expandable = styled.div`
grid-area: results;
position: relative;
display:flex;
justify-content: center;
border: 1px solid #000; 

&::after {
  content: "";
  border: 1px dashed #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 10px ;
  left: 10px ;
  z-index: -1;
  
}

&.expandable-image {
  position: relative;
}

.image-container {
  width: 100%;
  height: 550px; /* Set the initial height to the part of the image you want to show */
  background-size: cover;
  transition: height 0.5s;
}

&.expandable-image.expanded .image-container {
  height: 1445px; /* Set the expanded height to show the full image */
}
  @media screen and (max-width: 980px) {

    display: none;
  }
`

const Expand = styled.button`
height: 56px;
padding: 12px 40px; 
border: 1px solid #000;
background:  #FDFDFC;
box-shadow: 4px 4px 0px 0px rgba(13, 13, 13, 0.10);
position: absolute;
bottom: 30px;
font-size: 16px;
font-weight: 700; 
`

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px; /* Adjust the height of the gradient overlay as needed */
  background: linear-gradient(to bottom, rgba(249, 249, 248, 0.00) 1.76%, #F9F9F8 65.5%); 
`;

export default function IGamingPage({ location }) {
  const dispatch = useDispatch()
  const globalContext = useContext(GlobalContext)
  const [isMobileOnly, setIsMobileOnly] = useState(false)
  const version = 1
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 640 ? setIsMobileOnly(true) : setIsMobileOnly(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <SEO
        title="Discern real humans from bots and automation"
        description="Botlens analyzes your sessions to instantly reveal which are from genuine human users and which are from automated systems to empower your decision-making"
        canonical="/botlens/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />

      <Grid version={version}>
        <MiddleText
          h1
          primary
          gridArea="header"
          headerText="Discern real humans <br/> from bots and automation"
          subheaderText="Botlens analyzes your sessions to instantly reveal which are from genuine human users and which are from automated systems to empower your decision-making"
        />

        <Scaner />

        <FeaturesContainer>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Light-weight <br />
              background process</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Accurate response <br />
              in milliseconds</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Granular control over <br />
              incoming traffic</p>
          </Feature>
        </FeaturesContainer>


        <TalkToSales gridArea="talk-to-sales-1">
          <CTAText>
            Try Botlens in your own environment
          </CTAText>
          <CustomActionButton
            onClick={() => {
              dispatch(
                pushCustomDataLayerEvent({
                  event: "get_started",
                  eventCategory: "get_started",
                  eventAction: "open",
                  eventLabel: "top_cta",
                })
              )
              globalContext.openGetStarted()
            }}

          >
            Get started
          </CustomActionButton>
        </TalkToSales>

        <MiddleText
          gridArea="ai-analysis-text"
          headerText={
            isMobileOnly
              ? "AI-driven analysis"
              : "AI-driven analysis"}
          subheaderText={"Machine learning is employed to determine behavioural trends unique to humans but absent in bots"}
        />

        <AiAnalysis gridArea="ai-analysis" />

        <MiddleText
          gridArea="every-text"
          headerText={
            isMobileOnly
              ? "Reveal the true nature of visitors in milliseconds"
              : "Reveal the true nature of visitors in milliseconds"}
          subheaderText={"Decide how to handle different user types accessing your product at scale."}
        />

        <EverySolution gridArea="every-solution" />

        <MiddleText
          gridArea="how-works-text"
          headerText={
            isMobileOnly
              ? "How it works"
              : "How it works"}
          subheaderText={"Botlens analyzes user behavior to identify patterns to determine if the user is a human or an automated session"}
        />


        <ExplainerLiveness gridArea="liveness" />
        <ExplainerSignature gridArea="signature" />


        <MiddleText
          gridArea="results-text"
          className="results-text"
          headerText={
            isMobileOnly
              ? "Results"
              : "Results"}
          subheaderText={"All results are readily available in your backoffice.."}
        />


        <Expandable className={`expandable-image ${expanded ? 'expanded' : ''}`}>
          <div className="image-container" style={{ backgroundImage: `url(${Botlens})` }} />
          {expanded ? null : (<GradientOverlay />)}

          <Expand onClick={handleExpand}>{expanded ? 'Collapse' : 'See More'}</Expand>
        </Expandable>


        <MiddleText
          gridArea="usecase-text"
          headerText={
            isMobileOnly
              ? "Use-Cases"
              : "Use-Cases"}
          subheaderText={"For businesses with a mix of human and automated traffic, controlling session flow is crucial."}
        />


        <Card
          href="/industry-travel"
          className="travel"
          background={Travel}
          header='Travel and booking systems'
          text='Ensure genuine human interactions during crucial transactions or information changes.'
          gridArea='fintech' />
        <Card
          href="/industry-fintech"
          className="finance"
          background={Finance}
          header="Finance"
          text="For airlines and hospitality sectors to recognize and manage automated booking tools vs. individual customers. "
          gridArea='travel' />
        <Card
          href="/industry-igaming"
          className="igaming"
          background={Igaming}
          header="iGaming"
          text="Control which types of sessions are allowed to create accounts, receive bonuses and deposit funds."
          gridArea='igaming' />

        <Card
          background={Ecom}
          header="E-commerce"
          text="Identify automated bots scraping product data or making bulk purchases across your entire platform."
          className='smallback'
          gridArea='ecom' />
        <Card
          background={Social}
          header="Social media"
          text="Know which session is automated already at the point of account creation."
          className='smallback'
          gridArea='social' />
        <Card
          style={{ height: '230px' }}
          height="320px"
          header="Content Platforms"
          text="Distinguish between bots scraping content and genuine readers or viewers."
          className='smallback'
          gridArea='content' />

        <TalkToSales gridArea="talk-to-sales-2">
          <CTAText>
            Describe your use-case and we’ll
            show you the perfect setup
          </CTAText>
          <CustomActionButton
            onClick={() => {
              dispatch(
                pushCustomDataLayerEvent({
                  event: "get_started",
                  eventCategory: "get_started",
                  eventAction: "open",
                  eventLabel: "top_cta",
                })
              )
              globalContext.openGetStarted()
            }}

          >
            Schedule call
          </CustomActionButton>
        </TalkToSales>

      </Grid >
    </>
  )
}
