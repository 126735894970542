import React from "react"
import styled, { css } from "styled-components"

import useCounter from "../../hooks/use-counter"

const FinalResultsContainer = styled.div`
  display: flex;
  padding-top: 20px;
  margin-top: 40px;
  z-index: 1;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    margin-top: 25px;
  }
`

const FinalResultBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 323px;
  height: 167px;
  background: #fff;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.09);
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 214px;
    height: 110px;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 24px;
    height: 121px;
    width: 350px;

    &:last-child {
      display: none;
    }

    ${props =>
    props.primary &&
    css`
        margin-bottom: 0;
      `}
  }

  p {
    font-size: 22px;

    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }

    @media screen and (max-width: 640px) {
      font-size: 18px;
    }
  }

  span {
    font-size: 44px;
    font-weight: 700;
    margin-bottom: 10px;

    @media screen and (max-width: 1024px) {
      font-size: 30px;
    }

    @media screen and (max-width: 640px) {
      margin-bottom: 4px;
    }
  }

  ${props =>
    props.primary &&
    css`
      background: #b0f1d4;
      z-index: 2;

      @media screen and (min-width: 641px) {
        width: 421px;
        height: 207px;
        transform: translate3d(0, -20px, 0);
        margin: 0 -1px;

        @media screen and (max-width: 1024px) {
          width: 280px;
          height: 140px;
          transform: translate3d(0, -15px, 0);
        }
      }
    `}
`

const BlackBox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 12px !important;
  color: #fff;
  background: #000;
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  height: 30px;
  width: 262px;
  bottom: -15px;
  margin-bottom: 0 !important;

  @media screen and (max-width: 1024px) {
    font-size: 8px !important;
    height: 20px;
    width: 174px;
    bottom: -10px;
  }
`

export default function FinalResults({ results, lifetimeValue }) {
  const aggregatedUpliftCounter = useCounter(results.aggregatedUplift || 0)

  return (
    <FinalResultsContainer>
      <FinalResultBlock>
        <span>
          +
          {results.additionalSingups &&
            results.additionalSingups.toLocaleString()}
        </span>
        <p>Additional sign-ups / month</p>
      </FinalResultBlock>
      <FinalResultBlock primary>
        <span>€{aggregatedUpliftCounter}</span>
        <p>Additional value created / month</p>
        <BlackBox>Assuming {results.uplift}% conversion increase</BlackBox>
      </FinalResultBlock>
      <FinalResultBlock>
        <span>€{lifetimeValue && lifetimeValue.toLocaleString()}</span>
        <p>Customer Lifetime Value</p>
      </FinalResultBlock>
    </FinalResultsContainer>
  )
}
