import React, { useRef } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import Helmet from "react-helmet"

import { pushCustomDataLayerEvent } from "../../state/actions"

import faqPlus from "../../assets/images/connect/faq-plus.svg"
import faqMinus from "../../assets/images/connect/faq-minus.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: faq;
  align-items: center;
  z-index: 1;
`

const Header = styled.h2`
  font-size: 34px;
  margin-bottom: 42px;

  @media screen and (max-width: 640px) {
    font-size: 30px;
    margin-bottom: 40px;
    text-align: center;
  }
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  position: relative;
  background: #fff;


`

const TabLabelIcon = styled.div`
  height: 40px;
  width: 40px;
  background: url(${faqPlus}) no-repeat center/100%;
`

const TabLabel = styled.label.attrs(props => ({
  onClick: e => {
    props.dispatch(
      pushCustomDataLayerEvent({
        event: "funnel_engagement",
        eventCategory: "remote",
        eventAction: "faq_click",
        eventLabel: e.target.innerText,
      })
    )
  },
}))`
  font-size: 18px;
  font-weight: 700;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #f9f9f8;
  cursor: pointer;
  border-top: 1px solid #000;

  p {
    flex: 1;
  }

  @media screen and (max-width: 980px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }
`

const Tab = styled.div`
  width: 100%;
  overflow: hidden;

  &:first-child {
    ${TabLabel} {
      border-top: none;
    }
  }
`

const TabContent = styled.div`
  box-sizing: border-box;
  max-height: 0;
  opacity: 0;
  background: #fff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #000;
  }
`

const TabContentContainer = styled.div`
  padding: 30px;
  font-size: 16px;
  line-height: 28px;

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }

  ul {
    margin: 30px 0 30px 30px;

    li {
      margin: 10px 0;
    }
  }

  h3 {
    font-size: 16px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const TabSelector = styled.input.attrs({
  type: "radio",
  name: "faq",
})`
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked ~ ${TabContent} {
    max-height: 100vh;
    opacity: 1;
  }

  &:checked ~ ${TabLabel} {
    background: #ddeff4;

    ${TabLabelIcon} {
      background: url(${faqMinus}) no-repeat center/100%;
    }
  }
`

const faqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is Face Matching, and what are its main features?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          `<p>Face Matching is our AI-driven technology that detects, analyzes, and compares faces across various visual inputs, including selfies, document images, videos, and live streams. Key features include:</p>
          <p><b>Face Detection</b>: Accurately identifies human faces in both images and video streams.</p>
          <p><b>Facial Analysis</b>: Estimates key attributes like age and generates biometric face vectors for analysis.</p>
          <p><b>Face Comparison</b>: Enables 1:1 face matching for direct comparisons and 1comparisons to find matches within large databases efficiently.</p>
          `,
      },
    },
    {
      "@type": "Question",
      name: "Why Do I Need Face Matching for my Business?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>It helps detect and prevent identity fraud by verifying that individuals are who they claim to be. Face matching technology can authenticate customers during onboarding and transactions, enhancing trust and security in user interactions. Additionally, it assists businesses in complying with identity verification and data security regulations, thereby mitigating potential legal risks.</p>",
      },
    },
    {
      "@type": "Question",
      name: "How much does it cost, and are there any additional fees?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>The cost is included as part of our product offering, so there are no additional fees.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Do you offer a free trial or demo?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Yes, we offer a sandbox trial that allows you to test this feature. You can request this by clicking on this link: <a href='/contact-us'>book a meeting<a></p>",
      },
    },
    {
      "@type": "Question",
      name: "Is Face Matching secure and compliant with data privacy regulations?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Yes, we prioritize security and privacy. Our platform converts facial data into encrypted face vectors, ensuring no actual images are stored. This enhances data protection and complies with regulations like GDPR.</p>",
      },
    },
  ],
}

const FAQ = () => {
  const dispatch = useDispatch()
  const openTab = useRef()

  const onTabClick = e => {
    if (e.target === openTab.current) {
      openTab.current = null
      e.target.checked = false
      return
    }

    openTab.current = e.target
  }

  return (
    <Container>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>
      <Header>Frequently asked questions</Header>
      <TabsContainer>
        {faqSchema.mainEntity.map((item, index) => (
          <Tab key={index}>
            <TabSelector id={`faq-${index}`} onClick={onTabClick} />
            <TabLabel htmlFor={`faq-${index}`} dispatch={dispatch}>
              <p dangerouslySetInnerHTML={{ __html: item.name }}></p>
              <TabLabelIcon />
            </TabLabel>
            <TabContent>
              <TabContentContainer
                dangerouslySetInnerHTML={{ __html: item.acceptedAnswer.text }}
              ></TabContentContainer>
            </TabContent>
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export default FAQ
