import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: #000;

    &.ReactModal__Html--open {
      overflow: hidden;
    }

    * {
      -webkit-text-size-adjust: none;
    }

    &.fixed {
      overflow: hidden;
    }
  }

  html, body {
    overflow-x: hidden;
  }

  html, body {
    overflow-x: hidden;
  }

  button {
    color : #0D0D0D
  }

  body {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    font-family: 'Object Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    transition: background .5s ease;
    overflow-x: hidden;
    position: relative;
    min-height: 100%;

    &.ReactModal__Body--open {
      overflow: hidden;
    }

    .ReactModal__Content--after-open {
      pointer-events: all !important;

      @media screen and (max-width: 640px) {
        overflow: hidden !important;
      }
    }

    a {
      color: #000;
      text-decoration: none;
    }

    * {
      margin: 0;
      padding: 0;
      touch-action: manipulation;
    }

    #___gatsby {
      flex-direction: column;
      flex: 1;
      display: contents;
    }

    #gatsby-focus-wrapper {
      flex-direction: column;
      flex: 1;
      overflow-x: hidden;
      display: contents;
    }
  }
`

export default GlobalStyle
