import styled from "styled-components"

export const MobileView = styled.div`
  display: contents;

  @media screen and (min-width: 641px) {
    display: none;
  }
`

export const TabletView = styled.div`
  display: contents;

  @media screen and (min-width: 981px) {
    display: none;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
`

export const DesktopView = styled.div`
  display: contents;

  @media screen and (max-width: 980px) {
    display: none;
  }
`
