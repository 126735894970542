import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"


import Next from "../../assets/images/ocr/next.inline.svg"
import Invalid from "../../assets/images/ocr/invalid.inline.svg"
import DeviceIntegrity from "../../assets/images/botlens/deviceIntegrity.svg"
import UserBehavior from "../../assets/images/botlens/userBehavior.svg"
import SourceMonitoring from "../../assets/images/botlens/sourceMonitoring.svg"
import FlowSeq from "../../assets/images/botlens/flowSeq.svg"



const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  z-index: 1;

.sliders {
  display: none;
  @media screen and (max-width: 640px) {
    display:block;
  }

}

@media screen and (max-width: 640px) {
  padding: 0;
  height: auto;
}
`

const Mobilecontainer = styled.div`

@media screen and (max-width: 980px) {
  width: 100%;
}
@media screen and (max-width: 640px) {
  overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
}
`


const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0 40px 0;
  position: relative;


  @media screen and (max-width: 980px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 240px;
  cursor: pointer;
  justify-content: center;

  

  ${H4} {
    margin: 15px auto;
    font-size: 20px;
    line-height: 30px;
  }

  ${Text} {
    line-height: 24px;
  }

  @media screen and (max-width: 980px) {
    &:last-child {
      grid-column: span 2;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    margin-top: 0;
    margin-bottom: 20px;
    grid-column: 1;
  }
`

const CustomStep = styled(Step)`
${H4} {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}
${props =>
    props.active &&
    css`
    
    border: 1px dashed #000;
    ${H4} {
      font-weight: 700;
    }
  `}

img {
  width: 69px;
  height: 69px;
}

@media screen and (max-width: 980px) {

  ${H4} {
    font-size: 14px;
    line-height: 14px;
  }

  &:last-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 640px) {
  width: 230px;
  margin-bottom:0px;
  ${H4} {
    font-size: 14px;
    line-height: 22px;
  }
}
`

const CustomStepsContainer = styled(StepsContainer)`

@media screen and (max-width: 980px) {
  display: flex;
}

@media screen and (max-width: 640px) {
  width: 600px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
`

const ListImage = styled.div`
  display: flex;
  margin-left: 40px;
  width: 300px;

  object {
    width: 100%;
  }

  @media screen and (max-width: 980px) {
    margin-left: 0px;
  width: 250px;
  height: 250px
  }
  @media screen and (max-width: 640px) {
    height: auto;
    margin: 10px auto;
      width: 250px;
      height: 250px
   }
  

`
const NextButton = styled.span`
  position: absolute;
  width: 44px;
  top: 300px;
  right: -18px;
`

const PreviousButton = styled.span`
  position: absolute;
  width: 44px;
  top: 300px;
  left: -18px;
`
const InvalidNext = styled(Invalid)`
  transform: rotate(180deg);
`
const Prev = styled(Next)`
  transform: rotate(180deg);
`
const StepContent = styled.div`
height: 418px;
width: 890px;
padding: 34px 71px;
display: flex;
justify-content: space-between;
margin-bottom: 66px;
background-color: #FFFFFF;
border: 1px solid #000;
position: relative;
margin-right: 35px;

&.fade-enter {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&.fade-exit-active {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}
&.fade-enter-active,
&.fade-exit-active {
  transition: all 0.3s ease;

}
&.fade-enter-active,
&.fade-exit-active,
&.fade-exit,
&.fade-enter{
  &::after {
    display:none;
  }
}

&::after {
  content: "";
  border: 1px dashed #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 10px ;
  left: 10px ;
  z-index: -1;
  
}

@media screen and (max-width: 980px) {
  flex-direction: row;
  padding: 30px;
  align-items: center;
}

@media screen and (max-width: 640px) {
  grid-template-columns: 1fr;
  margin-top: 25px;
  height: auto;
  width: 100%;
  flex-direction: column;

  &.fade-enter {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.3s ease;
  }

  &.fade-enter-active,
  &.fade-exit-active,
  &.fade-exit,
  &.fade-enter{
    &::after {
      display:none;
    }
  }

}

`

const List = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
width: 390px;
@media screen and (max-width: 980px) {
  width: 317px;
}
@media screen and (max-width: 640px) {
  width: auto;
  ul {
    margin-left: 16px;
  }
}
${H4}{
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  @media screen and (max-width: 980px) {
      font-size: 20px;
      line-height: 32px;
  }
}
p {
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width: 980px) {
    font-size: 14px;
    line-height: 24px;
  }
 }
 ul {
  margin-top: 15px;
  margin-left: 14px;
 }




li {
  font-size: 16px;
  line-height: 32px;
  padding-left: 10px;
  font-weight: bold;
}
}

`

const Steps = styled.div`
display: flex;
overflow: scroll;
&::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

export default function FastEasy({
  gridArea,
}) {

  const scrollRef = useRef(null);
  const [activestep, setactivestep] = useState(0)

  const stepsContent = [
    {
      header: "Flow Sequence Control",
      subheader: 'Flow Sequence Control Ensure logical and consistent sequencing of screens and user actions. Detect anomalies like unusually rapid task completion or repetitive actions.',
      image: (< object data={FlowSeq} type="image/svg+xml" ></object>)
    },
    {
      header: "User Behavior Analysis",
      subheader: 'User Behavior Analysis Analyze user behavior patterns (mouse movements, keystrokes, page navigation) to identify irregularity signaling simulated sessions',
      image: (< object data={UserBehavior} type="image/svg+xml" ></object>)
    },
    {
      header: "Source Monitoring ",
      subheader: ' Implement robust security measures to validate application launches, allowing only recognized and trusted sources.',
      image: (< object data={SourceMonitoring} type="image/svg+xml" ></object>)
    },
    {
      header: "Device Integrity Check",
      subheader: 'Utilize device fingerprinting techniques to detect unique characteristics distinguishing bots from human users.',
      image: (< object data={DeviceIntegrity} type="image/svg+xml" ></object>)
    }

  ]


  const scrollToDiv = (divId) => {
    const divElement = document.getElementById(divId);
    if (divElement && scrollRef.current) {
      divElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  };

  const buttonHandler = (index) => {
    scrollToDiv('step' + index)
    setactivestep(index)
  }

  return (
    <Container gridArea={gridArea}>

      <Mobilecontainer className="InstantValue">
        <CustomStepsContainer>
          <CustomStep onClick={() => buttonHandler(0)} className="step1" active={activestep === 0} >
            <H4>Flow Sequence Control </H4>
          </CustomStep>
          <CustomStep onClick={() => buttonHandler(1)} className="step2" active={activestep === 1} >
            <H4>User Behavior Analysis</H4>
          </CustomStep>
          <CustomStep onClick={() => buttonHandler(2)} className="step3" active={activestep === 2} >
            <H4>Source Monitoring</H4>
          </CustomStep>
          <CustomStep onClick={() => buttonHandler(3)} className="step3" active={activestep === 3} >
            <H4>Device Integrity Check</H4>
          </CustomStep>
        </CustomStepsContainer>
      </Mobilecontainer>

      <Steps ref={scrollRef}>
        {stepsContent.map((step, index) => (
          <StepContent id={`step${index}`} key={index}>
            <List>
              <H4>{step.header}</H4>
              <p>{step.subheader}</p>
            </List>
            <ListImage>
              {/* <Icon /> */}
              {step.image}
            </ListImage>

          </StepContent>
        ))}
      </Steps>

    </Container>
  )
}
