import { useState, useEffect } from "react"
import anime from "animejs"

const useCounter = (number = 0) => {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const start = { x: counter }

    anime({
      targets: start,
      x: number,
      round: 1,
      duration: 100,
      easing: "easeOutQuad",
      update: () => {
        setCounter(start.x)
      },
      complete: () => {
        setCounter(number)
      }
    })
  }, [number, counter])

  if (typeof window !== "undefined" && window.Cypress) return number
  return counter
}

export default useCounter
