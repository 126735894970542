import React, { useContext, useRef } from "react"
import styled from "styled-components"

import { H1 } from "../components/common"
import SEO from "../components/seo"
import ActionButton from "../components/action-button"
import Calendar from "../components/meeting-booker/calendar"
import SendMessage from "../components/send-message"

import WhiteCheck from "../assets/images/white-check.inline.svg"
import Whatsapp from "../assets/images/whatsapp.inline.svg"

import GlobalContext from "../context/global-context"


const CustomAction = styled(ActionButton)`
    padding: 12px 26px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    height: 52px;
    width: 180px;
    margin-top: 0px;

    @media screen and (max-width: 640px) {
        width: 100%;
        height: 51px;
    }
`

const Grid = styled.div`
    display: grid;
    flex: 1;
    grid-template-columns: repeat(12, 68px);
    grid-gap: 30px;
    grid-auto-rows: min-content;
    align-items: start;
    padding-top: 150px;
    min-height: 100%;
    justify-content: center;

    @media screen and (max-width: 1150px) {
    grid-template-columns:   repeat(10, 51px)  ;

    }

    @media screen and (max-width: 980px) {
        padding-top: 50px;
        grid-template-columns: repeat(8, 60px);
        grid-gap: 37px 20px;

    }

    @media screen and (max-width: 640px) {
        padding-top: 50px;
        grid-template-columns: repeat(4, 75px);
        grid-gap: 23px 20px;
        

    }

    @media screen and (max-width: 375px) {
        grid-template-columns: 0 auto 0;
    }

    grid-template-areas:
    ". . getInTouch getInTouch getInTouch getInTouch getInTouch getInTouch getInTouch getInTouch . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    "ask ask ask ask  ask ask whatsapp whatsapp whatsapp whatsapp whatsapp whatsapp "
    ". . . . . . . . . . . ."
    "calendar calendar calendar calendar calendar calendar calendar calendar calendar calendar calendar calendar"
    ". . . . . . . . . . . ."
    "check1 check1 check1 check1 check2 check2 check2 check2 check3 check3 check3 check3"
    ". . . . . . . . . . . ."
    "line line line line line line line line line line line line "
    ". . . . . . . . . . . ."
    "anything anything anything anything anything anything anything anything anything anything anything anything"
    ". . . . . . . . . . . ."
    "box1 box1 box1 box2 box2 box2 box3 box3 box3 box4 box4 box4"
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . .";

    @media screen and (max-width: 980px) {

        grid-template-areas:
        ". getInTouch getInTouch getInTouch getInTouch getInTouch getInTouch ."
        " . . . . . . . . "
        " ask ask ask  ask ask ask ask ask"
        " whatsapp whatsapp whatsapp whatsapp whatsapp whatsapp whatsapp whatsapp"
        "calendar calendar calendar calendar calendar calendar calendar calendar"
        ". . . . . . . . "
        "line   line line line line line line line "
        "   anything anything anything anything anything anything anything anything"
        "box1  box1 box1  box1  box2 box2 box2 box2  "
        "box3 box3 box3 box3 box4 box4 box4 box4"
        ". . . . . . . ."
        ". . . . . . . .";
    }

    @media screen and (max-width: 460px) {

        grid-template-areas:
        "getInTouch getInTouch getInTouch getInTouch"
        ". . . . "

        " ask ask ask ask "
        " whatsapp whatsapp whatsapp  whatsapp " 
        "calendar calendar calendar calendar "
        ". . . . "
        " check1 check1 check1  check1" 
        " check2  check2 check2  check2 " 
        "check3 check3 check3 check3 "
        ". . . . "
        ". . . . "
        "line line line line "
        ". . . . "
        "anything anything anything anything"
        " . . . ."
        " box1  box1 box1  box1 " 
        " . . . ."
        " box2 box2 box2 box2"  
        " . . . ."
        " box3 box3 box3 box3" 
        " . . . ."
        " box4 box4 box4 box4 "
        " . . . ."
        " . . . ."
        " . . . ."
        " . . . .";
    }

    hr {
        grid-area: line;
        
    }

    .whatsapp{
        position: relative;
        top: 5px;
    }
`
const GetInTouch = styled.div`
    grid-area: getInTouch;
    text-align:center;
    p{
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        padding: 0 16px;
    }

    @media screen and (max-width: 460px) {

        ${H1}{
            width: auto;
            font-weight: 800;
            font-size: 36px;
            line-height: 44px;
        }
    }
`
const Card = styled.div`
    grid-area: ${props => props.gridArea};
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h1{
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin: 20px 0px;
        height: 60px;
        color: #757575; 
        text-align: center;
        @media screen and (max-width: 980px) {
            height: auto;
        }
        @media screen and (max-width: 460px) {
            margin-top: 20px;
            margin-bottom: 0px;
            height: 60px;

        }


    }
`
const CalendarContainer = styled.div`
    grid-area: ${props => props.gridArea};

    .meetings-iframe-container {
        border: 1px solid #000000;
        box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 35px 20px 0px 20px;

        iframe {
            height: 700px !important;
        }
    }

    @media screen and (max-width: 980px) {
        .meetings-iframe-container {
            height: auto !important;
        }
    }

    @media screen and (max-width: 460px) {
        .meetings-iframe-container{
            overflow: hidden;
            padding: 0px;
        }

        .meetings-iframe-container iframe {
            height: 615px !important;
            position: relative;
            border:none;
        }
    }
`
const Check = styled.div`
    grid-area: ${props => props.gridArea};
    display: flex;
    margin-top: 5px;

    h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin-left: 20px;
        width: 232px;
    }

    @media screen and (max-width: 980px) {
        display:none;
    }

    @media screen and (max-width: 460px) {
        display: flex;
    }
    
`
const CheckContainer = styled.div`
    width: 45px;
`
const Line = styled.div`
    grid-area: line;
    height: 1px;
    background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 10px,
    black 10px,
    black 20px
    );
`

const Anything = styled.div`
    grid-area: anything;
    text-align:center;

    ${H1}{
        font-weight: 800;
        font-size: 40px;
        line-height: 40px;
    }
    p{
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
    }

    br {
        display:none;
    }

    @media screen and (max-width: 460px) {
        br { display:block;}
        ${H1}{
            width: auto;
        }
        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
`
const Box = styled.div`
    grid-area: ${props => props.gridArea};

    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;              
    }
    @media screen and (max-width: 980px) {
        text-align: center;
    }
    @media screen and (max-width: 460px) {

        h2 {
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
        }
    }
`
const Mail = styled.div`
    grid-area: box;
    margin-top: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
`

export default function ContactUs() {
    const globalContext = useContext(GlobalContext)
    const sendMessageRef = useRef()

    const openSendMessage = (...args) => {
        sendMessageRef.current && sendMessageRef.current.open(...args)
    }

    return (
        <><SendMessage ref={sendMessageRef} />
            <Grid>
                <SEO
                    title="Reach out to Checkin.com for optimal onboarding in any market"
                    description="Talk to an expert and get a complete solution for secure and compliant customer onboarding with KYC and identity verification in any market."
                    canonical="/contact-us/"
                    ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
                />

                <GetInTouch>
                    <H1>Get in touch</H1>
                    <p>Let Checkin.com solve the toughest challenges related to  identifying and converting customers globally. </p>
                </GetInTouch>
                <Card gridArea="ask">
                    <h1>Ask a question</h1>
                    <p>Leave your email and a question. We will contact you soon!</p>
                    <CustomAction
                        onClick={() => openSendMessage()}
                    >
                        Send message
                    </CustomAction>
                </Card>
                <Card gridArea="whatsapp">
                    <h1>WhatsApp</h1>
                    <p>Connect with us via WhatsApp!</p>
                    <CustomAction
                        onClick={() => window.open("https://wa.me/46737470907", "_blank")}
                    >
                        WhatsApp <Whatsapp className="whatsapp" />
                    </CustomAction>
                </Card>
                <CalendarContainer gridArea="calendar">
                    <Calendar mobileHeight="auto" />
                </CalendarContainer>
                <Check gridArea="check1">
                    <CheckContainer><WhiteCheck /></CheckContainer>
                    <h2>Conversion increase guarantee</h2>
                </Check>
                <Check gridArea="check2">
                    <CheckContainer><WhiteCheck /></CheckContainer>

                    <h2>All KYC solutions in one simple integration</h2>
                </Check>
                <Check gridArea="check3">
                    <CheckContainer><WhiteCheck /></CheckContainer>

                    <h2>Fast & reliable identity verification</h2>
                </Check>
                <Line />
                <Anything>
                    <H1>Anything else on<br /> your mind?</H1>
                    <p>Feel free to reach out </p>
                </Anything>
                <Box gridArea="box1">
                    <h2>Sales</h2>
                    <Mail>sales@checkin.com</Mail>
                </Box>
                <Box gridArea="box2">
                    <h2>Media inquiries</h2>
                    <Mail>press@checkin.com</Mail>
                </Box>
                <Box gridArea="box3">
                    <h2>Careers</h2>
                    <Mail>join@checkin.com</Mail>
                </Box>
                <Box gridArea="box4">
                    <h2>General questions</h2>
                    <Mail>hello@checkin.com</Mail>
                </Box>

            </Grid>
        </>
    )
}
