import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import lottie from 'lottie-web/build/player/lottie_light.min.js'
import WhiteLabel from '../../assets/white-label.json'
import Global from '../../assets/global.svg'
import native from '../../assets/native.json'
import Countries from "../../assets/images/ocr/countries.inline.svg"
import Languages from "../../assets/images/ocr/languages.inline.svg"
import Doctype from "../../assets/images/ocr/doctype.inline.svg"
import Background from "../../assets/images/id-scanner/background.png"
import BackgroundMobile from "../../assets/images/id-scanner/background-mobile.png"


const Container = styled.div`
    display: flex;
    grid-area: bento;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

const Platforms = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    position: relative;
    width: 100%;
    height: 550px;

    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        max-width: 400px;
    }

    .content {
        width: 40%;
        padding: 72px 64px;
        padding-right: 64px;
        padding-right: 0px;
    }
    .video {
        flex: 1;
        display: flex;
        z-index: 2;
        align-items: end;
        margin-bottom: 32px;
        position: relative;
        img.others {
            width: 590px;
            position: absolute;
            top: 0px;
            right: 0px;
        }
        img.mobile {
            display: none;
        }


        video {
            width: 164px;
            height: 336px;
            margin: 0px auto;
            z-index: 1;
        }
    }

    @media screen and (max-width: 980px) {
        height: auto;
        .content {
            width: 40%;
            padding: 40px;
            padding-right: 0px;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }

        .video {
            img.others {
                width: 340px;
                display: block;
            }
            img.mobile {
                display: none;
            }
            video {
                height: 180px;
                width: auto;
                bottom: 20px;
                position: relative;
                left: 8px;
            }
        }
    }



    @media screen and (max-width: 640px) {
        flex-direction: column;
        .content {
            width: 100%;
            padding: 24px;
            padding-bottom: 0px;
            h1 {
                margin-top: 0px;
            }
        }

        .video {
            flex: auto;
            height: auto;
            margin-bottom: 0px;

            img.mobile {
                display: block;
                width: 100%;
            }
            
            img.others {
                display: none;
            }

            video {
                left: 31%;
                position: absolute;
                width: 70px;
                top: 0%;
            }
        }
        
    }

    }
    
`

const Headline = styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;

    img {
        width: 78px;
        height: 78px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #FFF;
        box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.10);
    }

    @media screen and (max-width: 980px) {
        font-size: 18px;
        line-height: 28px;
    }
`

const Logos = styled.div`
    margin-top: 64px;
    img {
        width: 78px;
        height: 78px; 
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: var(--Secondary-Colours-Primary-Colours-White, #FFF);
        box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.10); 
    }
    img.ios, img.android {
        margin-right: 16px;
    }
    img.web {
        padding:4px;
    }

    @media screen and (max-width: 980px) {
        margin-top: 16px;
        img {
            width: 38px;
            height: 38px;
        }

    }
`
const Animation = styled.div`
    position: absolute;
    bottom: -5px;
    left: 0px;
`

const Features = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    margin-top: 24px;
    .item {
        grid-column: span 1;
        border: 1px solid rgba(0, 0, 0, 0.20); 
        background: #FFF; 
        height: 550px;
        padding: 30px;
        position: relative;

        img {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
        }

    }
    h3 {
        color: #0D0D0D; 
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 700; 
    }
    p{
        color: rgba(13, 13, 13, 0.80); 
        font-size: 18px;
        font-weight: 400; 
        line-height: 30px;
    }

    @media screen and (max-width: 980px) {
        column-gap: 16px;
        margin-top: 16px;
        .item {
            padding: 16px;
            height: 376px;
        }
        h3{
            font-size: 18px;
        }
        p{
            font-size: 14px;
            line-height: 24px;
        }
    }

    @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        margin-top: 24px;

        .item{
            height: 432px;
            padding: 24px;
        }
        .item.global {
            height: 465px;
        }
        .item3{
            ${Animation}{
                bottom: -30px;
            }
            
        }
    }

`


const Numbers = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 93px;
  margin-top: 100px;

  @media screen and (max-width: 980px) {
    grid-template-columns: auto auto auto ;
    column-gap: 20px;
    width: 100%;
  }

  @media screen and (max-width: 640px) {
   display: block;
  margin-top: 0px;

   
  }
`
const Number = styled.div`

  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 68px;
  }
  p{
    text-align: center;
    margin-top: 10px;
  }
  @media screen and (max-width: 980px) {
        font-size: 14px;
        line-height: 24px;

    svg {
        height: 50px;
        width: auto;
       }

  }

  @media screen and (max-width: 640px) {
    display: flex;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;

    &:first-of-type {
    margin-top: 40px;
    }
  }
`
function Bento() {

    let animation1 = null;
    let animation2 = null;
    let animation3 = null;
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    useEffect(() => {

        animation1 = lottie.loadAnimation({
            container: ref1.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: WhiteLabel

        });
        animation2 = null
        animation3 = lottie.loadAnimation({
            container: ref3.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: native

        });
        return () => { animation1.destroy(); animation3.destroy() }
    }, []);
    return (
        <Container>
            <Platforms>
                <div className='content'>
                    <Headline>Support all platforms</Headline>
                    <p>Our platform is engineered to seamlessly integrate with any device, enabling you to manage documents effortlessly across desktop, Android, iOS, and mobile web. </p>
                    <Logos>
                        <img className='ios' src={require('../../assets/images/ios.svg').default} alt='ios' />
                        <img className='android' src={require('../../assets/images/android.svg').default} alt='android' />
                        <img className='web' src={require('../../assets/images/web.svg').default} alt='web' />
                    </Logos>
                </div>
                <div className='video'>
                    <img className='others' src={Background} alt='background' />
                    <img className='mobile' src={BackgroundMobile} alt='background' />
                    <video autoPlay loop muted playsInline>
                        <source src={require('../../assets/images/id-scanner/video-bento.mp4').default} type='video/mp4' />
                    </video>
                </div>
            </Platforms>
            <Features>
                <div className='item global'>
                    <h3>White label</h3>
                    <p>Adapted to your visual identity, streamlining user experience and reducing the need for extensive custom design work.</p>
                    <Animation ref={ref1} />
                </div>
                <div className='item'>
                    <h3>Global coverage</h3>
                    <p>Our platform supports over 14,000 types of documents from more than 190 countries.</p>
                    <img src={Global} />
                </div>
                <div className='item item3'>
                    <h3>Native experience</h3>
                    <p>Optimized for an easy, localized and intuitive user experience across all screen sizes and locations to ensure completion.</p>
                    <Animation ref={ref3} />
                </div>
            </Features>
            <Numbers>
                <Number>
                    <Languages />
                    <p>Global Languages</p>
                </Number>
                <Number>
                    <Doctype />
                    <p>ID Document Types</p>
                </Number>
                <Number>
                    <Countries />
                    <p>Countries and Territories</p>
                </Number>
            </Numbers>
        </Container>

    );
}

export default Bento;