import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { SwitchTransition, CSSTransition } from "react-transition-group"

import arrow from "../../assets/images/arrow-next.svg"
import herogamingLogo from "../../assets/images/igaming/logos-black/herogaming-black.svg"
import casumoLogo from "../../assets/images/igaming/logos-black/casumo-black.svg"
import forzaLogo from "../../assets/images/igaming/logos-black/forza-black.svg"
import luckydaysLogo from "../../assets/images/igaming/logos-black/luckydays-black.svg"
import wunderinoLogo from "../../assets/images/igaming/logos-black/wunderino-black.svg"

import AngleIcon from "../../assets/images/angle.inline.svg"

import { DesktopView, MobileView, TabletView } from "../device-views"

const Angle = styled(AngleIcon)`
  width: 30px;
  transition: all 0.2s ease;
  opacity: 0;
  ${props =>
    props.active &&
    css`
      fill: #000;
      opacity: 1;
    `}
`

const Container = styled.div`
  display: flex;
  grid-area: partner-quotes;
`

const FeatureSelector = styled.div`
  display: flex;
  flex: 1;
`

const FeatureSelectorList = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 0 34px 0 68px;

  @media screen and (max-width: 980px) {
    padding: 0;
  }
`

const Arrow = styled.span`
  width: 27px;
  height: 18px;
  background: url(${arrow}) no-repeat center;
  fill: #000;
`

const FeatureItem = styled.li`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  overflow: hidden;
  box-sizing: border-box;

  & > span {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 0 18px;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: normal;
  }

  ${Arrow} {
    opacity: 0;
    transition: all 0.2s ease;
  }

  @media screen and (max-width: 980px) {
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;
    font-size: 20px;
  }

  ${props =>
    props.isselected &&
    css`
      @media screen and (max-width: 980px) {
        max-height: 100%;
      }

      & > span {
        background: #fff;
        color: #000;
        border: 1px solid #040404;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.0853365);
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        @media screen and (max-width: 980px) {
          margin: 0;
        }
      }

      ${Arrow} {
        opacity: 1;
      }
    `}
`

const QuoteCardContainer = styled.div`
  flex: 1;
  z-index: 1;
  height: 400px;

  @media screen and (max-width: 980px) {
    box-sizing: border-box;
    height: auto;
    max-height: 100%;
    max-height: 0%;
    opacity: 0;
    overflow: hidden;
    transition: all 0.2s ease;
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;

    ${props =>
    props.active &&
    css`
        max-height: 100%;
        opacity: 1;
      `}
  }
`

const QuoteCard = styled.div`
  margin: 0 60px 0 0;
  background: #fff9f2;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
  display: flex;
  height: 440px;

  @media screen and (max-width: 980px) {
    margin: 20px 0;
    height: auto;
  }

  &::after {
    content: "";
    border: 1px dashed #000;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px;
    left: 10px;
    z-index: -1;
  }

  & > div {
    flex: 1;
    background: #fff9f2;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &.fade-enter {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.2s ease;
  }
`

const QuoteCardText = styled.div`
  font-size: 18px;
  line-height: 30px;

  span {
    font-weight: 700;
    margin: 0;
  }
  ul {
    margin-top: 20px;
    margin-left: 25px;

    li {
      margin-bottom: 25px;
    }
  }

  h1 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
  }
`

const Header = styled.h1`
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 24px;
`

export default function PartnerQuotes() {
  const [selectedFeature, setSelectedFeature] = useState(1)
  const [isMobileOnly] = useSelector(state => [state.global.isMobile])

  const quotes = [
    {
      feature: "Compliance",
      header: `
      <h1>Compliance makes it easier to enter new markets</h1>
      <div>The financial industry is saturated and increasingly regulated, which creates barriers for potential customers to start using your product.
      <br/>
      <br/>
      In the integration you can access every necessary solution for identifying your customer, verifying their identity, and perform registry checks to safely enroll new customers and adhere to regulations in any jurisdiction across 165 countries.</div>
      `
    },
    {
      feature: "Global coverage",
      header: `
      <div>
      <h1>Verify and onboard customers globally</h1>
      <div>Thousand of ID types, dozens of languages, and multitude of devices quickly make it challenging to <a href="/id-scanner">verify your customers' identities</a> across the globe.
      <br/><br/>
      With <a href='/id-scanner/documents'>13,000+ supported ID types</a> from 190+ countries, Checkin.com makes it easy for you to verify your customers wherever they are. We're also able to guide your clients through the verification process in 80+ languages.
      </div>
      </div>
      `
    },
    {
      feature: "Higher conversion",
      header: `
      <h1>More customers complete the process when it's easy and intuitive</h1>
      <div>Checkin.com uses machine learning and data-driven UX modules to <a href='/blog/kyc-signup-flow-guide'>optimize the flow for each customer</a> based on their location and behavior. The outcome is a verification flow that will grab your customers' attention and keep it throughout the process.</div>
      `
    },
    {
      feature: "Localization",
      header: `
      <div>
      <h1>Create better user experience</h1>
      <div>Users expect a localized and intuitive onboarding flow, and adapting it for a specific regions is a complex and often expensive project.
      <br/>
      <br/>
      Checkin.com <a href='/blog/localized-id-verification/'>localizes the flows</a> out of the box and gives you the ability to guide your customers in 80+ languages, access  necessary third-party services for additional lookups and user verifications.
      </div>
      `
    },

    {
      feature: "Secure data handling",
      header: `
      <div>
      <h1>You keep the full control of user data</h1>
      <div>Data protection regulation require companies to have a compliant process for data collection, storage and processing.
      <br/>
      <br/>
      With Checkin.com, all personal data is stored on database servers in the EU or US (depending on target market) situated in AWS or Google Cloud, fully compliant with european privacy laws. 
      <br/>
      <br/>
      
      All personal data is heavily encrypted and can only be accessed by an authorized person. </div>
      `
    }
  ]

  useEffect(() => {
    setSelectedFeature(0)
  }, [])
  return (
    <Container>
      <FeatureSelector>
        <FeatureSelectorList>
          {quotes.map((quote, index) => (
            <FeatureItem key={index} isselected={index === selectedFeature}>
              <span onClick={() => setSelectedFeature(index)}>
                {quote.feature}
                {isMobileOnly ? (
                  <Angle active={index === selectedFeature} />
                ) : (
                  <Arrow />
                )}
              </span>

              <TabletView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].header,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </TabletView>

              <MobileView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].header,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </MobileView>
            </FeatureItem>
          ))}
        </FeatureSelectorList>
      </FeatureSelector>

      <DesktopView>
        <QuoteCardContainer>
          <SwitchTransition>
            <CSSTransition
              key={selectedFeature}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
            >
              <QuoteCard>
                <div>
                  <QuoteCardText
                    dangerouslySetInnerHTML={{
                      __html: quotes[selectedFeature].header,
                    }}
                  />
                </div>
              </QuoteCard>
            </CSSTransition>
          </SwitchTransition>
        </QuoteCardContainer>
      </DesktopView>
    </Container>
  )
}
