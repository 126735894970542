import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import lottie from 'lottie-web/build/player/lottie_light.min.js'
import WhiteLabel from '../../assets/white-label.json'
import Global from '../../assets/global.svg'
import native from '../../assets/native.json'
import Face from '../../assets/images/brazil/face-reco.svg'
import Geo from '../../assets/images/brazil/geoloc.svg'
import CPF from '../../assets/images/brazil/cpf.svg'
import Data from '../../assets/images/brazil/data.svg'
import Pep from '../../assets/images/brazil/pep.svg'
import Scan from '../../assets/images/brazil/id-scan.svg'


const Container = styled.div`
    display: flex;
    grid-area: bento;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`


const Animation = styled.div`
    position: absolute;
    bottom: -5px;
    left: 0px;
`

const Features = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    margin-top: 32px;
    .item {
        grid-column: span 1;
        border: 1px solid rgba(0, 0, 0, 0.20); 
        background: #FFF; 
        height: 550px;
        padding: 30px;
        position: relative;

        img {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
        }

    }
    h3 {
        color: #0D0D0D; 
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 700; 
    }
    p{
        color: rgba(13, 13, 13, 0.80); 
        font-size: 18px;
        font-weight: 400; 
        line-height: 30px;
    }

    @media screen and (max-width: 980px) {
        column-gap: 16px;
        margin-top: 16px;
        .item {
            padding: 16px;
            height: 376px;
        }
        h3{
            font-size: 18px;
        }
        p{
            font-size: 14px;
            line-height: 24px;
        }
    }

    @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        margin-top: 24px;

        .item{
            height: 432px;
            padding: 24px;
        }
        .item.global {
            height: 465px;
        }
        .item3{
            ${Animation}{
                bottom: -30px;
            }
            
        }
    }

`

function Bento() {

    let animation1 = null;
    let animation2 = null;
    let animation3 = null;
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    useEffect(() => {

        animation1 = lottie.loadAnimation({
            container: ref1.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: WhiteLabel

        });
        animation2 = null
        animation3 = lottie.loadAnimation({
            container: ref3.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: native

        });
        return () => { animation1.destroy(); animation3.destroy() }
    }, []);
    return (
        <Container>
            <Features>
                <div className='item global'>
                    <h3>ID Scanning and Face matching</h3>
                    <p>Fast and secure ID checks that safeguard both your business and your users.</p>
                    <img src={Scan} />
                </div>
                <div className='item'>
                    <h3>Geo-Location check + VPN & Proxy Detection</h3>
                    <p>Confirm the location of your users to meet new geo-restrictions and avoid regulatory pitfalls.</p>
                    <img src={Geo} />
                </div>
                <div className='item item3'>
                    <h3>Fast and accurate CPF verification</h3>
                    <p>Ensure your players are who they say they are with our reliable CPF validation service.</p>
                    <img src={CPF} />
                </div>
            </Features>
            <Features>
                <div className='item global'>
                    <h3>Authenticate with Face recognition</h3>
                    <p>Seamless account recovery, deposit, and withdrawal verification, fully protected against deepfake attacks for top security.</p>
                    <img src={Face} />
                </div>
                <div className='item'>
                    <h3>AML, PEP, Sports affiliation screening</h3>
                    <p>Advanced AML checks with PEP screening, including International sporting organisation officials, ensuring you remain compliant.</p>
                    <img src={Pep} />
                </div>
                <div className='item item3'>
                    <h3>Secure data storage</h3>
                    <p>All sensitive data is securely stored within Brazil, with the option to keep it in a vault for up to 10 years, ensuring compliance.</p>
                    <img src={Data} />
                </div>
            </Features>
        </Container>

    );
}

export default Bento;