import React, { useState, useEffect, useRef, useCallback } from "react"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"

// import HeroIllustration from "../../assets/images/igaming/hero-illustration.inline.svg"
import HeroIllustration from "../../assets/images/igaming/hero-header.inline.svg"
import HeroIllustrationMobile from "../../assets/images/igaming/slot-no-handle.inline.svg"
import RedHandle from "../../assets/images/igaming/redhandle.inline.svg"
import SlotMachineTrigger from "../../assets/images/igaming/slotmachine-trigger.inline.svg"
import prizeRowOne from "../../assets/images/igaming/prize-row-1.svg"
import prizeRowTwo from "../../assets/images/igaming/prize-row-2.svg"
import prizeRowThree from "../../assets/images/igaming/prize-row-3.svg"
import slotSpin from "../../assets/images/igaming/slot-spin.mp4"

import { pushCustomDataLayerEvent } from "../../state/actions"

const CenteringContainer = styled.div`
  grid-area: slot-machine;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -250px;
  margin-bottom: -20px;

  @media screen and (max-width: 980px) {
    overflow: hidden;
  }

  @media screen and (max-width: 640px) {
    margin-top: -320px;
    margin-bottom: -228px;
  }

  @media screen and (max-width: 375px) {
    margin-top: -300px;
    margin-bottom: -248px;
  }
`

const Container = styled.div`
  height: 559px;
  min-height: 559px;
  min-width: 1086px;
  margin: 0 auto;
  transform: translate3d(-20px, -60px, 0) scale(1.3);
  position: relative;

  @media screen and (max-width: 640px) {
    transform: scale(0.8) translate3d(-39px, -60px, 0);
  }

  @media screen and (max-width: 375px) {
    transform: scale(0.7) translate3d(-20px, -65px, 0);
  }
`

const AnimatedHeroIllustration = styled(HeroIllustration)`
    width: 162%;
    left: -312px;
    height: 132%;
    pointer-events: none;
    position: absolute;

    @media screen and (max-width: 640px) {
      display: none;
    }
`
const AnimatedHeroIllustrationMobile = styled(HeroIllustrationMobile)`
    width: 34%;
    right: 341px;
    height: 157%;
    pointer-events: none;
    position: absolute;

    @media screen and (min-width: 641px) {
      display: none;
    }
`

const SlotOne = styled.div`
  position: absolute;
  width: 78px;
  height: 103px;
  background: #fff;
  top: 390px;
  left: 425px;
  overflow: hidden;
  z-index: -1;
`

const SlotTwo = styled(SlotOne)`
  left: 526px;
`

const SlotThree = styled(SlotOne)`
  left: 620px;
`

const PrizeRowOne = styled.div`
  width: 81px;
  height: 1000px;
  background: url(${prizeRowOne}) no-repeat top center/100%;
  transform: ${props => `translate3d(0, ${props.position || 0}px, 0)`};
  transition: all 0.15s ease;
`

const PrizeRowTwo = styled(PrizeRowOne)`
  background: url(${prizeRowTwo}) no-repeat top center/100%;
  transform: ${props => `translate3d(0, ${props.position || 0}px, 0)`};
`

const PrizeRowThree = styled(PrizeRowOne)`
  background: url(${prizeRowThree}) no-repeat top center/100%;
  transform: ${props => `translate3d(0, ${props.position || 0}px, 0)`};
`

const SlotSpin = styled.video`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${props => (props.active ? "1" : "0")};
  transition: opacity 0.2s ease;
`

const TriggerAndHandleContainer = styled.div`
  position: absolute;
  bottom: 84px;
  right: 272px;
  height: 105px;
  transition: all 0.2s ease;

  ${props =>
    props.spinning &&
    css`
      height: 80px;
    `}
`

const TriggerContainer = styled.div`
  overflow: hidden;
  max-height: 100%;
  display: flex;
  align-items: flex-end;
`

const StyledRedHandle = styled(RedHandle)`
  position: absolute;
  top: -10px;
  right: -8px;
  cursor: pointer;
  transition: all 0.2s ease;

  ${props =>
    props.spinning &&
    css`
      pointer-events: none;
      transform: scale(1.1);
    `}

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`

const StyledSlotMachineTrigger = styled(SlotMachineTrigger)`
  overflow: hidden;
`

export default function SlotMachine() {
  const dispatch = useDispatch()
  const [userSpinsCount, setUserSpinsCount] = useState(0)
  const [spinning, setSpinning] = useState(false)
  const [spinningOne, setSpinningOne] = useState(false)
  const [spinningTwo, setSpinningTwo] = useState(false)
  const [spinningThree, setSpinningThree] = useState(false)
  const [selectedPrizes, setSelectedPrizes] = useState([6, 6, 6])
  const prizePositions = [0, -100, -200, -300, -400, -500, -600]
  const spinInterval = useRef()
  const [slotSpin1Ref, slotSpin2Ref, slotSpin3Ref] = [
    useRef(),
    useRef(),
    useRef(),
  ]


  const onHandleClick = spinsCount => {
    slotSpin1Ref.current && slotSpin1Ref.current.play()
    slotSpin2Ref.current && slotSpin2Ref.current.play()
    slotSpin3Ref.current && slotSpin3Ref.current.play()
    setSpinning(true)
    setSpinningOne(true)
    setSpinningTwo(true)
    setSpinningThree(true)

    setSelectedPrizes([
      spinsCount % 3 === 0 ? 6 : Math.round(Math.random() * 6),
      spinsCount % 3 === 0 ? 6 : Math.round(Math.random() * 6),
      spinsCount % 3 === 0 ? 6 : Math.round(Math.random() * 6),
    ])

    setTimeout(() => {
      setSpinning(false)
    }, 500)

    setTimeout(() => {
      setSpinningOne(false)
    }, 1000)

    setTimeout(() => {
      setSpinningTwo(false)
    }, 2000)

    setTimeout(() => {
      setSpinningThree(false)
    }, 3000)
  }

  const clickHandle = useCallback(async (spinsCount, stop = false) => {
    onHandleClick(spinsCount)
    if (stop || !spinInterval.current) {
      return (spinInterval.current = false)
    }
    await new Promise(resolve => setTimeout(resolve, 4000))
    clickHandle(spinsCount + 1, spinsCount === 0)
  }, [])

  const userClickHandle = () => {
    clickHandle(userSpinsCount, true)
    setUserSpinsCount(v => v + 1)
    dispatch(
      pushCustomDataLayerEvent({
        event: "slot_machine",
        eventCategory: "slot_machine",
        eventAction: "click",
        eventLabel: "slot_machine",
      })
    )
  }

  useEffect(() => {
    spinInterval.current = true
    clickHandle(0)
    return () => (spinInterval.current = false)
  }, [clickHandle])

  return (
    <CenteringContainer>
      <Container>
        <AnimatedHeroIllustration spinning={spinning} />
        <AnimatedHeroIllustrationMobile spinning={spinning} />
        <TriggerAndHandleContainer spinning={spinning}>
          <StyledRedHandle
            spinning={spinning}
            disabled={
              spinningOne ||
              spinningTwo ||
              spinningThree ||
              spinInterval.current
            }
            onClick={userClickHandle}
          />

          <TriggerContainer>
            <StyledSlotMachineTrigger />
          </TriggerContainer>
        </TriggerAndHandleContainer>
        <SlotOne>
          <PrizeRowOne
            position={
              spinningOne
                ? prizePositions[selectedPrizes[0]] + 100
                : prizePositions[selectedPrizes[0]]
            }
          />
          <SlotSpin
            ref={slotSpin1Ref}
            autoPlay
            loop
            muted
            playsInline
            src={slotSpin}
            active={spinningOne}
          ></SlotSpin>
        </SlotOne>
        <SlotTwo>
          <PrizeRowTwo
            position={
              spinningTwo
                ? prizePositions[selectedPrizes[1]] + 100
                : prizePositions[selectedPrizes[1]]
            }
          />
          <SlotSpin
            ref={slotSpin2Ref}
            autoPlay
            loop
            muted
            playsInline
            src={slotSpin}
            active={spinningTwo}
          ></SlotSpin>
        </SlotTwo>
        <SlotThree>
          <PrizeRowThree
            position={
              spinningThree
                ? prizePositions[selectedPrizes[2]] + 100
                : prizePositions[selectedPrizes[2]]
            }
          />
          <SlotSpin
            ref={slotSpin3Ref}
            autoPlay
            loop
            muted
            playsInline
            src={slotSpin}
            active={spinningThree}
          ></SlotSpin>
        </SlotThree>
      </Container>
    </CenteringContainer>
  )
}
