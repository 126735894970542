import React, { useState, useContext, useEffect, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import { navigate, Link } from "gatsby"
import { useInView } from 'react-intersection-observer';
import { useSelector } from "react-redux"

import IconDoc from '../../assets/images/id-scanner/icon-doc.inline.svg'
import Liveness from '../../assets/images/id-scanner/liveness.inline.svg'
import NFC from '../../assets/images/id-scanner/nfc.inline.svg'
import Face from '../../assets/images/id-scanner/face.inline.svg'
import Signature from '../../assets/images/id-scanner/signature.inline.svg'
import Poa from '../../assets/images/id-scanner/poa.inline.svg'
import Data from '../../assets/images/id-scanner/data.inline.svg'
import Learn from '../../assets/images/learn.svg'

const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.5s ease-in-out;
`
const Container = styled.div`
    grid-area: video;
    display: flex;
    justify-content: space-between;


    .buttons {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        width: 350px;
    }

    .button {
        display: flex;
        padding: 10px;
        margin-bottom: 10px;
        padding-left: 0px;
        cursor: pointer;
        position: relative;
        margin-bottom: 24px;
        
        &.notSelected{
            opacity:0.5;
        }

        &.selected ${Icon} {
            background: #FACD38;
        }
    }
    .text-container{
    }

    .progress-bar {
        height: 2px;
        width: 0;
        background-color: #FACD38;
        position: absolute;
        bottom: 0;
        left: 0;
        
    }

    .video-container {
        position: relative;
        width: 736px;
        height: 800px;    
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: var(--Secondary-Colours-Primary-Colours-White, #FFF);

        button {
            cursor: pointer;
        }
    }


    video {
        width: 100%;
        transition: opacity 0.5s ease-in-out; /* Smooth fade transition */
      }
      
      video.fade-out {
        opacity: 0;
      }
      
      video.fade-in {
        opacity: 1;
      }

    .learn {
        position: absolute;
        bottom: 24px;
        right: 24px;
        height: 64px;
        padding: 16px;
        justify-content: center; 
        border-radius: 50px;
        border: 1px solid var(--Text-Primary, #000);
        background: #FFF;
        font-size: 18px;
        font-weight: 700; 
        box-shadow: 4px 4px 0px 0px rgba(13, 13, 13, 0.10);
        align-items: center;
        display: flex;

        img{
            width: 36px;
            height: 36px;
            margin-right: 16px;
        }
    }

    @media screen and (max-width: 980px) {
        justify-content: space-between;
        flex-direction: column-reverse;

        .video-container {
            width: auto;
            height: 743px;
            margin-bottom: 40px;
        }
        .buttons {
            width: 100%;
        }
        .button.notSelected{ 
            border-bottom: 1px solid rgba(0, 0, 0, 0.15); 
        }
    }

    @media screen and (max-width: 640px) {
        position: relative;
        .buttons.transitionOn {
            transition: margin 0.3s ease;
        }
        .buttons.transitionOff {
            transition: none;
        }
        .video-container {
            height: 373px; 
            width: 343px;

            video{
                width: 100%;
                height: 100%;
            }
        }
        .sticky-video {
            position: relative;
            background: white;
            transition: transform 1s ease; /* Smooth transition for transform property */
            will-change: transform;
        }

        .learn {
            width: 138px;
            height: 48px;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            padding: 0px;

            img {
                width: 30px;
                height: 30px;
                margin-right: 12px;
                }
        }
    }
`

const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;  
    p {
        margin-left: 8px;
    }

    @media screen and (max-width: 980px) {
        font-size: 20px;
        line-height: 28px;  
        font-weight: 800;
    }
`
const Description = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    overflow: hidden;
    margin-left: 50px;
    transition: max-height 0.3s ease-out;
  
    &.collapsed {
        max-height: 0;
        opacity: 0;

    }
    
    &.expanded {
        max-height: 200px; /* Adjust based on your content */
        opacity: 1;

    }

    @media screen and (max-width: 980px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;  
    }
`


const videos = [
    {
        id: '0',
        title: 'Digitalização de documentos',
        description: 'Tecnologia de alta precisão para autenticar e extrair dados.',
        src: '/videos/id-scanner/doc-scan-br.mp4',
        srcAv1: '/videos/id-scanner/doc-scan-br-av1.mp4',
        link: '/product/document-scanning/',
        icon: <IconDoc />,
        next: 1
    },
    {
        id: '1',
        title: 'Detecção de vida',
        description: 'Garante a presença física e protege contra falsificação.',
        src: '/videos/id-scanner/liveness.mp4',
        srcAv1: '/videos/id-scanner/liveness-av1.mp4',
        link: '/product/liveness',
        icon: <Liveness />,
        next: 2
    },
    {
        id: '2',
        title: 'Reconhecimento facial',
        description: 'Tanto reconhecimento 1-N para correspondência ampla quanto verificação 1-1.',
        src: '/videos/id-scanner/face-recognition.mp4',
        srcAv1: '/videos/id-scanner/face-recognition-av1.mp4',
        link: '/product/face-recognition',
        icon: <Face />,
        next: 3
    },
    {
        id: '3',
        title: 'Verificação de dados',
        description: 'Verificação automática, híbrida ou manual com checagem de bancos de dados.',
        src: '/videos/id-scanner/crosscheck-br.mp4',
        srcAv1: '/videos/id-scanner/crosscheck-br-av1.mp4',
        link: '/product/data-crosscheck',
        icon: <Data />,
        next: 0
    }
]

const VideoPlayer = ({ children, OpenModal }) => {
    const [currentVideo, setCurrentVideo] = useState({ ...videos[0] });
    const [nextVideo, setNextVideo] = useState(null);
    const [progress, setProgress] = useState({});
    const videoRef = useRef(null);
    const { ref: myRef, inView: sectionIsVisible } = useInView();
    const isMobile = useSelector(state => state.global.isMobileOnly)
    const [containerRef, containerInView] = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        containerInView ? videoRef.current.play() : videoRef.current.pause()
    }, [containerInView]);


    const handleButtonClick = (video) => {
        setNextVideo({ ...video });
    };

    const handleCanPlayThrough = () => {
        videoRef.current.play();
    };

    const handleClick = (video) => {
        navigate(videos[currentVideo.id].link)
    };

    const handleTimeUpdate = () => {
        const duration = videoRef.current.duration;
        const currentTime = videoRef.current.currentTime;
        const progressPercent = (currentTime / duration) * 100;
        setProgress(progressPercent);
        if (duration == currentTime) {

        }

    };

    const handleVideoEnd = () => {
        if (sectionIsVisible) { setNextVideo({ ...videos[currentVideo.next] }); }

    }
    useEffect(() => {
        videoRef.current.play()
    }, [])
    useEffect(() => {
        if (nextVideo) {
            videoRef.current.addEventListener('canplaythrough', handleCanPlayThrough, { once: true });
            videoRef.current.classList.add('fade-out');
            setTimeout(() => {
                setProgress(0);
                setCurrentVideo(nextVideo);
                videoRef.current.load();
                videoRef.current.classList.remove('fade-out');
                videoRef.current.classList.add('fade-in');
                setTimeout(() => {
                    videoRef.current.classList.remove('fade-in');
                }, 500);
            }, 500); // Duration should match the fade-out transition time
        }
    }, [nextVideo]);

    useEffect(() => {

        if (isMobile) {
            const handleScroll = () => {

                const videoElement = document.getElementsByClassName("video-container")[0];
                const buttonListElement = document.getElementsByClassName("buttons")[0];


                if (videoElement && buttonListElement) {
                    const videoRect = videoElement.getBoundingClientRect();
                    const buttonListRect = buttonListElement.getBoundingClientRect();
                    const offsetTop = videoElement.parentElement.offsetTop;
                    const offsetHeight = videoElement.offsetHeight;
                    if (window.scrollY + 45 >= offsetTop && window.scrollY < offsetTop + offsetHeight + buttonListRect.height - offsetHeight) {
                        videoElement.style.position = 'fixed';
                        videoElement.style.top = '45px';
                        videoElement.style.bottom = '';
                        buttonListElement.style.marginTop = offsetHeight + 45 + 'px';
                    } else if (window.scrollY >= offsetTop + buttonListRect.height - offsetHeight) {
                        videoElement.style.position = 'absolute';
                        videoElement.style.bottom = '-35px';
                        videoElement.style.top = '';


                    } else {
                        buttonListElement.classList.remove('transitionOn');
                        buttonListElement.classList.add('transitionOff');
                        buttonListElement.style.marginTop = '0px';
                        videoElement.style.position = 'relative';
                        videoElement.style.top = 'auto';

                    }
                }
            }

            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }


    }, []);

    return (
        <Container id='videoContainer'>
            <div ref={myRef} className="buttons">
                {videos.map(video => {
                    let isSelected = video.id == currentVideo.id
                    return (
                        <div
                            key={video.id}
                            className={`button ${isSelected ? 'selected' : 'notSelected'}`}
                            onClick={() => handleButtonClick({ ...video })}
                        >

                            <div className="text-container">
                                <Title><Icon>{video.icon}</Icon> <p>{video.title}</p></Title>
                                <Description className={` ${isSelected ? 'expanded' : 'collapsed'}`}>{video.description}</Description>
                                <div
                                    className="progress-bar"
                                    style={{
                                        width: `${isSelected ? progress : 0}%`,
                                        transition: isSelected ? 'width 1s linear' : 'width 0.2s ease-out'
                                    }}
                                ></div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div ref={containerRef} className="video-container">
                <video
                    muted
                    autoPlay
                    id="videoPlayer"
                    ref={videoRef}
                    onTimeUpdate={handleTimeUpdate}
                    onEnded={handleVideoEnd}
                    webkit-playsinline="true"
                    playsInline
                    className="sticky-video"
                >
                    <source src={currentVideo.srcAv1} type='video/mp4; codecs="av01.0.05M.08"' />
                    <source src={currentVideo.src} type='video/mp4; codecs="avc1.42E01E"' />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Container>
    );
};



export default VideoPlayer;
