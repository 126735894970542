import styled from "styled-components"
import LayoutGrid from "../layout-grid"

const Grid = styled(LayoutGrid)`
  grid-template-columns: auto repeat(12, 68px) auto;
  grid-gap: 30px;
  padding-top: 120px;
  z-index:1;
 
 
  @media screen and (max-width: 980px) {
    grid-template-columns: auto repeat(8, 68px) auto;
    grid-gap: 20px;
    overflow: hidden;
    padding-top: 50px;

    .fintech, .igaming, .ecom, .travel {
      height: 250px !important;
    }

  }

  @media screen and (max-width: 640px) {
    grid-template-columns: auto 147px 147px auto;
    grid-gap: 45px 25px;
    overflow: hidden;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: auto 147px 147px auto;
    grid-gap: 21px;
    grid-row-gap: 40px;
    overflow: hidden;
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: auto 132px 132px auto;
    grid-gap: 0px;
    grid-row-gap: 40px;
    overflow: hidden;
  }

  grid-template-areas:
    ". . . header header header header header header header header .  . ."
    "slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine slot-machine"
    ". . features-container features-container features-container features-container features-container features-container features-container features-container features-container features-container . ."
    ". . . . . . . . . . . . . ."
    ". . . . talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 talk-to-sales-1 . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . why-header why-header why-header why-header why-header why-header  why-header why-header why-header why-header . ."
    ". . why why why why why why why why why why . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    " . . partners partners partners partners partners partners partners partners partners partners . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    " . . . .  partnership-header partnership-header partnership-header partnership-header partnership-header partnership-header . . . ."
    " .  partnership partnership partnership partnership partnership partnership partnership partnership partnership partnership partnership partnership  ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". gateway gateway gateway gateway gateway gateway gateway gateway gateway gateway gateway gateway  ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . optimal optimal optimal optimal optimal optimal . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". fintech fintech fintech fintech fintech fintech travel travel travel travel travel travel ."
    ". fintech fintech fintech fintech fintech fintech igaming igaming igaming igaming igaming igaming ."
    ". more more ecom ecom  social social  subsc subsc edtech edtech health health  ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ;

  @media screen and (max-width: 980px) {
    grid-template-areas:
    ". header header header header header header header header ."
    ". slot-machine  slot-machine slot-machine slot-machine slot-machine  slot-machine slot-machine slot-machine . "
    ". . features-container features-container features-container features-container features-container features-container . ."
    ". . . talk-to-sales-1 talk-to-sales-1  talk-to-sales-1 talk-to-sales-1 . . ."
    ". . . . . . . . . ."
    ". . why-header why-header why-header why-header why-header why-header . ."
    ". why why why why why why why why ."
    ". . . . . . . . . ."
    ". partners partners  partners partners  partners partners  partners partners ."
    ". . . . . . . . . ."
    ". partnership-header partnership-header partnership-header partnership-header partnership-header partnership-header partnership-header partnership-header."
    ". partnership partnership partnership partnership partnership partnership partnership partnership ."
    ". . . . . . . . . ."
    ". gateway gateway  gateway gateway  gateway gateway  gateway gateway ."
    ". . . . . . . . . ."
    ". optimal optimal optimal optimal optimal optimal optimal optimal ."
    ". . . . . . . . . ."
    ". fintech fintech fintech fintech igaming igaming igaming igaming ."
    ". ecom ecom  ecom ecom travel travel travel travel ."
    ". subsc subsc social social  edtech edtech  health health."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . ."
    ;
  }

  @media screen and (max-width: 640px) {
    grid-template-areas:
    ". header header ."
    "slot-machine  slot-machine slot-machine slot-machine "
    ". features-container features-container ."
    ". talk-to-sales-1 talk-to-sales-1 ."
    ". . . ."
    ". why-header why-header ."
    ". why why ."
    ". . . ."
    ". partners partners ."
    ". . . ."
    ". partnership-header partnership-header ."
    ". partnership partnership ."
    ". . . ."
    ". gateway gateway ."
    ". . . ."
    ". . . ."
    ". optimal optimal ."
    ". . . ."
    ". fintech fintech ."
    ". igaming igaming ."
    ". travel travel ."
    ". ecom ecom ."
    ". social social ."
    ". subsc subsc ."
    ". edtech edtech ."
    ". health health ."
    ". . . ."
    ". . . ."
    ". . . .";
  }
  
`

export default Grid
