import React from "react"

import styled, { css } from "styled-components"
import { useSelector } from "react-redux"

import Logos from "../../assets/images/igaming/logos.inline.svg"
import LogosMobile from "../../assets/images/igaming/logos-mobile.inline.svg"


const Container = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  justify-content: center;
  

`

const LogosContainer = styled.div`
  min-width: 1440px;

  @media screen and (max-width: 980px) {
    width: 700px;
    min-width: 700px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
  
`

export default function LeadingBrands({
  gridArea,
}) {
  const isMobileOnly = useSelector(state => state.global.isMobileOnly)
  return (
    <Container gridArea={gridArea}>
      <LogosContainer>
        {isMobileOnly ? (<LogosMobile />) : (<Logos />)}
      </LogosContainer>
    </Container>
  )
}
