import { useState, useEffect, useRef } from "react"

const useWaypoint = (elementRef, offset = 0) => {
  const [reached, setReached] = useState(false)
  const requestRef = useRef()

  useEffect(() => {
    const trackIfReached = () => {
      if (
        !elementRef ||
        !elementRef.current ||
        !elementRef.current.getBoundingClientRect
      )
        return

      const { top } = elementRef.current.getBoundingClientRect()
      if (top <= window.innerHeight - offset) setReached(true)
      else setReached(false)

      requestRef.current =
        typeof window !== "undefined" &&
        window.requestAnimationFrame(trackIfReached)
    }

    requestRef.current =
      typeof window !== "undefined" &&
      window.requestAnimationFrame(trackIfReached)

    return () =>
      typeof window !== "undefined" &&
      window.cancelAnimationFrame(requestRef.current)
  }, [elementRef, offset])

  return reached
}

export default useWaypoint
