import React, { useRef, useEffect, useContext } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import GlobalContext from "../../context/global-context"

const FakeInput = styled.input`
  position: absolute;
  opacity: 0;
  transform: translateY(-200px);
`

const UsersCount = ({ onDone = null }) => {
  const fakeInputRef = useRef()
  const globalContext = useContext(GlobalContext)

  useEffect(() => {
    navigate("/")
    globalContext.openGetStarted("emailForm", true)
    fakeInputRef.current && fakeInputRef.current.focus()
  }, [globalContext])

  return (
    <>
      <FakeInput ref={fakeInputRef} readOnly />
    </>
  )
}

export default UsersCount
