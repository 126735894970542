import React, { useState } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { SwitchTransition, CSSTransition } from "react-transition-group"

import arrow from "../../assets/images/arrow-next.svg"

import AngleIcon from "../../assets/images/angle.inline.svg"

import { DesktopView, MobileView, TabletView } from "../device-views"

const Angle = styled(AngleIcon)`
  width: 30px;
  transition: all 0.2s ease;
  opacity: 0;
  ${props =>
    props.active &&
    css`
      fill: #000;
      opacity: 1;
    `}
`

const Container = styled.div`
  display: flex;
  grid-area: partner-quotes;
  margin-top: 70px;
`

const FeatureSelector = styled.div`
  display: flex;
  flex: 1;
`

const FeatureSelectorList = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 0 34px 0 68px;

  @media screen and (max-width: 980px) {
    padding: 0;
  }
`

const Arrow = styled.span`
  width: 27px;
  height: 18px;
  background: url(${arrow}) no-repeat center;
  fill: #000;
`

const FeatureItem = styled.li`
  font-size: 24px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  overflow: hidden;
  box-sizing: border-box;

  & > span {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 0 18px;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: normal;
  }

  ${Arrow} {
    opacity: 0;
    transition: all 0.2s ease;
  }

  @media screen and (max-width: 980px) {
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;
    font-size: 20px;
  }

  ${props =>
    props.isselected &&
    css`
      @media screen and (max-width: 980px) {
        max-height: 100%;
      }

      & > span {
        background: #fff;
        color: #000;
        border: 1px solid #040404;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.0853365);
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        @media screen and (max-width: 980px) {
          margin: 0;
        }
      }

      ${Arrow} {
        opacity: 1;
      }
    `}
`

const QuoteCardContainer = styled.div`
  flex: 1;
  z-index: 1;
  height: 400px;

  @media screen and (max-width: 980px) {
    box-sizing: border-box;
    height: auto;
    max-height: 100%;
    max-height: 0%;
    opacity: 0;
    overflow: hidden;
    transition: all 0.2s ease;
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    max-height: 72px;

    ${props =>
    props.active &&
    css`
        max-height: 100%;
        opacity: 1;
      `}
  }
`

const QuoteCard = styled.div`
  margin: 0 60px 0 0;
  background: #fff9f2;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
  display: flex;
  height: 430px;

  @media screen and (max-width: 980px) {
    margin: 20px 0;
    height: auto;
  }

  &::after {
    content: "";
    border: 1px dashed #000;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px;
    left: 10px;
    z-index: -1;
  }

  & > div {
    flex: 1;
    background: #fff9f2;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &.fade-enter {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  &.fade-enter-active,
  &.fade-exit-active {
    transition: all 0.2s ease;
  }
`

const QuoteCardText = styled.p`
  font-size: 18px;
  line-height: 30px;

  span {
    font-weight: 700;
  }
  ul {
    margin-top: 20px;
    margin-left: 25px;
  }
`

const Header = styled.h1`
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 24px;
`



export default function PartnerQuotes() {
  const [selectedFeature, setSelectedFeature] = useState(0)
  const [isMobileOnly] = useSelector(state => [state.global.isMobile])

  const quotes = [
    {
      feature: "Product",
      header: "Optimal user experience from day one",
      quoteText: `Instead of analyzing data, drafting new designs and testing them, product teams can focus on projects closer to your core business. Our data-driven modules ensures a great experience and high conversion across all devices and geographies.
      <ul>
        <li>AI-driven UX & localization</li>
        <li>Full control over branding</li>
        <li>Easy customization via backoffice</li>
      </ul>`,
    },
    {
      feature: "Tech",
      header: "Simple integration to powerful solutions",
      quoteText: `Save weeks of time normally spent on integrating and testing by accessing the optimal solutions through a single flexible integration. You can use any feature of the framework like, document scanning, liveness detection or data extaction in any manner that fits your structure. 
      <ul>
        <li>Easy integration to any system via JS or SDK</li>
        <li>Dedicated technical support</li>
        <li>Custom integration guide based on your setup</li>
      </ul>`,
    },
    {
      feature: "Operations",
      header: "Scale across multiple markets with ease",
      quoteText: `For regulated and age-restricted services it is critical for business expansion to have the correct solutions in place, and each day of delays means increased risk or lost opportunity. With Checkin.com new solutions are implemented within days, not months. 
      <ul>
        <li>Launch new initiatives faster</li>
        <li>More time to focus on your core business</li>
        <li>Powerful and cost-efficient technology</li>
      </ul>`,
    },
    {
      feature: "Marketing",
      header: "Boost conversion & stay compliant",
      quoteText: `Our technology is designed to enable faster growth for brands in regulated spaces. Checkin.com turns friction into engagement by grabbing the customers attention and keep it through the entire flow, ensuring high conversion and a great brand experience. 
      <ul>
        <li>Increase conversion from day one</li>
        <li>Detailed reporting and insights</li>
        <li>Powerful and cost-efficient technology</li>
      </ul>`,
    },
  ]
  return (
    <Container>
      <FeatureSelector>
        <FeatureSelectorList>
          {quotes.map((quote, index) => (
            <FeatureItem key={index} isselected={index === selectedFeature}>
              <span onClick={() => setSelectedFeature(index)}>
                {quote.feature}
                {isMobileOnly ? (
                  <Angle active={index === selectedFeature} />
                ) : (
                  <Arrow />
                )}
              </span>

              <TabletView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>

                      <Header>{quotes[index].header}</Header>
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].quoteText,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </TabletView>

              <MobileView>
                <QuoteCardContainer active={index === selectedFeature}>
                  <QuoteCard>
                    <div>
                      <Header>{quotes[index].header}</Header>
                      <QuoteCardText
                        dangerouslySetInnerHTML={{
                          __html: quotes[index].quoteText,
                        }}
                      />
                    </div>
                  </QuoteCard>
                </QuoteCardContainer>
              </MobileView>
            </FeatureItem>
          ))}
        </FeatureSelectorList>
      </FeatureSelector>

      <DesktopView>
        <QuoteCardContainer>
          <SwitchTransition>
            <CSSTransition
              key={selectedFeature}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
            >
              <QuoteCard>
                <div>
                  <Header>{quotes[selectedFeature].header}</Header>

                  <QuoteCardText
                    dangerouslySetInnerHTML={{
                      __html: quotes[selectedFeature].quoteText,
                    }}
                  />
                </div>
              </QuoteCard>
            </CSSTransition>
          </SwitchTransition>
        </QuoteCardContainer>
      </DesktopView>
    </Container>
  )
}
