import styled, { css } from "styled-components"


export const Container = styled.div`
grid-area: ${props => props.gridArea};
border: 1px solid #000; 
background: #FFF; 
padding: 48px;
position: relative;
padding-bottom: 0px;
&::after {
    content: "";
    border: 1px dashed #000;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px ;
    left: 10px ;
    z-index: -1;
    
  }
  @media screen and (max-width: 980px) {
    padding: 15px 15px 0px;
    text-align: center;
  }
`

export const H4 = styled.h4`
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 30px;
text-align: center;
`
export const ExplainerBody = styled.div`

display: flex;
flex-direction: row;
@media screen and (max-width: 980px) {
    flex-direction: column;
  }
}
`

export const AnimationContainer = styled.div`
padding: 60px;
width: 50%;
text-align: center;

p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
@media screen and (max-width: 980px) {
    width: 100%;
    padding: 0px;
}

`

export const ExplainerText = styled.div`
padding: 60px;
font-size: 16px;
font-weight: 400;
line-height: 28px;

@media screen and (max-width: 980px) {
    padding: 0px;
    margin-top: 40px;
}

`

export const Head = styled.div`
font-size: 18px;
font-weight: 700;
line-height: 30.02px;
margin-bottom: 8px;
@media screen and (max-width: 980px) {
    margin-top: 8px;
    }

`
export const Notice = styled.div`
background: #F9F9F8; 
color: #0D0D0D;
font-family: "Object Sans";
font-size: 14px;
font-weight: 400;
line-height: 20px;
border-radius: 8px; 
padding: 8px; 
margin-bottom: 8px;

`
export const Animation = styled.div`
padding-top: 25px;
width: 440px;
margin-bottom: 42px;
@media screen and (max-width: 980px) {
    width: 100%;
}
`
export const ExplainerBlock = styled.div`
margin-bottom: 40px;
svg {
  height: 35px;
}
@media screen and (max-width: 980px) {


display: flex;
  flex-direction: column;
  align-items: center;
}
`