import styled from "styled-components"
import LayoutGrid from "../layout-grid"

const Grid = styled(LayoutGrid)`
margin-top: 70px;
  grid-template-areas:
    "header header header header header header header header header header header header header header"
    ". description description description description description description description description description description description description ."
    ". . . . . . . . . . . . . ."
    ". topics topics topics topics topics topics topics topics search search search search ."
    ". . . . . . . . . . . . . ."
    ". posts posts posts posts posts posts posts posts posts posts posts posts  ."
    ". . . . . more more more more . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."
    ". popular popular popular popular popular popular popular popular popular popular popular popular ."
    ". . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . ."

    ;

  @media screen and (max-width: 980px) {
    margin-top: 50px;
    grid-template-areas:
      ". . header header header header header header . ."
      ". . description description description description description description . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". topics topics topics topics topics topics  . . ."
      ". search search search search search search  search search ."
      ". posts posts posts posts posts posts posts posts ."
      ". . .  more more more more  . . ."
      ". . . . . . . . . ."
      ". . . . . . . . . ."
      ". popular popular popular popular popular popular popular popular ."
      ". . . . . . . . . ."
      ". . . . . . . . . .";
  }

  @media screen and (max-width: 640px) {
    padding-top: 0px;
    
    grid-template-areas:   
      ". header ."
      ". description ."
      ". . ."
      ". topics ."
      ". search ."
      ". posts ."
      ". more ."
      ". . ."
      ". . ."
      ". popular ."
      ". . ."
      ". . ."
      ;
  }
`

export default Grid
