import React, { useContext } from "react"
import styled, { css } from "styled-components"


import ActionButton from "../../components/action-button"
import GlobalContext from "../../context/global-context"
import Check from "../../assets/images/check.inline.svg"
import Star from "../../assets/images/aml/star.inline.svg"
import Workflow from "../../assets/images/fintech/workflow.inline.svg"
import Statistics from "../../assets/images/fintech/statistics.inline.svg"


const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  z-index: 1;

  &:last-child {
    margin-bottom: 80px;
  }
  &#delivery, &#fast-easy {
    @media screen and (max-width: 640px) {
      margin-bottom: 0px;
    }
  }

`

const H2 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;

  

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;
    br {
      display: none;
    }
  }
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
  }
`


const FeaturesContainer2 = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
margin-bottom: 50px;
flex-direction: column;


@media screen and (max-width: 980px) {
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  width: 296px;
  flex-direction: column;
}

`
const FeaturesColumn = styled.div`
display: flex;
justify-content: inherit;
@media screen and (max-width: 640px) {
  flex-direction: column;
}
`

const Feature2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  z-index: 1;
  margin-top: 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column !important;
    margin-right: 60px;
  }

  &:last-of-type {
    margin-right: 0px;

  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }
  

  ${H4} {
    margin-bottom: 16px;
    margin-top: 15px;
    @media screen and (max-width: 460px) {
      width: 100%;
      text-align: center;
    }
  }

  p {
    font-size: 20px;
    line-height: 30px;
    width: 424px;

    @media screen and (max-width: 980px) {
      width: 278px;
      text-align: left;
      font-size: 16px !important;
      line-height: 28px !important;

    }
    
  }

  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
      width: 100%;

    }
  }

  @media screen and (max-width: 640px) {
    p{
    text-align: center !important;
    }
  }


`

const ImageComtainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    height: auto;
  }
  object {
    width: 80px;
  height: 80px;

  }
`

const Buttons = styled.div`
  display: flex;
  width: 55%;
  justify-content: space-around;
  bottom: 0px;

  

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  @media screen and (max-width:640px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;


    button, a {
      width: 100%;
    }
  }
`


export default function FastEasy({
  gridArea,
}) {
  const globalContext = useContext(GlobalContext)


  return (
    <Container gridArea={gridArea}>

      <FeaturesContainer2>
        <FeaturesColumn>
          <Feature2>
            <ImageComtainer>
              <Star />
            </ImageComtainer>
            <H4>All-in-one integration for everything KYC</H4>
            <p>Checkin.com offers a comprehensive solution for KYC and AML. This means no hassle with multiple integrations — just one flexible and scalable integration that allows for client-specific customizations.
            </p>
          </Feature2>
          <Feature2>
            <ImageComtainer>
              <Workflow />
            </ImageComtainer>
            <H4>Faster time to <br />
              market</H4>
            <p>Incorporating Checkin.com's solutions is a seamless and efficient process with user-friendly APIs and a highly skilled integration team ready to assist you every step of the way.</p>
          </Feature2>

        </FeaturesColumn>
        <FeaturesColumn>
          <Feature2>

            <ImageComtainer>
              <Check />
            </ImageComtainer>
            <H4>Enterprise solution trusted by industry leaders</H4>
            <p>Top names in Travel, Finance, and iGaming trust Checkin.com to scale their businesses. Leverage our solutions for your clients' growth.</p>
          </Feature2>



          <Feature2>
            <ImageComtainer>
              <Statistics />
            </ImageComtainer>
            <H4>Exclusive perks and  benefits</H4>
            <p>Meet business goals and enjoy the benefits, including commissions. Our dedicated support team ensures your partnership with us is successful.
            </p>
          </Feature2>
        </FeaturesColumn>
      </FeaturesContainer2>

    </Container>
  )
}
