import React, { useState } from "react"
import styled, { css } from "styled-components"
import { rgba } from "polished"

import ExpandArrow from "../../assets/images/product/expand-arrow.inline.svg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  overflow: hidden;
  margin-bottom: 120px;
  transition: max-height 0.2s ease;

  ${props =>
    props.maxHeight &&
    css`
      max-height: ${`${props.maxHeight}px`};
    `}

  ${props =>
    props.isExpanded &&
    css`
      max-height: 5000px;
      margin-bottom: 0;
    `}

  @media screen and (min-width: 641px) {
    max-height: unset !important;
    display: contents;
  }
`

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 300px;
  background: ${props =>
    `linear-gradient(0deg, #f9f9f9 0 30%, ${rgba("#f9f9f9", 0)} 80% 100%);`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;

  span {
    font-size: 18px;
    font-weight: 400;
  }

  @media screen and (min-width: 641px) {
    display: none;
  }
`

const ExpandButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 5px 5px 0 0 ${rgba("#000", 0.1)};
  background: black;
  outline: none;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: scale(1.3);
    transition: transform 0.2s ease;

    ${props =>
      props.isExpanded &&
      css`
        transform: scale(1.3) rotate(180deg);
      `}
  }
`

export default function ExpandableContainer({ children, maxHeight = null }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <Container maxHeight={maxHeight} isExpanded={expanded}>
      {children}
      {!expanded && (
        <Controls>
          <span>Read more</span>
          <ExpandButton onClick={() => setExpanded(true)}>
            <ExpandArrow />
          </ExpandButton>
        </Controls>
      )}
    </Container>
  )
}
