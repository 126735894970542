import React, { useContext, createRef, useEffect } from "react"
import styled, { css } from "styled-components"
import ActionButton from "../../components/action-button"
import SEO from "../../components/seo"
import GlobalContext from "../../context/global-context"
import lottie from 'lottie-web/build/player/lottie_light.min.js'


import GridElements from "../../components/common/grid-elements"
import headerBackground from "../../assets/images/aml-background.svg"
import headerForground from '../../assets/images/aml-header-forground.json'
import GetStartedBottom from "../../components/common/get-started-bottom"
import Checks from "../../components/common/checks"
import Pep from '../../assets/images/aml/pep.inline.svg'
import Id from '../../assets/images/aml/id-verification.inline.svg'
import Sanction from '../../assets/images/aml/sanction-list.inline.svg'
import WhiteCheck from '../../assets/images/aml/white-check.inline.svg'
import Million from '../../assets/images/aml/threem.inline.svg'
import TenK from '../../assets/images/aml/tenk.inline.svg'
import Customize from '../../assets/images/aml/customize.inline.svg'
import Glob from '../../assets/images/aml/glob.inline.svg'

import Workflow from "../../assets/images/fintech/workflow.inline.svg"
import Easy from "../../assets/images/fintech/easy.inline.svg"
import Secure from "../../assets/images/fintech/secure.inline.svg"
import Star from "../../assets/images/aml/star.inline.svg"
import AmlAnimation from "../../assets/images/aml/aml_animation.json"
import loadable from '@loadable/component'
const HeaderAnimation = loadable(() => import('../../components/common/header-animation'))


const H1 = styled.h1`
  font-size: 48px;
  line-height: 56px;
  font-weight: 900;
  margin-bottom: 16px;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media screen and (max-width: 640px) {
    font-size: 34px;
    line-height: 44px;
    width: 320px;
  }
`

const H2 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;

  

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;
    br {
      display: none;
    }
  }
`
const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`
const CTAText = styled(H4)`
  text-align: center;
  br {
    display: none;
  }

  @media screen and (max-width: 980px) {
    width: 389px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
    br {
      display: block;
    }
  }
`
const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
  }
`

const SectionDescription1 = styled(SectionDescription)`
  width: 679px;

  @media screen and (max-width: 980px) {
    width: 578px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const Container = styled.div`
  flex: 1;
  width: 1170px;
  align-self: center;
  padding: 135px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 980px) {
    padding: 40px 0 0;
    width: 640px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
    padding: 50px 0 0;
  }


  .sliders {
    display: none;
    @media screen and (max-width: 640px) {
      display:block;
    }

  }

`

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  z-index:1;

  &:last-child {
    margin-bottom: 80px;
  }


`

const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`
const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`


const IndexIllustrationContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 132px;
  width: 74%;

  @media screen and (max-width: 980px) {
    top: 121px;
  }

  @media screen and (max-width: 640px) {
    top: 235px;
  }
`



const LeadsContainer = styled.div`
width: 48%;
margin-top: 100px;
@media screen and (max-width: 640px) {
  width: 100%;
  margin-top: 0px;

}


`

const LeadsImage = styled.div`
width: 100px;
@media screen and (max-width: 640px) {
  width: auto;
  margin-bottom: 10px;
}

`
const Lead = styled.div`

  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  margin-top: 15px;


  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }

  p{
    font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  }

`

const Content = styled.div`
margin-left: 30px;

${H4}{
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  }
  p{
    font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-top: 5px;
  }
`

const Animation = styled.div`

`


export default function AmlPage({ data, location }) {
  const globalContext = useContext(GlobalContext)
  let desktopAnimation = createRef();


  useEffect(() => {
    lottie.loadAnimation({
      container: desktopAnimation.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: AmlAnimation
    });

  }, []);

  return (
    <Container>
      <SEO
        title="AML Screening Software, PEP & Sanctions Checks via API or SDK"
        description="Complete solution for PEP & Sanctions Screening. Reliable AML data sources with 3 million PEP profiles & hundreds of Sanction lists covered (OFAC, UN, HMT, EU)."
        canonical="/product/aml-pep-sanction-checks"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />
      <IndexIllustrationContainer>

        <HeaderAnimation

          forground={headerForground}
          background={headerBackground} />

      </IndexIllustrationContainer>

      <Section>
        <Sectionheader primary>
          <H1>
            AML Screening <br />
            with global coverage
          </H1>
          <SectionDescription1>
            Use industry-leading PEP & Sanctions Screening to identify risky customers, minimize financial fraud risks, and comply with regulation.
          </SectionDescription1>
        </Sectionheader>

        <Checks
          margin="355px 0 40px"
          marginMobile="350px 0 40px"
          features={[
            "Automate the AML Screening process",
            "Extensive & accurate AML data sources",
            "Reduce manual reviews and operational costs"
          ]}
        />
        <CTAText>
          See how it will work in your site or app
        </CTAText>
        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Section>

      <Section >
        <Sectionheader primary>
          <H2>Cover all AML checks<br />
            with a single solution</H2>
          <SectionDescription1>
            Get the complete kit that enables you to perform greater customer due diligence and make better decisions
          </SectionDescription1>
        </Sectionheader>

        <GridElements
          gridTemlplateCol="1fr 1fr 1fr"
          columnGap='60px'
          data={[
            {
              image: <Pep />,
              title: 'PEPs',
              text: 'Get real-time updates on Politically Exposed Persons and their close associates (RCAs). We cover over 3 million complete PEP profiles.'
            },
            {
              image: <Sanction />,
              title: 'Sanctions Lists',
              text: 'Identify customers associated with global and local sanctions. We cover hundreds of lists including OFAC, UN, HMT, EU, DFAT and others.'
            },
            {
              image: <Id />,
              title: 'Identity Verification',
              text: 'Combine our powerful ID verification range of solutions with AML Screening to fulfill regulatory requirements.'
            }

          ]} />
      </Section>

      <Section >
        <Sectionheader primary>
          <H2>The leading data source for <br /> PEP & Sanctions Screening</H2>
          <SectionDescription1>
            Get access to the most extensive and reliable AML data sources, automate customer onboarding, and keep regulators satisfied.
          </SectionDescription1>
        </Sectionheader>

        <GridElements
          justify="space-between"
          headerStyle={{ fontSize: "18px", textAlign: "center", lineHeight: "unset" }}
          gridTemlplateCol="140px 140px 140px 140px"
          width='80%'
          featureStyle={{ alignItems: 'center' }}
          data={[
            {
              image: <Million />,
              title: 'Consolidated risk profiles',
            },
            {
              image: <Glob />,
              title: 'True global coverage',
            },
            {
              image: <TenK />,
              title: 'Data sources monitored',
            },
            {
              image: <Customize />,
              title: 'Customizable parameters',
            },

          ]} />

        <LeadsContainer>
          <Lead>
            <LeadsImage>
              <WhiteCheck />
            </LeadsImage>
            <Content>
              <H4>Reliable data</H4>
              <p>Dow Jones, industry top-preforming risk database, is our main source of AML Data for PEP & Sanctions screening to ensure the most accurate, structured, and constantly updated data. </p>
            </Content>
          </Lead>
          <Lead>
            <LeadsImage>
              <WhiteCheck />
            </LeadsImage>
            <Content>
              <H4>Additional data sources</H4>
              <p>Checkin.com lets you access additional AML data through other external data sources to fit your exact needs. For a full list of available sources, reach out to your partner manager or sales rep.</p>
            </Content>
          </Lead>
          <Lead>
            <LeadsImage>
              <WhiteCheck />
            </LeadsImage>
            <Content>
              <H4>Ensure compliance</H4>
              <p>All of the above enables us to provide your business with a comprehensive set of solutions that will help you identify risky customers and adhere to AML regulations in different jurisdictions.</p>
            </Content>
          </Lead>
        </LeadsContainer>
      </Section>

      <Section>
        <Sectionheader primary>
          <H2>Easy and fully <br />
            flexible integration</H2>
          <SectionDescription1>
            Our integration supports direct connection to any endpoint. You can use AML/PEP Screening as a standalone or as a part of our end-to-end flows for customer onboarding.
          </SectionDescription1>
        </Sectionheader>

        <Animation ref={desktopAnimation} />

        <GridElements
          gridTemlplateCol="1fr 1fr"
          width='80%'
          columnGap='212px'
          rowGap='60px'
          data={[
            {
              image: <Star />,
              title: 'Flexible integration',
              text: 'Integrate through API or SDK to any API, endpoint or platform you already use today. Our team will guide you through the entire process.'
            },
            {
              image: <Easy />,
              title: 'Easy-to-use dashboard',
              text: 'Navigate the dashboard to observe the incoming requests and their statuses, types of used checks. Advanced filtering comes handy for finding the user customer data.'
            },
            {
              image: <Workflow />,
              title: 'Adjust your workflow',
              text: 'The backoffice enables full flexibility for your team; set preferrable verification rules for all types of checks, document quality, fraud assessment, quality and validity of extracted data, AML/PEP Screening, and more.'
            },
            {
              image: <Secure />,
              title: 'Secure Data storage',
              text: 'Personal Data is stored on Database servers in the EU situated in AWS or Google Cloud, compliant with ISO 27001 and GDPR. All personal data is heavily encrypted and can only be accessed by an authorized person.'
            },

          ]} />


      </Section>

      <Section>
        <GetStartedBottom
          header="Get access to the optimal 
          solution for AML checks"
        />

      </Section>

    </Container >
  )
}
