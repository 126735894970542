import React, { useRef, useEffect, useState, useContext } from "react"
import loadable from '@loadable/component'

import Grid from "../../components/face/grid-face.js"
import MiddleText from "../../components/common/middle-text.js"
import Header from '../../components/face/header-animation.js'
import SEO from "../../components/seo.js"

const Platform = loadable(() => import('../../components/face/module.js'))
const Module = loadable(() => import('../../components/face/platform.js'))
const Biometric = loadable(() => import('../../components/face/biometric.js'))
const Videos = loadable(() => import('../../components/face/videos.js'))
const Backoffice = loadable(() => import('../../components/face/backoffice.js'))
const Verify = loadable(() => import('../../components/face/verify.js'))
const FAQ = loadable(() => import('../../components/face/faq.js'))


export default function IdScanner({ location }) {
    const [isMobileOnly, setIsMobileOnly] = useState(false)

    return (
        <>
            <SEO
                title="AI-Powered Face Matching & Face Recognition Platform | Accurate & Secure"
                description="Discover our AI-driven face matching and face recognition platform, designed to detect faces, estimate age, and analyze facial similarity without storing images. Enhance security and accuracy in identification with advanced facial analysis technology."
                canonical="/face-recognition"
                ogImage={require("/src/assets/images/og-startpage.jpg").default}
            />

            <Grid >
                <MiddleText
                    h1
                    primary
                    gridArea="header"
                    className={"middle"}
                    headerText="Compare faces, estimate age, and analyze similarity"
                    subheaderText="Detect, analyze, and compare human faces without storing any images"
                />

                <Header />
                <MiddleText
                    gridArea="platform-header"
                    headerText="Face recognition platform"
                    subheaderText={"AI driven detection of user's face, trained to compare faces, determine age, and analyze similarity to other faces based on biometric facial vectors"}
                />
                <Platform />

                <MiddleText
                    gridArea="videos-header"
                    headerText="Fast, adaptive, and precise checks"
                    subheaderText={"Customised verification rules let you decide the thresholds accepted for your use case and automatically change the status of the application"}
                />
                <Videos />


                <MiddleText
                    gridArea="backoffice-header"
                    headerText="See results in your backoffice"
                    subheaderText={"Extra layer of security by face comparison with a document, highlight age difference and finding duplicated applications "}
                />
                <Backoffice />

                <MiddleText
                    gridArea="module-header"
                    headerText="Face recognition module"
                    subheaderText={"AI based detection of user's face, trained to compare faces, determine age, and analyze similarity to other faces based on biometric facial vectors"}
                />
                <Module />
                <MiddleText
                    gridArea="biometric-header"
                    headerText="Complete biometric verification"
                    subheaderText={"Botlens is using behavioral data from millions of sessions and continuously learning to identify patterns unique to humans"}
                />
                <Biometric gridArea="biometric" />
                <Verify />
                <FAQ />

            </Grid >

        </>
    )
}
