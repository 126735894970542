import React, { useState, useCallback, useEffect } from "react"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"

import RangeSlider from "./range-slider"
import ResultsTable from "./results-table"
import FinalResults from "./final-results"

import getUrlParam from "../../utils/get-url-param"

const CustomToolTip = styled(ReactTooltip)`
  font-size: 12px !important;
  background: #000 !important;
  opacity: 1 !important;
  border-radius: 2px !important;
  padding: 5px 6px !important;
`

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 980px) {
    padding: 30px 0 100px;
  }

  @media screen and (max-width: 640px) {
    padding: 15px 0 100px;
  }
`

const SlidersContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1141px;
  height: 168px;
  margin-top: 60px;

  @media screen and (max-width: 1024px) {
    width: 708px;
    height: 115px;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    width: 350px;
    height: 216px;
    margin-top: 36px;
    padding: 0 10px;
    box-sizing: border-box;
  }
`

export default function Calculator() {
  const [monthlySignups, setMonthlySignups] = useState(4000)
  const [lifetimeValue, setLifetimeValue] = useState(400)
  const [finalResults, setFinalResults] = useState({})
  const [tableValues, setTableValues] = useState([
    {
      uplift: 5,
      additionalSingups: 0,
      aggregatedUplift: 0,
      selectorTooltip: "Below average uplift when switching to Regily",
    },
    {
      uplift: 10,
      additionalSingups: 0,
      aggregatedUplift: 0,
      selectorTooltip: "Below average uplift when switching to Regily",
    },
    {
      uplift: 15,
      additionalSingups: 0,
      aggregatedUplift: 0,
      selectorTooltip: "Average uplift when switching to Regily",
    },
    {
      uplift: 20,
      additionalSingups: 0,
      aggregatedUplift: 0,
      selectorTooltip: "Above average uplift when switching to Regily",
    },
    {
      uplift: 25,
      additionalSingups: 0,
      aggregatedUplift: 0,
      selectorTooltip: "Above average uplift when switching to Regily",
    },
  ])

  useEffect(() => {
    if (typeof window === "undefined") return
    setTimeout(() => {
      setTableValues(v =>
        v.map(value => ({
          ...value,
          additionalSingups: (monthlySignups * value.uplift) / 100,
          aggregatedUplift:
            ((monthlySignups * value.uplift) / 100) * lifetimeValue,
        }))
      )
    }, 0)
  }, [monthlySignups, lifetimeValue])

  const onMonthlySignupsChange = useCallback(value => {
    setMonthlySignups(value)
  }, [])

  const onLifetimeValueChange = useCallback(value => {
    setLifetimeValue(value)
  }, [])

  const onFinalResultsChange = useCallback(value => {
    setFinalResults(value)
  }, [])

  if (typeof window === "undefined") return null

  return (
    <Container>
      {typeof window !== "undefined" && <CustomToolTip />}
      <SlidersContainer>
        <RangeSlider
          title="Number of monthly sign-ups"
          value={Number(getUrlParam("signups")) || 4000}
          tooltipSuffix=" sign-ups per month"
          onChange={onMonthlySignupsChange}
          values={[
            100,
            200,
            300,
            400,
            500,
            600,
            800,
            1000,
            1500,
            2000,
            3000,
            4000,
            5000,
            6000,
            8000,
            10000,
            15000,
            20000,
            30000,
            40000,
            50000,
            60000,
            80000,
            100000,
          ]}
        />

        <RangeSlider
          title="Customer Lifetime Value"
          value={Number(getUrlParam("ltv")) || 400}
          tooltipPrefix="€"
          tooltipSuffix=" per sign-up"
          onChange={onLifetimeValueChange}
          values={[
            25,
            50,
            75,
            100,
            150,
            200,
            250,
            300,
            400,
            500,
            600,
            800,
            1000,
            1500,
            2000,
            3000,
            5000,
            10000,
          ]}
        />
      </SlidersContainer>

      <FinalResults results={finalResults} lifetimeValue={lifetimeValue} />

      <ResultsTable
        initialSelectedRow={2}
        values={tableValues}
        onChange={onFinalResultsChange}
      />
    </Container>
  )
}
