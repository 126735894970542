import React from "react"
import styled from "styled-components"

const Container = styled.div`
  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
    flex: 50%;
    max-width: 272px;
    margin: 0 20px 40px;
  }
`

const IllustrationBox = styled.div`
  width: 90px;
  height: 90px;
  background: url(${props => props.illustration}) no-repeat bottom left;

  @media screen and (max-width: 640px) {
    background: url(${props => props.illustration}) no-repeat bottom center;
  }
`

const Title = styled.h3`
  font-size: 24px;
  margin: 20px 0 15px;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const Text = styled.p`
  font-size: 16px;
  line-height: 28px;
  white-space: pre-line;

  @media screen and (max-width: 980px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const BigFeature = ({ className, title, text, illustration }) => {
  return (
    <Container className={className}>
      <IllustrationBox illustration={illustration} />
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Container>
  )
}

export default BigFeature
