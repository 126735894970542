import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import LogoFooter from "../assets/images/regily-white-logo.svg"
import Location from "../assets/images/location.inline.svg"
import Message from "../assets/images/message.inline.svg"
import Sms from "../assets/images/sms.inline.svg"
import Stock from "../assets/images/activity.inline.svg"
import FooterLogo from "../assets/images/footer_logo.svg"
import MenuData from "../utils/menu-footer.js"
import Outsider from "../assets/images/outside_link_white.inline.svg"


import GlobalContext from "../context/global-context"

const Logo = styled(Link)`
  width: 230px;
  height: 28px;
  text-indent: -9999px;
  background: url(${LogoFooter}) no-repeat center/contain;
`

const LayoutGrid = styled.div`
  display: grid;
  flex: 1;
  padding-top: 40px;
  padding-bottom: 90px;
  grid-template-columns: repeat(12, 68px);
  grid-gap: 67px 30px;
  grid-auto-rows: min-content;
  align-items: start;
  min-height: 100%;
  background: #000;
  @media screen and (max-width: 980px) {
    grid-template-columns: repeat(8, 60px);
    grid-gap: 37px 20px;
    padding-top: 42px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(4, 75px);
    padding-top: 32px;
    grid-gap: 28px 16px;
    padding-bottom: 45px;
  }
  @media screen and (max-width: 375px) {
    grid-template-columns: 16px 130px 130px 16px;
  }
  grid-template-areas: 
  "Product Product Product Industries Industries Industries Resources Resources Resources Company Company Company"
  "locationSms locationSms locationSms messageStock messageStock messageStock . . . checkin checkin checkin";
  @media screen and (max-width: 980px) {
    grid-template-areas: 
    "Product Product  Product Product Industries Industries Industries Industries"
    "Resources Resources Resources Resources Company Company Company Company "
    "locationSms locationSms locationSms locationSms messageStock messageStock messageStock messageStock  "
    ". . checkin checkin checkin checkin . .";
  }
  @media screen and (max-width: 640px) {
    grid-template-areas: 
    "Product Product Product Product"
    "Industries Industries Industries Industries"
    "Resources Resources Resources Resources"
    "Company Company Company Company"
    ". . . ."
    "locationSms locationSms locationSms locationSms"
    "messageStock messageStock messageStock messageStock"
    "checkin checkin checkin checkin";
  }
  @media screen and (max-width: 375px) {
    grid-template-areas: 
    "Product Product"
    "Industries Industries Industries Industries"
    "Resources Resources Resources Resources"
    "Company Company Company Company"
    ". . . ."
    "locationSms locationSms locationSms locationSms"
    "messageStock messageStock messageStock messageStock"
    "checkin checkin checkin checkin";
  }
  .nobr { white-space: nowrap }
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: black;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1380px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const FooterBottom = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: bottom;
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`

const Address = styled.p`
  font-size: 12px;
  color: #bdbcc1;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    span {
      display: none;
    }
  }
  a {
    color: #fff;
    margin-left: 4px;
    color: #bdbcc1;
    &:first-child {
      margin-left: 0;
      margin-right: 4px;
      white-space: nowrap;
      @media screen and (max-width: 640px) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
    &:hover {
      color: #fff;
    }
  }
`

const EmailLink = styled.a`
  color: #fff;
  font-size: 12px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`
const FooterBody = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin-bottom: 50px;
  a {
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
  }
  @media screen and (max-width: 640px) {
    width: 94%;
     a {
       text-align: center;
     }
  }
  
`
const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100% ;
  grid-area: ${props => props.gridArea};
  a {
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-top: 20px;
  }
  @media screen and (max-width: 980px) {
     a {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 14px;
     }
  }
  @media screen and (max-width: 640px) {
    a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    }
  }
`
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  margin-bottom: 14px;
  color: #FFFFFF;
  @media screen and (max-width: 980px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 2px;
  }
  @media screen and (max-width: 640px) {
    font-size: 20px;
    margin-bottom: 2px;
    line-height: 28px;
  }
`

const Text = styled.p`
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: 980px) {
    line-height: 28px;
  }
`

const Contact = styled(Text)`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 30px;
  width: 70%;
  a {
    color: white;
  }
  a:hover {
    font-weight: bold;
    cursor: pointer;
  }
  @media screen and (max-width: 980px) {
    line-height: 28px;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 640px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`
const InfoSection = styled.div`
  grid-area: ${props => props.gridArea};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 640px) {
    &.last {
      margin-top: -8px;
    }
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 22px;
  color: #929292;
  a {
    text-decoration: underline;
    color: #929292;
  }
  svg {
    margin-right: 10px;
  }
  @media screen and (max-width: 640px) {
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    br {
      display: none;
    }
  }
`

const CheckinLogo = styled.div`
grid-area: ${props => props.gridArea};
margin: 0 auto;
img {
  width: 200px;
}
@media screen and (max-width: 640px) {
  margin-top: 50px;
}
`

const Outsider1 = styled(Outsider)`
position: relative;
top: 4px;
margin-left: 3px;
`
const MessageCustom = styled(Message)`
    `

const ImageContainer = styled.div`
  width : 25px;
  margin-right: 10px;
  svg{
    display: block;
    width : 25px;
  }
`
const Footer = ({ className, location }) => {
  const globalContext = useContext(GlobalContext)
  const baseUrl = "https://checkin.com/"
  const FooterArray = [
    [
      {
        title: "Product",
      },
      {
        title: "Checkin Software",
        link: baseUrl
      },
      {
        title: "ID Scanner",
        link: baseUrl + "id-scanner/"
      },
      {
        title: "Checkin Connect",
        link: baseUrl + "connect/"
      },
    ],
    [
      {
        title: "Industries",
      },

      {
        title: "iGaming",
        link: baseUrl + "industry-igaming"
      },
      {
        title: "Travel",
        link: baseUrl + "industry-travel"
      },
      {
        title: "Finance",
        link: baseUrl + "industry-fintech"
      },
    ],
    [
      {
        title: "Checkin.com",
      },
      {
        title: "About us",
        link: baseUrl + "about-us"
      },
      {
        title: "Contact",
        link: baseUrl + "about-us"
      },
      {
        title: "Blog",
        link: baseUrl + "blog"
      },
    ],
    [
      {
        title: "Resources",
      },
      {
        title: "Supported documents",
        link: baseUrl + "id-scanner/documents"
      },
      {
        title: "Documentation",
        link: "https://dev.checkin.com"
      },
      {
        title: "Privacy policy",
        link: baseUrl + "privacy-policy"
      },
    ]
  ]

  return (
    <Container>
      <LayoutGrid>

        {MenuData.map((column, index) =>
        (<FooterColumn gridArea={column.title} key={index}>
          <Title key={index} >{column.title}</Title>
          {column.items.map((el, index) => {
            return (<a key={index} to={el.link} href={el.link} target={el.outsider && '_blank'} >{el.title} {el.outsider && <Outsider1 />}</a>)
          }
          )}
        </FooterColumn>)
        )}

        <InfoSection gridArea='locationSms' >
          <Info style={{ marginBottom: '16px' }} >
            <ImageContainer>
              <Location />
            </ImageContainer>
            <div>
              <b>Checkin.com Group AB</b> <br />
              <p>Engelbrektsplan 2, 114 34<br /> Stockholm</p>
            </div>
          </Info>
          <Info >
            <ImageContainer>
              <Sms />
            </ImageContainer>
            <p>hello@checkin.com</p>
          </Info>
        </InfoSection>
        <InfoSection className="last" gridArea='messageStock'>
          <Info style={{ marginBottom: '16px' }}>
            <ImageContainer>
              <MessageCustom />
            </ImageContainer>
            <p>Our support team is ready to help you anytime. Contact us at <a href="mailto:support@checkin.com"><b>support@checkin.com</b></a>  or reach out to <span className="nobr"><b> +1 (347) 434-8955</b></span></p>
          </Info>
          <Info >
            <ImageContainer>
              <Stock />
            </ImageContainer>
            <p><b>Nasdaq</b>: CHECK</p>
          </Info>
        </InfoSection>

        <CheckinLogo style={{ gridArea: 'checkin' }} >
          <img alt="checkin" src={FooterLogo} />
        </CheckinLogo>
      </LayoutGrid>
    </Container>
  )
}

export default Footer