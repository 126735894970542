import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import simplecasinoCard from "../../assets/images/igaming/simplecasino-card.png"
import simplecasinoLogoCard from "../../assets/images/igaming/simplecasino-logocard.png"
import forzaCard from "../../assets/images/igaming/forza-card.png"
import forzaLogoCard from "../../assets/images/igaming/forza-logocard.png"
import Caret from "../../assets/images/caret.inline.svg"

const Container = styled.div`
  grid-area: case-studies;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 68px;

  @media screen and (max-width: 980px) {
    padding: 0;
  }
`

const SectionHeader = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 45px;
`

const CaseContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 120px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin-bottom: 60px;
    width: 320px;
    max-width: 320px;
  }
`

const CaseIllustrationContainer = styled(Link)`
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;

  ${props =>
    props.invert &&
    css`
      justify-content: flex-end;

      @media screen and (max-width: 980px) {
        justify-content: flex-start;
      }
    `}

  @media screen and (max-width: 980px) {
    order: 1;
    margin-bottom: 30px;
    position: relative;
    height: 175px;
    min-height: 175px;
    flex: 0;
  }
`

const CaseTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 980px) {
    order: 2;
  }
`

const SmallHeader = styled.p`
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 2px;
  color: #ffa133;
`

const Header = styled(Link)`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;

  &:hover {
    text-decoration: underline;
  }
`

const Description = styled.p`
  width:71%;
  line-height: 28px;
  font-size: 16px;
  margin-bottom: 16px;
  @media screen and (max-width: 980px) {
    width: 100%
  }
`

const ReadMore = styled(Link)`
  outline: none;
  box-shadow: none;
  background: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  align-self: flex-start;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
`

const Illustration = styled.div`
  width: 295px;
  height: 240px;
  background: url(${props => props.primaryIllustration}) no-repeat bottom/cover;
  position: relative;

  &::after {
    content: "";
    width: 270px;
    height: 175px;
    background: url(${props => props.secondaryIllustration}) no-repeat
      center/contain;
    position: absolute;
    top: 33px;
    left: calc(100% - 120px);
  }

  ${props =>
    props.invert &&
    css`
      &::after {
        left: auto;
        right: calc(100% - 120px);

        @media screen and (max-width: 980px) {
          left: calc(100% - 120px);
          right: auto;
        }
      }
    `}

  @media screen and (max-width: 980px) {
    position: absolute;
    width: 213px;
    height: 175px;
    top: 0;
    right: 30%;
    left: 0;

    &::after {
      width: 195px;
      height: 124px;
      top: 15%;
      left: calc(100% - 100px);
    }
  }
`

const CasesHolder = styled.div`
  display: contents;
  width: 100%;

  @media screen and (max-width: 980px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 640px) {
    display: contents;
  }
`

export default function CaseStudies({ version = 1 }) {
  return (
    <Container>
      <SectionHeader>Case studies</SectionHeader>
      <CasesHolder>
        <CaseContainer>
          <CaseIllustrationContainer
            to={`/case-studies/case-study-simple-casino-2/`}
          >
            <Illustration
              primaryIllustration={simplecasinoCard}
              secondaryIllustration={simplecasinoLogoCard}
            />
          </CaseIllustrationContainer>
          <CaseTextContainer>
            <SmallHeader>Localization and local knowledge</SmallHeader>
            <Header
              to={`/case-studies/case-study-simple-casino-2/`}
            >
              A localized solution for Simple
              <br />
              Casino in Japan
            </Header>
            <Description>
              Hero Gaming's brand Simple Casino planned to enter the Japanese
              market. They required a streamlined sign-up solution that was
              agile, robust, met the unique needs of their new target…
            </Description>
            <ReadMore
              to={`/case-studies/case-study-simple-casino-2/`}
            >
              Read study <Caret />
            </ReadMore>
          </CaseTextContainer>
        </CaseContainer>

        <CaseContainer>
          <CaseTextContainer>
            <SmallHeader>Multi-brand and Multi-Market support</SmallHeader>
            <Header
              to={`/case-studies/case-study-forza-football/`}
            >
              How Forza Football increased
              <br />
              conversions by 500%
            </Header>
            <Description>
              Forza Football was seeing fewer conversion rates when they decided
              to build their own in-house sign-up form. They quickly realized
              that building sign-up forms was not their core strength and knew…
            </Description>
            <ReadMore
              to={`/case-studies/case-study-forza-football/`}
            >
              Read study <Caret />
            </ReadMore>
          </CaseTextContainer>
          <CaseIllustrationContainer
            invert
            to={`/case-studies/case-study-forza-football/`}
          >
            <Illustration
              primaryIllustration={forzaCard}
              secondaryIllustration={forzaLogoCard}
              invert
            />
          </CaseIllustrationContainer>
        </CaseContainer>
      </CasesHolder>
    </Container>
  )
}
