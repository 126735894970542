import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"

import SEO from "../components/seo"
import UsersCount from "../components/beta/users-count"
import { resetBeta } from "../state/actions"

const BetaPage = () => {
  const dispatch = useDispatch()
  const isScanningDone = useSelector(
    state => state.scanning.status === "succeeded"
  )

  const onDone = () => { }

  useEffect(() => {
    return () => {
      dispatch(resetBeta())
    }
  }, [dispatch])

  useEffect(() => {
    if (!isScanningDone) return navigate("/")
  }, [isScanningDone])

  return (
    <>
      <SEO title="Get started" ogImage={require("/src/assets/images/og/og-startpage-min.png").default} />
      <UsersCount onDone={onDone} />
    </>
  )
}

export default BetaPage
