import React from "react";
import styled from "styled-components";

import Blue from "../../assets/images/blue.svg"
import Green from "../../assets/images/green.svg"
import Yellow from "../../assets/images/yellow.svg"
import BackofficeImage from "../../assets/images/face-recognition/backoffice.png"

const Container = styled.div`
    grid-area: backoffice;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 60px;

    img {
        width: 50%;
        flex: 1;
        box-sizing: border-box;
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
        margin-top: 32px;

        img {
            width: 100%;
        }
    }

    
`

const Content = styled.div`
    box-sizing: border-box;
    gap: 32px;
    display: flex;
    flex-direction: column;
    width: 490px;

    @media screen and (max-width: 980px) {
        width: 50%;
        gap: 16px;
    }
    @media screen and (max-width: 640px) {
        width: 100%;
    }
`

const Row = styled.div`
    display: flex;
    align-items: flex-start;

    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    img {
        width: 28px;
        margin-right: 16px;
    }

    @media screen and (max-width: 980px) {
        p{
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
        }
        img {
            width: 14px;
        }
    }

    @media screen and (max-width: 640px) {
        p{
            font-size: 12px;
            line-height: 20px;
        }
        img {
            width: 20px;
        }
    }
`


export default function Backoffice() {
    return (
        <Container>
            <Content>
                <Row>
                    <img src={Blue} />
                    <p>Match the document portrait with a selfie captured during the session. Once the face is detected, the selfie alone is sufficient for verification. </p>
                </Row>
                <Row>
                    <img src={Green} />
                    <p>Age can be compared to the document, ensuring the difference is not significant. If the estimated age exceeds the minimum threshold, the user can be declined.</p>
                </Row>
                <Row>
                    <img src={Yellow} />
                    <p>Advanced facial similarity search and biometric analysis across all historical records instantly finds duplicated users portraits and documents.</p>
                </Row>
            </Content>
            <img src={BackofficeImage} />
        </Container>
    )
}


