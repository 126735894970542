import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import noUiSlider from "nouislider"

import sliderHandle from "../../assets/images/calculator/slider-handle.svg"

const Container = styled.div`
  width: 488px;

  @media screen and (max-width: 1024px) {
    width: 300px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    position: relative;
  }
`

const Slider = styled.div`
  height: 8px;
  border-radius: 22.68px;
  margin-top: 55px;

  @media screen and (max-width: 1024px) {
    margin-top: 40px;
    height: 5px;
  }

  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }

  .noUi-handle {
    top: -9px;
    width: 24px;
    height: 24px;
    background: url(${sliderHandle}) no-repeat center/cover;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;

    @media screen and (max-width: 640px) {
      width: 30px;
      height: 30px;
      top: -12px;
    }

    &::after,
    &::before {
      display: none;
    }
  }

  .noUi-base {
    background: #c5c5c5;
    border-radius: 22.68px;
  }

  .noUi-connects {
    border-radius: 22.68px;

    .noUi-connect {
      background: #fbd65d;
    }
  }

  .noUi-tooltip {
    background: #000;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    padding: 6px;
    bottom: calc(120% + 2px);
    border-radius: 2px;

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(0, 0, 0, 0);
      border-top-color: #000;
      border-width: 4px;
      margin-left: -4px;
    }

    @media screen and (max-width: 1024px) {
      padding: 2px 4px;
      font-size: 8px;
    }

    @media screen and (max-width: 640px) {
      display: none;
    }
  }
`

const Title = styled.p`
  font-size: 20px;
  line-height: 24px;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }

  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`

const MobileDisplay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  align-items: flex-end;

  span:first-child {
    font-size: 20px;
    line-height: 18px;
  }

  span:last-child {
    font-size: 8px;
  }

  @media screen and (min-width: 641px) {
    display: none;
  }
`

export default function RangeSlider({
  title = "",
  tooltipPrefix = "",
  tooltipSuffix = "",
  value = 0,
  values = [],
  onChange = () => [],
}) {
  const [startIndex] = useState(values.indexOf(value) || 0)
  const [sliderValues] = useState([...values])
  const sliderRef = useRef()
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    const config = {
      start: [startIndex],
      connect: "lower",
      tooltips: [
        {
          to: index =>
            `${tooltipPrefix}${sliderValues[
              Math.round(index)
            ].toLocaleString()}${tooltipSuffix}`,
        },
      ],
      range: {
        min: [0],
        max: [sliderValues.length - 1],
      },
    }

    if (sliderRef.current.noUiSlider)
      sliderRef.current.noUiSlider.updateOptions(config, true)
    else noUiSlider.create(sliderRef.current, config)

    sliderRef.current.noUiSlider.on("update", index => {
      setInternalValue(sliderValues[Math.round(index)])
    })
  }, [startIndex, tooltipSuffix, sliderValues, tooltipPrefix])

  useEffect(() => {
    onChange(internalValue)
  }, [internalValue, onChange])

  return (
    <Container>
      <Title>{title}</Title>
      <Slider ref={sliderRef} />

      <MobileDisplay>
        <span>{`${tooltipPrefix}${internalValue.toLocaleString()}`}</span>
        <span>{tooltipSuffix}</span>
      </MobileDisplay>
    </Container>
  )
}
