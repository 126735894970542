import React, { createRef, useEffect } from "react"
import styled from "styled-components"

import HeroIllustration from "../../assets/images/fintech/hero-header.svg"
import Phones from "../../assets/images/ocr/phones.svg"
import animationData from "../../assets/images/fintech/fintech_animation.json"

import lottie from 'lottie-web/build/player/lottie_light.min.js'


const AnimationContainer = styled.div`
background: url(${HeroIllustration}) no-repeat center;
grid-area: slot-machine;
position: absolute;
top: 140px;
display: flex;
width: 100%;
height: 760px;
justify-content: center;
img {
  width: 63%;
  height: 52% !important;
  bottom: 163px;
  position: absolute;
}

@media screen and (max-width: 980px) {
  height: 825px;
  img {
    height: 52% !important;
    bottom:163px;
  }
}

@media screen and (max-width: 640px) {
  background: none;

  height: 458px;
  img {
    width: 54%;
    height: 58% !important;
    bottom: -78px;
  }
}

img {}


`

const Animation = styled.div`
  @media screen and (max-width: 640px) {

    position: relative;
    top: 176px;

  }

`
export default function SlotMachine() {


  let animationContainer = createRef();
  let animation = null;

  useEffect(() => {
    if (window.innerWidth <= 640) {
      animation = lottie.loadAnimation({
        container: animationContainer.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData

      });
    }
  }, []);

  return (
    <AnimationContainer >
      <Animation ref={animationContainer} />
      <img src={Phones} />
    </AnimationContainer>
  )
}
