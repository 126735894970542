import styled, { css } from "styled-components"

export const PageContainer = styled.div`
  display: flex;
  width: 1170px;
  box-sizing: border-box;
  padding: 135px 0 0;
  flex-direction: column;
  flex: 1;
  align-self: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 980px) {
    padding: 50px 0 0;
    width: 614px;
  }

  @media screen and (max-width: 640px) {
    width: 345px;
    br {
      display: none;
    }
  }
`

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 140px;

  &:last-child {
    margin-bottom: 80px;
  }
`

export const H1 = styled.h1`
  font-size: 48px;
  line-height: 56px;
  font-weight: 900;
  margin-bottom: 16px;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media screen and (max-width: 640px) {
    width: 320px;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
  }
`

export const H2 = styled.h2`
  font-size: 34px;
  line-height: 36px;
  font-weight: 800;
  margin-bottom: 16px;

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;

    br {
      display: none;
    }
  }
`

export const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 28px;

  a {
    color: #0070c9;
    text-decoration: underline;
  }
`

export const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`

export const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  span {
    font-weight: 700;
  }

  a {
    background: #fbd65d;
    border: 1px solid rgba(13, 13, 13, 0.1);
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.1);
    border-radius: 19px;
    padding: 8px 12px;
    margin: 0 8px;

    &:hover {
      box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 1);
    }
  }

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;

    br {
      display: none;
    }
  }

  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 60px;
  margin-bottom: 140px;

  a:first-of-type {
    transform: translateY(-160px);
  }

  ${H2} {
    margin-bottom: 40px;
    /* text-align: center; */
  }

  ${H4} {
    margin-bottom: 20px;
  }

  ${Text} {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 980px) {
    padding-left: 0;
    margin-bottom: 50px;

    ${H2} {
      font-size: 18px;
      color: #535353;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1.4px;
      text-align: left;
      margin-bottom: 32px;
    }
  }
`
