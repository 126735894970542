import React, { useRef } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import Helmet from "react-helmet"

import { pushCustomDataLayerEvent } from "../../state/actions"

import faqPlus from "../../assets/images/connect/faq-plus.svg"
import faqMinus from "../../assets/images/connect/faq-minus.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: faq;
  align-items: center;
  z-index: 1;
`

const Header = styled.h2`
  font-size: 34px;
  margin-bottom: 42px;

  @media screen and (max-width: 640px) {
    font-size: 30px;
    margin-bottom: 40px;
  }
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  position: relative;
  background: #fff;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px dashed #000;
    top: -10px;
    left: 10px;
    z-index: -1;
    box-sizing: border-box;
  }
`

const TabLabelIcon = styled.div`
  height: 40px;
  width: 40px;
  background: url(${faqPlus}) no-repeat center/100%;
`

const TabLabel = styled.label.attrs(props => ({
  onClick: e => {
    props.dispatch(
      pushCustomDataLayerEvent({
        event: "funnel_engagement",
        eventCategory: "remote",
        eventAction: "faq_click",
        eventLabel: e.target.innerText,
      })
    )
  },
}))`
  font-size: 18px;
  font-weight: 700;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #f9f9f8;
  cursor: pointer;
  border-top: 1px solid #000;

  p {
    flex: 1;
  }

  @media screen and (max-width: 980px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }
`

const Tab = styled.div`
  width: 100%;
  overflow: hidden;

  &:first-child {
    ${TabLabel} {
      border-top: none;
    }
  }
`

const TabContent = styled.div`
  box-sizing: border-box;
  max-height: 0;
  opacity: 0;
  background: #fff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: rgba(0, 0, 0, 0.1);
    border-top: 1px solid #000;
  }
`

const TabContentContainer = styled.div`
  padding: 30px;
  font-size: 16px;
  line-height: 28px;

  @media screen and (max-width: 640px) {
    padding: 20px 10px;
  }

  ul {
    margin: 30px 0 30px 30px;

    li {
      margin: 10px 0;
    }
  }

  h3 {
    font-size: 16px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const TabSelector = styled.input.attrs({
  type: "radio",
  name: "faq",
})`
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked ~ ${TabContent} {
    max-height: 100vh;
    opacity: 1;
  }

  &:checked ~ ${TabLabel} {
    background: #ddeff4;

    ${TabLabelIcon} {
      background: url(${faqMinus}) no-repeat center/100%;
    }
  }
`

const faqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is Checkin Connect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Checkin Connect is our compliant turnkey solution that enables users to sign-up to your brand directly on trusted third party websites, triggering the registration flow directly on top of the site.</p><p>It provides a secure and compliant framework that is adapted for new legislation where third parites have no control over your sign-up flow, data, and brand guidelines support.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Who can benefit from using Checkin Connect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Checkin Connect is the best solution to optimize all types of traffic conversion for companies and businesses that use Checkin:</p><ul><li>iGaming and sports betting companies</li><li>Fintech companies</li><li>Subscription-based businesses</li><li>Aviation, transport and logistics companies</li><li>Technology companies</li><li>And many more…</li></ul>",
      },
    },
    {
      "@type": "Question",
      name: "How can I start using Checkin Connect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>The set up is easy and will radically increase conversion - two aspects we know everyone likes.</p><p>Connect with us and we'll help set up everything for you!</p>",
      },
    },
    {
      "@type": "Question",
      name: "By how much does Checkin Connect sign-ups increase conversion?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>The conversion increase for partners using remote is varying depending on the vertical, brand, seasonality. Our best case to date received an increase of over 22%.</p>",
      },
    },
    {
      "@type": "Question",
      name: "Is Regily Remote and Checkin Connect the same thing?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "<p>Yes they are! And besides the name-change, we have really improved the product too. We wanted to connect (pun intended) better with the key objective of the product i.e. bringing the user closer to your brand and your brand closer to the user, be it a third party website you own or a lead generation company you work with.</p>",
      },
    },
  ],
}

const FAQ = () => {
  const dispatch = useDispatch()
  const openTab = useRef()

  const onTabClick = e => {
    if (e.target === openTab.current) {
      openTab.current = null
      e.target.checked = false
      return
    }

    openTab.current = e.target
  }

  return (
    <Container>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>
      <Header>FAQ</Header>
      <TabsContainer>
        {faqSchema.mainEntity.map((item, index) => (
          <Tab key={index}>
            <TabSelector id={`faq-${index}`} onClick={onTabClick} />
            <TabLabel htmlFor={`faq-${index}`} dispatch={dispatch}>
              <p>{item.name}</p>
              <TabLabelIcon />
            </TabLabel>
            <TabContent>
              <TabContentContainer
                dangerouslySetInnerHTML={{ __html: item.acceptedAnswer.text }}
              ></TabContentContainer>
            </TabContent>
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export default FAQ
