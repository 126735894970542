const tags = {
  product: {
    name: "Product",
    slug: "product",
    colors: {
      card: "#4BC1FF",
      tag: "#B4DEEC",
    },
  },
  insights: {
    name: "Insights",
    slug: "insight",
    colors: {
      card: "#69DDC8",
      tag: "#DCEFE1",
    },
  },
  regily: {
    name: "Regily",
    slug: "regily",
    colors: {
      card: "#FF977A",
      tag: "#FACDC7",
    },
  },
  checkin: {
    name: "Checkin",
    slug: "checkin",
    colors: {
      card: "#FF977A",
      tag: "#FF977A",
    },
  },
  culture: {
    name: "Culture",
    slug: "culture",
    colors: {
      card: "#D18ADE",
      tag: "#F77EB1",
    },
  },
  'case-study': {
    name: "case-study",
    slug: "case-study",
    colors: {
      card: "#4BC1FF",
      tag: "#DDEFF4",
    },
  },
}

export default tags
