import React, { useState } from "react"
import styled, { keyframes, css } from "styled-components"

import ArrowSquare from "../../assets/images/meeting-booker/arrow-square.inline.svg"
import Decta from "../../assets/images/fintech/decta.svg"
import Rayanair from "../../assets/images/product/rayanair.svg"
import DectaPartner from "../../assets/images/product/decta.svg"
import Borica from "../../assets/images/product/borica.svg"
import Telus from "../../assets/images/telus.svg"
import Pacaso from "../../assets/images/product/pacaso.svg"
import Playtech from "../../assets/images/product/playtech.svg"
import Etan from "../../assets/images/etan.svg"
import Klarna from "../../assets/images/klarna.svg"

const fadeInOut = keyframes`
  0%   {opacity: 1;}
  50%  {opacity: 0;}
  100%  {topacityop: 1;}
`

const H2 = styled.h2`
    font-weight: 800;
    font-size: 34px;
    line-height: 36px;
    text-align: center;
`

const MiddleText = styled.div`
    margin: 0 auto;
    padding: 0 180px;

    p{
      width: 700px;
      margin-top: 16px;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
  @media screen and (max-width: 980px) {
    padding: 0px;
   p {
    width: auto;
   }
  }
  @media screen and (max-width: 640px) {
    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  ${H2} {
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
  }
}
`

const FastGrowing = styled.div`
    grid-area:  ${props => props.gridArea};
    margin-top: 20px;
    ${H2}{
        text-align: center;
    }

`
const PartnerRowsMobile = styled.div`
display: none;

@media screen and (max-width: 640px){
  display: block;
}`

const Row = styled.div`
  align-self: center;
  display: flex;
  justify-content: space-between;

`

const PartnerContainer = styled.div`

  width: 207px;
  display: flex;
  justify-content: center;
  align-items: center;

  img.klarna{
    width: 75%;
  }

  img {
    width: 100%;
  }

  img.klarna{
    width: 75%;
  }

  @media screen and (max-width: 980px){
    width : 140px;
  }

  @media screen and (max-width: 640px){
    margin-top: 20px;
    width : 168px;
    height: 40px;
    img {
      width : 168px;
      height: 40px;
    }

    img.decta {
      width : 122px;
      height: 40px;
    }
    
  }

`

const PartnerRows = styled.div`
  margin: 45px auto; 
  margin-bottom: 100px;
  @media screen and (max-width: 640px){
    display: none;
  }

  img {
    height: auto;
  }
`

const QuoteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; 
  border: 1px solid;
  margin-bottom: 50px;
  background-color: white;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    position: relative;
    width: 90%;
  }

`
const ImageContainerLeft = styled.div`
  background-color: white;
  position: relative;
  right: 30px;
  height: 57px;

  @media screen and (max-width: 640px){
    position: absolute;
    width: 57px;
    top: 26px;
    left: -27px;
    right: unset;

  }

`
const ImageContainerRight = styled.div`
  background-color: white;
  position: relative;
  left: 30px;
  height: 57px;
  @media screen and (max-width: 640px){
      position: absolute;
      left: unset;
      right: -27px;
      top: 26px;
      width: 57px;
  }
`
const PartnerQuote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
  margin: 42px 0px;
  height: 370px;
  &.fadeInOut{
    animation: ${fadeInOut} 0.7s ease forwards;
  }

  @media screen and (max-width: 980px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    height: 200px;  
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    background: white;
    padding: 0px  21px;
    width: 100%;
  height: 300px;

  }
`
const PartnerLogoMobile = styled.div`
  display: none;

  @media screen and (max-width: 640px) {
    display: block;
    width: 150px;
    img {
      width: 100%;
    }
  }
`
const PartnerLogo = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 36px;
  position: relative;
  heigh: auto;

  @media screen and (max-width: 980px) {
    margin-right: 20px;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
  
`
const Quote = styled.div`
  width: 755px;
  font-weight: 400;
    font-size: 28px;
    line-height: 40px;
  margin-bottom: 72px;
  @media screen and (max-width: 980px) {
    width: 442px;
    font-weight: 400;
    font-size: 15.9319px;
    line-height: 23px;
  }
  @media screen and (max-width: 640px) {
    margin-bottom: 28px;
    width: auto;
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
  }
`

const Author = styled.div``
const AuthorName = styled.p`
font-weight: 700;
font-size: 20px;
line-height: 28px;

@media screen and (max-width: 980px) {
  font-weight: 700;
  font-size: 11.3799px;
  line-height: 16px;
}

`
const AuthorPost = styled.p`
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #4A4A4A;

@media screen and (max-width: 980px) {
  font-weight: 400;
  font-size: 9.10392px;
  line-height: 14px;
}
`

const Footer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;

img {
    width: 240px;
}
@media screen and (max-width: 980px) {
  img {
    width: 140px;
}
}

`
const Right = styled(ArrowSquare)`
  box-shadow: 5px 5px 0px rgb(0 0 0 / 10%);
  position: relative;

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
    left: unset;
  }
`

const Left = styled(ArrowSquare)`
  position: relative;
  transform: rotate(180deg);
  box-shadow: 5px -5px 0px rgb(0 0 0 / 10%);

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
  }

`
const SmallTitle = styled.h1`
    color: rgba(117, 117, 117, 0.70);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; 
    letter-spacing: 3.78px;
    text-transform: uppercase;
    margin-bottom:16px;

`
const FastComponent = ({ gridArea, smallTitle }) => {

  return (

    <FastGrowing gridArea={gridArea} >
      <MiddleText>
        {!smallTitle ?
          <H2>
            Adopted by industry-leaders
          </H2> :
          <SmallTitle>made for enterprises </SmallTitle>
        }

      </MiddleText>

      <PartnerRowsMobile>
        <Row>
          <PartnerContainer>
            <img width="168" height="40" alt="Rayanair" src={Rayanair} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="168" height="40" alt="Borica" src={Borica} />
          </PartnerContainer>
        </Row>

        <Row>
          <PartnerContainer>
            <img width="168" height="40" alt="Etan" src={Etan} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="168" height="40" alt="pacaso" src={Pacaso} />
          </PartnerContainer>
        </Row>

        <Row>
          <PartnerContainer>
            <img width="168" height="40" alt="decta" className="decta" src={Decta} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="168" height="40" alt="Telus" src={Telus} />
          </PartnerContainer>
        </Row>
        <Row>
          <PartnerContainer>
            <img width="168" height="40" alt="playtech" src={Playtech} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="165" height="36" alt="maxer" className="klarna" src={Klarna} />
          </PartnerContainer>
        </Row>
        <br />
        <br />
        <br />
      </PartnerRowsMobile>

      <PartnerRows>
        <Row>
          <PartnerContainer>
            <img width="207" height="49" alt="Rayanair" src={Rayanair} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="207" height="49" alt="borica" src={Borica} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="207" height="49" alt="decta" src={DectaPartner} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="207" height="49" alt="casumo" src={Telus} />
          </PartnerContainer>
        </Row>

        <br />
        <br />

        <Row>
          <PartnerContainer>
            <img width="207" height="49" alt="wh" src={Etan} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="207" height="49" alt="pacaso" src={Pacaso} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="207" height="49" alt="playtech" src={Playtech} />
          </PartnerContainer>
          <PartnerContainer>
            <img width="207" height="49" className="klarna" alt="maxer" src={Klarna} />
          </PartnerContainer>
        </Row>
      </PartnerRows>

    </FastGrowing>

  )
}

export default FastComponent