import React, { useRef, useEffect } from "react"

import HumanLike from '../../assets/images/botlens/humanLike.inline.svg'
import FaceSimilarity from '../../assets/images/botlens/faceSimilarity.inline.svg'
import animationData from "../../assets/images/botlens/Liveness.json"
import lottie from 'lottie-web/build/player/lottie_light.min.js'
import * as Styled from './explainer-style'


export default function Explainer({
    gridArea,
}) {

    let animationContainer = useRef();
    let animation = null;

    useEffect(() => {
        animation = lottie.loadAnimation({
            container: animationContainer.current, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData

        });

        return () => animation.destroy();
    }, []);
    return (
        <Styled.Container gridArea={gridArea}>

            <Styled.H4>Biometric: Liveness module</Styled.H4>

            <Styled.ExplainerBody>

                <Styled.AnimationContainer>
                    <Styled.Animation ref={animationContainer} />
                    <Styled.Head> Face and movement analysis</Styled.Head>
                    <p>Liveness check ensures that the biometric data being presented is from a live, present individual and not a static or replicated source
                    </p>
                </Styled.AnimationContainer>
                <Styled.ExplainerText>
                    <Styled.ExplainerBlock>
                        <HumanLike />
                        <Styled.Head>Human-like behaviour</Styled.Head>
                        <Styled.Notice>Are actions performed correctly? Is it modified live-stream?</Styled.Notice>
                        <p>Real-time tracking of movement, order of actions combined with irregular visual artefacts analysis
                        </p>
                    </Styled.ExplainerBlock>
                    <Styled.ExplainerBlock>

                        <FaceSimilarity />
                        <Styled.Head>Face similarity score</Styled.Head>
                        <Styled.Notice>How similar are the faces?
                            Is it the same face throughout the whole session?</Styled.Notice>
                        <p>Enables comparison of the captured face and portrait picture from the document
                        </p>
                    </Styled.ExplainerBlock>
                </Styled.ExplainerText>

            </Styled.ExplainerBody>

        </Styled.Container>

    );
}