import React from "react"
import styled, { css } from "styled-components"

import GreenCircle from "../green-circle"

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  z-index: 1;
  width: 263px;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    width: 280px;
    margin-bottom: 30px;
    ${props => props.noMargin && `
      margin-bottom: 0px;
    `};
     p{
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
     }

  }
`

const ImageComtainer = styled.div`
  width: 40px;
  height: 40px;
`

const Title = styled.h2`
  margin-top: 18px;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;

  @media screen and (max-width: 980px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 640px) {
    margin-top: 0px;
    font-size: 20px;
  }


`
const Content = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 980px) {
      margin-left: 32px;
      align-items: baseline;
    } 
    @media screen and (max-width: 640px) {

    
    }
`

export default function Checks({ feature, margin, marginMobile, gridArea, noMargin }) {

  return (

    <Feature noMargin={noMargin}>
      <ImageComtainer>
        <GreenCircle />
      </ImageComtainer>
      <Content>
        <Title>{feature.title}</Title>
        <p
          dangerouslySetInnerHTML={{ __html: feature.text }}
        />
      </Content>
    </Feature>
  )
}