import styled from "styled-components"

const Grid = styled.div`
  display: grid;
  flex: 1;
  grid-auto-rows: min-content;
  align-items: start;
  min-height: 100%;
  grid-template-columns: auto repeat(12, 64px) auto;
  grid-gap: 32px;
  row-gap: 24px;
  padding-top: 120px;
  z-index:1;


  @media screen and (max-width: 980px) {
    grid-template-columns: auto repeat(8, 68px) auto;
    grid-gap: 20px;
    overflow: hidden;
    padding-top: 50px;

  }

  @media screen and (max-width: 640px) {
    grid-template-columns: auto 160px 160px auto;
    grid-gap: 16px 24px;
    overflow: hidden;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: auto 171px 172px auto;
    grid-column-gap: 0px;
    grid-row-gap: 8px;
    overflow: hidden;
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: auto 132px 132px auto;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    overflow: hidden;
  }

`

export default Grid
