import React, { useContext } from "react"
import styled from "styled-components"

import ActionButton from "../action-button"
import GlobalContext from "../../context/global-context"


const Container = styled.div`
  width: 435px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 640px) {
    width: auto;
  }
`
const H4 = styled.h2`
font-weight: 700;
font-size: 24px;
line-height: 30px;
text-align: center;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  width: ${props => props.width};
  margin-top: 24px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin: 30px auto;

  }
`

const Cta = ({ title, ctaText, width }) => {
  const globalContext = useContext(GlobalContext)

  return (
    <Container>
      {title ? (<H4>{title}</H4>) : null}
      <TalkToSalesButton name="talktosales" width={width} onClick={() => globalContext.openGetStarted()}>
        {ctaText ? ctaText : 'Get started'}
      </TalkToSalesButton>
    </Container>
  )
}

export default Cta